// @ts-nocheck
import { isProductionServer } from "../utils/EnvUtils";

const dsn = isProductionServer
  ? "https://fe3bfc18558f4045bf2f813ac9d97f3b@sentry.io/1299571"
  : "https://e68b9080f760420fbe9a3c3cf00e9721@sentry.io/1299567";

const projectName = isProductionServer
  ? "modbox-dashboard-prod"
  : "modbox-dashboard-dev";

const SentryIOService = {
  dsn,
  projectName,
}

export default SentryIOService;
