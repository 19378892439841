// @ts-nocheck
import { Fragment, useState } from "react";
import { inject, observer } from "mobx-react";

import { withLocalized } from "../HOC";
import { GridContainer, GridItem } from "../Grid";
import { ButtonGroup, InputSelect } from "../Form";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import Translate from "../I18n/Translate";

const DuplicatePlaylistModal = inject("contractStore")(
  observer(
    ({
      contractStore,
      localizedString,
      cloneAppResourceOn,
      didCloseModal,
      isLoading,
    }) => {
      const contractsList = contractStore.contractsList.filter(
        ({ hash, resources }) =>
          hash !== contractStore.selectedContract.hash && resources.playlistSchedule
      );
      const [type, setType] = useState("single");
      const [contracts, setContracts] = useState([]);
      return (
        <Modal
          isOpen
          didCloseModal={isLoading ? null : didCloseModal}
          maxWidth="sm"
          modalTitle={
            <Translate id="Resources@chooseWhereToDuplicatePlaylistTitle">
              Escolha onde duplicar a playlist
            </Translate>
          }
          actions={
            <Fragment>
              <Button
                color="default"
                onClick={didCloseModal}
                style={{ marginRight: 16 }}
                disabled={isLoading}
              >
                <i className="fas fa-chevron-left" />{" "}
                <Translate id="Common@cancel">Cancelar</Translate>
              </Button>
              <Button
                color="primary"
                onClick={cloneAppResourceOn(contracts)}
                isLoading={isLoading}
              >
                <Translate id="Common@continue">Continuar</Translate>{" "}
                <i className="fas fa-chevron-right" />
              </Button>
            </Fragment>
          }
        >
          <GridContainer>
            <GridItem xs={12}>
              <ButtonGroup
                name="type"
                value={type}
                color="success"
                large
                small
                disabled={isLoading}
                onChange={(_, type) => setType(type)}
                options={[
                  {
                    label: localizedString({
                      id:
                        "Resources@chooseWhereToDuplicatePlaylistActualOption",
                      defaultString: "Contrato atual",
                    }),
                    value: "single",
                  },
                  {
                    label: localizedString({
                      id:
                        "Resources@chooseWhereToDuplicatePlaylistSelectOption",
                      defaultString: "Selecionar contratos",
                    }),
                    value: "multiple",
                  },
                ]}
              />
            </GridItem>
            {type === "multiple" && (
              <GridItem xs={12}>
                <InputSelect
                  withPortal
                  name="contracts"
                  value={contracts}
                  options={contractsList}
                  onChange={(_, contracts) => setContracts(contracts)}
                  valueKey="hash"
                  multiple
                  disabled={isLoading}
                />
                <Button
                  outline
                  small
                  onClick={() => setContracts([...contractsList])}
                  style={{
                    marginTop: -15,
                    padding: "4px 6px",
                  }}
                >
                  <Translate id="Metrics@selectAllLocationsButtonLabel">
                    Selecionar tudo
                  </Translate>{" "}
                  <i className="far fa-arrows-h" />
                </Button>
              </GridItem>
            )}
          </GridContainer>
        </Modal>
      );
    }
  )
);

export default withLocalized(DuplicatePlaylistModal);
