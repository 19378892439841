import { Container, FormHelperText, Input } from "@material-ui/core";
import { ChangeEvent, ReactNode, useRef, useState } from "react";
import { Button, LinkButton } from "../components/Button";
import Heading from "../components/Heading/Heading";
import LoadingView from "../components/LoadingView/LoadingView";
import PageTitle from "../components/PageTitle/PageTitle";
import ModboxAPIService from "../services/ModboxAPIService";
import { LOGO_IMAGE } from "../utils/EnvUtils";

type PlayerActivationProps = {
  modboxService: ModboxAPIService;
  searchString: string;
};

const PIN_LENGTH = 4;

const PlayerActivation = ({
  modboxService,
  searchString,
}: PlayerActivationProps) => {
  const urlPin = searchString.match(/chave=(\d{4})/)?.length
    ? searchString.match(/chave=(\d{4})/)[1]
    : "";

  const [pin, setPin] = useState(urlPin);
  const [isFetching, setIsFetching] = useState(false);
  const [activationStatus, setActivationStatus] = useState("");

  const successfulActivation = activationStatus === "active";
  const pinIsInvalid = activationStatus === "expired";

  const pinRef = useRef("");
  if (urlPin.length === PIN_LENGTH && urlPin !== pinRef.current) {
    pinRef.current = urlPin;
    submitCode(urlPin);
  }

  return (
    <Container
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        marginTop: 40,
      }}
    >
      <PageTitle>Ativar dispositivo</PageTitle>

      <div style={{ height: "fit-content" }}>
        {logo()}
        {heading()}
        {input()}
        {renderTip()}
      </div>
    </Container>
  );

  function logo() {
    return centralize(<img src={LOGO_IMAGE} alt="Logo" />);
  }

  function heading() {
    const title = successfulActivation
      ? "Dispositivo ativado com sucesso!"
      : "Ative seu dispositivo inserindo o código:";
    return <Heading title={title} textAlign="center" />;
  }

  function input() {
    return successfulActivation
      ? centralize(successMessage())
      : centralize(
          <Input
            autoFocus
            disabled={isFetching}
            id="pin"
            error={pinIsInvalid}
            inputProps={{
              autoComplete: "off",
              maxLength: 4,
              style: {
                color: pinIsInvalid ? "red" : "inherit",
                textAlign: "center",
              },
            }}
            onChange={handleChange}
            style={{
              fontSize: 60,
              width: 180,
            }}
            value={pin}
          />
        );
  }

  function renderTip() {
    return (
      <div style={{ height: 30, marginTop: 5 }}>
        {isFetching ? (
          <LoadingView height={30} />
        ) : pinIsInvalid ? (
          centralize(
            <FormHelperText id="pin-helper-text" error>
              <i className="fa fa-exclamation-triangle" /> O código informado é
              inválido
            </FormHelperText>
          )
        ) : null}
      </div>
    );
  }

  function successMessage() {
    return (
      <div style={{ display: "flex", gap: 5 }}>
        <LinkButton color="primary" to="/">
          Acessar painel administrativo
        </LinkButton>
        <Button color="primary" outline onClick={resetState}>
          Ativar outro dispositivo
        </Button>
      </div>
    );
  }

  function centralize(node: ReactNode) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {node}
      </div>
    );
  }

  function resetState() {
    setPin("");
    setActivationStatus("");
  }

  function handleChange({ target }: ChangeEvent<HTMLInputElement>) {
    const newPin = target.value.replace(/\D/g, "");
    setPin(newPin);

    if (!isFetching && newPin.length === PIN_LENGTH) {
      submitCode(newPin);
    } else {
      setActivationStatus("");
    }
  }

  async function submitCode(code: string) {
    setIsFetching(true);

    try {
      // @ts-expect-error - It is hard to type server return
      const { data, status } = await modboxService.submitActivationCode(code);
      if (status === 200) {
        setActivationStatus(data.status);
      }
    } catch (e) {
      window.debugError("Error trying to submitActivationCode", e);
    } finally {
      setIsFetching(false);
    }
  }
};

export default PlayerActivation;
