//@ts-nocheck
import { Fragment, FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";

import { GridContainer, GridItem } from "../Grid";
import Modal from "../Modal/Modal";
import Translate from "../I18n/Translate";
import { Button } from "../Button";

import ChatSubjectEditable from "./ChatSubjectEditable";

import AuthStore from "../../store/AuthStore/AuthStore";
import ContractModel from "../../Model/ContractModel";

const useStyles = makeStyles({
  email: {
    margin: 0,
    marginTop: -8,
    fontWeight: 300,
    fontSize: "0.8em",
    display: "block",
  },
  contractName: {
    marginTop: 0,
    marginBottom: 8,
    fontWeight: "bold",
  },
  listIcon: {
    minWidth: 20,
    textAlign: "center",
  },
  listItemIcon: {
    width: 26,
    marginRight: 0,
    textAlign: "center",
  },
});

interface UserInfoModalProps {
  authStore?: AuthStore;
  isOpen: boolean;
  didCloseModal: () => void;
  currentContract?: ContractModel;
  name: string;
  email: string;
  currentUrl: string;
  deviceInfo?: any;
  didRequestAccessToContract: () => void;
}

const UserInfoModal: FunctionComponent<UserInfoModalProps> = ({
  authStore,
  isOpen,
  didCloseModal,
  currentContract,
  name,
  email,
  currentUrl,
  deviceInfo,
  didRequestAccessToContract,
}) => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={isOpen}
      didCloseModal={didCloseModal}
      modalTitle={
        <Fragment>
          {name}
          <span className={classes.email}>{email}</span>
        </Fragment>
      }
    >
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <ChatSubjectEditable />
        </GridItem>
        {currentContract && (
          <GridItem xs={12} sm={6}>
            <p style={{ margin: 0 }}>Contrato</p>
            <h4 className={classes.contractName}>{currentContract.name}</h4>
            {authStore.currentUserIsSupportOperator && (
              <Button
                color="warning"
                small
                onClick={didRequestAccessToContract}
              >
                Solicitar acesso <i className="fal fa-life-ring" />
              </Button>
            )}
          </GridItem>
        )}
        {deviceInfo && (
          <GridItem xs={12} style={{ marginTop: 30 }}>
            <Divider />
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <List>
                  <ListItem divider dense>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <i className={classes.listIcon + " fas fa-link"} />
                    </ListItemIcon>
                    <ListItemText
                      primary={currentUrl}
                      secondary={
                        <Translate id="Chat@currentUrl">
                          URL atual do cliente
                        </Translate>
                      }
                    />
                  </ListItem>
                  <ListItem divider dense>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <i className={classes.listIcon + " fas fa-globe"} />
                    </ListItemIcon>
                    <ListItemText
                      primary={deviceInfo.browser}
                      secondary={
                        <Translate id="Chat@browser">Navegador</Translate>
                      }
                    />
                  </ListItem>
                  <ListItem divider dense>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <i className={classes.listIcon + " fas fa-globe"} />
                    </ListItemIcon>
                    <ListItemText
                      primary={deviceInfo.userAgent}
                      secondary="User agent"
                    />
                  </ListItem>
                </List>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <List>
                  <ListItem divider dense>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <i
                        className={classes.listIcon + " fas fa-window-maximize"}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={deviceInfo.operatingSystem}
                      secondary={
                        <Translate id="Chat@operatingSystem">
                          Sistema operacional
                        </Translate>
                      }
                    />
                  </ListItem>
                  <ListItem divider dense>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <i
                        className={classnames({
                          [classes.listIcon]: true,
                          fas: true,
                          "fa-desktop": !deviceInfo.isMobile,
                          "fa-mobile-alt": deviceInfo.isMobile,
                        })}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={deviceInfo.isMobile ? "Mobile" : "Desktop"}
                      secondary="Tipo de dispositivo"
                    />
                  </ListItem>
                  <ListItem divider dense>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <i
                        className={
                          classes.listIcon + " fas fa-expand-arrows-alt"
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={deviceInfo.screenSize}
                      secondary={
                        <Translate id="Chat@screenSize">
                          Tamanho da tela
                        </Translate>
                      }
                    />
                  </ListItem>
                </List>
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
      </GridContainer>
    </Modal>
  );
};

export default inject("authStore")(observer(UserInfoModal));
