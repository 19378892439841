// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../HOC";

import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import { InputText, InputSelect } from "../Form";
import NumberEditingWithLabelInput from "../Form/NumberEditingWithLabelInput";

import Modal from "../Modal/Modal";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import { ExpansionPanelContainer } from "../ExpansionPanel";
import Tooltip from "../Tooltip/Tooltip";

import ToolboxBar, {
  calculateToolboxHeightForActionsList,
} from "../Toolbox/ToolboxBar";

import ConfigurationForm from "./networkConfigurationForm/ConfigurationForm";
import SmartAuthCustomizationContainer from "./networkConfigurationForm/SmartAuthCustomizationContainer";

// Translate
import {
  SaveString,
  LabelHintString,
  FormErrorString,
  MinutesString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Style
const styles = {
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
};

const NETWORK_TYPE = Object.freeze({
  OPEN: "openAuth",
  SMART: "smartAuth",
  ENTERPRISE: "enterprise",
});

@withLocalized
@inject("contractStore", "networksStore", "notificationsStore", "settingsStore")
@observer
class NetworkConfigurationForm extends Component {
  constructor(props) {
    super(props);

    if (props.network) {
      this.state = this.getStateFromExistingNetwork(props.network);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    const { authRedirectToDefault, maxTimeDefault } = this.props.settingsStore;
    return {
      label: "",
      wifiName: "",
      networkType: NETWORK_TYPE.SMART,
      wifiPassword: "",
      maxTime: maxTimeDefault,
      isEditingMaxTime: false,
      authRedirect: authRedirectToDefault,
      authUrl: "",
      bandwidth: { label: "60%", value: "60" },
      proxyFilter: {
        label: this.getProxyFilterLabel("none"),
        value: "none",
      },
      wifiBanners: [],
      smsCustomMessage: "",
      denyReauth: false,
      denyCaptive: false,
      denyNetworks: false,
      onlyAllowed: false,
      isWaitingForm: false,
      formWasSubmit: false,
      didWantToDelete: false,
      modalIsOpen: false,
      isValid: {
        wifiName: false,
        wifiPassword: false,
        maxTime: true,
        authRedirect: authRedirectToDefault.length > 0,
        authUrl: false,
      },
    };
  };

  getConfigurationNetworkType = (network) => {
    if (network.category === "standard") {
      if (network.smartAuth) {
        return NETWORK_TYPE.SMART;
      }
      return NETWORK_TYPE.OPEN;
    }
    return NETWORK_TYPE.ENTERPRISE;
  };

  getStateFromExistingNetwork = (network) => {
    return {
      ...this.getDefaultState(),
      label: network.label,
      wifiName: network.wifiName,
      networkType: this.getConfigurationNetworkType(network),
      wifiPassword: network.wifiPassword,
      maxTime: network.maxTime,
      authRedirect: network.authRedirect,
      authUrl: network.authUrl,
      bandwidth: { label: `${network.bandwidth}%`, value: network.bandwidth },
      proxyFilter: {
        label: this.getProxyFilterLabel("network.proxyFilter"),
        value: network.proxyFilter,
      },
      wifiBanners: network.wifiBanners,
      smsCustomMessage: network.smsCustomMessage,
      denyReauth: network.denyReauth,
      denyCaptive: network.denyCaptive,
      denyNetworks: network.denyNetworks,
      onlyAllowed: network.onlyAllowed,
      isValid: {
        wifiName:
          network.wifiName.length >= 4 && network.wifiName.length <= 32
            ? true
            : false,
        maxTime: network.maxTime.length > 0,
        authRedirect: network.authRedirect.length > 0,
      },
    };
  };

  onChangeInputText = (event) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  onChangeMaxTime = (value) => {
    let time = parseInt(value, 10);

    if (value === "") {
      time = 0;
    } else if (time > 1440) {
      time = 1440;
    }

    this.props.settingsStore.actionChangeMaxTimeDefault(time);

    this.setState((state) => ({
      maxTime: `${time}`,
      isValid: {
        ...state.isValid,
        maxTime: time > 0 && time <= 1440,
      },
      wasEdited: true,
    }));
  };

  onChangeNetworkType = (selectedValue) => {
    this.setState({
      networkType: selectedValue,
      wasEdited: true,
    });
  };

  didUploadWifiBanner = (wifiBanner) => {
    this.setState((prevState) => ({
      wifiBanners: [...prevState.wifiBanners, wifiBanner],
      wasEdited: true,
    }));
  };

  didWantToRemoveWifiBanner = (index) => () => {
    this.setState((prevState) => {
      return {
        wifiBanners: prevState.wifiBanners.filter((_, i) => i !== index),
        wasEdited: true,
      };
    });
  };

  didChangeWifiBannersListOrder = (wifiBanners) => {
    this.setState({
      wifiBanners,
      wasEdited: true,
    });
  };

  didSelectEnterprise = () => {
    this.onChangeNetworkType(NETWORK_TYPE.ENTERPRISE);
  };
  didSelectSmartAuth = () => {
    this.onChangeNetworkType(NETWORK_TYPE.SMART);
  };
  didSelectOpenAuth = () => {
    this.onChangeNetworkType(NETWORK_TYPE.OPEN);
  };

  toggleBoolean = (key) => () => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
      wasEdited: true,
    }));
  };

  toggleIsEditingMaxTimeModal = () => {
    this.setState({
      isEditingMaxTime: true,
      modalIsOpen: true,
    });
  };

  toggleIsEditingProxyFilterModal = () => {
    this.setState({
      isEditingProxyFilter: true,
      modalIsOpen: true,
    });
  };

  didCloseModal = () => {
    this.setState(
      {
        modalIsOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isEditingMaxTime: false,
            isEditingProxyFilter: false,
          });
        }, 200);
      }
    );
  };

  onChangeSelectInput = (name, value) => {
    this.setState({
      [name]: value,
      wasEdited: true,
    });
  };

  canSubmitForm = () => {
    const { isValid, networkType } = this.state;
    if (networkType === NETWORK_TYPE.ENTERPRISE) {
      const { maxTime, authRedirect } = isValid;
      return maxTime && authRedirect;
    } else if (networkType === NETWORK_TYPE.SMART) {
      const { wifiName, maxTime, authRedirect } = isValid;
      return wifiName && maxTime && authRedirect;
    } else {
      return isValid.wifiName;
    }
  };

  getFormDataToSubmit = ({
    label,
    wifiName,
    networkType,
    wifiPassword,
    maxTime,
    authRedirect,
    authUrl,
    bandwidth,
    proxyFilter,
    denyReauth,
    denyCaptive,
    denyNetworks,
    wifiBanners,
    smsCustomMessage,
    onlyAllowed,
  }) => {
    let defaultFormData = {
      label: label.length > 0 ? label : wifiName,
      wifiName,
      wifiPassword: "",
      proxyFilter: proxyFilter.value,
      bandwidth: bandwidth.value,
      maxTime,
      authRedirect,
      denyReauth: false,
      denyCaptive,
      denyNetworks,
      wifiBanners: wifiBanners.map(($0) => $0.token),
      smsCustomMessage,
    };

    switch (networkType) {
      case NETWORK_TYPE.ENTERPRISE:
        return {
          ...defaultFormData,
          authUrl,
          denyReauth,
          onlyAllowed,
        };
      case NETWORK_TYPE.SMART:
        return {
          ...defaultFormData,
          smartAuth: true,
          denyReauth,
        };
      case NETWORK_TYPE.OPEN:
      default:
        return {
          ...defaultFormData,
          smartAuth: false,
          wifiPassword,
        };
    }
  };

  submitForm = () => {
    const { network, networksStore, settingsStore } = this.props;
    const formData = this.getFormDataToSubmit(this.state);

    settingsStore.actionChangeAuthRedirectToDefault(formData.authRedirect);

    if (this.state.networkType === NETWORK_TYPE.ENTERPRISE) {
      if (network) {
        this.submitPromise(
          networksStore.updateEnterprise({
            ...formData,
            networkToken: network.token,
          })
        );
      } else {
        this.submitPromise(networksStore.submitEnterprise(formData));
      }
    } else {
      if (network) {
        this.submitPromise(
          networksStore.update({
            ...formData,
            networkToken: network.token,
          })
        );
      } else {
        this.submitPromise(networksStore.submit(formData));
      }
    }
  };

  submitPromise = (promise) => {
    promise
      .then((response) => {
        this.submitHasSuccess({
          token: (response.data && response.data.token) || null,
        });
      })
      .catch(() => {
        this.submitHasError();
      });
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.networksStore
      .delete({
        networkToken: this.props.network.token,
      })
      .then(() => {
        this.props.didDelete();
      });
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState(
        { isWaitingForm: true, formWasSubmit: true },
        this.submitForm
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = (_) => {
    this.setState(
      {
        wasEdited: false,
      },
      () => {
        if (this.props.contractStore.selectedContract.resources.connectivity) {
          this.props.didSave();
        }
      }
    );
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  getProxyFilterLabel = (proxyFilter) => {
    const { localizedString } = this.props;
    switch (proxyFilter) {
      case "high":
        return localizedString({
          id: "Connectivity@wifiFilterHighLabel",
          defaultString: "Pornografia, Entretenimento e Redes Sociais",
        });
      case "medium":
        return localizedString({
          id: "Connectivity@wifiFilterMediumLabel",
          defaultString: "Pornografia e Entretenimento",
        });
      case "low":
        return localizedString({
          id: "Connectivity@wifiFilterLowLabel",
          defaultString: "Pornografia",
        });
      case "none":
      default:
        return localizedString({
          id: "Connectivity@wifiFilterNoneLabel",
          defaultString: "Sem filtro",
        });
    }
  };

  connectivityToolboxActions = () => {
    const {
      isActive,
      contractStore,
      localizedString,
      localizedPlural,
    } = this.props;
    const {
      networkType,
      denyReauth,
      denyCaptive,
      denyNetworks,
      maxTime,
      proxyFilter,
      onlyAllowed,
    } = this.state;

    const {
      resources: { networking },
    } = contractStore.selectedContract;

    const isActiveEnterpriseType = networkType === NETWORK_TYPE.ENTERPRISE;
    const isActiveSmartType = networkType === NETWORK_TYPE.SMART;
    const isActiveOpenType = networkType === NETWORK_TYPE.OPEN;

    const list = [
      {
        type: networking ? "hidden" : "button",
        inactiveColor: "danger",
        label: isActive
          ? localizedString({
              id: "Connectivity@isActiveLabel",
              defaultString: "Rede Wi-Fi disponível",
            })
          : localizedString({
              id: "Connectivity@isInactiveLabel",
              defaultString: "Rede Wi-Fi inativa",
            }),
        isActive: isActive,
        onClick: this.props.toggleIsActive,
        icon: "power-off",
      },
      {
        type: networking ? "hidden" : "divider",
      },
      {
        type: "button",
        label: localizedString({
          id: "Connectivity@smartAuthentication",
          defaultString: "Autenticação inteligente",
        }),
        isActive: isActiveSmartType,
        disabled: !networking && !isActive,
        onClick: this.didSelectSmartAuth,
        icon: isActiveSmartType ? "shield-check" : "shield",
      },
      {
        type: "button",
        label: localizedString({
          id: "Connectivity@openAuthButtonLabel",
          defaultString: "Autentitação padrão",
        }),
        isActive: isActiveOpenType,
        disabled: !networking && !isActive,
        onClick: this.didSelectOpenAuth,
        icon: "unlock",
      },
      {
        type: "button",
        label: "Enterprise",
        isHidden: !networking,
        isActive: isActiveEnterpriseType,
        disabled: !networking && !isActive,
        onClick: this.didSelectEnterprise,
        icon: "server",
      },
      {
        type: "divider",
      },
      {
        type: "button",
        label:
          proxyFilter.value !== "none"
            ? localizedString(
                {
                  id: "Connectivity@wifiFilterSelectedLabel",
                  defaultString: "Filtro de sites definido: $filterValue.",
                },
                { filterValue: this.getProxyFilterLabel(proxyFilter.value) }
              )
            : localizedString({
                id: "Connectivity@wifiFilterNoneIsSelectedLabel",
                defaultString: "Nenhum filtro de sites definido.",
              }),
        isActive: proxyFilter.value !== "none",
        onClick: this.toggleIsEditingProxyFilterModal,
        disabled: isActiveOpenType || (!networking && !isActive),
        icon: "ban",
      },
      {
        type: "button",
        label: localizedPlural({
          id: "Connectivity@wifiTimeButtonLabel",
          value: maxTime,
          one: "Tempo limite de conexão: um minuto",
          other: "Tempo limite de conexão: # minutos",
        }),
        isActive: isActiveSmartType || isActiveEnterpriseType,
        onClick: this.toggleIsEditingMaxTimeModal,
        disabled: isActiveOpenType || (!networking && !isActive),
        icon: "clock",
      },
      {
        type: "button",
        label: !denyReauth
          ? localizedString({
              id: "Connectivity@denyReauthIsActiveLabel",
              defaultString:
                "Permite que clientes conectem várias vezes por dia nesta rede",
            })
          : localizedString({
              id: "Connectivity@denyReauthIsInactiveLabel",
              defaultString:
                "Permite que cada cliente conecte apenas uma única vez por dia nesta rede",
            }),
        isActive: !denyReauth,
        onClick: this.toggleBoolean("denyReauth"),
        disabled: isActiveOpenType || (!networking && !isActive),
        icon: "repeat",
      },
      {
        type: "divider",
        isHidden: !networking,
      },
      {
        type: "button",
        isHidden: !networking,
        label: denyNetworks
          ? localizedString({
              id: "Connectivity@denyNetworksIsActiveLabel",
              defaultString:
                "Isola os clientes em uma rede reservada, sem comunicação com demais redes do ambiente.",
            })
          : localizedString({
              id: "Connectivity@denyNetworksIsInactiveLabel",
              defaultString:
                "Permite a comunicação com outras redes existentes.",
            }),
        isActive: denyNetworks,
        onClick: this.toggleBoolean("denyNetworks"),
        disabled: !isActiveEnterpriseType || (!networking && !isActive),
        icon: "network-wired",
      },
      {
        type: "button",
        isHidden: !networking,
        label: onlyAllowed
          ? localizedString({
              id: "Connectivity@onlyAllowedIsActiveLabel",
              defaultString:
                "Somente dispositivos permitidos através de convite conseguem acessar a internet.",
            })
          : localizedString({
              id: "Connectivity@onlyAllowedIsInactiveLabel",
              defaultString:
                "Todos os dispositivos conseguem acessar a internet.",
            }),
        isActive: onlyAllowed,
        onClick: this.toggleBoolean("onlyAllowed"),
        disabled: !isActiveEnterpriseType || (!networking && !isActive),
        icon: "user",
      },
      {
        type: "button",
        isHidden: !networking,
        label: denyCaptive
          ? localizedString({
              id: "Connectivity@denyCaptiveIsActiveLabel",
              defaultString:
                "Desabilita a autenticação dos equipamentos via captive portal.",
            })
          : localizedString({
              id: "Connectivity@denyCaptiveIsInactiveLabel",
              defaultString:
                "Autenticação tradicional através do captive portal.",
            }),
        isActive: denyCaptive,
        onClick: this.toggleBoolean("denyCaptive"),
        disabled: !isActiveEnterpriseType || (!networking && !isActive),
        icon: "window-close",
      },
      {
        type: "divider",
      },
      {
        type: networking && this.props.network ? "button" : "hidden",
        label: localizedString({
          id: "Common@delete",
          defaultString: "Apagar",
        }),
        isActive: true,
        activeColor: "danger",
        onClick: this.didWantToDelete,
        icon: "trash-alt",
      },
      {
        type: "submit",
        label: localizedString({
          id: "Common@save",
          defaultString: "Salvar",
        }),
        isActive:
          this.state.wasEdited ||
          (!networking && isActive && this.state.wasEdited),
        onClick: this.onSubmit,
        icon: "save",
        disabled:
          (!networking && !isActive && !this.state.wasEdited) ||
          !this.state.wasEdited,
      },
      {
        type: "submit",
        label: localizedString({
          id: "Common@close",
          defaultString: "Fechar",
        }),
        isHidden: !this.props.didClose,
        onClick: this.props.didClose,
        icon: "times",
      },
    ];

    return {
      list,
      height: calculateToolboxHeightForActionsList(list),
    };
  };

  renderTitle = ({ title, description }) => {
    const { classes } = this.props;
    return (
      <h3 className={classes.title}>
        {title}{" "}
        {description && (
          <Tooltip title={description}>
            <i className={`fa fa-question-circle ${classes.titleHelpIcon}`} />
          </Tooltip>
        )}
      </h3>
    );
  };

  renderConfigurationForm = (formIsDisabled) => {
    const {
      wifiName,
      networkType,
      wifiPassword,
      bandwidth,
      authRedirect,
      formWasSubmit,
      isValid,
    } = this.state;
    return (
      <ConfigurationForm
        networkType={networkType}
        formIsDisabled={formIsDisabled}
        formWasSubmit={formWasSubmit}
        isValid={isValid}
        bandwidth={bandwidth}
        wifiName={wifiName}
        wifiPassword={wifiPassword}
        authRedirect={authRedirect}
        onChangeInputText={this.onChangeInputText}
        onChangeSelectInput={this.onChangeSelectInput}
      />
    );
  };

  getPanels = (formIsDisabled) => {
    let panels = [
      {
        title: (
          <Translate id="Connectivity@configurationFormPanelTitle">
            Configurações
          </Translate>
        ),
        subtitle: (
          <Translate id="Connectivity@configurationFormPanelSubtitle">
            Nome da rede, velocidade da conexão, filtros e muito mais...
          </Translate>
        ),
        details: this.renderConfigurationForm(formIsDisabled),
      },
    ];

    if (
      !formIsDisabled &&
      [NETWORK_TYPE.ENTERPRISE, NETWORK_TYPE.SMART].includes(
        this.state.networkType
      )
    ) {
      panels.push({
        title: (
          <Translate id="Connectivity@customizationPanelTitle">
            Customização
          </Translate>
        ),
        subtitle: (
          <Translate id="Connectivity@customizationPanelSubtitle">
            Página customizada ou mensagem do SMS e carrossel de imagens da
            autenticação
          </Translate>
        ),
        details: !formIsDisabled && (
          <SmartAuthCustomizationContainer
            networkType={this.state.networkType}
            authUrl={this.state.authUrl}
            smsCustomMessage={this.state.smsCustomMessage}
            onChangeInputText={this.onChangeInputText}
            wifiBanners={this.state.wifiBanners}
            didUploadWifiBanner={this.didUploadWifiBanner}
            didWantToRemoveWifiBanner={this.didWantToRemoveWifiBanner}
            didChangeWifiBannersListOrder={this.didChangeWifiBannersListOrder}
          />
        ),
      });
    }

    return panels;
  };

  render() {
    const { contractStore, localizedString } = this.props;
    const { selectedContract } = contractStore;

    const formIsDisabled = selectedContract.resources.connectivity
      ? false
      : !this.props.isActive;

    const {
      label,
      networkType,
      maxTime,
      isEditingMaxTime,
      isEditingProxyFilter,
      proxyFilter,
      didWantToDelete,
      modalIsOpen,
    } = this.state;

    const {
      list: toolboxActionsList,
      height: toolboxHeight,
    } = this.connectivityToolboxActions();

    const isActiveEnterpriseType = networkType === NETWORK_TYPE.ENTERPRISE;
    const isActiveSmartType = networkType === NETWORK_TYPE.SMART;

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteConfiguration}
            didCancel={this.didCancelDeleteAction}
          />
        )}
        {(isEditingMaxTime || isEditingProxyFilter) && (
          <Modal isOpen={modalIsOpen} didCloseModal={this.didCloseModal}>
            <GridContainer
              justify="center"
              style={{ marginTop: 40, marginBottom: 40 }}
            >
              <GridItem xs={12} sm={11}>
                {isEditingMaxTime && (
                  <NumberEditingWithLabelInput
                    label={
                      <Translate id="Connectivity@setWifiTimeHint">
                        Tempo máximo que o cliente pode ficar conectado nesta
                        Wi-Fi:
                      </Translate>
                    }
                    typeLabel={<MinutesString />}
                    onChange={this.onChangeMaxTime}
                    value={maxTime}
                  />
                )}
                {isEditingProxyFilter && (
                  <InputSelect
                    withPortal
                    label={
                      <Translate id="Connectivity@firewallFilterLabel">
                        Os visitantes NÃO poderão acessar
                      </Translate>
                    }
                    name="proxyFilter"
                    value={proxyFilter}
                    disabled={formIsDisabled}
                    options={[
                      {
                        label: this.getProxyFilterLabel("high"),
                        value: "high",
                      },
                      {
                        label: this.getProxyFilterLabel("medium"),
                        value: "medium",
                      },
                      {
                        label: this.getProxyFilterLabel("low"),
                        value: "low",
                      },
                      {
                        label: this.getProxyFilterLabel("none"),
                        value: "none",
                      },
                    ]}
                    onChange={this.onChangeSelectInput}
                  />
                )}
              </GridItem>
              <GridItem xs={12} sm={11}>
                <Button
                  small
                  block
                  color="primary"
                  onClick={this.didCloseModal}
                >
                  <SaveString /> <i className="fas fa-save" />
                </Button>
              </GridItem>
            </GridContainer>
          </Modal>
        )}
        <div
          style={{
            position: "relative",
            padding: "16px",
            paddingLeft: 60,
            minHeight: toolboxHeight,
          }}
        >
          <ToolboxBar
            leftPosition={selectedContract.resources.connectivity ? -24 : -46}
            actions={toolboxActionsList}
          />
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  {isActiveEnterpriseType
                    ? this.renderTitle({
                        title: (
                          <Translate id="Connectivity@enterpriseNetworkTitle">
                            Enterprise
                          </Translate>
                        ),
                      })
                    : isActiveSmartType
                    ? this.renderTitle({
                        title: (
                          <Translate id="Connectivity@smartAuthentication">
                            Autenticação inteligente
                          </Translate>
                        ),
                        description: localizedString({
                          id: "Connectivity@smartAuthenticationHint",
                          defaultString:
                            "Seus clientes autenticarão na sua Wi-Fi informando dados como: nome, celular, e-mail, etc. Com isso você conhecerá melhor seus clientes e poderá utilizar estas informações em suas ações de Marketing!",
                        }),
                      })
                    : this.renderTitle({
                        title: (
                          <Translate id="Connectivity@openAuthenticationTitle">
                            Autenticação tradicional
                          </Translate>
                        ),
                        description: localizedString({
                          id: "Connectivity@passwordAuthenticationHint",
                          defaultString:
                            "Autenticação padrão utilizando uma senha que você irá definir.",
                        }),
                      })}
                  {selectedContract.resources.connectivity && (
                    <InputText
                      label={
                        <Translate id="Connectivity@titleLabel">
                          Título da configuração(opcional)
                        </Translate>
                      }
                      name="label"
                      disabled={formIsDisabled}
                      value={label}
                      onChange={this.onChangeInputText}
                      maxLength={30}
                      helpText={<LabelHintString />}
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <ExpansionPanelContainer
                    selectedIndex={0}
                    panels={this.getPanels(formIsDisabled)}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NetworkConfigurationForm);
