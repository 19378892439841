
import ReactDOM from "react-dom";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import { syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";

// Main CSS
import "./assets/scss/app-style.css";

// CSS
import "@uppy/core/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/status-bar/dist/style.min.css";

import MainStore from "./store/MainStore/MainStore";
import RoutingStore from "./store/RoutingStore/RoutingStore";
import MainServices from "./services/MainServices";

import App from "./App";

import { isProductionServer } from "./utils/EnvUtils";

declare global {
  interface Window {
    isDebugActive: boolean;
    debugLog: (message?: any, ...optionalParams: any[]) => void;
    debugError: (message?: any, ...optionalParams: any[]) => void;
  }
}

window.isDebugActive =
  window.location.href.includes("debug=true") || !isProductionServer;

window.debugLog =
  window.debugLog ||
  function(message?: any, ...optionalParams: any[]) {
    if (window.isDebugActive) {
      console.log(message, ...optionalParams);
    }
  };
window.debugError =
  window.debugError ||
  function(message?: any, ...optionalParams: any[]) {
    if (window.isDebugActive) {
      console.error(message, ...optionalParams);
    }
  };

configure({ enforceActions: isProductionServer ? "never" : "observed" });

const browserHistory = createBrowserHistory();
const routingStore = new RoutingStore();
const history = syncHistoryWithStore(browserHistory, routingStore);

const services = new MainServices();
const mainStore = new MainStore(routingStore, services);

ReactDOM.render(
  <Provider {...mainStore} {...services} history={history}>
    <App />
  </Provider>,
  document.getElementById("app-root")
);
