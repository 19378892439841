// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";

import { GridContainer } from "../../components/Grid";

import MetricChartSectionTitle from "../../components/Charts/MetricChartSectionTitle";
import MetricChartBody from "../../components/Charts/MetricChartBody";

import CameraAccessByLocationPerDaysChartWidget from "./CameraAccessByLocationPerDaysChartWidget";
import CameraAccessByLocationTotalChartWidget from "./CameraAccessByLocationTotalChartWidget";

// Translate
import Translate from "../../components/I18n/Translate";

@observer
class CameraAccessByLocationChartContainer extends Component {
  prepareChartData = (result) => {
    if (!result) return null;

    const accessByLocation = result.access_by_location;

    let highestValue = 0;
    let lowerValue = Number.MAX_SAFE_INTEGER;
    let wifiBusiestLocation = "";
    let wifiLessBusyLocation = "";

    Object.keys(accessByLocation).forEach((locationName) => {
      const locationTotal = accessByLocation[locationName].total;
      // Verify busiest location
      if (locationTotal > highestValue) {
        highestValue = locationTotal;
        wifiBusiestLocation = locationName;
      }
      // Verify less busy location
      if (locationTotal < lowerValue) {
        lowerValue = locationTotal;
        wifiLessBusyLocation = locationName;
      }
    });

    // Reset highestValue & lowerValue
    highestValue = 0;
    lowerValue = Number.MAX_SAFE_INTEGER;

    let wifiBusiestDay = "";
    let wifiLessBusyDay = "";

    const accessByLocationValues = Object.values(accessByLocation);

    // Find all day strings
    const perDaysKeys = Object.keys(accessByLocationValues[0].per_days);

    // Loop every day
    perDaysKeys.forEach((dayKeyToCompare) => {
      let countForDay = 0;

      // Loop locations to sum all days
      accessByLocationValues
        .map(($0) => $0.per_days)
        .forEach(($1) => {
          Object.keys($1).forEach((dayKey) => {
            if (dayKey === dayKeyToCompare) {
              countForDay += $1[dayKey];
            }
          });
        });

      // Verify busiest day
      if (countForDay > highestValue) {
        highestValue = countForDay;
        wifiBusiestDay = dayKeyToCompare;
      }
      // Verify less busy day
      if (countForDay > 0 && countForDay < lowerValue) {
        lowerValue = countForDay;
        wifiLessBusyDay = dayKeyToCompare;
      }
    });

    return {
      accessByLocation,
      wifiBusiestLocation,
      wifiLessBusyLocation,
      wifiBusiestDay,
      wifiLessBusyDay,
    };
  };

  render() {
    const {
      isFetching,
      error,
      chartData: result,
      locationsList,
      datePeriod,
    } = this.props;
    const chartData = this.prepareChartData(result);

    return (
      <GridContainer>
        <MetricChartSectionTitle
          locationsList={locationsList}
          datePeriod={datePeriod}
        >
          <Translate id="Connectivity@accessByLocationPerDayTitle">
            Acessos por dia
          </Translate>
        </MetricChartSectionTitle>
        <MetricChartBody isFetching={isFetching} hasError={error}>
          <CameraAccessByLocationPerDaysChartWidget chartData={chartData} />
        </MetricChartBody>
        {chartData && Object.keys(chartData).length > 0 && (
          <>
            <MetricChartSectionTitle
              locationsList={locationsList}
              datePeriod={datePeriod}
              showDivider
            >
              <Translate id="Connectivity@accessByLocationTotalTitle">
                Total de acessos no período
              </Translate>
            </MetricChartSectionTitle>
            <MetricChartBody isFetching={isFetching} hasError={error}>
              <CameraAccessByLocationTotalChartWidget chartData={chartData} />
            </MetricChartBody>
          </>
        )}
      </GridContainer>
    );
  }
}

export default CameraAccessByLocationChartContainer;
