// @ts-nocheck

import { observer } from "mobx-react";
import Select from "react-select";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../HOC";

import { Button } from "../Button";
import Wizard from "../Wizard/Wizard";
import { GridContainer, GridItem } from "../Grid";
import RadioGroup from "../Form/RadioGroup";

import DateRange from "./DateRange";
import DateRangeFilter from "./DateRangeFilter";
import TargetAudienceFilter from "./TargetAudienceFilter";
import MacaddressRuleFilter from "./MacaddressRuleFilter";
import ConnectionTimeLimitFilter from "./ConnectionTimeLimitFilter";
import ConnectionTypeFilter from "./ConnectionTypeFilter";

import SelectLocationFilter from "./SelectLocationFilter";

// Translate
import Translate from "../I18n/Translate";

// Styles
const styles = {
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.2em",
  },
  messageTextArea: {
    width: "100%",
    resize: "none",
    borderRadius: 6,
    padding: 6,
    borderColor: "#D2D1D2",
  },
  messageHelpText: {
    fontSize: "0.9em",
  },
};

const ChartWizard = ({
  classes,
  localizedString,
  isCameraType,
  color,
  savedCharts,
  isCompareChartActive,
  didWantToManageSavedCharts,
  reportTypes,
  chartType,
  locationsList,
  dateRange,
  fromDate,
  toDate,
  targetAudience,
  macaddressRule,
  connectionTimeLimit,
  connectionType,
  onChangeChartType,
  didSelectSavedChart,
  onChangeLocationsListForView,
  didWantToResetChart,
  didWantToStartNewChart,
  didWantToSaveChart,
  selectedLocationsList,
  isFetchingLocations,
  onChangeDateRange,
  onChangeFromDate,
  onChangeTargetAudience,
  onChangeMacaddressRule,
  onChangeConnectionTimeLimit,
  onChangeConnectionType,
  onChangeToDate,
  currentStep,
  disabledSteps,
  onChangeCurrentStep,
  renderReport,
  renderSavedChartView,
  singleLocation,
  allowToContinue,
  allowToExportData,
  didSelectAllLocations,
  didWantToExportCsv,
}) => {
  const isFinalStep = isCameraType ? currentStep === 3 : currentStep === 4;
  let steps = [
    {
      stepName: localizedString({
        id: "Metrics@selectChartStepTitle",
        defaultString: "Relatório",
      }),
      stepComponent: (
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <h2 className={classes.sessionTitle}>
              <Translate id="Metrics@selectChartTypeTitle">
                Escolha um tipo de relatório
              </Translate>
            </h2>
            <RadioGroup
              name="chartType"
              value={chartType}
              onChange={onChangeChartType}
              options={reportTypes.map(($0) => ({
                label: $0.title,
                value: $0.id,
              }))}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <h2 className={classes.sessionTitle}>
              <Translate id="Metrics@loadSavedChartTitle">
                Relatórios favoritos
              </Translate>
              {savedCharts && savedCharts.length > 0 && (
                <Button
                  color="primary"
                  outline
                  small
                  justIcon
                  onClick={didWantToManageSavedCharts}
                  style={{ marginLeft: 8 }}
                >
                  <i className="fas fa-pen" />
                </Button>
              )}
            </h2>
            {savedCharts && savedCharts.length > 0 ? (
              <Select
                isSearchable={false}
                value={null}
                options={savedCharts.map((savedChart) => ({
                  value: savedChart.id,
                  ...savedChart,
                }))}
                getOptionLabel={renderSavedChartView}
                noOptionsMessage={() =>
                  localizedString({
                    id: "Metrics@noSavedChartFound",
                    defaultString: "Nada encontrado",
                  })
                }
                onChange={didSelectSavedChart}
              />
            ) : (
              <p>
                <Translate id="Metrics@noSavedChartsFoundMessage">
                  Você não salvou nenhum relatório até o momento.
                </Translate>
              </p>
            )}
          </GridItem>
        </GridContainer>
      ),
    },
    {
      stepName: localizedString({
        id: "Metrics@selectLocationStepTitle",
        defaultString: "Localidade",
      }),
      stepComponent: (
        <GridContainer>
          <GridItem xs={12}>
            <h2 className={classes.sessionTitle}>
              {singleLocation ? (
                <Translate id="Metrics@selectLocationTitle">
                  Selecione uma localidade
                </Translate>
              ) : (
                <Translate id="Metrics@selectLocationsTitle">
                  Selecione uma ou mais localidades
                </Translate>
              )}
            </h2>
            <SelectLocationFilter
              locationsList={locationsList}
              getSelectedLocations={selectedLocationsList}
              isFetchingLocations={isFetchingLocations}
              onChange={onChangeLocationsListForView}
              allowMultipleLocations={!singleLocation}
            />
            {!singleLocation && (
              <Button
                outline
                small
                onClick={didSelectAllLocations}
                style={{
                  opacity:
                    !selectedLocationsList ||
                    selectedLocationsList.length < locationsList.length
                      ? 1
                      : 0,
                }}
              >
                <Translate id="Metrics@selectAllLocationsButtonLabel">
                  Selecionar tudo
                </Translate>{" "}
                <i className="far fa-arrows-h" />
              </Button>
            )}
          </GridItem>
        </GridContainer>
      ),
    },
    {
      stepName: localizedString({
        id: "Metrics@dateRangeStepTitle",
        defaultString: "Período",
      }),
      stepComponent: (
        <GridContainer>
          <GridItem xs={12}>
            <h2 className={classes.sessionTitle}>
              <Translate id="Metrics@dateRangeTitle">
                Escolha o período
              </Translate>
            </h2>
            <DateRangeFilter
              dateRange={dateRange}
              fromDate={fromDate}
              toDate={toDate}
              onChangeDateRange={onChangeDateRange}
              onChangeFromDate={onChangeFromDate}
              onChangeToDate={onChangeToDate}
            />
          </GridItem>
        </GridContainer>
      ),
    },
    {
      stepName: localizedString({
        id: "Metrics@showReportStepTitle",
        defaultString: "Visualizar",
      }),
      stepComponent: renderReport,
    },
  ];

  if (!isCameraType) {
    steps.splice(steps.length - 1, 0, {
      stepName: localizedString({
        id: "Metrics@otherParamsStepTitle",
        defaultString: "Parâmetros",
      }),
      stepComponent: (
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <h2 className={classes.sessionTitle}>
              <Translate id="Metrics@connectionTypeTitle">
                Tipo de conexão
              </Translate>
            </h2>
            <ConnectionTypeFilter
              connectionType={connectionType}
              onChange={onChangeConnectionType}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <h2 className={classes.sessionTitle}>
              <Translate id="Metrics@connectionTimeLimitTitle">
                Tempo de permanência
              </Translate>
            </h2>
            <ConnectionTimeLimitFilter
              connectionTimeLimit={connectionTimeLimit}
              onChange={onChangeConnectionTimeLimit}
            />
          </GridItem>
          {connectionType === 0 && (
            <GridItem xs={12} sm={6}>
              <h2 className={classes.sessionTitle}>
                <Translate id="Metrics@targetAudienceTitle">Público</Translate>
              </h2>
              <TargetAudienceFilter
                targetAudience={targetAudience}
                onChange={onChangeTargetAudience}
              />
            </GridItem>
          )}
          {connectionType === 0 && (
            <GridItem xs={12} sm={6}>
              <h2 className={classes.sessionTitle}>
                <Translate id="Metrics@macaddressRuleTitle">
                  Filtrar endereços MAC
                </Translate>
              </h2>
              <MacaddressRuleFilter
                macaddressRule={macaddressRule}
                onChange={onChangeMacaddressRule}
              />
            </GridItem>
          )}
        </GridContainer>
      ),
    });
  }

  return (
    <Wizard
      color={color}
      allowToContinue={allowToContinue}
      resetOnFinish
      removeNavMargin
      currentStep={currentStep}
      onChangeCurrentStep={onChangeCurrentStep}
      disabledSteps={disabledSteps}
      previousButtonLabel={
        <span>
          <i className="fas fa-chevron-left" />{" "}
          <Translate id="Common@back">Voltar</Translate>
        </span>
      }
      nextButtonLabel={
        <span>
          <Translate id="Common@goForward">Avançar</Translate>{" "}
          {allowToContinue ? (
            <i className="fas fa-chevron-right" />
          ) : (
            <i className="fas fa-ban" />
          )}
        </span>
      }
      actionButtons={
        isFinalStep && (
          <GridContainer>
            {allowToExportData && (
              <GridItem xs={12} sm>
                <Button color="default" block onClick={didWantToExportCsv}>
                  <Translate id="Common@export">Exportar</Translate>{" "}
                  <i className="fas fa-file-export" />
                </Button>
              </GridItem>
            )}
            <GridItem xs={12} sm>
              <Button
                color="success"
                block
                onClick={didWantToSaveChart}
                disabled={dateRange === DateRange.custom}
              >
                <Translate id="Metrics@saveChartViewButtonLabel">
                  Salvar como favorito
                </Translate>{" "}
                <i className="fas fa-star" />
              </Button>
            </GridItem>
            <GridItem xs={12} sm>
              {isCompareChartActive ? (
                <Button color="warning" block onClick={didWantToResetChart}>
                  <Translate id="Metrics@resetChartViewButtonLabel">
                    Resetar relatório atual
                  </Translate>{" "}
                  <i className="fas fa-undo-alt" />
                </Button>
              ) : (
                <Button color="primary" block onClick={didWantToStartNewChart}>
                  <Translate id="Metrics@startNewChartViewButtonLabel">
                    Novo relatório
                  </Translate>{" "}
                  <i className="fas fa-chart-pie" />
                </Button>
              )}
            </GridItem>
          </GridContainer>
        )
      }
      steps={steps}
    />
  );
};

ChartWizard.defaultProps = {
  isCameraType: false,
};

export default withStyles(styles)(withLocalized(observer(ChartWizard)));
