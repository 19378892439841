// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import { Button } from "../components/Button";
import FloatingChatButton from "../components/Chat/FloatingChatButton";

import YouTubeVideo from "../components/Video/YouTubeVideo";
import { LoaderRing } from "../components/Animations";

// Utils
import { isSimplyasServer } from "../utils/EnvUtils";
import { settingsRef } from "../store/FirebaseStore/FirebaseStore";

// Translate
import Translate from "../components/I18n/Translate";

// Style
const styles = {
  downloadItem: {
    fontSize: "1.4em",
    marginLeft: 16,
    marginBottom: 16,
  },
};

@inject("i18nStore", "contractStore")
@observer
class InstallSystemLayout extends Component {
  state = { pageVideo: null };

  componentDidMount() {
    if (isSimplyasServer) {
      settingsRef
        .child(
          `affiliateVideos/installSystemPage/${this.props.i18nStore.currentLanguage}`
        )
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            this.setState({ pageVideo: snapshot.val() });
          }
        });
    }
  }

  backToWelcomeStep = () => {
    this.props.contractStore.setStatusForDidFinishedWelcomeStep(false);
  };
  goToActivateDeviceStep = () => {
    this.props.contractStore.setStatusForDidFinishedInstallSystemStep(true);
  };
  render() {
    const { classes, contractStore, i18nStore } = this.props;

    if (!contractStore.contractsList) {
      return <LoaderRing height={100} />;
    }

    const pageVideo = this.state.pageVideo;

    const installationGuideDownloadUrl =
      i18nStore.currentLanguage === "pt"
        ? "https://simplyas.com/modbox/wp-content/uploads/2019/01/manual-instalacao-modbox.pdf"
        : "https://simplyas.com/modbox/wp-content/uploads/2019/01/installation-guide-modbox.pdf";

    return (
      <Fragment>
        <PageTitle id="InstallSystem@installSystemStepTitle">
          Gravação do sistema
        </PageTitle>
        <GridContainer
          justify="center"
          direction="row"
          style={{ height: "100vh" }}
        >
          <GridItem xs={11}>
            <Fade>
              <SimpleCard>
                <GridContainer justify="center">
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    <h1>
                      <Translate id="InstallSystem@installSystemStepTitle">
                        Gravação do sistema
                      </Translate>
                    </h1>
                  </GridItem>
                  <GridItem xs={12}>
                    {pageVideo && (
                      <YouTubeVideo
                        videoId={pageVideo}
                        autoplay
                        disableKeyboard
                        width={"100%"}
                        height={500}
                        showControls
                      />
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} style={{ marginBottom: 20 }}>
                    <h3>Links úteis:</h3>
                    {isSimplyasServer && (
                      <p className={classes.downloadItem}>
                        <i className="fal fa-hand-point-right" />{" "}
                        <a
                          href={installationGuideDownloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Translate id="InstallSystem@downloadInstallationManualLinkLabel">
                            Download do manual de instalação
                          </Translate>
                        </a>
                      </p>
                    )}
                    <p className={classes.downloadItem}>
                      <i className="fal fa-hand-point-right" />{" "}
                      <a
                        href="https://modbox.app/files/latest-modbox.zip"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Translate id="InstallSystem@downloadModboxImageLinkLabel">
                          Download da imagem do instalador
                        </Translate>
                      </a>
                    </p>
                    <p className={classes.downloadItem}>
                      <i className="fal fa-hand-point-right" />{" "}
                      <a
                        href="https://www.balena.io/etcher/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Translate id="InstallSystem@downloadEtcherLinkLabel">
                          Download do app Balena Etcher
                        </Translate>
                      </a>
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={4}>
                    <Button block large onClick={this.backToWelcomeStep}>
                      <i className="fas fa-chevron-left" />{" "}
                      <Translate id="InstallSystem@backButtonLabel">
                        Voltar
                      </Translate>
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={8} style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      block
                      large
                      onClick={this.goToActivateDeviceStep}
                    >
                      <Translate id="InstallSystem@continueButtonLabel">
                        Continuar
                      </Translate>{" "}
                      <i className="fas fa-chevron-right" />
                    </Button>
                  </GridItem>
                </GridContainer>
              </SimpleCard>
            </Fade>
          </GridItem>
        </GridContainer>
        <FloatingChatButton />
      </Fragment>
    );
  }
}

InstallSystemLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InstallSystemLayout);
