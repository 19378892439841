// @ts-nocheck
import { Component, isValidElement } from "react";
import { inject } from "mobx-react";

import Translate, { textAndComponentsToTranslators } from "./Translate";

@inject("i18nStore")
class Plural extends Component {
  transformElementToTranslate = (elementValue) => {
    const { id, value, variables } = this.props;
    return (
      <Translate id={id} isPlural pluralValue={value} variables={variables}>
        {elementValue}
      </Translate>
    );
  };

  getElementValue = (element) => {
    return isValidElement(element) ? element.props.children : element;
  };

  render() {
    const { i18nStore, id, value, zero, one, other } = this.props;

    const zeroValue = this.getElementValue(zero);
    const oneValue = this.getElementValue(one);
    const otherValue = this.getElementValue(other);

    const { text: zeroText } = textAndComponentsToTranslators(zeroValue);
    const { text: oneText } = textAndComponentsToTranslators(oneValue);
    const { text: otherText } = textAndComponentsToTranslators(otherValue);

    const textObjectToTranslators = {
      key: id,
      pt: {
        zero: zeroText,
        one: oneText,
        other: otherText,
      },
      en: {
        zero: "",
        one: "",
        other: "",
      },
      isPlural: true,
    };
    i18nStore.saveIfNotExists(textObjectToTranslators);

    if (value === 0) return this.transformElementToTranslate(zeroValue);
    if (value === 1) return this.transformElementToTranslate(oneValue);
    return this.transformElementToTranslate(otherValue);
  }
}
export default Plural;
