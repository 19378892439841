import { inject, observer } from "mobx-react";
import { FC } from "react";

import I18nStore from "../../store/I18nStore/I18nStore";

import { stringDateFromUnixDate } from "../../utils/DateUtils";

interface IDisplayDateProps {
  i18nStore?: I18nStore;
  date: string | number;
}

const DisplayDate: FC<IDisplayDateProps> = ({ i18nStore, date }) => {
  return (
    <span>
      {stringDateFromUnixDate(date, i18nStore.dateFormatWithFourDigitsYear)}
    </span>
  );
};

export default inject("i18nStore")(observer(DisplayDate));
