// @ts-nocheck

import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@material-ui/core";

import Tooltip from "../../components/Tooltip/Tooltip";
import Maps from "../../components/Maps/Maps";

// Translate
import Translate from "../../components/I18n/Translate";
import Plural from "../../components/I18n/Plural";

// Icons
import { WifiIcon, TVIcon } from "../../components/Icon";

// UTILS
import { IMAGE_PATH } from "../../utils/EnvUtils";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = (theme) => ({
  card: {
    width: "100%",
    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
    color: "#fff",
  },
  cardHeaderTitle: {
    fontSize: "1.2em",
    fontWeight: 500,
    color: "#333",
  },
  cardHeaderSubtitle: {
    fontSize: "0.9em",
    marginTop: -2,
  },
  cardContent: {
    padding: 0,
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
    margin: "0 auto",
    height: 140,
    filter: "grayscale(100%)",
  },
  actions: {
    display: "flex",
    padding: 4,
    justifyContent: "space-around",
  },
  iconButton: {
    width: 30,
    height: 30,
    fontSize: "1.2em",
    color: "#efefef",
  },
  iconButtonActive: {
    color: `${primaryColor}!important`,
  },
  iconButtonDefaultLocation: {
    color: "#feca57!important",
  },
});

const LocationItemContainer = ({
  classes,
  location,
  didWantToOpenLocationModal,
}) => (
  <Card
    className={classes.card}
    onClick={() => didWantToOpenLocationModal(location.token)}
  >
    <CardHeader
      classes={{
        title: classes.cardHeaderTitle,
        subheader: classes.cardHeaderSubtitle,
      }}
      className={classes.cardHeader}
      title={location.name}
      subheader={
        location.terminalsList.length > 0 ? (
          <Plural
            id="DevicesPage@devicesQtyLabel"
            value={location.terminalsList.length}
            one={"# dispositivo"}
            other={"# dispositivos"}
          />
        ) : (
          "-"
        )
      }
    />
    <CardContent className={classes.cardContent}>
      {location.latitude && location.longitude ? (
        <Maps
          latitude={location.latitude}
          longitude={location.longitude}
          zoom={14}
          height={134}
          disabled
          hideZoomButtons
        />
      ) : (
        <img
          className={classes.image}
          src={`${IMAGE_PATH}/default-locations-map.jpg`}
          alt="mapa"
        />
      )}
    </CardContent>
    <CardActions className={classes.actions} disableSpacing>
      {location.isDefault && (
        <Tooltip
          title={
            <Translate id="Location@defaultLocationTooltipInfo">
              Localidade padrão. Novos dispositivos serão adicionados
              automaticamente aqui.
            </Translate>
          }
        >
          <span>
            <IconButton
              disabled
              className={classnames({
                [classes.iconButton]: true,
                [classes.iconButtonDefaultLocation]: true,
              })}
            >
              <i className="fas fa-star" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <IconButton
        disabled
        className={classnames({
          [classes.iconButton]: true,
          [classes.iconButtonActive]: location.hasActivePlaylist,
        })}
      >
        <TVIcon />
      </IconButton>
      <IconButton
        disabled
        className={classnames({
          [classes.iconButton]: true,
          [classes.iconButtonActive]: location.hasActiveNetwork,
        })}
      >
        <WifiIcon />
      </IconButton>
      {/*
      <IconButton
        className={classnames({
          [classes.iconButton]: true,
          [classes.iconButtonActive]: location.hasActiveCamera
        })}
      >
        <VideoIcon />
      </IconButton>
      */}
    </CardActions>
  </Card>
);

export default withStyles(styles)(LocationItemContainer);
