import { RouterStore } from "mobx-react-router";
import { computed } from "mobx";
import queryString from "query-string";

import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";

class RoutingStore extends RouterStore {
  setStores(internalEventsStore: InternalEventsStore) {
    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.triggeredAction,
      observer: this,
      callback: (params: any) => {
        if (!params) return;

        if (params?.includes("internalUrl_")) {
          this.handleInternalUrl(params);
        } else if (params?.includes("function_")) {
          const functionAction = params.replace("function_", "");
          if (functionAction.includes("openPeople_")) {
            this.setState({
              phoneNumber: functionAction.replace("openPeople_", ""),
            });
          }
        }
      },
    });
  }

  @computed
  get searchQuery() {
    return queryString.parse(this.location.search);
  }

  @computed
  get pathname() {
    return this.location.pathname;
  }

  @computed
  get lastSubPath() {
    return this.pathname
      .substring(this.pathname.lastIndexOf("/"), this.pathname.length)
      .replace("/", "");
  }

  openPeopleModal({ current, ...rest }) {
    let state = rest;
    if (!!current) state = { ...current, ...state };

    this.setState(state);
  }

  pushSubPath = (path: string) => {
    this.push(`${this.pathname}/${path}`);
  };

  pushKeepingSearch = (path: string) => {
    this.push({
      pathname: path,
      search: this.location.search,
    });
  };

  removeSubPath = (quantity: number = 1) => {
    let url = this.pathname;
    for (let index = 0; index < quantity; index++) {
      url = url.substring(0, url.lastIndexOf("/"));
    }
    this.push(url);
  };

  pushObjectToSearchQuery = (newObject: { [key: string]: any }) => {
    this.push({
      search: queryString.stringify(newObject),
    });
  };

  setState = (newState: object) =>
    this.push({ state: newState || {}});

  clearState = () => {
    this.push({
      state: {},
    });
  };

  handleInternalUrl = (internalUrl: string) => {
    const url = internalUrl.replace("internalUrl_", "");
    this.push(url);
  };
}

export default RoutingStore;
