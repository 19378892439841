// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";

// ../../Components
import { InputText } from "../../components/Form";
import InputMap from "../../components/Form/InputMap";
import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";

import Validate, {
  ValidationType,
  verifyMacAddress,
} from "../../components/Form/Validate";

// Translate
import {
  DeleteString,
  CancelString,
  UpdateString,
  SaveString,
} from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Icon
import {
  SaveIcon,
  DeleteIcon,
  ChevronLeftIcon,
} from "../../components/Icon/MaterialIcon";
import InputRepeater from "../../components/Form/InputRepeater";

@inject("groupsStore", "notificationsStore")
class LocationForm extends Component {
  constructor(props) {
    super(props);

    if (props.location) {
      this.state = this.getStateFromExistingLocation(props.location);
    } else {
      this.state = this.getDefaultState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.updateForm();
    }
  }

  updateForm = () => {
    if (this.props.location) {
      this.setState({
        ...this.getStateFromExistingLocation(this.props.location),
      });
    } else {
      this.setState({ ...this.getDefaultState() });
    }
  };

  getDefaultState = () => {
    return {
      name: "",
      latitude: "-15.813355",
      longitude: "-47.920682",
      macAddressListToIgnoreOnMonitor: [],
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        name: false,
      },
    };
  };

  getStateFromExistingLocation = (location) => {
    return {
      ...this.getDefaultState(),
      name: location.name,
      latitude: location.latitude ? `${location.latitude}` : "-15.813355",
      longitude: location.longitude ? `${location.longitude}` : "-47.920682",
      macAddressListToIgnoreOnMonitor: location.macAddressListToIgnoreOnMonitor,
      isValid: {
        name: Validate(location.name, ValidationType.REQUIRED),
      },
    };
  };

  onChangeName = ({ target }) => {
    const { value } = target;

    this.setState({
      name: value,
      isValid: {
        ...this.state.isValid,
        name: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeLocation = (name, { latitude, longitude }) => {
    this.setState({ latitude: `${latitude}`, longitude: `${longitude}` });
  };

  onChangeMacAddress = (updatedIndex) => ({ target }) => {
    const value = target.value.replace(/[^a-zA-Z\d:]+/, "").toLowerCase();

    this.setState((prevState) => ({
      macAddressListToIgnoreOnMonitor: prevState.macAddressListToIgnoreOnMonitor.map(
        (currentValue, index) => (index === updatedIndex ? value : currentValue)
      ),
    }));
  };

  didAddMacAddress = () => {
    this.setState((prevState) => ({
      macAddressListToIgnoreOnMonitor: [
        ...prevState.macAddressListToIgnoreOnMonitor,
        "",
      ],
    }));
  };

  didRemoveMacAddress = (indexToRemove) => () => {
    this.setState((prevState) => ({
      macAddressListToIgnoreOnMonitor: prevState.macAddressListToIgnoreOnMonitor.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  canSubmitForm = () => {
    return this.state.isValid.name;
  };

  submitForm = () => {
    const formData = {
      name: this.state.name,
      latitude: parseFloat(this.state.latitude).toFixed(6),
      longitude: parseFloat(this.state.longitude).toFixed(6),
      macAddressListToIgnoreOnMonitor: this.state.macAddressListToIgnoreOnMonitor.filter(
        verifyMacAddress
      ),
    };

    if (this.props.location) {
      this.props.groupsStore
        .updateGroup({
          ...this.props.location,
          ...formData,
        })
        .then(() => {
          this.submitHasSuccess({ token: null });
        })
        .catch(() => {
          this.submitHasError();
        });
    } else {
      this.props.groupsStore
        .submitGroup(formData)
        .then((response) => {
          this.submitHasSuccess({ token: response.data.token });
        })
        .catch(() => {
          this.submitHasError();
        });
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    event.preventDefault();

    this.props.didWantToDelete();
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="Location@nameErrorMessage">
                Por favor informe o nome da localidade.
              </Translate>
            ),
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = (token) => {
    if (this.props.didSave) {
      this.props.didSave();
    }
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  render() {
    const isEditing = this.props.location !== null;
    const {
      name,
      latitude,
      longitude,
      macAddressListToIgnoreOnMonitor,
      isWaitingForm,
      formWasSubmit,
      isValid,
    } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={7}>
          <InputText
            label={
              <Translate id="Location@nameLabel">Nome da Localidade</Translate>
            }
            name="name"
            hasError={formWasSubmit && !isValid.name}
            hasSuccess={formWasSubmit && isValid.name}
            value={name}
            onChange={this.onChangeName}
          />

          <InputMap
            name="location"
            latitude={latitude}
            longitude={longitude}
            onChange={this.onChangeLocation}
          />

          <GridContainer justify="space-between">
            <GridItem xs={12} sm={4}>
              <Button block onClick={this.didCancel}>
                <ChevronLeftIcon /> <CancelString />
              </Button>
            </GridItem>
            {isEditing && !this.props.location.isDefault && (
              <GridItem xs={12} sm={4}>
                <Button color="danger" block onClick={this.didWantToDelete}>
                  <DeleteString /> <DeleteIcon />
                </Button>
              </GridItem>
            )}
            <GridItem xs={12} sm={4}>
              <Button
                color="primary"
                block
                isLoading={isWaitingForm}
                onClick={this.onSubmit}
              >
                {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={6} md={5}>
          <h4 style={{ fontWeight: 500 }}>
            Lista de de dispositivos à serem ignorados nos relatórios desta
            Localidade
          </h4>
          <InputRepeater
            items={macAddressListToIgnoreOnMonitor.map((macAddress, index) => (
              <InputText
                key={`macAddress_${index}`}
                label={
                  <Fragment>
                    <Translate id="ConnectivityDevice@macAddressTypeLabel">
                      Endereço MAC
                    </Translate>
                    {` #${index + 1}`}
                  </Fragment>
                }
                name={`macAddress_${index}`}
                value={macAddress}
                onChange={this.onChangeMacAddress(index)}
                maxLength={17}
              />
            ))}
            didAdd={this.didAddMacAddress}
            didRemove={this.didRemoveMacAddress}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default LocationForm;
