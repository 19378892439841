// @ts-nocheck
import { action, observable, computed } from "mobx";

import ConnectivityDeviceModel from "../../Model/ConnectivityDeviceModel";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

import { didSearchList } from "../../utils/Utils";

export const CONNECTIVITY_DEVICE_STATUS = Object.freeze({
  ANONYMOUS: "anonymous",
  DEFINED: "defined",
  ACTIVE: "active",
  BANNED: "banned",
  UNDEFINED: "undefined",
});

class ConnectivityDevicesStore {
  @observable dataList = [];
  @observable searchString = "";

  constructor(
    internalEventsStore,
    notificationsStore,
    groupsStore,
    modboxAPIService
  ) {
    this.notificationsStore = notificationsStore;
    this.groupsStore = groupsStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setDataList = (newValue) => {
    this.dataList = newValue;
  };

  filterWithSearchString = (list) => {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        list,
        (device, textIncludesSearchString) => {
          const matchLabel = textIncludesSearchString(device.label);
          const matchCode = textIncludesSearchString(device.code);
          const matchPhoneNumber = textIncludesSearchString(device.phoneNumber);
          const matchMacAddress = textIncludesSearchString(device.macAddress);
          let matchLocations = false;
          device.locations.forEach(($0) => {
            if (!matchLocations) {
              matchLocations = textIncludesSearchString($0.label);
            }
          });

          return (
            matchLabel ||
            matchCode ||
            matchPhoneNumber ||
            matchMacAddress ||
            matchLocations
          );
        }
      );
    }
    return list;
  };

  @computed
  get allowedDevicesList() {
    return this.filterWithSearchString(
      this.dataList.filter(
        ($0) => $0.status !== CONNECTIVITY_DEVICE_STATUS.BANNED
      )
    );
  }

  @computed
  get deniedDevicesList() {
    return this.filterWithSearchString(
      this.dataList.filter(
        ($0) => $0.status === CONNECTIVITY_DEVICE_STATUS.BANNED
      )
    );
  }

  getDataList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);
    if (this.groupsStore.groupsList.length < 1) {
      setTimeout(() => {
        if (!this.groupsStore.isFetching) {
          this.groupsStore.getGroupsList();
        }
        this.getDataList(withFetchingAnimation);
      }, 200);
      return false;
    }
    this.modboxAPIService
      .requestDevicesNetworks()
      .then((response) => {
        let newList = [];
        if (response.status === 200) {
          response.data.forEach((data) => {
            newList.push(new ConnectivityDeviceModel(data, this.groupsStore));
          });
        }

        this.setDataList(newList);

        this.setIsFetching(false);
      })
      .catch((error) => {
        window.debugError("error in requestDevicesNetworks", error);
        if (error && error.statusCode === 429) {
          setTimeout(this.getDataList, 2000);
        }
      });
  };

  submit = (data) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitDevicesNetwork(data)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList();
          resolve(response.data.code);
        })
        .catch((error) => reject(error));
    });
  };

  update = (data) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateDevicesNetwork(data)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList();
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  delete = (token) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .deleteDevicesNetwork(token)
        .then((response) => {
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          this.getDataList();
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getWithToken = (token) => {
    if (this.dataList.length > 0) {
      return this.dataList.find(($0) => $0.token === token);
    }
    return null;
  };
}

export default ConnectivityDevicesStore;
