// @ts-nocheck
import { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { inject } from "mobx-react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import PageTitle from "../components/PageTitle/PageTitle";
import { Button } from "../components/Button";
import { InputText } from "../components/Form";
import Validate, { ValidationType } from "../components/Form/Validate";
import { Card, CardBody, CardHeader, CardFooter } from "../components/Card";

import PasswordStrengthMeter from "../components/Form/PasswordStrengthMeter";

// Translate
import Translate from "../components/I18n/Translate";

// Icons
import { CheckIcon } from "../components/Icon/MaterialIcon";

// Utils
import { LOGO_IMAGE } from "../utils/EnvUtils";

// Style
import { container, cardTitle } from "../assets/jss/app-js-styles";
const styles = {
  container: {
    ...container,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF",
  },
  textCenter: {
    textAlign: "center",
  },
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  logoContainer: {
    textAlign: "center",
  },
};

@inject("notificationsStore", "modboxAPIService")
class CreateNewPasswordPage extends Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      password: "",
      confirmPassword: "",
      cardAnimaton: "cardHidden",
      isWaitingForm: false,
      formWasSubmit: false,
      recoverSuccess: false,
      isValid: {
        password: false,
        confirmPassword: false,
      },
    };
  }
  componentDidMount() {
    this.doAnimation();
  }

  doAnimation = () => {
    setTimeout(() => {
      this.setState({
        cardAnimaton: "",
      });
    }, 400);
  };

  onChangePassword = ({ target }) => {
    const { value, name } = target;

    let isValid = Validate(value, ValidationType.REQUIRED);

    if (this.state.formWasSubmit && name === "confirmPassword") {
      isValid = value === this.state.password;
    }

    this.setState({
      [name]: value,
      isValid: {
        ...this.state.isValid,
        [name]: isValid,
      },
    });
  };

  didSubmitForm = () => {
    this.setState(
      {
        formWasSubmit: true,
        isWaitingForm: true,
      },
      () => {
        const { password, confirmPassword } = this.state;

        if (password !== confirmPassword) {
          this.setState({
            isWaitingForm: false,
            isValid: {
              ...this.state.isValid,
              confirmPassword: false,
            },
          });
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="RecoverPassword@passwordConfirmationMessage">
                A confirmação de senha está incorreta.
              </Translate>
            ),
            timeout: 10000,
            color: "danger",
          });

          return false;
        } else if (password.length < 5) {
          this.setState({
            isWaitingForm: false,
            isValid: {
              ...this.state.isValid,
              password: false,
              confirmPassword: false,
            },
          });
          this.props.notificationsStore.addSnackbarNotification({
            message: (
              <Translate id="RecoverPassword@passwordMinLengthMessage">
                A senha precisa ter pelo menos 5 caracteres.
              </Translate>
            ),
            timeout: 10000,
            color: "warning",
          });

          return false;
        }

        const oldUserToken = this.props.location.search.replace("?token=", "");

        this.props.modboxAPIService
          .recoverPassword({
            password: password,
            user_token: oldUserToken,
          })
          .then((response) => {
            if (response.status === 200) {
              window.localStorage.setItem("oldUserToken", oldUserToken);
              this.props.notificationsStore.addSnackbarNotification({
                message: (
                  <Translate id="RecoverPassword@successMessage">
                    Senha alterada com sucesso! Faça seu login agora :-)
                  </Translate>
                ),
                timeout: 10000,
                color: "success",
              });
              this.setState({
                recoverSuccess: true,
              });
            }
          })
          .catch((error) => {
            if (error && error.statusCode === 404) {
              this.setState({ recoverSuccess: true });

              this.props.notificationsStore.addSnackbarNotification({
                message: (
                  <Translate id="RecoverPassword@invalidTokenMessage">
                    Nenhum cadastro encontrado. Caso necessário solicite a
                    recuperação de senha novamente.
                  </Translate>
                ),
                timeout: 10000,
                color: "danger",
              });
            } else if (error && error.statusCode === 409) {
              this.setState({ recoverSuccess: true, isWaitingForm: false });

              this.props.notificationsStore.addSnackbarNotification({
                message: error.message,
                timeout: 10000,
                color: "success",
              });
            } else {
              this.setState({ isWaitingForm: false });
            }
          });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      password,
      confirmPassword,
      isValid,
      isWaitingForm,
      formWasSubmit,
    } = this.state;

    if (this.state.recoverSuccess) {
      return <Redirect to="/login" />;
    }

    return (
      <Fragment>
        <PageTitle id="Login@recoverPasswordPageTitle">
          Recuperar conta
        </PageTitle>
        <div className={classes.content}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={4}>
                <form>
                  <Card login className={classes[this.state.cardAnimaton]}>
                    <CardHeader style={{ paddingBottom: 0 }}>
                      <GridContainer>
                        <GridItem xs={12} className={classes.logoContainer}>
                          <img src={LOGO_IMAGE} alt="Logo" />
                        </GridItem>
                      </GridContainer>
                    </CardHeader>
                    <CardBody>
                      {this.state.recoverSuccess && (
                        <p style={{ textAlign: "center" }}>
                          <Translate id="Login@didSendEmailMessage">
                            Um link foi enviado para o e-mail informado!
                          </Translate>
                        </p>
                      )}
                      <InputText
                        label={
                          <Translate id="RecoverPassword@newPasswordLabel">
                            Informe a nova senha
                          </Translate>
                        }
                        name="password"
                        value={password}
                        inputProps={{ type: "password", autoComplete: "off" }}
                        rightIcon={
                          <InputAdornment position="end">
                            <i
                              className={`fas fa-key ${classes.inputAdornmentIcon}`}
                            />
                          </InputAdornment>
                        }
                        hasError={formWasSubmit && !isValid.password}
                        hasSuccess={formWasSubmit && isValid.password}
                        onChange={this.onChangePassword}
                      />
                      <InputText
                        label={
                          <Translate id="RecoverPassword@confirmPasswordLabel">
                            Confirme a nova senha
                          </Translate>
                        }
                        name="confirmPassword"
                        value={confirmPassword}
                        inputProps={{ type: "password", autoComplete: "off" }}
                        rightIcon={
                          <InputAdornment position="end">
                            <i
                              className={`fas fa-key ${classes.inputAdornmentIcon}`}
                            />
                          </InputAdornment>
                        }
                        hasError={formWasSubmit && !isValid.confirmPassword}
                        hasSuccess={formWasSubmit && isValid.confirmPassword}
                        onChange={this.onChangePassword}
                      />
                      <PasswordStrengthMeter password={password} />
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Button
                            color="info"
                            large
                            block
                            disabled={!isValid.password}
                            isLoading={isWaitingForm}
                            onClick={this.didSubmitForm}
                          >
                            <Translate id="RecoverPassword@submitButtonLabel">
                              Definir nova Senha
                            </Translate>{" "}
                            <CheckIcon />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  </Card>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </Fragment>
    );
  }
}

CreateNewPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateNewPasswordPage);
