// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

import Modal from "./Modal";
import YouTubeVideo from "../Video/YouTubeVideo";

@inject("tutorialsStore")
@observer
class TutorialModal extends Component {
  render() {
    const {
      modalIsOpen,
      selectedTutorial,
      toggleModal,
    } = this.props.tutorialsStore;

    if (selectedTutorial) {
      return (
        <Modal
          isOpen={modalIsOpen}
          didCloseModal={toggleModal}
          modalTitle={selectedTutorial.title}
          maxWidth="lg"
        >
          <YouTubeVideo
            videoId={selectedTutorial.id}
            width="100%"
            height={600}
            autoplay
            showControls
          />
        </Modal>
      );
    }
    return null;
  }
}

export default TutorialModal;
