// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";

import { withLocalized } from "../HOC";
import { GridContainer, GridItem } from "../Grid";
import LoadingView from "../LoadingView/LoadingView";
import { Button } from "../Button";
import Modal from "../Modal/Modal";
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import ScheduleText from "../ScheduleText/ScheduleText";
import ResourceScheduleForm from "../AppForm/ResourceScheduleForm";

// Translate
import {
  DeleteString,
  UpdateString,
  SaveString,
  SetupString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Utils
import { defaultAlwaysOnSchedule } from "../../utils/ScheduleUtils";

// Icon
import { SaveIcon, DeleteIcon } from "../Icon";

// Styles
const styles = {
  sectionTitle: {
    fontSize: "1.4em",
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 0,
  },
};

@withLocalized
@inject("groupsStore", "resourcesStore", "notificationsStore")
@observer
class ResourceWhatWhereWhen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      what: this.getWhatFromProps(props),
      where: props.where ? props.where : null,
      when: props.when ? props.when : null,
      isActive: props.isActive,
      isOpenModal: false,
      didWantToDelete: false,
    };
  }

  componentDidMount() {
    const { groupsStore } = this.props;
    if (groupsStore.groupsList.length < 1) {
      groupsStore.getGroupsList();
    }
  }

  getWhatFromProps = (props) => {
    if (props.what) return props.what;
    if (props.whatList.length === 1) {
      if (props.resourceType === "playlist") return [props.whatList[0]];
      return props.whatList[0];
    }
    return null;
  };

  prepareUpdateData = () => {
    const { resourceType } = this.props;
    const { what } = this.state;
    return {
      ...this.props.originalResourceRule,
      resourceType,
      resourceToken: resourceType === "playlist" ? what[0].token : what.token,
      secondResourceToken:
        resourceType === "playlist" && what.length > 1
          ? what[1].token
          : undefined,
      groupToken: this.state.where.token,
      schedule: [this.state.when],
      isActive: this.props.isActive,
    };
  };

  toggleIsActive = () => {
    this.onUpdate({
      ...this.prepareUpdateData(),
      isActive: !this.props.isActive,
    });
  };

  didSelectWhat = (selected) =>
    this.setState({
      what: selected,
    });

  didSelectWhere = (selected) =>
    this.setState({
      where: selected,
    });

  didChangeWhen = (changedResource) => {
    this.setState({
      when: changedResource,
    });
  };

  didCancelConfiguringWhen = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  didWantToConfigureWhen = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didConfirmDeleteAction = () => {
    this.props.resourcesStore.delete(this.props.originalResourceRule);
    this.props.didDelete();
  };

  onUpdate = (data) => {
    this.props.resourcesStore.update(data).then(() => {
      this.didSubmit();
    });
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();
    const { resourceType } = this.props;
    const { what, where } = this.state;
    if (!what) {
      this.props.notificationsStore.addSnackbarNotification({
        message: (
          <Translate id="Resource@whatErrorMessage">
            Selecione o recurso (O QUE)
          </Translate>
        ),
        color: "danger",
      });
      return false;
    }

    if (!where) {
      this.props.notificationsStore.addSnackbarNotification({
        message: (
          <Translate id="Resource@whereErrorMessage">
            Selecione uma localidade (ONDE)
          </Translate>
        ),
        color: "danger",
      });
      return false;
    }

    if (this.props.originalResourceRule) {
      this.onUpdate(this.prepareUpdateData());
    } else {
      this.props.resourcesStore
        .submit({
          resourceType,
          resourceToken:
            resourceType === "playlist" && Array.isArray(what)
              ? what[0].token
              : what.token,
          secondResourceToken:
            resourceType === "playlist" &&
            Array.isArray(what) &&
            what.length > 1
              ? what[1].token
              : undefined,
          groupToken: where.token,
          schedule: this.state.when || defaultAlwaysOnSchedule(),
          isActive: this.props.isActive,
        })
        .then(() => {
          this.didSubmit();
        });
    }
  };

  didSubmit = () => {
    if (this.props.didSubmit) {
      this.props.didSubmit();
    }
  };

  render() {
    const { classes, isActive, localizedString, resourceType } = this.props;
    const { isOpenModal, didWantToDelete } = this.state;
    const isEditing = this.props.originalResourceRule;

    return (
      <GridContainer>
        {isOpenModal && (
          <Modal
            isOpen={isOpenModal}
            didCloseModal={this.didCancelConfiguringWhen}
            contentOverflowVisible
          >
            <ResourceScheduleForm
              schedule={this.state.when || defaultAlwaysOnSchedule()}
              saveSchedule={this.didChangeWhen}
            />
          </Modal>
        )}
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didConfirmDeleteAction}
            didCancel={this.didCancelDeleteAction}
            message={
              <Translate id="Resource@deleteMessage">
                Você realmente quer apagar esta regra?
              </Translate>
            }
          />
        )}

        <GridItem xs={12} md={4}>
          <h2 className={classes.sectionTitle}>
            <Translate id="Resource@whatTitle">O que</Translate>
          </h2>
          <Select
            value={this.state.what}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            placeholder={
              <Translate id="Common@selectPlaceholder">selecione...</Translate>
            }
            options={
              resourceType === "playlist" &&
              this.state.what &&
              this.state.what.length === 2
                ? []
                : this.props.whatList
            }
            noOptionsMessage={() =>
              resourceType === "playlist"
                ? localizedString({
                    id: "Common@maxAlreadySelected",
                    defaultString: "Máximo já selecionado",
                  })
                : localizedString({
                    id: "Common@nothingFound",
                    defaultString: "Nada encontrado",
                  })
            }
            onChange={this.didSelectWhat}
            isClearable={false}
            isMulti={resourceType === "playlist"}
            getOptionValue={(option) => option.token}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <h2 className={classes.sectionTitle}>
            <Translate id="Resource@whereTitle">Onde</Translate>
          </h2>
          {this.props.groupsStore.isFetching ? (
            <LoadingView height={30} />
          ) : (
            <Select
              value={this.state.where}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              placeholder={
                <Translate id="Common@selectPlaceholder">
                  selecione...
                </Translate>
              }
              options={this.props.groupsStore.groupsList}
              noOptionsMessage={() =>
                localizedString({
                  id: "Common@nothingFound",
                  defaultString: "Nada encontrado",
                })
              }
              onChange={this.didSelectWhere}
              isClearable={false}
              getOptionValue={(option) => option.token}
            />
          )}
        </GridItem>
        <GridItem xs={12} md={4}>
          <h2 className={classes.sectionTitle}>
            <Translate id="Resource@whenTitle">Quando</Translate>
          </h2>
          {this.state.when ? (
            <Button
              small
              block
              color="success"
              outline
              onClick={this.didWantToConfigureWhen}
            >
              <ScheduleText schedule={this.state.when} />
            </Button>
          ) : (
            <Button
              small
              block
              color="info"
              onClick={this.didWantToConfigureWhen}
            >
              <SetupString /> <i className="fas fa-cog" />
            </Button>
          )}
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <GridContainer justify="space-between">
            {isEditing && (
              <Fragment>
                <GridItem xs={12} sm={4}>
                  <Button color="danger" block onClick={this.didWantToDelete}>
                    <DeleteString /> <DeleteIcon />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    color={isActive ? "warning" : "success"}
                    block
                    onClick={this.toggleIsActive}
                  >
                    {isActive ? (
                      <span>
                        <Translate id="Resource@disableRule">
                          Inativar regra
                        </Translate>{" "}
                        <i className="fas fa-calendar-times" />
                      </span>
                    ) : (
                      <span>
                        <Translate id="Resource@enableRule">
                          Habilitar regra
                        </Translate>{" "}
                        <i className="fas fa-calendar-check" />
                      </span>
                    )}
                  </Button>
                </GridItem>
              </Fragment>
            )}
            <GridItem xs sm>
              <Button block color="primary" onClick={this.onSubmit}>
                {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ResourceWhatWhereWhen);
