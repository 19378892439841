// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PageTitle from "../components/PageTitle/PageTitle";
import AffiliateHeader from "../components/Header/AffiliateHeader";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import InfoLabel from "../components/Label/InfoLabel";
import { Card, CardBody } from "../components/Card";
import AffiliateDashboardContainer from "./affiliateDashboardPage/AffiliateDashboardContainer";

// Translate
import Translate from "../components/I18n/Translate";

// Style
const styles = {
  mainContainer: {
    marginTop: 120,
    padding: "0 16px",
  },
};

@inject("affiliateAuthStore")
@observer
class AffiliateDashboardPage extends Component {
  render() {
    const { classes, affiliateAuthStore } = this.props;

    return (
      <div className={classes.content}>
        <PageTitle id="Affiliate@dashboardPageTitle">
          Painel do afiliado
        </PageTitle>
        <AffiliateHeader />

        {affiliateAuthStore.hasValidContract ? (
          <AffiliateDashboardContainer />
        ) : (
          <GridContainer className={classes.mainContainer} justify="center">
            <GridItem xs={10} sm={6}>
              <Card>
                <CardBody>
                  <Translate id="Affiliate@dashboardNoValidContractMessage">
                    <InfoLabel
                      large
                      style={{ textAlign: "center", margin: 16 }}
                    >
                      Aguardando a aprovação do seu cadastro.
                    </InfoLabel>
                    <p style={{ textAlign: "center" }}>
                      Em caso de dúvidas entre em contato com o suporte.
                    </p>
                  </Translate>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}

AffiliateDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AffiliateDashboardPage);
