// @ts-nocheck

import InputAdornment from "@material-ui/core/InputAdornment";

import { InputText } from "../../components/Form";

// Translate
import Translate from "../../components/I18n/Translate";

const InputEmail = ({ value, formWasSubmit, isValid, onChange }) => (
  <InputText
    label={<Translate id="Login@email">E-mail</Translate>}
    name="email"
    value={value}
    autoFocus
    inputProps={{
      type: "email",
    }}
    rightIcon={
      <InputAdornment position="end">
        <i className="fas fa-envelope" style={{ color: "#555" }} />
      </InputAdornment>
    }
    hasError={formWasSubmit && !isValid}
    hasSuccess={formWasSubmit && isValid}
    onChange={onChange}
  />
);

export default InputEmail;
