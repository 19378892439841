// @ts-nocheck

import AlfredStore from "../AlfredStore/AlfredStore";
import SettingsStore from "../SettingsStore/SettingsStore";
import RoutingStore from "../RoutingStore/RoutingStore";
import SoundStore from "../SoundStore/SoundStore";
import TutorialsStore from "../TutorialsStore/TutorialsStore";
import I18nStore from "../I18nStore/I18nStore";
import AuthStore from "../AuthStore/AuthStore";
import FirebaseAuthStore from "../FirebaseAuthStore/FirebaseAuthStore";
import ContractStore from "../ContractStore/ContractStore";
import ChatStore from "../ChatStore/ChatStore";
import GroupsStore from "../GroupsStore/GroupsStore";
import InformativeClocksStore from "../InformativeClocksStore/InformativeClocksStore";
import InformativeLogotypeStore from "../InformativeLogotypeStore/InformativeLogotypeStore";
import InformativeMessageStore from "../InformativeMessageStore/InformativeMessageStore";
import MediaLibraryStore from "../MediaLibraryStore/MediaLibraryStore";
import NetworksStore from "../NetworksStore/NetworksStore";
import ConnectivityDevicesStore from "../ConnectivityDevicesStore/ConnectivityDevicesStore";
import PlaylistsStore from "../PlaylistsStore/PlaylistsStore";
import ResourcesStore from "../ResourcesStore/ResourcesStore";
import InternalEventsStore from "../InternalEventsStore/InternalEventsStore";
import TagsStore from "../TagsStore/TagsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import ReportsStore from "../ReportsStore/ReportsStore";
import MetricsReportStore, {
  MetricsStoreType,
} from "../ReportsStore/MetricsReportStore";
import TerminalsStore from "../TerminalsStore/TerminalsStore";
import AdvertisingStore from "../AdvertisingStore/AdvertisingStore";
import InternalNewsStore from "../InternalNewsStore/InternalNewsStore";

import OrdersStore from "../OrdersStore/OrdersStore";
import ProductsStore from "../ProductsStore/ProductsStore";

import AffiliateAuthStore from "../AffiliateAuthStore/AffiliateAuthStore";
import AffiliateStore from "../AffiliateStore/AffiliateStore";

import MainServices from "../../services/MainServices";

class MainStore {
  settingsStore: SettingsStore;
  routingStore: RoutingStore;
  alfredStore: AlfredStore;
  soundStore: SoundStore;
  i18nStore: I18nStore;
  internalEventsStore: InternalEventsStore;
  tagsStore: TagsStore;
  tutorialsStore: TutorialsStore;
  authStore: AuthStore;
  firebaseAuthStore: FirebaseAuthStore;
  contractStore: ContractStore;
  groupsStore: GroupsStore;
  informativeClocksStore: InformativeClocksStore;
  informativeLogotypeStore: InformativeLogotypeStore;
  informativeMessageStore: InformativeMessageStore;
  mediaLibraryStore: MediaLibraryStore;
  networksStore: NetworksStore;
  connectivityDevicesStore: ConnectivityDevicesStore;
  playlistsStore: PlaylistsStore;
  resourcesStore: ResourcesStore;
  chatStore: ChatStore;
  notificationsStore: NotificationsStore;
  reportsStore: ReportsStore;
  wifiMetricsReportStore: MetricsReportStore;
  cameraMetricsReportStore: MetricsReportStore;
  terminalsStore: TerminalsStore;
  advertisingStore: AdvertisingStore;
  internalNewsStore: InternalNewsStore;
  ordersStore: OrdersStore;
  productsStore: ProductsStore;
  affiliateAuthStore: AffiliateAuthStore;
  affiliateStore: AffiliateStore;

  constructor(routingStore: RoutingStore, services: MainServices) {
    this.routingStore = routingStore;

    this.settingsStore = new SettingsStore();
    this.internalEventsStore = new InternalEventsStore();
    this.alfredStore = new AlfredStore(this.internalEventsStore);
    this.soundStore = new SoundStore();
    this.notificationsStore = new NotificationsStore(services.modboxAPIService);
    this.authStore = new AuthStore(
      this.internalEventsStore,
      this.notificationsStore,
      services.analyticsService,
      services.modboxAPIService
    );
    this.i18nStore = new I18nStore(this.authStore);
    this.firebaseAuthStore = new FirebaseAuthStore(
      this.authStore,
      this.internalEventsStore
    );
    this.contractStore = new ContractStore(
      this.authStore,
      this.alfredStore,
      this.internalEventsStore,
      this.notificationsStore,
      services.modboxAPIService,
      services.autonomediaService,
      services.telegramAPIService
    );
    this.tagsStore = new TagsStore(
      this.contractStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.tutorialsStore = new TutorialsStore(this.i18nStore);
    this.groupsStore = new GroupsStore(
      this.internalEventsStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.resourcesStore = new ResourcesStore(
      this.internalEventsStore,
      this.groupsStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.informativeClocksStore = new InformativeClocksStore(
      this.internalEventsStore,
      this.resourcesStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.informativeLogotypeStore = new InformativeLogotypeStore(
      this.internalEventsStore,
      this.resourcesStore,
      this.notificationsStore,
      services.modboxAPIService,
      services.modboxFileService
    );
    this.informativeMessageStore = new InformativeMessageStore(
      this.internalEventsStore,
      this.resourcesStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.mediaLibraryStore = new MediaLibraryStore(
      this.i18nStore,
      this.tagsStore,
      this.internalEventsStore,
      this.notificationsStore,
      services.modboxAPIService,
      services.modboxFileService
    );
    this.networksStore = new NetworksStore(
      this.contractStore,
      this.internalEventsStore,
      this.resourcesStore,
      this.notificationsStore,
      services.modboxAPIService,
      services.modboxFileService
    );
    this.connectivityDevicesStore = new ConnectivityDevicesStore(
      this.internalEventsStore,
      this.notificationsStore,
      this.groupsStore,
      services.modboxAPIService
    );
    this.playlistsStore = new PlaylistsStore(
      this.contractStore,
      this.internalEventsStore,
      this.resourcesStore,
      this.notificationsStore,
      this.settingsStore,
      services.modboxAPIService,
      services.modboxFileService
    );
    this.chatStore = new ChatStore(
      this.authStore,
      this.contractStore,
      this.internalEventsStore,
      this.i18nStore,
      this.routingStore,
      this.firebaseAuthStore,
      this.soundStore
    );
    this.reportsStore = new ReportsStore(
      this.internalEventsStore,
      services.modboxAPIService
    );
    this.wifiMetricsReportStore = new MetricsReportStore(
      MetricsStoreType.wifi,
      this.authStore,
      this.contractStore,
      this.i18nStore,
      this.groupsStore,
      this.internalEventsStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.cameraMetricsReportStore = new MetricsReportStore(
      MetricsStoreType.camera,
      this.authStore,
      this.contractStore,
      this.i18nStore,
      this.groupsStore,
      this.internalEventsStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.terminalsStore = new TerminalsStore(
      this.authStore,
      this.contractStore,
      this.internalEventsStore,
      this.notificationsStore,
      this.routingStore,
      services.modboxAPIService
    );
    this.advertisingStore = new AdvertisingStore(
      this.contractStore,
      this.i18nStore,
      this.internalEventsStore,
      this.notificationsStore,
      services.modboxAPIService
    );
    this.internalNewsStore = new InternalNewsStore(
      this.i18nStore,
      services.wordPressAPIService,
      services.discourseAPIService
    );
    this.ordersStore = new OrdersStore(
      this.authStore,
      this.firebaseAuthStore,
      this.contractStore,
      services.modboxAPIService,
      services.pagSeguroAPIService,
      services.telegramAPIService
    );
    this.productsStore = new ProductsStore(
      this.authStore,
      this.contractStore,
      this.firebaseAuthStore
    );
    this.affiliateAuthStore = new AffiliateAuthStore();
    this.affiliateStore = new AffiliateStore(
      this.affiliateAuthStore,
      this.internalEventsStore,
      this.ordersStore
    );

    // Set stores on another stores that cannot be passed as dependency on constructor()
    this.notificationsStore.setStores(
      this.authStore,
      this.soundStore,
      this.internalEventsStore
    );
    this.routingStore.setStores(this.internalEventsStore);

    // Set stores on services
    services.modboxAPIService.authStore = this.authStore;
    services.modboxAPIService.contractStore = this.contractStore;
    services.modboxAPIService.notificationsStore = this.notificationsStore;
    services.modboxFileService.contractStore = this.contractStore;

    // Set i18nStore to stores who need it
    this.authStore.i18nStore = this.i18nStore;
    this.groupsStore.i18nStore = this.i18nStore;
    this.informativeClocksStore.i18nStore = this.i18nStore;
    this.informativeLogotypeStore.i18nStore = this.i18nStore;
    this.informativeMessageStore.i18nStore = this.i18nStore;
    this.networksStore.i18nStore = this.i18nStore;
    this.ordersStore.i18nStore = this.i18nStore;
    this.playlistsStore.i18nStore = this.i18nStore;
    this.resourcesStore.i18nStore = this.i18nStore;
  }
}

export default MainStore;
