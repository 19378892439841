// @ts-nocheck


import LoadingView from "../LoadingView/LoadingView";

const IfNotFetching = ({ isFetching, children, loadingHeight }) => {
  if (isFetching) return <LoadingView height={loadingHeight} />;
  return children;
};

export default IfNotFetching;
