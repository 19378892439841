// @ts-nocheck

import PropTypes from "prop-types";
import classnames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { AppBar, Toolbar, Hidden } from "@material-ui/core";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "../Button/Button";

import headerStyle from "../../assets/jss/js-styles/components/headerStyle";

const Header = ({
  classes,
  color,
  activeRoute,
  handleMobileDrawerToggle,
  handleNotificationsDrawerToggle,
}) => {
  const appBarClasses = classnames({
    [" " + classes[color]]: color,
  });

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {activeRoute && (
            <h1 className={classes.title}>
              <i
                className={`fas fa-${activeRoute.icon} ${classes.titleIcon}`}
              />
              {activeRoute.title}
            </h1>
          )}
        </div>

        <Hidden smDown>
          <HeaderLinks
            handleNotificationsDrawerToggle={handleNotificationsDrawerToggle}
            tutorialKey={activeRoute ? activeRoute.tutorialKey || false : false}
          />
        </Hidden>

        <Hidden mdUp>
          <Button
            className={classes.mobileMenuButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleMobileDrawerToggle}
          >
            <i className="fas fa-bars" />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default withStyles(headerStyle)(Header);
