// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import Tooltip from "../Tooltip/Tooltip";

import MessagesList from "./MessagesList";
import ChatInputAndButton from "./ChatInputAndButton";
import ModalMessageImageType from "./ModalMessageImageType";
import ConfirmModal from "../Modal/ConfirmModal";

import UserInfoModal from "./UserInfoModal";

// Util Stores
import { CHAT_VIEW } from "../../store/ChatStore/ChatStore";
import {
  KEYCODE,
  IMappedKeyFunctions,
} from "../../services/KeyboardEventsService";

// Translate
import { CloseString, ConfirmActionString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Style

import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = {
  chatContainer: {
    backgroundColor: "#EEEEEE",
    borderTopLeftRadius: 8,
    width: "100%",
    margin: 0,
  },
  chatHeader: {
    backgroundColor: primaryColor,
    borderTopLeftRadius: 8,
  },
  chatTitle: {
    color: "#fff",
    margin: "8px 0",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  chatSubject: {
    color: "#fff",
  },
};

@inject("chatStore", "keyboardEventsService")
@observer
class ChatView extends Component {
  state = {
    isOpenUserInfoModal: false,
    didWantToCloseChat: false,
  };

  componentDidMount() {
    this.props.chatStore.didReadMessages();
    this.setKeyboardListener();
  }
  componentWillUnmount() {
    this.removeKeyboardListener();
  }

  setKeyboardListener = () => {
    this.props.keyboardEventsService.setListener(this, this.mapKeyFunctions);
  };
  removeKeyboardListener = () => {
    this.props.keyboardEventsService.removeListener(this);
  };

  mapKeyFunctions = (): IMappedKeyFunctions => {
    const { chatStore } = this.props;
    const isModalOpen = chatStore.isAttachmentModalOpen;

    return {
      [KEYCODE.ENTER]: (event) => {
        const { ctrlKey, shiftKey, metaKey } = event;
        if (ctrlKey || shiftKey || metaKey) {
          event.preventDefault();
          chatStore.onChangeMessage(chatStore.message + `\n`);
        } else if (!isModalOpen) {
          event.preventDefault();
          this.didSubmitMessage();
        }
      },
      [KEYCODE.ARROW_UP]: () =>
        chatStore.setSelectedMessageId(
          chatStore.selectedChatMessagesListLastMessage.id
        ),
      [KEYCODE.ESC]: (event) => {
        event.preventDefault();
        if (isModalOpen) {
          this.didCloseImageModal();
        } else if (chatStore.selectedMessageForEditing) {
          chatStore.cancelEditingMessage();
        } else {
          chatStore.removeSelectedChat();
        }
      },
    };
  };

  didSubmitMessage = () => {
    const { chatStore } = this.props;
    if (chatStore.selectedMessageForEditing) {
      chatStore.didUpdateChatMessage();
    } else {
      chatStore.addTextMessageForCurrentAuthor();
    }
  };

  toggleUserInfoModal = () => {
    if (this.props.chatStore.currentAuthor === CHAT_VIEW.ADMIN) {
      this.setState({
        isOpenUserInfoModal: !this.state.isOpenUserInfoModal,
      });
    }
  };

  didCloseImageModal = () => {
    this.props.chatStore.didCancelAddingAttachment();
  };

  didWantToAddAttachment = () => {
    this.props.chatStore.didWantToAddAttachment();
  };
  onChangeMessage = ({ target }) => {
    this.props.chatStore.onChangeMessage(target.value);
  };

  setIsReading = (status) => {
    this.props.chatStore.setIsReadingStatus(status);
    if (status) {
      this.props.chatStore.didReadMessages();
    }
  };

  didWantToCloseChat = () => {
    this.setState({
      didWantToCloseChat: true,
    });
  };

  didCloseChat = () => {
    this.props.chatStore.didSetChatClosed();
  };

  didCancelClosingChat = () => {
    this.setState({
      didWantToCloseChat: false,
    });
  };

  render() {
    const { classes, chatStore } = this.props;
    const { isOpenUserInfoModal } = this.state;

    const { message, selectedChat: chat, currentAuthor } = chatStore;

    if (!chat || !currentAuthor) {
      return null;
    }

    const isAdminView = currentAuthor === CHAT_VIEW.ADMIN;

    const { user } = chat;
    const {
      deviceInfo = null,
      currentContract = null,
      name = "",
      email,
      currentUrl,
    } = user;

    return (
      <Fragment>
        {this.state.didWantToCloseChat && (
          <ConfirmModal
            isOpen
            title={<ConfirmActionString />}
            didConfirm={this.didCloseChat}
            didCancel={this.didCancelClosingChat}
            confirmLabel={
              <Translate id="Chat@confirmCloseChatButtonLabel">
                Finalizar
              </Translate>
            }
          >
            <Translate id="Chat@confirmCloseChatMessage">
              O chat será finalizado e enviado para o arquivo.
            </Translate>
          </ConfirmModal>
        )}
        <ModalMessageImageType
          didClose={this.didCloseImageModal}
          onChangeMessage={this.onChangeMessage}
        />
        <UserInfoModal
          didRequestAccessToContract={chatStore.didRequestAccessToContract}
          isOpen={isOpenUserInfoModal}
          didCloseModal={this.toggleUserInfoModal}
          name={name}
          email={email}
          currentUrl={currentUrl}
          currentContract={currentContract}
          deviceInfo={deviceInfo}
        />
        <GridContainer className={classes.chatContainer}>
          <GridItem xs={12} removePadding>
            <GridContainer
              justify="space-between"
              alignItems="center"
              className={classes.chatHeader}
            >
              <GridItem xs={2} onClick={this.toggleUserInfoModal}>
                <Button
                  justIcon
                  style={{ color: "#fff" }}
                  color="transparent"
                  onClick={chatStore.removeSelectedChat}
                >
                  <i className="fas fa-chevron-left" />
                </Button>
              </GridItem>
              <GridItem xs={6} onClick={this.toggleUserInfoModal}>
                {isAdminView ? (
                  <Fragment>
                    <Tooltip
                      placement="top-start"
                      title={
                        <Translate id="Chat@userInfoTooltip">
                          Ver informações do usuário
                        </Translate>
                      }
                    >
                      <p
                        className={classes.chatTitle}
                        style={{ marginBottom: 0, cursor: "pointer" }}
                      >
                        {name}
                      </p>
                    </Tooltip>
                    <p className={classes.chatSubject}>
                      {chat.subject ? chat.subject : "---"}
                    </p>
                  </Fragment>
                ) : (
                  <p className={classes.chatTitle}>
                    {chat.subject ? (
                      chat.subject
                    ) : (
                      <Translate id="Chat@title">Conversa</Translate>
                    )}
                  </p>
                )}
              </GridItem>
              <GridItem xs style={{ textAlign: "right" }}>
                {!chat.isClosed && (
                  <Tooltip
                    title={
                      <Translate id="Chat@closeChatButtonLabel">
                        Finalizar esta conversa
                      </Translate>
                    }
                  >
                    <Button
                      justIcon
                      color="success"
                      style={{ marginRight: 4 }}
                      onClick={this.didWantToCloseChat}
                    >
                      <i className="fas fa-check" />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title={<CloseString />}>
                  <Button
                    justIcon
                    style={{ color: "#fff" }}
                    color="transparent"
                    onClick={chatStore.closeChat}
                  >
                    <i className="fas fa-times" />
                  </Button>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} removePadding>
            <MessagesList
              messagesList={chatStore.selectedChatMessagesList}
              isTyping={chatStore.isTyping}
              isAdminView={isAdminView}
              setIsReading={this.setIsReading}
            />
          </GridItem>
          <GridItem xs={12} removePadding>
            {chat.isClosed ? (
              <Fragment>
                <Translate id="Chat@chatIsClosedMessage">
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: 8,
                      marginBottom: 0,
                    }}
                  >
                    Esta conversa foi finalizada pelo suporte.
                  </p>
                  <p style={{ textAlign: "center", marginBottom: 0 }}>
                    Se precisar, ative ela novamente no botão abaixo.
                  </p>
                </Translate>

                <Button
                  small
                  block
                  onClick={() => chatStore.didWantToReopenChat(chat.id)}
                >
                  Ativar conversa
                </Button>
              </Fragment>
            ) : (
              <ChatInputAndButton
                message={message}
                didWantToAddAttachment={this.didWantToAddAttachment}
                isEditingMessage={
                  chatStore.selectedMessageForEditing
                    ? chatStore.selectedMessageForEditing.message
                    : false
                }
                didCancelEditingMessage={chatStore.cancelEditingMessage}
                onChangeMessage={this.onChangeMessage}
                didSubmitMessage={this.didSubmitMessage}
              />
            )}
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}
export default withStyles(styles)(ChatView);
