// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";

// Components
import ConfirmDeleteModal from "../Modal/ConfirmDeleteModal";
import { InputText, InputSwitch } from "../Form";
import { Button } from "../Button";
import { GridContainer, GridItem } from "../Grid";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import Badge from "../Badge/Badge";

import Validate, { ValidationType } from "../Form/Validate";

// Translate
import {
  NameString,
  EmailString,
  DeleteString,
  CancelString,
  CloseString,
  UpdateString,
  SaveString,
  EmailErrorString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Icon
import {
  SaveIcon,
  DeleteIcon,
  ChevronLeftIcon,
  CheckIcon,
  RemoveIcon,
} from "../Icon";


@inject("authStore", "contractStore", "notificationsStore", "modboxAPIService")
class ContractUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.updateForm();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.updateForm();
    }
  }

  updateForm = () => {
    if (this.props.user) {
      this.setState({
        ...this.defaultState,
        ...this.getStateFromExistingUser(this.props.user),
      });
    } else {
      this.setState(this.defaultState);
    }
  };

  defaultState = {
    newUserPassword: null,
    name: "",
    email: "",
    devices: false,
    locations: false,
    informative: true,
    connectivity: true,
    administrative: false,
    camera: false,
    advertising: false,
    api: false,
    didWantToDelete: false,
    isWaitingForm: false,
    formWasSubmit: false,
    isValid: {
      name: false,
      email: false,
    },
  };

  getStateFromExistingUser = (user) => {
    return {
      name: user.name,
      email: user.email,
      devices: user.rules.devices,
      locations: user.rules.locations,
      informative: user.rules.informative,
      connectivity: user.rules.connectivity,
      administrative: user.rules.administrative,
      camera: user.rules.camera,
      advertising: user.rules.advertising,
      api: user.rules.api,
      isValid: {
        name: Validate(user.name, ValidationType.REQUIRED),
        email:
          Validate(user.email, ValidationType.REQUIRED) &&
          Validate(user.email, ValidationType.EMAIL),
      },
    };
  };

  onChangeName = ({ target }) => {
    const { value } = target;

    this.setState({
      name: value,
      isValid: {
        ...this.state.isValid,
        name: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeEmail = ({ target }) => {
    const { value } = target;

    this.setState({
      email: value,
      isValid: {
        ...this.state.isValid,
        email:
          Validate(value, ValidationType.REQUIRED) &&
          Validate(value, ValidationType.EMAIL),
      },
    });
  };

  onChangeSwitch = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    return isValid.name && isValid.email;
  };

  getFormDataToSubmit = ({
    name,
    email,
    devices,
    locations,
    informative,
    connectivity,
    administrative,
    camera,
    advertising,
    api,
  }) => {
    return {
      name,
      email,
      rules: {
        devices,
        locations,
        informative,
        connectivity,
        administrative,
        camera,
        advertising,
        api,
      },
    };
  };

  submitForm = () => {
    const formData = this.getFormDataToSubmit(this.state);

    if (this.props.user) {
      this.props.modboxAPIService
        .updateRule({
          ...formData,
          token: this.props.user.rulesToken,
        })
        .then((response) => {
          if (response.data && response.data.message) {
            this.props.notificationsStore.addSnackbarNotification({
              message: response.data.message,
            });
          }
          this.didSave();
        })
        .catch(() => this.submitHasError());
    } else {
      this.props.modboxAPIService
        .submitRule(formData)
        .then((response) => {
          const newUserPassword = response.data.password || null;
          if (newUserPassword && newUserPassword.length > 0) {
            this.props.notificationsStore.addSnackbarNotification({
              message: (
                <Translate id="ContractUsers@successfullyRegisteredMessage">
                  Novo usuário cadastrado com sucesso!
                </Translate>
              ),
              timeout: 10000,
              color: "success",
            });
            this.setState({ newUserPassword });
          } else {
            this.didSave();
          }
        })
        .catch(() => this.submitHasError());
    }
  };

  didSave = () => {
    if (this.props.didSave) {
      this.props.didSave();
    }
  };

  didCancel = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.didCancel();
  };

  didWantToDelete = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteUser = () => {
    this.props.modboxAPIService
      .deleteRule({
        token: this.props.user.rulesToken,
      })
      .then(() => {
        this.props.didDeleteUser();
      });
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState(
        { isWaitingForm: true, formWasSubmit: true },
        this.submitForm
      );
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: <EmailErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  hasTotalAccess = () => {
    const {
      devices,
      locations,
      informative,
      connectivity,
      administrative,
      camera,
      advertising,
      api,
    } = this.state;
    return (
      devices &&
      locations &&
      informative &&
      connectivity &&
      administrative &&
      camera &&
      advertising &&
      api
    );
  };

  hasZeroAccess = () => {
    const {
      devices,
      locations,
      informative,
      connectivity,
      administrative,
      camera,
      advertising,
      api,
    } = this.state;
    return (
      !devices &&
      !locations &&
      !informative &&
      !connectivity &&
      !administrative &&
      !camera &&
      !advertising &&
      !api
    );
  };

  changeAllAccessToNewStatus = (newStatus) => {
    this.setState({
      devices: newStatus,
      locations: newStatus,
      informative: newStatus,
      connectivity: newStatus,
      administrative: newStatus,
      camera: newStatus,
      advertising: newStatus,
      api: newStatus,
    });
  };

  didCopyRecoverPasswordLink = () => {
    this.props.notificationsStore.addSnackbarNotification({
      message: (
        <Translate id="ContractUsers@copyRecoverPasswordLinkSuccessMessage">
          Link copiado! Agora envie ele para o usuário usando a opção de colar.
        </Translate>
      ),
    });
  };

  render() {
    const { newUserPassword } = this.state;
    if (newUserPassword) {
      return (
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={10} sm={6} style={{ textAlign: "center" }}>
            <p style={{ marginTop: 60 }}>
              <Translate id="ContractUsers@newUserPasswordMessage">
                Clique abaixo para copiar a <strong>senha</strong> do novo
                usuário:
              </Translate>
            </p>

            <CopyToClipboard text={newUserPassword}>
              <p>
                <Badge
                  color="gray"
                  style={{
                    padding: "10px 20px",
                    fontSize: "2em",
                    marginBottom: 60,
                  }}
                >
                  {newUserPassword}
                </Badge>
              </p>
            </CopyToClipboard>
            <Button small color="primary" onClick={this.didSave}>
              <CloseString />
            </Button>
          </GridItem>
        </GridContainer>
      );
    }

    const isEditing = this.props.user !== null;
    const {
      name,
      email,
      devices,
      locations,
      informative,
      connectivity,
      administrative,
      camera,
      advertising,
      api,
      isWaitingForm,
      formWasSubmit,
      didWantToDelete,
      isValid,
    } = this.state;

    const { resources } = this.props.contractStore.selectedContract;

    return (
      <Fragment>
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteUser}
            didCancel={this.didCancelDeleteAction}
            message={
              <Translate
                id="ContractUsers@deleteUserMessage"
                variables={{ name: this.state.name }}
              >
                $name perderá o acesso ao sistema.
              </Translate>
            }
          />
        )}
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <InputText
              label={<NameString />}
              name="name"
              hasError={formWasSubmit && !isValid.name}
              hasSuccess={formWasSubmit && isValid.name}
              value={name}
              onChange={this.onChangeName}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <InputText
              label={<EmailString />}
              name="email"
              hasError={formWasSubmit && !isValid.email}
              hasSuccess={formWasSubmit && isValid.email}
              value={email}
              disabled={isEditing}
              onChange={this.onChangeEmail}
            />
          </GridItem>
        </GridContainer>

        <GridContainer
          alignItems="center"
          style={{ marginTop: 30, marginBottom: 6 }}
        >
          <GridItem xs sm>
            <p style={{ margin: 0 }}>
              <Translate id="ContractUsers@setRulesTitle">
                Defina permissões do usuário:
              </Translate>
            </p>
          </GridItem>
          <GridItem
            xs={12}
            sm={6}
            style={{ textAlign: "right", marginRight: 8 }}
          >
            <Button
              small
              color="success"
              outline={!this.hasTotalAccess()}
              onClick={() => this.changeAllAccessToNewStatus(true)}
            >
              <Translate id="ContractUsers@fullAccessButtonLabel">
                Acesso Total
              </Translate>{" "}
              <CheckIcon />
            </Button>
            <Button
              small
              color="danger"
              outline={!this.hasZeroAccess()}
              onClick={() => this.changeAllAccessToNewStatus(false)}
            >
              <Translate id="ContractUsers@noAccessButtonLabel">
                Nenhum Acesso
              </Translate>{" "}
              <RemoveIcon />
            </Button>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <InputSwitch
              label={<Translate id="Menu@devices">Dispositivos</Translate>}
              name="devices"
              isActive={devices}
              onChange={this.onChangeSwitch}
              labelInVertical
            />
            <InputSwitch
              label={<Translate id="Menu@locations">Localidades</Translate>}
              name="locations"
              isActive={locations}
              onChange={this.onChangeSwitch}
              labelInVertical
            />
            {resources.informative && (
              <InputSwitch
                label={<Translate id="Menu@informative">Informativo</Translate>}
                name="informative"
                isActive={informative}
                onChange={this.onChangeSwitch}
                labelInVertical
              />
            )}
            {resources.connectivity && (
              <InputSwitch
                label={
                  <Translate id="Menu@connectivity">Conectividade</Translate>
                }
                name="connectivity"
                isActive={connectivity}
                onChange={this.onChangeSwitch}
                labelInVertical
              />
            )}
            {resources.camera && (
              <InputSwitch
                label={<Translate id="Menu@camera">Ambiente</Translate>}
                name="camera"
                isActive={camera}
                onChange={this.onChangeSwitch}
                labelInVertical
              />
            )}
            {resources.advertising && (
              <InputSwitch
                label={<Translate id="Menu@advertising">Publicidade</Translate>}
                name="advertising"
                isActive={advertising}
                onChange={this.onChangeSwitch}
                labelInVertical
              />
            )}
            <InputSwitch
              label={
                <Translate id="Menu@administrative">Administrativo</Translate>
              }
              name="administrative"
              isActive={administrative}
              onChange={this.onChangeSwitch}
              labelInVertical
            />
            <InputSwitch
              label={<Translate id="Menu@developer">Desenvolvedor</Translate>}
              name="api"
              isActive={api}
              onChange={this.onChangeSwitch}
              labelInVertical
            />
          </GridItem>
        </GridContainer>

        <GridContainer justify="space-between">
          <GridItem xs={12} sm={4}>
            <Button block onClick={this.didCancel}>
              <ChevronLeftIcon /> <CancelString />
            </Button>
          </GridItem>
          {isEditing && (
            <GridItem xs={12} sm={4}>
              <Button color="danger" block onClick={this.didWantToDelete}>
                <DeleteString /> <DeleteIcon />
              </Button>
            </GridItem>
          )}
          <GridItem xs={12} sm={4}>
            <Button
              color="primary"
              block
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? <UpdateString /> : <SaveString />} <SaveIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default ContractUserForm;
