// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Textfit } from "react-textfit";
import Avatar from "react-avatar";
import packageJson from "../../../package.json";

import { NavLink } from "react-router-dom";

import HeaderLinks from "../Header/HeaderLinks";

import Translate from "../I18n/Translate";

import { EAppRoutes } from "../../routes/AppRoutes";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Collapse,
  Divider,
} from "@material-ui/core";

// Style
import sidebarStyle from "../../assets/jss/js-styles/components/sidebarStyle";
class SidebarWrapper extends Component {
  render() {
    const { className, user, links, simplyAsMenu, currentUser } = this.props;

    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {links}
        {currentUser && currentUser.isAdmin && simplyAsMenu}
      </div>
    );
  }
}

@observer
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDevicesMenu: this.isRouteActive(EAppRoutes.DEVICES),
      openLocationsMenu: this.isRouteActive(EAppRoutes.LOCATIONS),
      openInformativeMenu: this.isRouteActive(EAppRoutes.INFORMATIVE),
      openConnectivityMenu: this.isRouteActive(EAppRoutes.CONNECTIVITY),
      openAdvertisingMenu: this.isRouteActive(EAppRoutes.ADVERTISING),
      openCameraMenu: this.isRouteActive(EAppRoutes.CAMERA),
      openAdministrativeMenu: this.isRouteActive(EAppRoutes.ADMINISTRATIVE),
      openSimplyAsMenu: this.isRouteActive(EAppRoutes.SIMPLYAS),
    };
  }
  // verifies if routeName is the one active (in browser input)
  isRouteActive = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1;
  };

  toggleCollapse = (collapseKey) => (event) => {
    event.preventDefault();

    this.setState({
      [collapseKey]: !this.state[collapseKey],
    });
  };

  renderBrand = () => {
    const { classes, contractStore } = this.props;
    const { name, isChecked } = contractStore.selectedContract;
    return (
      <div className={classes.logo}>
        <a
          href="/"
          onClick={(event) => {
            event.preventDefault();

            if (contractStore.contractsList.length > 1) {
              contractStore.didWantToRemoveSelectedContract();
              contractStore.getContractsList();
            } else {
              this.props.history.push(EAppRoutes.ADMINISTRATIVE + "/contract");
            }
          }}
          className={classes.logoNormal}
        >
          <Textfit mode="single" max={20}>
            <div className={classes.logoSpan}>
              {name}
              {isChecked && (
                <i
                  className={classnames({
                    "fas fa-badge-check": true,
                    [classes.verifiedIcon]: true,
                  })}
                />
              )}
            </div>
          </Textfit>
        </a>
      </div>
    );
  };

  renderUser = () => {
    const { classes, currentUser } = this.props;
    return (
      <div className={classes.user}>
        <div className={classes.photo}>
          <Avatar
            className={classes.avatarImg}
            name={currentUser.name}
            email={currentUser.email}
            size={30}
            round
          />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to="/my-profile"
              className={classes.itemLink + " " + classes.userCollapseButton}
            >
              <ListItemText
                primary={currentUser.name}
                disableTypography={true}
                className={classes.itemText + " " + classes.userItemText}
              />
            </NavLink>
          </ListItem>
        </List>
      </div>
    );
  };

  renderButtonIcons = () => {
    const { classes, activeRoute } = this.props;
    return (
      <Hidden mdUp>
        <div
          className={classes.user}
          style={{ textAlign: "center", zIndex: 100 }}
        >
          <HeaderLinks
            handleNotificationsDrawerToggle={
              this.props.handleNotificationsDrawerToggle
            }
            tutorialKey={activeRoute ? activeRoute.tutorialKey || false : false}
          />
          <Divider />
        </div>
      </Hidden>
    );
  };

  renderLinks = (routes) => {
    const { classes } = this.props;
    return (
      <List className={classes.list} style={{ marginBottom: 60 }}>
        {routes.map((prop, key) => {
          if (prop.collapse) {
            const navLinkClasses = classnames({
              [classes.itemLink]: true,
              [classes.collapseActive]: this.isRouteActive(prop.path),
            });
            return (
              <ListItem key={key} className={classes.item}>
                {prop.nameId === "Menu@simplyAs" && (
                  <Divider className={classes.listDivider} />
                )}
                <NavLink
                  to={"#"}
                  className={navLinkClasses}
                  onClick={this.toggleCollapse(prop.state)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <i className={`fas fa-${prop.icon}`} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Translate id={prop.nameId}>{prop.name}</Translate>
                    }
                    secondary={
                      <b
                        className={
                          classes.caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={classes.itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      const navLinkClasses = classnames({
                        [classes.collapseItemLink]: true,
                        [classes.blue]: this.isRouteActive(prop.path),
                      });
                      const collapseItemMini = classes.collapseItemMini;
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              <i className={`fas fa-${prop.icon}`} />
                            </span>
                            <ListItemText
                              primary={
                                <Translate id={prop.nameId}>
                                  {prop.name}
                                </Translate>
                              }
                              disableTypography={true}
                              className={classes.collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
                <Divider className={classes.listDivider} />
              </ListItem>
            );
          }
          const navLinkClasses = classnames({
            [classes.itemLink]: true,
            [classes.blue]: this.isRouteActive(prop.path),
          });
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={classes.itemIcon}>
                  <i className={`fas fa-${prop.icon}`} />
                </ListItemIcon>
                <ListItemText
                  primary={<Translate id={prop.nameId}>{prop.name}</Translate>}
                  disableTypography={true}
                  className={classes.itemText}
                />
              </NavLink>
              {prop.nameId !== "Menu@logout" && (
                <Divider className={classes.listDivider} />
              )}
            </ListItem>
          );
        })}
      </List>
    );
  };

  renderDrawer = ({ isMobile }) => {
    const {
      classes,
      routes,
      currentUser,
      simplyAsMenuRoutes,
      isMobileDrawerOpen,
      handleMobileDrawerToggle,
    } = this.props;
    return (
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor={isMobile ? "right" : "left"}
        open={isMobile ? isMobileDrawerOpen : true}
        classes={{
          paper: classes.drawerPaper + " " + classes.blackBackground,
        }}
        onClose={handleMobileDrawerToggle}
        ModalProps={{
          keepMounted: isMobile, // Better open performance on mobile.
        }}
      >
        {this.renderBrand()}
        {this.renderButtonIcons()}
        <SidebarWrapper
          className={classes.sidebarWrapper}
          currentUser={currentUser}
          user={this.renderUser()}
          links={this.renderLinks(routes)}
          simplyAsMenu={this.renderLinks(simplyAsMenuRoutes)}
        />
        <div
          className={classes.background}
          style={{
            backgroundImage: `url("${this.props.settingsStore.sidebarBgImage}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
          }}
        />
      </Drawer>
    );
  };

  render() {
    return (
      <div ref="mainPanel">
        <Hidden mdUp>{this.renderDrawer({ isMobile: true })}</Hidden>
        <Hidden smDown>{this.renderDrawer({ isMobile: false })}</Hidden>
        <span className={this.props.classes.version}>
          v{packageJson.version}
        </span>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(sidebarStyle)(Sidebar);
