// @ts-nocheck
import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Button, LinkButton } from "../Button";

// Style
import { dangerColor } from "../../assets/jss/app-js-styles";
const style = {
  badge: {
    zIndex: "4",
    position: "absolute",
    top: 2,
    border: "1px solid #FFF",
    right: 0,
    fontSize: "12px",
    background: dangerColor,
    color: "#FFFFFF",
    minWidth: 20,
    height: 20,
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "18px",
    verticalAlign: "middle",
    display: "block",
  },
};

@inject(
  "alfredStore",
  "contractStore",
  "tutorialsStore",
  "ordersStore",
  "notificationsStore"
)
@observer
class HeaderLinks extends Component {
  renderBadge = (badgeCount) => {
    if (badgeCount < 1) return null;
    return (
      <span className={this.props.classes.badge}>
        {badgeCount > 99 ? 99 : badgeCount}
      </span>
    );
  };

  openTutorial = () => {
    this.props.tutorialsStore.didSelectTutorialKey(this.props.tutorialKey);
  };

  render() {
    const {
      contractStore,
      tutorialsStore,
      ordersStore,
      notificationsStore,
      handleNotificationsDrawerToggle,
      tutorialKey,
    } = this.props;

    const selectedOrderHasProducts = ordersStore.selectedOrderProductsCount > 0;

    return (
      <div>
        {tutorialsStore.isAvailable && tutorialKey && (
          <Button color="transparent" justIcon onClick={this.openTutorial}>
            <i className="fas fa-question-circle" />
          </Button>
        )}
        {contractStore.selectedContract.isAffiliateContract && (
          <Fragment>
            {selectedOrderHasProducts && (
              <LinkButton color="transparent" justIcon to="/checkout">
                <i className="fas fa-shopping-basket" />
                {this.renderBadge(ordersStore.selectedOrderProductsCount)}
              </LinkButton>
            )}
            <LinkButton color="transparent" justIcon to="/financial#orders">
              <i className="fas fa-clipboard-list" />
            </LinkButton>
          </Fragment>
        )}

        <Button
          color="transparent"
          justIcon
          onClick={this.props.alfredStore.toggle}
        >
          <i className="fas fa-search" />
        </Button>
        <Button
          color="transparent"
          justIcon
          onClick={handleNotificationsDrawerToggle}
        >
          <i className="fas fa-bell" />
          {this.renderBadge(notificationsStore.unreadNotificationsCount)}
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(HeaderLinks);
