type Contract = {
  resources: { [key: string]: boolean };
  rules: { [key: string]: boolean };
};

const resourceToRouteMap = {
  equipmentMonitor: "devices",
  locationControl: "locations",
  informative: "informative",
  connectivity: "connectivity",
  camera: "camera",
  advertising: "advertising",
  administrative: "administrative",
  developer: "developer",
};

export function computeBlockedRoutes(contract?: Contract) {
  if (!contract || !contract.resources || !contract.rules) {
    return [];
  }

  const { resources, rules } = contract;
  const blockedRoutes: string[] = [];

  Object.entries(resourceToRouteMap).forEach(([key, value]) => {
    // the default true is necessary because of the way backend sets this
    // rules should block routes only if explicitly denied by backend,
    // otherwise the route will be blocked only by the resource.
    if (!(resources[key] && (rules[key] ?? true))) {
      blockedRoutes.push(value);
    }
  });

  return blockedRoutes;
}
