// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import withFetchingStore from "../withFetchingStore";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";
import Tooltip from "../../components/Tooltip/Tooltip";
import { GridContainer, GridItem } from "../../components/Grid";
import { SimpleCard } from "../../components/Card";
import { Button } from "../../components/Button";
import Modal from "../../components/Modal/Modal";
import ContractUserForm from "../../components/AppForm/ContractUserForm";
import SearchBar from "../../components/SearchBar/SearchBar";
import Table from "../../components/Table/Table";

import AdvancedOnlyFeatureContainer from "../../layouts/upgradeToAdvancedContractLayout/AdvancedOnlyFeatureContainer";

// Translate
import { NameString, EmailString } from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Utils Stores
import { ContractAvailableResources } from "../../store/ContractStore/ContractStore";

// Icons
import {
  CheckIcon,
  BanIcon,
  TerminalsIcon,
  PlaceIcon,
  TvIcon,
  BusinessCenterIcon,
  CameraIcon,
  CodeIcon,
  AddIcon,
  WifiIcon,
  BullhornIcon,
} from "../../components/Icon/FontAwesome";

// Style
import {
  successColor,
  dangerColor,
  cardTitle,
} from "../../assets/jss/app-js-styles";
const styles = {
  ...cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  success: {
    color: successColor,
  },
  danger: {
    color: dangerColor,
  },
};

@withLocalized
@inject("authStore", "contractStore")
@observer
class ContractUsersPageContainer extends Component {
  state = {
    isOpenModal: false,
    selectedUser: null,
  };

  componentDidMount() {
    this.props.contractStore.getUsersRulesForSelectedContract();
  }

  didCloseModal = () => {
    this.props.contractStore.getUsersRulesForSelectedContract();
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedUser: null,
          });
        }, 200);
      }
    );
  };

  didWantToAddNewUser = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  didWantToEditUser = (selectedUserToken) => {
    const selectedUser = this.props.contractStore.usersForSelectedContract.find(
      (user) => user.rulesToken === selectedUserToken
    );

    this.setState({
      selectedUser,
      isOpenModal: true,
    });
  };

  renderRuleCell = (hasAccess) => (
    <span
      className={
        hasAccess ? this.props.classes.success : this.props.classes.danger
      }
    >
      {hasAccess ? <CheckIcon /> : <BanIcon />}
    </span>
  );

  getIconForRuleHeader = (id) => {
    switch (id) {
      case "devices":
        return <TerminalsIcon />;
      case "locations":
        return <PlaceIcon />;
      case "connectivity":
        return <WifiIcon />;
      case "administrative":
        return <BusinessCenterIcon />;
      case "camera":
        return <CameraIcon />;
      case "advertising":
        return <BullhornIcon />;
      case "api":
        return <CodeIcon />;
      case "informative":
      default:
        return <TvIcon />;
    }
  };

  defaultRuleHeader = ({ title, id }) => {
    return {
      Header: (props) => (
        <Tooltip title={title}>
          <p style={{ margin: 0 }}>{this.getIconForRuleHeader(id)}</p>
        </Tooltip>
      ),
      sortable: false,
      filterable: false,
      resizable: false,
      width: 50,
      accessor: id,
      getHeaderProps: () => {
        return {
          style: {
            textAlign: "center",
          },
        };
      },
      getProps: () => {
        return {
          style: {
            textAlign: "center",
          },
        };
      },
    };
  };

  render() {
    const { localizedString, contractStore } = this.props;
    const { selectedContract } = contractStore;

    const { selectedUser, isOpenModal } = this.state;

    const contractResources = selectedContract.resources;

    const users = contractStore.filteredUsersForSelectedContract;

    const columns = [
      {
        Header: <NameString />,
        accessor: "name",
      },
      {
        Header: <EmailString />,
        accessor: "email",
      },
      {
        accessor: "devices",
        ...this.defaultRuleHeader({
          id: "devices",
          title: localizedString({
            id: "Menu@devices",
            defaultString: "Dispositivos",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "locations",
          title: localizedString({
            id: "Menu@locations",
            defaultString: "Localidades",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "informative",
          title: localizedString({
            id: "Menu@informative",
            defaultString: "Informativo",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "connectivity",
          title: localizedString({
            id: "Menu@connectivity",
            defaultString: "Conectividade",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "camera",
          title: localizedString({
            id: "Menu@camera",
            defaultString: "Ambiente",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "advertising",
          title: localizedString({
            id: "Menu@advertising",
            defaultString: "Publicidade",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "administrative",
          title: localizedString({
            id: "Menu@administrative",
            defaultString: "Administrativo",
          }),
        }),
      },
      {
        ...this.defaultRuleHeader({
          id: "api",
          title: localizedString({
            id: "Menu@developer",
            defaultString: "Desenvolvedor",
          }),
        }),
      },
    ];

    return (
      <Fragment>
        <Modal isOpen={isOpenModal} didCloseModal={this.didCloseModal}>
          <ContractUserForm
            user={selectedUser}
            didSave={this.didCloseModal}
            didCancel={this.didCloseModal}
            didDeleteUser={this.didCloseModal}
          />
        </Modal>

        <SimpleCard cardStyle={{ marginTop: 0 }}>
          <GridContainer>
            {contractStore.usersForSelectedContract.length > 4 && (
              <GridItem xs={12}>
                <SearchBar
                  marginBottom
                  value={contractStore.usersSearchString}
                  onChange={contractStore.setUsersSearchString}
                />
              </GridItem>
            )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                data={users.map((user) => {
                  return {
                    id: user.rulesToken,
                    name: user.name,
                    email: user.email,
                    devices: this.renderRuleCell(user.rules.devices),
                    locations: this.renderRuleCell(user.rules.locations),
                    informative: this.renderRuleCell(user.rules.informative),
                    connectivity: this.renderRuleCell(user.rules.connectivity),
                    camera: this.renderRuleCell(user.rules.camera),
                    advertising: this.renderRuleCell(user.rules.advertising),
                    administrative: this.renderRuleCell(
                      user.rules.administrative
                    ),
                    api: this.renderRuleCell(user.rules.api),
                  };
                })}
                columns={columns.filter((column) => {
                  const matchContractResources = ContractAvailableResources.includes(
                    column.accessor
                  )
                    ? contractResources[column.accessor]
                    : true;

                  return matchContractResources;
                })}
                getTrProps={(state, rowInfo, column) => ({
                  style: {
                    cursor: rowInfo && rowInfo.original ? "pointer" : "auto",
                  },
                })}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: () => {
                      if (rowInfo && rowInfo.original) {
                        this.didWantToEditUser(rowInfo.original.id);
                      }
                    },
                  };
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer style={{ marginTop: 20 }}>
            <GridItem xs={12}>
              <Button block color="primary" onClick={this.didWantToAddNewUser}>
                <Translate id="ContractUsers@addButton">
                  Adicionar Usuário
                </Translate>{" "}
                <AddIcon />
              </Button>
            </GridItem>
          </GridContainer>
        </SimpleCard>
      </Fragment>
    );
  }
}

export default withFetchingStore(
  withStyles(styles)(ContractUsersPageContainer)
);
