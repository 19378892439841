import { Component } from "react";
import { inject, observer } from "mobx-react";

import SnackbarNotificationContainer from "./components/Notifications/SnackbarNotificationContainer";
import SoundManagerContainer from "./components/Sound/SoundManagerContainer";

import ErrorCatcher from "./components/ErrorCatcher";
import AppRouter from "./routes/AppRouter";

import KeyboardEventsService from "./services/KeyboardEventsService";

interface IAppProps {}

interface InjectedProps extends IAppProps {
  keyboardEventsService: KeyboardEventsService;
}

@inject("keyboardEventsService")
@observer
class App extends Component<IAppProps> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    document.addEventListener(
      "keydown",
      this.injected.keyboardEventsService.notify,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "keydown",
      this.injected.keyboardEventsService.notify,
      false
    );
  }
  render() {
    return (
      <ErrorCatcher showErrorOnTheScreen>
        <SnackbarNotificationContainer />
        <SoundManagerContainer />
        <AppRouter />
      </ErrorCatcher>
    );
  }
}

export default App;
