// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import { Hidden } from "@material-ui/core";

// Components
import {
  ButtonGroup,
  InputText,
  SubmitButton,
  InputSelect,
  InputSwitch,
} from "../Form";
import Validate, { ValidationType } from "../Form/Validate";
import { withLocalized } from "../HOC";
import { ExpansionPanelContainer } from "../ExpansionPanel";

import { GridContainer, GridItem } from "../Grid";

// Translate
import {
  NameString,
  IdiomString,
  TimezoneString,
  FormErrorString,
  UpdateString,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Utils
import { TERMINAL_DISABLED_OPTIONS } from "../../Model/TerminalModel";
import { timezoneOptionsList } from "../../utils/TimezoneUtils";

// Icons
import { SaveIcon } from "../Icon";

@withLocalized
@inject(
  "authStore",
  "contractStore",
  "groupsStore",
  "notificationsStore",
  "terminalsStore"
)
@observer
class DeviceForm extends Component {
  constructor(props) {
    super(props);

    const { device } = props;

    this.state = {
      name: device.name,
      comments: device.comments,
      disabledList: device.disabledList,
      screenResolution: device.mediaPreferences.screen_resolution || "auto",
      screenRotation: device.mediaPreferences.screen_rotation || "default",
      timezone: device.timezone,
      language: device.language,
      isLending: device.isLending,
      location:
        device?.group && device.group.token?.length > 0 ? device.group : null,
      monitorWifiIsActive: device.monitorWifiIsActive,
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        name: true,
      },
    };
  }

  componentDidMount() {
    this.props.groupsStore.getGroupsList();
  }

  onChangeComments = ({ target }) => {
    const { value } = target;

    this.setState({
      comments: value,
    });
  };

  onChangeButtonGroup = (name, selectedValue) => {
    this.setState({
      [name]: selectedValue,
    });
  };

  onChangeInputText = ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value,
      isValid: {
        ...this.state.isValid,
        [key]: Validate(value, ValidationType.REQUIRED),
      },
    });
  };

  onChangeTimezone = (_, selectedValue) => {
    this.setState({
      timezone: selectedValue.value,
    });
  };

  onChangeLocation = (_, selectedValue) => {
    this.setState({
      location: selectedValue,
    });
  };

  onChangeSwitch = (isDisabled) => (option) => {
    this.setState((prevState) => {
      if (option === TERMINAL_DISABLED_OPTIONS.MONITOR_WIFI) {
        return {
          monitorWifiIsActive: !prevState.monitorWifiIsActive,
        };
      }

      return {
        disabledList: isDisabled
          ? prevState.disabledList.filter(($0) => $0 !== option)
          : [...prevState.disabledList, option],
      };
    });
  };

  canSubmitForm = () => {
    const { isValid } = this.state;

    return isValid.name;
  };

  submitForm = () => {
    const {
      name,
      comments,
      disabledList,
      screenResolution,
      screenRotation,
      timezone,
      location,
      monitorWifiIsActive,
      language,
      isLending,
    } = this.state;
    const { device } = this.props;

    this.props.terminalsStore
      .update({
        token: device.token,
        name,
        comments,
        disabledList,
        mediaPreferences: {
          ...device.mediaPreferences,
          screen_resolution: screenResolution,
          screen_rotation: screenRotation,
        },
        timezone,
        language,
        isLending,
        locationToken: location?.token || null,
        monitorWifiIsActive,
      })
      .then(this.props.didSave)
      .catch(() => this.submitHasError());
  };

  onSubmit = (event) => {
    if (event) event.preventDefault();
    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message: <FormErrorString />,
            color: "danger",
          });
        }
      );
    }
  };

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  terminalOptionLabel = (option) => {
    switch (option) {
      case TERMINAL_DISABLED_OPTIONS.CAMERA:
        return (
          <Translate id="Device@cameraOptionLabel">
            Análise de ambientes
          </Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.CLOCK:
        return (
          <Translate id="Device@clockOptionLabel">Recurso Relógio</Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.LOG:
        return (
          <Translate id="Device@logOptionLabel">
            Logs das aplicações câmera e vídeo
          </Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.LOGO:
        return (
          <Translate id="Device@logoOptionLabel">Recurso Logotipo</Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.MESSAGES:
        return (
          <Translate id="Device@messagesOptionLabel">
            Recurso Mensagens na tela
          </Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.SOUND_NORMALIZER:
        return (
          <Translate id="Device@soundNormalizerOptionLabel">
            Equalização do som
          </Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.SPEEDTEST:
        return (
          <Translate id="Device@speedtestOptionLabel">
            Teste de velocidade da Internet
          </Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.MONITOR_WIFI:
        return (
          <Translate id="Device@monitorWifiIsActive">
            Análise de dispositivos no ambiente
          </Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.VIDEO:
        return (
          <Translate id="Device@videoOptionLabel">Recurso Vídeo</Translate>
        );
      case TERMINAL_DISABLED_OPTIONS.WIFI:
      default:
        return (
          <Translate id="Device@wifiOptionLabel">
            Compartilhamento da Internet
          </Translate>
        );
    }
  };

  hasAccessTo = (resource) => {
    if (this.props.authStore.currentUserIsAdmin) return true;
    return this.props.contractStore.selectedContract.hasAccessTo(resource);
  };

  renderDisabledOption = (option) => {
    const isDisabled = this.state.disabledList.includes(option);
    return (
      <InputSwitch
        label={this.terminalOptionLabel(option)}
        name={option}
        isActive={
          option === TERMINAL_DISABLED_OPTIONS.MONITOR_WIFI
            ? this.state.monitorWifiIsActive
            : !isDisabled
        }
        onChange={this.onChangeSwitch(isDisabled)}
      />
    );
  };

  renderDisabledOptions = () => {
    return (
      <GridContainer style={{ marginTop: 30 }}>
        <GridItem xs={12}>
          <p>
            <Translate id="Device@videoDisabledListOptionsTitle">
              Customize o seu ambiente habilitando/desabilitando permissões de
              execução dos recursos nos dispositivos que fazem parte de
              localidades de acordo com suas configurações.
            </Translate>
          </p>
          <GridContainer>
            {this.hasAccessTo("informative") && (
              <GridItem xs={12} md>
                <h4>
                  <Translate id="Device@videoDisabledOptionsTitle">
                    VIDEO
                  </Translate>
                </h4>
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.VIDEO)}
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.CLOCK)}
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.MESSAGES)}
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.LOGO)}
              </GridItem>
            )}
            {this.hasAccessTo("connectivity") && (
              <GridItem xs={12} md style={{ position: "relative" }}>
                {this.hasAccessTo("informative") && (
                  <Hidden smDown>
                    <div
                      style={{
                        position: "absolute",
                        height: "94%",
                        left: 0,
                        width: 1,
                        backgroundColor: "#ddd",
                      }}
                    />
                  </Hidden>
                )}
                <h4>
                  <Translate id="Device@wifiDisabledOptionsTitle">
                    WIFI
                  </Translate>
                </h4>
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.WIFI)}
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.SPEEDTEST)}
                {this.renderDisabledOption(
                  TERMINAL_DISABLED_OPTIONS.MONITOR_WIFI
                )}
              </GridItem>
            )}
            {this.hasAccessTo("camera") && (
              <GridItem xs={12} md style={{ position: "relative" }}>
                {(this.hasAccessTo("informative") ||
                  this.hasAccessTo("connectivity")) && (
                  <Hidden smDown>
                    <div
                      style={{
                        position: "absolute",
                        height: "94%",
                        left: 0,
                        width: 1,
                        backgroundColor: "#ddd",
                      }}
                    />
                  </Hidden>
                )}
                <h4>
                  <Translate id="Device@cameraDisabledOptionsTitle">
                    CAMERA
                  </Translate>
                </h4>
                {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.CAMERA)}
              </GridItem>
            )}
            <GridItem xs={12} md style={{ position: "relative" }}>
              {(this.hasAccessTo("informative") ||
                this.hasAccessTo("connectivity") ||
                this.hasAccessTo("camera")) && (
                <Hidden smDown>
                  <div
                    style={{
                      position: "absolute",
                      height: "94%",
                      left: 0,
                      width: 1,
                      backgroundColor: "#ddd",
                    }}
                  />
                </Hidden>
              )}
              <h4>
                <Translate id="Device@systemDisabledOptionsTitle">
                  SISTEMA
                </Translate>
              </h4>
              {this.renderDisabledOption(
                TERMINAL_DISABLED_OPTIONS.SOUND_NORMALIZER
              )}
              {this.renderDisabledOption(TERMINAL_DISABLED_OPTIONS.LOG)}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  renderConfigurationForm = () => {
    const {
      name,
      comments,
      timezone,
      location,
      language,
      screenResolution,
      screenRotation,
      isLending,
      formWasSubmit,
      isValid,
    } = this.state;
    const { localizedString, groupsStore } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                label={<NameString />}
                name="name"
                value={name}
                hasSuccess={formWasSubmit && isValid.name}
                hasError={formWasSubmit && !isValid.name}
                onChange={this.onChangeInputText}
                isRequired
                maxLength={30}
              />
            </GridItem>
            <GridItem xs={12}>
              <InputText
                label={
                  <Translate id="Device@commentsLabel">Comentários</Translate>
                }
                name="comments"
                multiline
                maxLength={140}
                value={comments}
                onChange={this.onChangeComments}
              />
            </GridItem>
            {!this.props.device.isEnterprise && (
              <GridItem xs={12} md={6}>
                <ButtonGroup
                  label={
                    <Translate id="Device@screenResolutionLabel">
                      Resolução de vídeo
                    </Translate>
                  }
                  name="screenResolution"
                  value={screenResolution}
                  color="success"
                  onChange={this.onChangeButtonGroup}
                  options={[
                    {
                      value: "auto",
                      label: this.props.localizedString({
                        id: "Common@auto",
                        defaultString: "Automático",
                      }),
                    },
                    {
                      value: "full_hd",
                      label: "FullHD (1080P)",
                    },
                    {
                      value: "hd",
                      label: "HD (720P)",
                    },
                    {
                      value: "sd",
                      label: "SD (480P)",
                    },
                  ]}
                />
              </GridItem>
            )}
            <GridItem xs={12} md={6}>
              <ButtonGroup
                label={<IdiomString />}
                name="language"
                value={language}
                color="success"
                onChange={this.onChangeButtonGroup}
                options={[
                  {
                    value: "en",
                    label: localizedString({
                      id: "I18n@english",
                      defaultString: "Inglês",
                    }),
                  },
                  {
                    value: "pt",
                    label: localizedString({
                      id: "I18n@portuguese",
                      defaultString: "Português",
                    }),
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <ButtonGroup
                label={
                  <Translate id="Device@screenRotationLabel">
                    Orientação de Tela
                  </Translate>
                }
                name="screenRotation"
                value={screenRotation}
                color="success"
                onChange={this.onChangeButtonGroup}
                options={[
                  {
                    value: "default",
                    label: this.props.localizedString({
                      id: "Common@default",
                      defaultString: "Normal",
                    }),
                  },
                  {
                    value: "flip",
                    label: "Virado",
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <InputSelect
                withPortal
                label={<TimezoneString />}
                name="timezone"
                value={{ label: timezone, value: timezone }}
                options={timezoneOptionsList}
                onChange={this.onChangeTimezone}
              />
            </GridItem>
            {this.props.authStore.currentUserCanSupport && (
              <GridItem xs={12} md={6}>
                <ButtonGroup
                  label={
                    <Translate id="Device@isLendingLabel">
                      Proprietário do equipamento
                    </Translate>
                  }
                  name="isLending"
                  value={isLending}
                  color="success"
                  onChange={this.onChangeButtonGroup}
                  options={[
                    {
                      value: false,
                      label: localizedString({
                        id: "Device@notALendingEquipment",
                        defaultString: "Cliente",
                      }),
                    },
                    {
                      value: true,
                      label: localizedString({
                        id: "Device@itIsALendingEquipment",
                        defaultString: "Comodato",
                      }),
                    },
                  ]}
                />
              </GridItem>
            )}

            <GridItem xs={12} md={6}>
              <InputSelect
                withPortal
                label={
                  <Translate id="Device@locationInfo">Localidade</Translate>
                }
                name="location"
                value={location}
                isLoading={groupsStore.isFetching}
                options={groupsStore.groupsList}
                onChange={this.onChangeLocation}
                labelKey="name"
                valueKey="token"
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  getPanels = () => {
    return [
      {
        title: (
          <Translate id="Device@configurationFormPanelTitle">
            Configurações
          </Translate>
        ),
        subtitle: (
          <Translate id="Device@configurationFormPanelSubtitle">
            Nome, comentário, idioma e muito mais...
          </Translate>
        ),
        details: this.renderConfigurationForm(),
      },
      {
        title: (
          <Translate id="Device@disabledListOptionsPanelTitle">
            Permissões
          </Translate>
        ),
        subtitle: (
          <Translate id="Device@disabledListOptionsPanelSubtitle">
            Customize as permissões de execução dos recursos do dispositivo
          </Translate>
        ),
        details: this.renderDisabledOptions(),
      },
    ];
  };

  render() {
    const { isWaitingForm } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            {this.props.device.isEnterprise ? (
              this.renderConfigurationForm()
            ) : (
              <ExpansionPanelContainer
                selectedIndex={0}
                panels={this.getPanels()}
              />
            )}
          </GridItem>
        </GridContainer>

        <GridContainer justify="space-between" style={{ marginTop: 20 }}>
          <GridItem xs>
            <SubmitButton
              onClick={this.onSubmit}
              block
              large={false}
              isLoading={isWaitingForm}
            >
              <UpdateString /> <SaveIcon />
            </SubmitButton>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

export default DeviceForm;
