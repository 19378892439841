// @ts-nocheck
import { Component, Fragment } from "react";
import classnames from "classnames";
import { inject, observer } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import Tooltip from "../Tooltip/Tooltip";
import InputText from "../Form/InputText";

import ChatView from "../Chat/ChatView";
import ChatListItem from "./ChatListItem";

// Translate
import { BackString, CloseString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Util
import {
  KEYCODE,
  IMappedKeyFunctions,
} from "../../services/KeyboardEventsService";

// Style
import { primaryColor, warningColor } from "../../assets/jss/app-js-styles";
const styles = {
  chatContainer: {
    position: "relative",
    backgroundColor: "#F8FAF9",
    borderTopLeftRadius: 8,
    minHeight: 200,
    maxHeight: "90vh",
    overflowY: "scroll",
    margin: 0,
    width: "100%",
  },
  header: {
    backgroundColor: primaryColor,
    borderTopLeftRadius: 8,
    position: "sticky",
    top: 0,
    zIndex: 100,
    marginBottom: 10,
  },
  historyHeader: {
    backgroundColor: warningColor,
  },
  title: {
    color: "#fff",
    margin: "8px 0",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  createNewChatButtonContainer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingBottom: 4,
    textAlign: "center",
  },
};

@inject("chatStore", "notificationsStore", "keyboardEventsService")
@observer
class ChatsListContainer extends Component {
  state = { showHistory: false };

  componentDidMount() {
    this.setKeyboardListener();
  }
  componentWillUnmount() {
    this.removeKeyboardListener();
  }

  setKeyboardListener = () => {
    this.props.keyboardEventsService.setListener(this, this.mapKeyFunctions);
  };
  removeKeyboardListener = () => {
    this.props.keyboardEventsService.removeListener(this);
  };

  mapKeyFunctions = (): IMappedKeyFunctions => {
    return {
      [KEYCODE.ESC]: (event) => {
        event.preventDefault();
        this.props.chatStore.closeChat();
      },
    };
  };

  onChangeNewChatTitle = ({ target }) => {
    this.props.chatStore.onChangeNewChatTitle(target.value);
  };

  onChangeMessage = ({ target }) => {
    this.props.chatStore.onChangeMessage(target.value);
  };

  didSelectChat = (selectedChatId) => {
    this.props.chatStore.didSelectChat(selectedChatId);

    if (this.state.showHistory) {
      this.toggleHistory();
    }
  };

  didWantToAddNewChat = () => {
    this.props.chatStore.didWantToStartNewChatWithSubjectAndMessage("", "");
  };

  cancelAddingNewChat = () => {
    this.props.chatStore.cancelAddingNewChat();
  };

  toggleHistory = () => {
    this.setState((prevState) => {
      return { showHistory: !prevState.showHistory };
    });
  };

  createNewChat = async () => {
    const { chatStore } = this.props;
    if (chatStore.message.length < 1) {
      this.props.notificationsStore.addSnackbarNotification({
        message: (
          <Translate id="Chat@messageIsInvalidError">
            Por favor escreva algo em "Compo podemos ajuda-lo?"
          </Translate>
        ),
        color: "danger",
      });
    } else {
      const chatId = await chatStore.createNewChat({
        userId: chatStore.selectedUserId,
        shouldSelectChat: true,
      });
      if (chatId) {
        chatStore.addTextMessageForCurrentAuthor();
      }
    }
  };

  renderChatsList = (userChats) => {
    if (userChats.length > 0)
      return (
        <GridContainer>
          {userChats.map((chat) => (
            <ChatListItem
              key={chat.id}
              chat={chat}
              currentAuthor={this.props.chatStore.currentAuthor}
              didSelectChat={this.didSelectChat}
            />
          ))}
        </GridContainer>
      );

    return (
      <p style={{ textAlign: "center", margin: "40px 0" }}>
        <Translate id="Chat@nothingFoundMessage">
          Nenhuma conversa encontrada.
        </Translate>
      </p>
    );
  };

  renderAddNewChatForm = () => {
    const { classes } = this.props;
    const { newChatTitle, message } = this.props.chatStore;
    return (
      <GridContainer className={classes.chatContainer}>
        <GridItem xs={12}>
          <InputText
            label={<Translate id="Chat@newChatTitleLabel">Assunto</Translate>}
            name="newChatTitle"
            value={newChatTitle}
            onChange={this.onChangeNewChatTitle}
            maxLength={60}
          />
          <InputText
            label={
              <Translate id="Chat@newChatMessageLabel">
                Como podemos ajuda-lo?
              </Translate>
            }
            name="message"
            value={message}
            onChange={this.onChangeMessage}
            multiline
          />
        </GridItem>
        <GridItem>
          <Button
            color="default"
            block
            justIcon
            onClick={this.cancelAddingNewChat}
          >
            <i className="fas fa-chevron-left" />
          </Button>
        </GridItem>
        <GridItem xs>
          <Button color="primary" block onClick={this.createNewChat}>
            <Translate id="Chat@sendNewMessageToChatButtonLabel">
              Enviar mensagem
            </Translate>{" "}
            <i className="fas fa-paper-plane" />
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { chatStore } = this.props;
    if (!chatStore.isAvailable) return null;

    const { classes } = this.props;
    const { showHistory } = this.state;

    const { selectedChat, userChats, isAddingNewChat } = chatStore;
    if (selectedChat) {
      return <ChatView />;
    }

    return (
      <GridContainer className={classes.chatContainer}>
        <GridItem xs={12} style={{ marginBottom: 20 }}>
          <GridContainer
            justify="space-between"
            alignItems="center"
            className={classnames({
              [classes.header]: true,
              [classes.historyHeader]: showHistory,
            })}
          >
            <GridItem xs={8}>
              <p className={classes.title}>
                {showHistory ? (
                  <Fragment>
                    <Button
                      small
                      justIcon
                      color="transparent"
                      onClick={showHistory && this.toggleHistory}
                      disableRipple={!showHistory}
                    >
                      <i className="fas fa-chevron-left" />
                    </Button>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={showHistory && this.toggleHistory}
                    >
                      <BackString />
                    </span>
                  </Fragment>
                ) : isAddingNewChat ? (
                  <Translate id="Chat@addNewChatTitle">
                    Enviar uma mensagem
                  </Translate>
                ) : (
                  <Translate id="Chat@userChatsListTitle">
                    Suas conversas
                  </Translate>
                )}
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginRight: -15, textAlign: "right" }}>
              {!showHistory && !isAddingNewChat && (
                <Tooltip
                  title={
                    <Translate id="Chat@openHistoryButtonlabel">
                      Ver conversas anteriores
                    </Translate>
                  }
                >
                  <Button
                    small
                    justIcon
                    style={{ color: "#fff" }}
                    color="transparent"
                    onClick={this.toggleHistory}
                  >
                    <i className="fas fa-history" />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title={<CloseString />}>
                <Button
                  justIcon
                  style={{ color: "#fff" }}
                  color="transparent"
                  onClick={chatStore.closeChat}
                >
                  <i className="fas fa-times" />
                </Button>
              </Tooltip>
            </GridItem>
          </GridContainer>
          {isAddingNewChat
            ? this.renderAddNewChatForm(classes)
            : this.renderChatsList(
                userChats.filter(($0) =>
                  showHistory
                    ? $0.isClosed === true
                    : $0[chatStore.currentAuthor].unreadMessagesCount > 0 ||
                      $0.isClosed === false
                )
              )}
          {!isAddingNewChat && (
            <Button
              color="transparent"
              small
              block
              onClick={this.toggleHistory}
            >
              {!showHistory && (
                <span
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <i className="fas fa-chevron-left" />{" "}
                  <Translate id="Chat@openHistoryButtonlabel">
                    Ver conversas anteriores
                  </Translate>{" "}
                </span>
              )}
            </Button>
          )}
        </GridItem>
        {!isAddingNewChat && !showHistory && (
          <div className={classes.createNewChatButtonContainer}>
            <Button
              color="primary"
              round
              block
              onClick={this.didWantToAddNewChat}
            >
              <Translate id="Chat@createNewChatButtonLabel">
                Nova conversa
              </Translate>{" "}
              <i className="fas fa-paper-plane" />
            </Button>
          </div>
        )}
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ChatsListContainer);
