// @ts-nocheck
import { observable, computed, action } from "mobx";

// Model
import LocationModel from "../../Model/LocationModel";

// Utils Stores
import { EInternalEvents } from "../InternalEventsStore/InternalEventsStore";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class GroupsStore {
  @observable isFetching = false;
  @observable groupsList = [];
  @observable searchString = "";

  constructor(internalEventsStore, notificationsStore, modboxAPIService) {
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;
    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.groupsList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString) => {
    this.searchString = searchString;
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };
  @action
  setGroupsList = (newValue) => {
    this.groupsList = newValue;
  };

  @computed
  get filteredGroupsList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.groupsList,
        (group, textIncludesSearchString) =>
          textIncludesSearchString(group.name)
      );
    }

    return this.groupsList;
  }

  getGroupsList = () => {
    this.setIsFetching(true);
    let newLocationsList = [];

    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .requestGroups()
        .then((response) => {
          if (response.status === 200) {
            response.data.groups.forEach((locationData) => {
              const location = new LocationModel(locationData);
              newLocationsList.push(location);
            });
          }

          const list = newLocationsList.sort(dynamicSortByProperty("name"));
          this.setGroupsList(list);
          this.setIsFetching(false);
          resolve();
        })
        .catch((error) => {
          window.debugError("error in requestGroups", error);
          if (error && error.statusCode === 429) {
            setTimeout(this.getGroupsList, 1000);
          }
          reject();
        });
    });
  };

  @computed
  get getSingleGroup() {
    if (this.groupsList && this.groupsList.length > 0) {
      return this.groupsList[0];
    }
    return null;
  }

  submitGroup = (newGroup) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .submitGroup(newGroup)
        .then((response) => {
          this.getGroupsList();

          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject(error);
        });
    });
  };

  updateGroup = (updatedGroup) => {
    return new Promise((resolve, reject) => {
      this.modboxAPIService
        .updateGroup(updatedGroup)
        .then((response) => {
          this.getGroupsList();
          this.notificationsStore.addSnackbarNotification({
            message: response.data.message,
            color: "success",
          });
          resolve(response);
        })
        .catch((error) => {
          if (error && error.statusCode === 409) {
            this.notificationsStore.addSnackbarNotification({
              message: error.message,
              color: "danger",
            });
          }
          reject(error);
        });
    });
  };

  deleteGroup = (groupTokenToDelete) => {
    this.modboxAPIService
      .deleteGroup({
        token: groupTokenToDelete,
      })
      .then(() => {
        this.getGroupsList();
        this.notificationsStore.addSnackbarNotification({
          message: this.i18nStore.localizedString({
            id: "Location@deletedMessage",
            defaultString: "Apagou localidade",
          }),
          timeout: 2000,
          color: "danger",
        });
      });
  };

  getWithToken = (token: string) => {
    if (this.groupsList.length > 0) {
      return this.groupsList.find((group) => group.token === token);
    }
    return null;
  };

  requestGroup = async (token: string) => {
    try {
      const response = await this.modboxAPIService.requestGroup(token);
      if (response.status === 200 && response.data && response.data.groups) {
        return new LocationModel(response.data.groups[0]);
      }
    } catch (error) {
      debugError("error in requestGroup", error);
      return null;
    }
  };
}

export default GroupsStore;
