import { Component } from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";

import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import Conditional from "../Conditional/Conditional";
import AlfredStore from "../../store/AlfredStore/AlfredStore";
import KeyboardEventsService, {
  KEYCODE,
  IMappedKeyFunctions,
} from "../../services/KeyboardEventsService";

// Utils
import { textIncludesSearchString } from "../../utils/Utils";

// Styles
import { grayColor } from "../../assets/jss/app-js-styles";
const styles = createStyles({
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    height: "100vh",
    width: "100%",
    paddingTop: 16,
    background: "rgba(0, 0, 0, 0.8)",
    zIndex: 100000,
    display: "flex",
    justifyContent: "center",
  },
  body: { width: "60%" },
  optionTitle: {
    margin: 0,
    fontWeight: "bold",
  },
  optionSubtitle: {
    margin: 0,
    fontSize: "0.9em",
    fontWeight: 300,
    color: grayColor,
    marginLeft: 16,
  },
});

interface IAlfredContainerProps {
  classes: any;
  className: any;
}

interface InjectedProps extends IAlfredContainerProps {
  alfredStore: AlfredStore;
  keyboardEventsService: KeyboardEventsService;
}

@inject("alfredStore", "keyboardEventsService")
@observer
class AlfredContainer extends Component<IAlfredContainerProps> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.injected.keyboardEventsService.setGlobalListener(
      this,
      this.mapKeyFunctions
    );
  }
  componentWillUnmount() {
    this.injected.keyboardEventsService.removeGlobalListener(this);
  }

  didSelectAction = (action) => {
    this.injected.alfredStore.didSelectAction(action);
  };

  mapKeyFunctions = (): IMappedKeyFunctions => {
    const { alfredStore } = this.injected;
    return {
      [KEYCODE.ESC]: () => alfredStore.close(),
      [KEYCODE.SPACE]: ({ ctrlKey }) => {
        if (ctrlKey) {
          alfredStore.toggle();
        }
      },
    };
  };

  renderOption = (option: any): any => {
    const { classes } = this.props;
    return (
      <div>
        <p className={classes.optionTitle}>
          <i
            className={`fas fa-fw fa-${option.icon || "chevron-right"}`}
            style={{ marginRight: 12 }}
          />
          {option.title}
          <Conditional when={option.subtitle}>
            <span className={classes.optionSubtitle}>{option.subtitle}</span>
          </Conditional>
        </p>
      </div>
    );
  };

  filterOptions = (candidate: any, input: string) => {
    const search = (textToSearch: string) =>
      textIncludesSearchString(textToSearch, input);

    const { title, subtitle } = candidate.data;

    const matchTitle = search(title);
    const matchSubtitle = subtitle ? search(subtitle) : false;

    return matchTitle || matchSubtitle;
  };

  render() {
    const { classes, children, className } = this.props;
    const { isOpen, actions } = this.injected.alfredStore;

    return (
      <div className={className}>
        <Conditional when={isOpen}>
          <div className={classes.container}>
            <div className={classes.body}>
              <Select
                autoFocus
                value={null}
                options={actions}
                onChange={this.didSelectAction}
                defaultMenuIsOpen
                menuIsOpen={true}
                components={{ DropdownIndicator: () => null }}
                getOptionLabel={this.renderOption}
                filterOption={this.filterOptions}
              />
            </div>
          </div>
        </Conditional>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(AlfredContainer);
