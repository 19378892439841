// @ts-nocheck
import { Component } from "react";
import { observer } from "mobx-react";

import { withLocalized } from "../../components/HOC";
import { GridContainer } from "../../components/Grid";

import MetricChartSectionTitle from "../../components/Charts/MetricChartSectionTitle";
import MetricChartBody from "../../components/Charts/MetricChartBody";

import CameraVisitorsChartWidget from "./CameraVisitorsChartWidget";

// Translate
import Translate from "../../components/I18n/Translate";

@withLocalized
@observer
class CameraVisitorsChartContainer extends Component {
  prepareChartData = (result) =>
    result ? Object.values(result.faces_by_location)[0] : null;

  render() {
    const {
      isFetching,
      error,
      chartData: result,
      locationsList,
      datePeriod,
    } = this.props;
    const chartData = this.prepareChartData(result);

    return (
      <GridContainer justify="center">
        <MetricChartSectionTitle
          locationsList={locationsList}
          datePeriod={datePeriod}
        >
          <Translate id="Camera@visitorsChartTitle">
            Perfil dos visitantes
          </Translate>
        </MetricChartSectionTitle>
        <MetricChartBody isFetching={isFetching} hasError={error}>
          <CameraVisitorsChartWidget
            chartData={chartData}
            isDisabled={this.props.isDisabled}
          />
        </MetricChartBody>
      </GridContainer>
    );
  }
}

export default CameraVisitorsChartContainer;
