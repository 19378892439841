import { ILocation, ILocationTerminal } from "../types/Location";

class LocationModel implements ILocation {
  label: string;
  name: string;
  token: string;
  hasActivePlaylist: boolean;
  hasActiveNetwork: boolean;
  hasActiveCamera: boolean;
  isDefault: boolean;
  latitude?: number;
  longitude?: number;
  terminalsList: ILocationTerminal[];
  macAddressListToIgnoreOnMonitor: string[];

  constructor(data: any) {
    let terminalsList: ILocationTerminal[] = [];

    if (data.terminals) {
      data.terminals.forEach((terminal: any) => {
        terminalsList.push({
          name: terminal.terminal_name,
          token: terminal.terminal_token,
          color: terminal.color,
          category: terminal.category || "standard",
          isActive: terminal.is_active === "True",
        });
      });
    }

    this.label = data.group_name || data.name;
    this.name = data.group_name || data.name;
    this.token = data.group_token || data.token;
    this.hasActivePlaylist = data.playlist === "True";
    this.hasActiveNetwork = data.network === "True";
    this.hasActiveCamera = data.camera === "True";
    this.isDefault = data.default === "True";
    this.latitude = data.latitude ? parseFloat(data.latitude) : null;
    this.longitude = data.longitude ? parseFloat(data.longitude) : null;
    this.terminalsList = terminalsList || [];
    this.macAddressListToIgnoreOnMonitor = data.monitor_macaddress || [];
  }
}

export default LocationModel;
