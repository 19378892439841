// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardHeader, CardFooter } from "../components/Card";
import Modal from "../components/Modal/Modal";
import LoadingView from "../components/LoadingView/LoadingView";

import LoginForm from "./loginPage/LoginForm";
import VerifyPinForm from "./loginPage/VerifyPinForm";
import RecoverPasswordForm from "./loginPage/RecoverPasswordForm";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { LOGO_IMAGE } from "../utils/EnvUtils";
import { VIEW_MODE } from "../store/AuthStore/AuthStore";

// Style
import { container, cardTitle } from "../assets/jss/app-js-styles";
const styles = {
  container: {
    ...container,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF",
  },
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
  },
  logoContainer: {
    textAlign: "center",
  },
};

@inject("authStore", "i18nStore")
@observer
class LoginPage extends Component {
  state = {
    terms: null,
    openTermsModal: false,
  };

  didWantToOpenTermsModal = (event) => {
    if (event) event.preventDefault();
    if (this.state.terms) {
      this.setState({
        openTermsModal: true,
      });
    } else {
      this.setState(
        {
          openTermsModal: true,
          terms: "loading",
        },
        () => {
          axios
            .get(`/files/modbox/terms_${this.props.i18nStore.currentLanguage}.txt`)
            .then(({ data }) =>
              this.setState({
                terms: data,
              })
            );
        }
      );
    }
  };

  closeTermsModal = (event) => {
    event.preventDefault();
    this.setState({
      openTermsModal: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { viewMode, recoverSuccess } = this.props.authStore;

    return (
      <div className={classes.content}>
        <PageTitle>Login</PageTitle>
        {this.state.terms !== null && (
          <Modal
            isOpen={this.state.openTermsModal}
            didCloseModal={this.closeTermsModal}
            modalTitle={<Translate id="termsOfUse">Termos de uso</Translate>}
            maxWidth="xl"
          >
            {this.state.terms === "loading" ? (
              <LoadingView />
            ) : (
              <pre style={{ overflow: "scroll" }}>{this.state.terms}</pre>
            )}
          </Modal>
        )}
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <Card login>
                <CardHeader style={{ paddingTop: 20, paddingBottom: 0 }}>
                  <GridContainer>
                    <GridItem xs={12} className={classes.logoContainer}>
                      <img src={LOGO_IMAGE} alt="Logo" />
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                {viewMode === VIEW_MODE.LOGIN && (
                  <LoginForm
                    body={(body) => <CardBody>{body}</CardBody>}
                    actions={(actions) => <CardFooter>{actions}</CardFooter>}
                  />
                )}
                {viewMode === VIEW_MODE.VERIFY_PIN && (
                  <VerifyPinForm
                    body={(body) => <CardBody>{body}</CardBody>}
                    actions={(actions) => <CardFooter>{actions}</CardFooter>}
                  />
                )}
                {viewMode === VIEW_MODE.LOGIN && (
                  <CardFooter>
                    <GridContainer>
                      <GridItem xs={12}>
                        <p style={{ textAlign: "center", fontSize: "0.9em" }}>
                          <Translate id="Login@termsOfUse">
                            Continuando com o login você declara que leu e
                            aceita os{" "}
                            <a
                              href="#terms"
                              onClick={this.didWantToOpenTermsModal}
                            >
                              Termos de uso
                            </a>
                            .
                          </Translate>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginPage);
