/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { Component, Fragment } from "react";
import { inject } from "mobx-react";
import axios from "axios";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import PageTitle from "../components/PageTitle/PageTitle";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import { Button } from "../components/Button";
import { InputText } from "../components/Form";
import Validate, { ValidationType } from "../components/Form/Validate";
import { Card, CardBody, CardHeader, CardFooter } from "../components/Card";
import Modal from "../components/Modal/Modal";
import LoadingView from "../components/LoadingView/LoadingView";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { LOGO_IMAGE } from "../utils/EnvUtils";

// Style
import { container } from "../assets/jss/app-js-styles";
const styles = {
  container: {
    ...container,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  logoContainer: {
    textAlign: "center",
  },
};

@inject("affiliateAuthStore", "notificationsStore", "i18nStore")
class AffiliateLoginPage extends Component {
  constructor(props) {
    super(props);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      email: "",
      password: "",
      keepConnected: true,
      viewMode: "login",
      isWaitingForm: false,
      formWasSubmit: false,
      recoverSuccess: false,
      isValid: {
        email: false,
        password: false,
      },
      terms: null,
      openTermsModal: false,
    };
  }

  changeViewMode = (viewMode) => {
    this.setState({
      viewMode,
    });
  };

  toggleTermsModal = (event) => {
    event.preventDefault();
    this.setState(
      {
        openTermsModal: !this.state.openTermsModal,
      },
      () => {
        if (!this.state.terms) {
          axios
            .get(`/files/modbox/terms_${this.props.i18nStore.currentLanguage}.txt`)
            .then((response) => {
              this.setState({
                terms: response.data,
              });
            });
        }
      }
    );
  };

  onChangeEmail = ({ target }) => {
    const email = target.value;
    this.setState({
      email,
      isValid: {
        ...this.state.isValid,
        email: Validate(email, ValidationType.EMAIL),
      },
    });
  };

  onChangePassword = ({ target }) => {
    const password = target.value;
    this.setState({
      password,
      isValid: {
        ...this.state.isValid,
        password: Validate(password, ValidationType.REQUIRED),
      },
    });
  };

  canSubmitForm = () => {
    const { viewMode, isValid } = this.state;
    if (viewMode === "login") {
      return isValid.email && isValid.password;
    }

    return isValid.email;
  };

  didSubmitForm = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!this.canSubmitForm()) {
      return false;
    }

    this.setState(
      {
        formWasSubmit: true,
        isWaitingForm: true,
      },
      () => {
        if (this.state.viewMode === "login") {
          const { email, password } = this.state;

          this.props.affiliateAuthStore
            .makeLogin(email, password)
            .then((user) => {
              // console.log("user", user);
            })
            .catch((error) => {
              this.setState(
                {
                  isWaitingForm: false,
                },
                () => {
                  this.props.notificationsStore.addSnackbarNotification({
                    message: error,
                    timeout: 10000,
                    color: "danger",
                  });
                }
              );
            });
        } else {
          this.props.affiliateAuthStore
            .sendPasswordResetEmail(this.state.email)
            .then(() => {
              this.setState(
                {
                  recoverSuccess: true,
                  isWaitingForm: false,
                  viewMode: "login",
                },
                () => {
                  this.props.notificationsStore.addSnackbarNotification({
                    message:
                      "Verifique o e-mail que acabamos de lhe enviar com instruções para redefinir sua senha.",
                    timeout: 10000,
                    color: "success",
                  });
                }
              );
            })
            .catch((error) => {
              let errorMessage = "";
              if (error === "auth/invalid-email") {
                errorMessage = "O e-mail informado é inválido.";
              } else if (error === "auth/user-not-found") {
                errorMessage =
                  "Não existe usuário registrado para este e-mail.";
              } else if (error === "auth/too-many-requests") {
                errorMessage =
                  "Você foi bloqueado temporariamente. Aguarde 5 minutos para tentar novamente.";
              }

              this.setState({ isWaitingForm: false }, () => {
                this.props.notificationsStore.addSnackbarNotification({
                  message: errorMessage,
                  timeout: 10000,
                  color: "danger",
                });
              });
            });
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      isValid,
      viewMode,
      isWaitingForm,
      formWasSubmit,
    } = this.state;

    return (
      <div className={classes.content}>
        <PageTitle id="Affiliate@loginPageTitle">Login do afiliado</PageTitle>
        <Modal
          isOpen={this.state.openTermsModal}
          didCloseModal={this.toggleTermsModal}
          modalTitle={<Translate id="termsOfUse">Termos de uso</Translate>}
          maxWidth="lg"
        >
          {this.state.terms ? <p>{this.state.terms}</p> : <LoadingView />}
        </Modal>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.didSubmitForm}>
                <Card login>
                  <CardHeader style={{ paddingTop: 20, paddingBottom: 0 }}>
                    <GridContainer>
                      <GridItem xs={12} className={classes.logoContainer}>
                        <img src={LOGO_IMAGE} alt="Logo" />
                      </GridItem>
                      <GridItem xs={12}>
                        <h4 style={{ textAlign: "center", fontWeight: "500" }}>
                          <Translate id="Affiliate@loginPageTitle">
                            Login do afiliado
                          </Translate>
                        </h4>
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody>
                    {viewMode === "recoverPassword" &&
                      this.state.recoverSuccess && (
                        <p style={{ textAlign: "center" }}>
                          <Translate id="Login@didSendEmailMessage">
                            Um link foi enviado para o e-mail informado!
                          </Translate>
                        </p>
                      )}
                    {(viewMode === "login" || !this.state.recoverSuccess) && (
                      <InputText
                        label={<Translate id="Login@email">E-mail</Translate>}
                        name="email"
                        value={email}
                        inputProps={{
                          type: "email",
                        }}
                        rightIcon={
                          <InputAdornment position="end">
                            <i
                              className={`fas fa-envelope ${classes.inputAdornmentIcon}`}
                            />
                          </InputAdornment>
                        }
                        autoFocus
                        hasError={formWasSubmit && !isValid.email}
                        hasSuccess={formWasSubmit && isValid.email}
                        onChange={this.onChangeEmail}
                      />
                    )}
                    {viewMode === "login" && (
                      <Fragment>
                        <InputText
                          label={
                            <Translate id="Login@password">Senha</Translate>
                          }
                          name="password"
                          value={password}
                          inputProps={{
                            type: "password",
                            autoComplete: "off",
                          }}
                          rightIcon={
                            <InputAdornment position="end">
                              <i
                                className={`fas fa-key ${classes.inputAdornmentIcon}`}
                              />
                            </InputAdornment>
                          }
                          hasError={formWasSubmit && !isValid.password}
                          hasSuccess={formWasSubmit && isValid.password}
                          onChange={this.onChangePassword}
                        />
                      </Fragment>
                    )}
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    {viewMode === "login" && (
                      <GridContainer>
                        <GridItem xs={12}>
                          <Button
                            type="submit"
                            color="primary"
                            block
                            disabled={!isValid.email || !isValid.password}
                            isLoading={isWaitingForm}
                          >
                            <Translate id="Login@signInButton">
                              Entrar
                            </Translate>{" "}
                            <i className="fas fa-sign-in-alt" />
                          </Button>
                        </GridItem>
                        {!isWaitingForm && (
                          <GridItem xs={12}>
                            <Button
                              color="transparent"
                              block
                              onClick={() =>
                                this.changeViewMode("recoverPassword")
                              }
                            >
                              <Translate id="Login@forgotPassword">
                                Esqueceu sua Senha?
                              </Translate>
                            </Button>
                          </GridItem>
                        )}
                      </GridContainer>
                    )}
                    {viewMode === "recoverPassword" && (
                      <GridContainer>
                        {!this.state.recoverSuccess && (
                          <GridItem xs={12}>
                            <Button
                              type="submit"
                              color="warning"
                              block
                              disabled={!isValid.email}
                              isLoading={isWaitingForm}
                            >
                              <Translate id="Login@recoverPasswordButton">
                                Recuperar senha
                              </Translate>{" "}
                              <i className="fas fa-undo" />
                            </Button>
                          </GridItem>
                        )}
                        {!isWaitingForm && (
                          <GridItem xs={12}>
                            <Button
                              color="transparent"
                              block
                              onClick={() => this.changeViewMode("login")}
                            >
                              <Translate id="Login@backToLoginButton">
                                Voltar ao Login
                              </Translate>
                            </Button>
                          </GridItem>
                        )}
                      </GridContainer>
                    )}
                  </CardFooter>
                  {viewMode === "login" && (
                    <CardFooter>
                      <GridContainer>
                        <GridItem xs={12}>
                          <p style={{ textAlign: "center", fontSize: "0.9em" }}>
                            <Translate id="Login@termsOfUse">
                              Continuando com o login você declara que leu e
                              aceita os{" "}
                              <a href="#terms" onClick={this.toggleTermsModal}>
                                Termos de uso
                              </a>
                              .
                            </Translate>
                          </p>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  )}
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

AffiliateLoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AffiliateLoginPage);
