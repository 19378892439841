// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";

import { withLocalized } from "../HOC";
import { Button } from "../Button";
import Tooltip from "../Tooltip/Tooltip";
import Badge from "../Badge/Badge";
import LoadingView from "../LoadingView/LoadingView";

// translate
import Translate from "../I18n/Translate";
import { BackString } from "../I18n/CommonStrings";
import DisplayDate from "../Date/DisplayDate";

// Model
import {
  INotificationModel,
  ENotificationColor,
} from "../../Model/NotificationModel";

// Utils
import { IMAGE_PATH } from "../../utils/EnvUtils";
import { textNewLineToBr } from "../../utils/StringUtils";

// Style
import {
  primaryColor,
  successColor,
  infoColor,
  warningColor,
  dangerColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  drawerPaper: {
    backgroundColor: "#EEEEEE",
    maxWidth: 400,
  },
  drawerHeader: {
    backgroundColor: primaryColor,
    padding: "0 4px",
  },
  historyDrawerHeader: {
    backgroundColor: warningColor,
    padding: "0 4px",
  },
  drawerTitle: {
    color: "#fff",
    margin: 0,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  messageContainer: {
    position: "relative",
    padding: 8,
    border: "1px solid #D2D1D2",
    borderRadius: 6,
    marginBottom: 8,
    backgroundColor: "#fff",
    cursor: "pointer",
    opacity: 0.6,
  },
  unreadMessageContainer: {
    borderBottom: "2px solid #D2D1D2",
    opacity: 1,
  },
  messagePrimary: {
    borderColor: primaryColor,
  },
  messageSuccess: {
    borderColor: successColor,
  },
  messageInfo: {
    borderColor: infoColor,
  },
  messageWarning: {
    borderColor: warningColor,
  },
  messageDanger: {
    borderColor: dangerColor,
  },
  title: {
    fontWeight: 500,
    fontSize: "1em",
    margin: 0,
  },
  subtitle: {
    fontWeight: 300,
    fontSize: "0.9em",
    margin: 0,
  },
  date: {
    fontSize: "0.9em",
    color: "#999999",
    fontWeight: 300,
    margin: 0,
  },
  shelvedButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 0,
    color: "#999999",
    zIndex: 1000,
  },
};

const NotificationItem = ({
  classes,
  notification,
  onClick,
  onShelve,
}: {
  classes: any;
  notification: INotificationModel;
  onClick: any;
  onShelve: any;
}) => {
  const { title, subtitle, isShelved, isRead, color, deliverAt } = notification;
  return (
    <div
      onClick={onClick}
      className={classnames({
        [classes.messageContainer]: true,
        [classes.unreadMessageContainer]: !isRead,
        [classes.messagePrimary]:
          !isRead && color === ENotificationColor.primary,
        [classes.messageSuccess]:
          !isRead && color === ENotificationColor.success,
        [classes.messageInfo]: !isRead && color === ENotificationColor.info,
        [classes.messageWarning]:
          !isRead && color === ENotificationColor.warning,
        [classes.messageDanger]: !isRead && color === ENotificationColor.danger,
      })}
    >
      <p className={classes.date}>
        <DisplayDate date={deliverAt} />
      </p>
      <h4 className={classes.title}>{title}</h4>
      {subtitle && (
        <p className={classes.subtitle}>{textNewLineToBr(subtitle)}</p>
      )}
      {!isRead && (
        <Badge color={color}>
          <Translate id={`Common@color_${color}`}>Label</Translate>
        </Badge>
      )}
      {!isShelved && (
        <Tooltip
          title={
            <Translate id="Notification@hideNotificationLabel">
              Arquivar
            </Translate>
          }
        >
          <Button
            small
            justIcon
            color="transparent"
            className={classes.shelvedButton}
            onClick={onShelve}
          >
            <i className="fas fa-times" />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

@inject("productsStore", "notificationsStore")
@observer
class NotificationsDrawer extends Component {
  state = { openHistory: false };

  onClickNotification = (token: string) => async () => {
    await this.props.notificationsStore.didReadNotification(token);
  };

  onShelveNotification = (token: string) => (
    event: MouseEvent | TouchEvent
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.props.notificationsStore.didShelveNotification(token);
  };

  toggleHistory = () =>
    this.setState((prevState) => ({ openHistory: !prevState.openHistory }));

  render() {
    const { classes, notificationsStore } = this.props;
    const { openHistory } = this.state;
    const { isFetching, isDrawerOpen, toggleIsDrawerOpen } = notificationsStore;

    const notificationsList = notificationsStore.notificationsList.filter(
      ({ isShelved }) => isShelved === openHistory
    );

    return (
      <Drawer
        variant={"temporary"}
        anchor={"right"}
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={toggleIsDrawerOpen}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={
                openHistory ? classes.historyDrawerHeader : classes.drawerHeader
              }
            >
              <Grid item xs>
                <p className={classes.drawerTitle}>
                  <Button
                    small
                    justIcon
                    style={{ color: "#fff" }}
                    color="transparent"
                    onClick={openHistory && this.toggleHistory}
                    disableRipple={!openHistory}
                  >
                    {openHistory ? (
                      <i className="fas fa-chevron-left" />
                    ) : (
                      <i className="fas fa-bell" />
                    )}
                  </Button>
                  {openHistory ? (
                    <Translate id="Notification@historyTitle">
                      Histórico
                    </Translate>
                  ) : (
                    <Translate id="Notification@title">Notificações</Translate>
                  )}
                </p>
              </Grid>
              <Grid item style={{ textAlign: "right" }}>
                {!openHistory && (
                  <Button
                    small
                    justIcon
                    style={{ color: "#fff" }}
                    color="transparent"
                    onClick={this.toggleHistory}
                  >
                    <i className="fas fa-history" />
                  </Button>
                )}
                <Button
                  small
                  justIcon
                  style={{ color: "#fff" }}
                  color="transparent"
                  onClick={toggleIsDrawerOpen}
                >
                  <i className="fas fa-times" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "0 16px" }}>
            {isFetching ? (
              <LoadingView />
            ) : notificationsList.length > 0 ? (
              notificationsList.map((notification: INotificationModel) => (
                <NotificationItem
                  classes={classes}
                  key={notification.token}
                  notification={notification}
                  onClick={this.onClickNotification(notification.token)}
                  onShelve={this.onShelveNotification(notification.token)}
                />
              ))
            ) : (
              <Grid container justify="center">
                {openHistory ? (
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", marginTop: 40 }}
                  >
                    <p style={{ fontSize: "1.2em" }}>
                      <Translate id="Notification@emptyHistoryStateMessage">
                        Nenhuma notificação arquivada no histórico.
                      </Translate>
                    </p>
                  </Grid>
                ) : (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img
                      src={`${IMAGE_PATH}/notificationsDrawerEmptyState.png`}
                      alt={this.props.localizedString({
                        id: "Notification@emptyStateMessage",
                        defaultString: "Nenhuma notificação no momento",
                      })}
                      style={{
                        maxWidth: "80%",
                        marginTop: 30,
                        marginBottom: 20,
                      }}
                    />
                    <p style={{ fontSize: "1.2em" }}>
                      <Translate id="Notification@emptyStateMessage">
                        Nenhuma notificação no momento.
                      </Translate>
                    </p>
                  </Grid>
                )}
              </Grid>
            )}
            <Button
              color="transparent"
              small
              block
              onClick={this.toggleHistory}
            >
              {openHistory ? (
                <span
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <i className="fas fa-chevron-left" /> <BackString />
                </span>
              ) : (
                <span
                  style={{
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <Translate id="Notification@openHistoryButtonlabel">
                    Ver histórico
                  </Translate>{" "}
                  <i className="fas fa-chevron-right" />
                </span>
              )}
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withLocalized(withStyles(styles)(NotificationsDrawer));
