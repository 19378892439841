// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import LoadingView from "../LoadingView/LoadingView";
import { GridContainer, GridItem } from "../Grid";
import { LinkButton, Button } from "../Button";
import Translate from "../I18n/Translate";

// Utils
import { IMAGE_PATH } from "../../utils/EnvUtils";

import { EAppRoutes } from "../../routes/AppRoutes";

// Styles
import { primaryColor, grayColor } from "../../assets/jss/app-js-styles";
const stylesGridNoPadding = {
  gridNoPadding: {
    padding: "0 4px !important",
  },
};
const styles = {
  dataContainer: {
    border: "2px solid #f4f4f4",
    borderRadius: 8,
    margin: "4px 0",
    padding: "4px 8px",
  },
  dataLabel: {
    fontWeight: 300,
    margin: 0,
    textTransform: "uppercase",
    fontSize: "0.8em",
  },
  dataValue: {
    fontWeight: 700,
    fontSize: "1.4em",
    color: primaryColor,
    margin: 0,
  },
  waitingData: {
    color: grayColor,
    fontSize: "0.8em",
  },
  profileImg: {
    width: "100%",
    maxWidth: "100%",
    borderRadius: 8,
    margin: "4px 0",
  },
  socialNetworkIcon: {
    marginRight: 8,
  },
};

const GridItemNoPadding = withStyles(stylesGridNoPadding)(
  ({ classes, children, ...rest }) => (
    <GridItem className={classes.gridNoPadding} {...rest}>
      {children}
    </GridItem>
  )
);

@inject("advertisingStore", "chatStore", "i18nStore", "notificationsStore")
@observer
class PeopleDataContainer extends Component {
  state = {
    isFetching: false,
    hasError: false,
    people: null,
    accessDenied: false,
  };

  componentDidMount() {
    this.setState(
      {
        isFetching: true,
      },
      () => {
        this.props.advertisingStore
          .requestPeople(this.props.peopleToken)
          .then((people) => {
            this.setState({
              isFetching: false,
              people,
            });
          })
          .catch((error) => {
            if (error && error.statusCode === 403) {
              this.props.notificationsStore.addSnackbarNotification({
                message: error.message,
                color: "danger",
              });
              this.setState({
                accessDenied: true,
              });
            } else {
              this.setState({
                hasError: true,
              });
            }
          });
      }
    );
  }

  presentValueIfExists = (value) => {
    return value && value.length > 0 ? (
      value
    ) : (
      <span className={this.props.classes.waitingData}>
        <Translate id={"Advertising@waitingDataMessage"}>
          Aguardando informação...
        </Translate>
      </span>
    );
  };

  renderData = ({ label, value, onClick }) => {
    const { classes } = this.props;
    return (
      <div
        className={classes.dataContainer}
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "inherit" }}
      >
        <p className={classes.dataLabel}>{label}</p>
        <p className={classes.dataValue}>{this.presentValueIfExists(value)}</p>
      </div>
    );
  };

  renderSocialNetworks = (socialNetworks) => {
    const { classes } = this.props;
    return (
      <div className={classes.dataContainer}>
        <p className={classes.dataLabel}>
          <Translate id="Advertising@labelForPeopleKey_socialNetworks">
            Redes sociais
          </Translate>
        </p>
        <p className={classes.dataValue}>
          {socialNetworks.facebook && (
            <i
              className={classnames({
                [classes.socialNetworkIcon]: true,
                "fab fa-facebook": true,
              })}
              style={{ color: "#1877f2" }}
            />
          )}
          {socialNetworks.whatsApp && (
            <i
              className={classnames({
                [classes.socialNetworkIcon]: true,
                "fab fa-whatsapp": true,
              })}
              style={{ color: "#25d366" }}
            />
          )}
        </p>
      </div>
    );
  };

  renderBirth = ({ day, month, year }) => {
    const { classes } = this.props;
    const placeholderIfEmpty = (value, length = 2) =>
      value.length > 0 ? (
        value
      ) : (
        <span className={this.props.classes.waitingData}>
          {length === 2 ? "XX" : "XXXX"}
        </span>
      );

    const { currentLanguage } = this.props.i18nStore;

    return (
      <div className={classes.dataContainer}>
        <p className={classes.dataLabel}>
          <Translate id="Advertising@labelForPeopleKey_birth">
            Data Nascimento
          </Translate>
        </p>
        <p className={classes.dataValue}>
          {placeholderIfEmpty(currentLanguage === "pt" ? day : month)} /{" "}
          {placeholderIfEmpty(currentLanguage === "pt" ? month : day)} /{" "}
          {placeholderIfEmpty(year, 4)}
        </p>
      </div>
    );
  };

  renderProfileImage = () => {
    return (
      <img
        src={this.state.people.profileImgUrl}
        alt={this.state.people.name}
        className={this.props.classes.profileImg}
        onError={this.handleError}
      />
    );
  };

  handleError = () => {
    this.setState((prev) => ({
      ...prev,
      people: {
        ...prev.people,
        profileImgUrl: `${IMAGE_PATH}/people_placeholder.png`,
      },
    }));
  };

  render() {
    if (this.state.accessDenied) {
      return (
        <GridContainer style={{ paddingTop: 30, paddingBottom: 30 }}>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <p style={{ fontSize: "1.2em", fontWeight: 500 }}>
              <i className="fas fa-exclamation-triangle" />{" "}
              <Translate id="Advertising@missingBusinessDataMessage">
                Por questão de privacidade, preencha o cadastro do seu contrato
                para visualizar informações de terceiros.
              </Translate>
            </p>
            <LinkButton
              color="success"
              block
              to={EAppRoutes.ADMINISTRATIVE + "/contract#business"}
            >
              <Translate id="Advertising@missingBusinessDataButtonLabel">
                Finalizar cadastro
              </Translate>{" "}
              <i className="fas fa-chevron-right" />
            </LinkButton>
          </GridItem>
        </GridContainer>
      );
    }

    if (this.state.isFetching) {
      return <LoadingView height={60} />;
    }

    if (this.state.hasError || !this.state.people) {
      return (
        <p>
          <Translate id="Advertising@loadingPeopleDataErrorMessage">
            Erro inesperado, não foi possível exibir informações da pessoa neste
            momento.
          </Translate>
        </p>
      );
    }

    const {
      name,
      gender,
      language,
      phoneNumber,
      socialNetworks,
      email,
      birth,
      profileImgUrl,
    } = this.state.people;
    const { phoneOperator } = this.props;

    return (
      <Fragment>
        <GridContainer alignItems="flex-end">
          <GridItemNoPadding xs={4}>
            {this.renderProfileImage(profileImgUrl, name)}
          </GridItemNoPadding>
          <GridItemNoPadding xs={8}>
            <GridContainer>
              <GridItem xs={12}>
                {this.renderData({
                  label: (
                    <Translate id="Advertising@labelForPeopleKey_name">
                      Nome
                    </Translate>
                  ),
                  value: name,
                })}
              </GridItem>
              <GridItem xs={12}>{this.renderBirth(birth)}</GridItem>
              <GridItem xs={12}>
                {this.renderData({
                  label: (
                    <Translate id="Advertising@labelForPeopleKey_gender">
                      Gênero
                    </Translate>
                  ),
                  value: gender,
                })}
              </GridItem>
            </GridContainer>
          </GridItemNoPadding>
        </GridContainer>
        <GridContainer>
          <GridItemNoPadding xs={8}>
            {this.renderData({
              label: (
                <Translate id="Advertising@labelForPeopleKey_phoneNumber">
                  Telefone
                </Translate>
              ),
              value: `${phoneNumber}${
                phoneOperator !== "None"
                  ? ` (${phoneOperator?.replace(" - Celular", "")})`
                  : ""
              }`,
              onClick: this.props.setSendSMSView,
            })}
          </GridItemNoPadding>
          <GridItemNoPadding xs={4}>
            {this.renderSocialNetworks(socialNetworks)}
          </GridItemNoPadding>
        </GridContainer>
        <GridContainer>
          <GridItemNoPadding xs={8}>
            {this.renderData({
              label: (
                <Translate id="Advertising@labelForPeopleKey_email">
                  E-mail
                </Translate>
              ),
              value: email,
            })}
          </GridItemNoPadding>
          <GridItemNoPadding xs={4}>
            {this.renderData({
              label: (
                <Translate id="Advertising@labelForPeopleKey_language">
                  Idioma
                </Translate>
              ),
              value: language,
            })}
          </GridItemNoPadding>
        </GridContainer>
        <GridContainer>
          <GridItemNoPadding
            xs={12}
            style={{ marginTop: 16, textAlign: "center" }}
          >
            <Translate id="Advertising@howDataAreObtainedExplanation">
              <p style={{ margin: 0 }}>
                Estas informações são coletadas ao longo do tempo de acordo com
                o uso da Wi-Fi.
              </p>
              <p style={{ margin: 0 }}>
                Incentive a utilização do serviço para potencializar suas
                campanhas de marketing!
              </p>
            </Translate>
          </GridItemNoPadding>
          {this.displaySMSButton()}
        </GridContainer>
      </Fragment>
    );
  }

  displaySMSButton() {
    const shouldDisplaySMSButton = this.props.advertisingStore.contractStore
      .selectedContract.resources.advertising;
    if (!shouldDisplaySMSButton) return null;

    return (
      <GridItemNoPadding xs={12} style={{ marginTop: 16, textAlign: "center" }}>
        <Button
          color="primary"
          outline
          block
          small
          onClick={() => {
            const { localizedString } = this.props.i18nStore;
            this.props.chatStore.didWantToStartNewChatWithSubjectAndMessage(
              localizedString({
                id: "Advertising@suggestPeopleRevisionChatSubject",
                defaultString: "Feedback sobre cadastro de pessoa",
              }),
              localizedString(
                {
                  id: "Advertising@suggestPeopleRevisionChatMessage",
                  defaultString:
                    "Gostaria de sugerir ou informar algo sobre a pessoa com o número $phoneNumber:",
                },
                { phoneNumber }
              )
            );
          }}
        >
          <Translate id="Advertising@suggestPeopleRevisionButtonLabel">
            Encontrou alguma informação inconsistente ou gostaria de mudar algo?
          </Translate>
        </Button>
      </GridItemNoPadding>
    );
  }
}

export default withStyles(styles)(PeopleDataContainer);
