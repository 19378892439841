// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Fade } from "react-reveal";
import withStyles from "@material-ui/core/styles/withStyles";

import PageTitle from "../components/PageTitle/PageTitle";
import { withLocalized } from "../components/HOC";
import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardHeader, CardText, CardBody } from "../components/Card";
import { Button } from "../components/Button";
import FloatingChatButton from "../components/Chat/FloatingChatButton";

import { LoaderRing } from "../components/Animations";

import Translate from "../components/I18n/Translate";

// Style
import { cardTitle } from "../assets/jss/app-js-styles";
const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
};

@withLocalized
@inject("authStore", "contractStore")
@observer
class SelectContractLayout extends Component {
  didSelectContract = (selectedContract) => {
    this.props.contractStore.didSelectContractWithHash(selectedContract.hash);
  };

  render() {
    const { contractStore, localizedString } = this.props;
    const { contractsList } = contractStore;

    if (!contractsList) {
      return <LoaderRing height={100} />;
    }

    return (
      <Fragment>
        <FloatingChatButton />
        <PageTitle id="SelectContract@selectorTitle">
          Selecione um contrato
        </PageTitle>
        <GridContainer
          justify="center"
          direction="row"
          style={{ height: "100vh" }}
        >
          <GridItem xs={11} sm={10} md={6}>
            <Fade>
              {contractsList.length > 0 ? (
                <Card>
                  <CardHeader color="primary" text>
                    <CardText color="primary">
                      <h4 className={this.props.classes.cardTitleWhite}>
                        <Translate id="SelectContract@selectorTitle">
                          Selecione um contrato
                        </Translate>
                      </h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>
                    <Select
                      value={null}
                      placeholder={
                        <Translate id="SelectContract@selectorPlaceholder">
                          selecione um contrato...
                        </Translate>
                      }
                      options={contractsList}
                      autoFocus={true}
                      noOptionsMessage={() =>
                        localizedString({
                          id: "SelectContract@nothingFound",
                          defaultString: "Nenhum contrato encontrado",
                        })
                      }
                      onChange={this.didSelectContract}
                      isClearable={false}
                    />
                  </CardBody>
                </Card>
              ) : (
                <GridContainer
                  justify="center"
                  alignItems="center"
                  style={{ minHeight: "100vh" }}
                >
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    <h4>
                      <Translate id="SelectContract@noContractMessage">
                        Você não tem permissão para acessar nenhum contrato.
                      </Translate>
                    </h4>
                    <Button
                      color="primary"
                      small
                      onClick={this.props.authStore.logout}
                    >
                      <Translate id="Login@backToLoginButton">
                        Voltar ao Login
                      </Translate>
                    </Button>
                    <p style={{ marginTop: 50 }}>
                      <i className="fas fa-info-circle" />{" "}
                      <Translate id="SelectContract@askForSupportMessage">
                        Se acredita que existe um erro você pode solicitar ajuda
                        no chat.
                      </Translate>
                    </p>
                  </GridItem>
                </GridContainer>
              )}
            </Fade>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

SelectContractLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectContractLayout);
