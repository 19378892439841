// @ts-nocheck
import { Component, Fragment } from "react";
import classnames from "classnames";
import { inject, observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { withLocalized } from "../HOC";
import Modal from "./Modal";
import TerminalMetricsModal from "./TerminalMetricsModal";
import ConfirmModal from "./ConfirmModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { Button } from "../Button";

import Tooltip from "../Tooltip/Tooltip";
import LoadingView from "../LoadingView/LoadingView";
import { GridContainer, GridItem } from "../Grid";

import TerminalConfigureNetworkForm from "../AppForm/TerminalConfigureNetworkForm";
import ModWifiTerminalConfigureNetworkForm from "../AppForm/ModWifiTerminalConfigureNetworkForm";
import DeviceForm from "../AppForm/DeviceForm";
import DeviceInfoColumn from "./modalTerminalDetails/DeviceInfoColumn";
import DeviceAdvancedActions from "./modalTerminalDetails/DeviceAdvancedActions";
import DeviceAdvancedData from "./modalTerminalDetails/DeviceAdvancedData";

// Translate
import {
  LoadingString,
  CriticalStateMessage,
  IssueIdentifiedStateMessage,
  NormalStateMessage,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

//Utils
import { TERMINAL_DISABLED_OPTIONS } from "../../Model/TerminalModel";
import predefinedMessage, {
  PREDEFINED_MESSAGES_KEYS,
} from "../../store/ChatStore/PredefinedMessages";
import { showInternetSpeedFromBytes } from "../../utils/StringUtils";

// Style
import { dangerColor, successColor } from "../../assets/jss/app-js-styles";

const styles = {
  media: {
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginBottom: 20,
    transition: "all 600ms",
  },
  mediaVertical: {
    paddingTop: "38.25%",
    transform: "rotate(-90deg) scale(0.8)",
    margin: "80px 0",
  },
  grayscale: {
    filter: "grayscale(100%)",
  },
  actions: {
    display: "flex",
    padding: 4,
  },
  iconButton: {
    width: 30,
    height: 30,
    fontSize: "1.2em",
  },
  listItemIcon: {
    width: 26,
    marginRight: 0,
    textAlign: "center",
  },
};

@withLocalized
@inject("authStore", "contractStore", "chatStore", "terminalsStore")
@observer
class ModalTerminalDetails extends Component {
  state = {
    isOpenModal: false,
    modalType: null,
    isWaitingPrintScreenCommand: false,
    isFetchingMetrics: false,
    metrics: [],
    screenshotViewHeight: 0,
    didWantToResetToFactory: false,
    didWantToShutdown: false,
    didWantToDisconnectFromLocation: false,
    didRequestCriticalCommand: null,
  };

  didWantToEdit = () => {
    this.setState({
      modalType: "edit",
      isOpenModal: true,
    });
  };

  didWantToConfigureTerminalNetwork = () => {
    this.setState({
      modalType: "configureNetwork",
      isOpenModal: true,
    });
  };

  didCloseInternalModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalType: null,
            metrics: [],
          });
        }, 200);
      }
    );
  };

  didWantToDelete = () => {
    this.didAskToDelete();
  };

  didAskToDelete = () => {
    const { chatStore, localizedString, terminal } = this.props;
    chatStore.didWantToStartNewChatWithSubjectAndMessage(
      localizedString({
        id: "Device@askToDeleteButton",
        defaultString: "Solicitar exclusão",
      }),
      predefinedMessage(
        PREDEFINED_MESSAGES_KEYS.requestDeleteDevice,
        localizedString,
        {
          deviceName: terminal.name,
          deviceToken: terminal.token,
        }
      )
    );
    this.didCloseModal();
  };

  didWantToResetDeviceToFactory = () => {
    this.setState({
      didWantToResetToFactory: true,
    });
  };

  didWantToShutdownDevice = () => {
    this.setState({
      didWantToShutdown: true,
    });
  };

  didWantToDisconnectDeviceFromLocation = () => {
    this.setState({
      didWantToDisconnectFromLocation: true,
    });
  };

  didCancelAlertModal = () => {
    this.setState({
      didWantToResetToFactory: false,
      didWantToShutdown: false,
      didWantToDisconnectFromLocation: false,
    });
  };

  disconnectFromLocation = () => {
    this.didCloseModal();
    this.props.terminalsStore.disconnectFromLocation(this.props.terminal.token);
  };

  sendCommand = (command, closeModal = true) => {
    const { token, contract } = this.props.terminal;
    this.props.terminalsStore.sendCommandToTerminal({
      token,
      command,
      contractHash: contract?.hash,
    });

    if (closeModal) {
      this.didCloseModal();
    }
  };

  sendCommandRestart = () => {
    this.sendCommand("reboot");
  };

  sendCommandShutdown = () => {
    this.sendCommand("shutdown");
  };

  sendCommandReset = () => {
    this.sendCommand("reset");
  };

  sendCommandUpdateSystem = () => {
    this.sendCommand("reload");
  };

  sendCommandRebootTv = () => {
    this.sendCommand("reboot_tv");
  };

  sendCommandTakeScreenshot = () => {
    this.setState(
      {
        isWaitingPrintScreenCommand: true,
        screenshotViewHeight: this.refs.screenshotView.clientHeight,
      },
      () => {
        this.sendCommand("printscreen", false);
        setTimeout(() => {
          this.setState(
            {
              isWaitingPrintScreenCommand: false,
            },
            this.props.terminal.setScreenshot
          );
        }, 10000);
      }
    );
  };

  setScreenVertical = () => {
    this.props.terminalsStore.toggleTerminalScreenRotate(this.props.terminal);
  };

  didCloseModal = this.props.didCloseModal;

  didWantToRequestMetrics = () => {
    this.setState(
      {
        isFetchingMetrics: true,
        modalType: "metrics",
        isOpenModal: true,
      },
      () => {
        this.props.terminalsStore
          .requestMetricsTerminal(this.props.terminal.token)
          .then((result) => {
            this.setState({
              isFetchingMetrics: false,
              metrics: result,
            });
          });
      }
    );
  };

  requestAdvancedCommand = (command) => () => {
    if (["btsync", "pingofdeath"].includes(command)) {
      this.requestCriticalCommand(command);
    } else {
      this.sendCommand(command);
    }
  };
  requestCriticalCommand = (command) =>
    this.setState({
      didRequestCriticalCommand: command,
    });
  didCancelConfirmation = () =>
    this.setState({
      didRequestCriticalCommand: null,
    });

  renderIcon = (iconName, color) => (
    <i className={`fas fa-fw fa-${iconName}`} style={{ color }} />
  );

  renderListItem = ({
    button,
    divider,
    disabled,
    onClick,
    icon,
    iconColor,
    primary,
    secondary,
  }) => (
    <ListItem
      button={button}
      divider={divider}
      disabled={disabled}
      dense
      onClick={onClick}
    >
      <ListItemIcon classes={{ root: this.props.classes.listItemIcon }}>
        {this.renderIcon(icon, iconColor)}
      </ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );

  renderTerminalMetricsModal = () => (
    <TerminalMetricsModal
      isOpen={this.state.isOpenModal}
      didCloseModal={this.didCloseInternalModal}
      isFetching={this.state.isFetchingMetrics}
      chartData={this.state.metrics}
      isEnterprise={this.props.terminal.isEnterprise}
    ></TerminalMetricsModal>
  );

  renderTerminalEditModal = () => (
    <Modal
      isOpen={this.state.isOpenModal}
      didCloseModal={this.didCloseInternalModal}
      maxWidth="md"
      modalTitle={
        <Translate id="Device@editDeviceModalTitle">
          Editar dispositivo
        </Translate>
      }
    >
      <DeviceForm
        device={this.props.terminal}
        didSave={this.didCloseModal}
        didCancel={this.didCloseInternalModal}
      />
    </Modal>
  );

  render() {
    const { classes, isOpen, terminal } = this.props;

    if (!terminal) {
      return null;
    }

    const { currentUserCanSupport } = this.props.authStore;
    const { resources } = this.props.contractStore.selectedContract;
    const {
      isEnterprise,
      isOnline,
      category,
      name,
      comments,
      screenshot,
      screen,
      version,
      timezone,
      language,
      network,
      wifi,
      monitorWifiIsActive,
      camera,
      hardware,
      group,
      gps,
      stats,
      hasTemperatureAlert,
      hasCriticalTemperatureAlert,
      hasEnergyAlert,
      hasCriticalEnergyAlert,
      hasDiskSpeedAlert,
      hasCriticalDiskSpeedAlert,
      disabledList,
      isVerticalScreen,
    } = terminal;

    const {
      isOpenModal,
      modalType,
      isWaitingPrintScreenCommand,
      didWantToResetToFactory,
      didWantToShutdown,
      didWantToDisconnectFromLocation,
    } = this.state;

    return (
      <Fragment>
        {modalType === "edit" && this.renderTerminalEditModal()}
        {modalType === "metrics" && this.renderTerminalMetricsModal()}
        {modalType === "configureNetwork" && category === "modwifi" && (
          <ModWifiTerminalConfigureNetworkForm
            didSave={this.didCloseModal}
            didCancel={this.didCloseInternalModal}
            terminal={terminal}
          >
            {({ action, body }) => (
              <Modal
                isOpen={isOpenModal}
                didCloseModal={this.didCloseInternalModal}
                maxWidth="lg"
                height={80}
                modalTitle={
                  <Translate id="Device@configureModWifiNetworkModalTitle">
                    Configurar rede do dispositivo Enterprise
                  </Translate>
                }
                actions={action}
              >
                {body}
              </Modal>
            )}
          </ModWifiTerminalConfigureNetworkForm>
        )}
        {modalType === "configureNetwork" && category !== "modwifi" && (
          <Modal
            isOpen={isOpenModal}
            didCloseModal={this.didCloseInternalModal}
            maxWidth="sm"
            modalTitle={
              <Translate
                id="Device@configureNetworkModalTitle"
                variables={{
                  status: `(${isOnline ? "online" : "offline"})`,
                }}
              >
                Configurar rede do dispositivo $status
              </Translate>
            }
          >
            <TerminalConfigureNetworkForm
              didSave={this.sendCommand}
              didCancel={this.didCloseInternalModal}
              isOnline={isOnline}
            />
          </Modal>
        )}
        {this.state.didRequestCriticalCommand && (
          <ConfirmModal
            isOpen
            title={
              <Translate id="Common@confirmAction">
                Confirmar esta ação
              </Translate>
            }
            didCancel={this.didCancelConfirmation}
            didConfirm={() =>
              this.sendCommand(this.state.didRequestCriticalCommand, true)
            }
          >
            <Translate id="Device@confirmSendCommandMessage">
              Atenção! Tem certeza que deseja seguir com esse comando?
            </Translate>
          </ConfirmModal>
        )}
        {(didWantToResetToFactory ||
          didWantToShutdown ||
          didWantToDisconnectFromLocation) && (
          <ConfirmDeleteModal
            didDelete={
              didWantToResetToFactory
                ? this.sendCommandReset
                : didWantToShutdown
                ? this.sendCommandShutdown
                : this.disconnectFromLocation
            }
            didCancel={this.didCancelAlertModal}
            deleteLabel={
              didWantToResetToFactory ? (
                <Translate id="Device@factoryRestoreConfirmButton">
                  Resetar dispositivo
                </Translate>
              ) : didWantToShutdown ? (
                <Translate id="Device@shutdownDeviceCommand">
                  Desligar dispositivo
                </Translate>
              ) : (
                <Translate id="Device@disconnectFromLocationButtonLabel">
                  Desconectar
                </Translate>
              )
            }
            message={
              didWantToResetToFactory ? (
                <Translate id="Device@factoryRestoreMessage">
                  O dispositivo será desconectado e removido do contrato.
                </Translate>
              ) : didWantToShutdown ? (
                <Translate id="Device@confirmShutdownDeviceMessage">
                  Você perderá o acesso online ao dispositivo.
                </Translate>
              ) : (
                <Translate id="Device@confirmDisconnectFromLocationMessage">
                  Ao continuar o dispositivo perderá todas as configurações
                  vinculadas à localidade atual.
                </Translate>
              )
            }
          />
        )}
        <Modal isOpen={isOpen} didCloseModal={this.didCloseModal} maxWidth="lg">
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={currentUserCanSupport ? 6 : 7}
              style={{ position: "relative" }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  color: stats.color,
                  marginBottom: comments.length > 0 && 0,
                }}
              >
                {name}
                {isEnterprise && (
                  <span style={{ marginLeft: 8 }}>
                    (
                    <Translate id="Connectivity@enterpriseNetworkTitle">
                      PRO
                    </Translate>
                    )
                  </span>
                )}
              </h4>
              {comments.length > 0 && (
                <p style={{ fontStyle: "italic" }}>{comments}</p>
              )}
              {isWaitingPrintScreenCommand && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 20,
                    height: this.state.screenshotViewHeight,
                  }}
                >
                  <LoadingView height={60} />
                </div>
              )}
              <div
                ref="screenshotView"
                className={classnames({
                  [classes.media]: true,
                  [classes.mediaVertical]: isVerticalScreen,
                })}
                style={{
                  display: isWaitingPrintScreenCommand ? "none" : "block",
                  backgroundImage: screenshot ? `url("${screenshot}")` : null,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={currentUserCanSupport ? 6 : 5}>
              <GridContainer>
                <GridItem xs={12} md={currentUserCanSupport ? 6 : 12}>
                  <List>
                    {this.renderListItem({
                      button: true,
                      divider: true,
                      onClick: this.didWantToEdit,
                      icon: "edit",
                      primary: (
                        <Translate id="Device@editButton">
                          Editar dispositivo
                        </Translate>
                      ),
                    })}
                    {this.renderListItem({
                      button: true,
                      divider: true,
                      disabled: !isOnline,
                      onClick: this.sendCommandRestart,
                      icon: "sync",
                      primary: (
                        <Translate id="Device@restartDeviceCommand">
                          Reiniciar dispositivo
                        </Translate>
                      ),
                    })}
                    {this.renderListItem({
                      button: true,
                      divider: true,
                      disabled: !isOnline,
                      onClick: this.didWantToShutdownDevice,
                      icon: "power-off",
                      primary: (
                        <Translate id="Device@shutdownDeviceCommand">
                          Desligar dispositivo
                        </Translate>
                      ),
                    })}
                    {group &&
                      this.renderListItem({
                        button: true,
                        divider: true,
                        onClick: this.didWantToDisconnectDeviceFromLocation,
                        icon: "layer-minus",
                        primary: (
                          <Translate id="Device@disconnectFromLocation">
                            Desconectar da localidade
                          </Translate>
                        ),
                      })}
                    {!isEnterprise &&
                      this.renderListItem({
                        button: true,
                        divider: true,
                        disabled: !isOnline,
                        onClick: this.didWantToResetDeviceToFactory,
                        icon: "eraser",
                        primary: (
                          <Translate id="Device@factoryRestoreCommand">
                            Configuração de fábrica
                          </Translate>
                        ),
                      })}
                    {this.renderListItem({
                      button: true,
                      divider: true,
                      disabled: !isOnline,
                      onClick: this.sendCommandUpdateSystem,
                      icon: "download",
                      primary: (
                        <Translate id="Device@updateSystemCommand">
                          Atualizar sistema
                        </Translate>
                      ),
                    })}
                    {this.renderListItem({
                      button: true,
                      divider: true,
                      onClick: this.didWantToConfigureTerminalNetwork,
                      icon: "globe",
                      primary: (
                        <Translate
                          id="Device@configureDeviceNetworkCommand"
                          variables={{
                            status: !isEnterprise
                              ? `(${stats.connection})`
                              : "",
                          }}
                        >
                          Configurar rede $status
                        </Translate>
                      ),
                    })}
                    {!isEnterprise &&
                      !disabledList.includes(TERMINAL_DISABLED_OPTIONS.VIDEO) &&
                      this.renderListItem({
                        button: true,
                        divider: true,
                        disabled: isWaitingPrintScreenCommand || !isOnline,
                        onClick: this.sendCommandTakeScreenshot,
                        icon: "tv",
                        primary: isWaitingPrintScreenCommand ? (
                          <LoadingString />
                        ) : (
                          <Translate id="Device@takeScreenshotCommand">
                            Atualizar captura de tela
                          </Translate>
                        ),
                      })}
                    {!currentUserCanSupport &&
                      this.renderListItem({
                        button: true,
                        divider: true,
                        onClick: this.didWantToDelete,
                        disabled: !this.props.chatStore.isAvailable,
                        icon: "trash-alt",
                        primary: (
                          <Translate id="Device@askToDeleteButton">
                            Solicitar exclusão
                          </Translate>
                        ),
                      })}
                    {this.renderListItem({
                      button: true,
                      onClick: this.sendCommandRebootTv,
                      disabled: !(screen.name !== "unknown" && isOnline),
                      icon: "sync",
                      primary: (
                        <Translate id="Device@restartTvCommand">
                          Reiniciar televisor
                        </Translate>
                      ),
                    })}
                  </List>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <List>
                    {currentUserCanSupport && (
                      <DeviceAdvancedActions
                        terminal={terminal}
                        renderListItem={this.renderListItem}
                        sendCommand={this.requestAdvancedCommand}
                      />
                    )}
                    {this.renderListItem({
                      button: true,
                      onClick: this.didWantToRequestMetrics,
                      icon: "chart-area",
                      primary: (
                        <Translate id="Device@requestMetricsButton">
                          Histórico de funcionamento
                        </Translate>
                      ),
                    })}
                  </List>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem xs>
              <h4 style={{ textAlign: "center" }}>
                <Translate id="Device@infoSectionTitle">
                  Informações do dispositivo
                </Translate>
              </h4>
              <GridContainer>
                <DeviceInfoColumn>
                  <List>
                    {version &&
                      this.renderListItem({
                        divider: true,
                        icon: "window-maximize",
                        primary: version,
                        secondary: (
                          <Translate id="Device@systemVersionInfo">
                            Versão do sistema
                          </Translate>
                        ),
                      })}
                    {this.renderListItem({
                      divider: true,
                      icon: "clock",
                      primary: stats.last,
                      secondary: (
                        <Translate id="Device@lastStatusInfo">
                          Último status
                        </Translate>
                      ),
                    })}
                    {isOnline &&
                      stats.uptime &&
                      this.renderListItem({
                        divider: true,
                        icon: "plug",
                        primary: stats.uptime,
                        secondary: (
                          <Translate id="Device@uptimeInfo">
                            Tempo ligado
                          </Translate>
                        ),
                      })}
                    {!isEnterprise && hardware.temperature && (
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: hardware.temperature,
                            }}
                          />
                        }
                      >
                        {this.renderListItem({
                          divider: true,
                          icon: hasTemperatureAlert
                            ? "fire"
                            : "thermometer-empty",
                          iconColor: hasTemperatureAlert
                            ? dangerColor
                            : "inherit",
                          primary: hasCriticalTemperatureAlert ? (
                            <CriticalStateMessage />
                          ) : hasTemperatureAlert ? (
                            <IssueIdentifiedStateMessage />
                          ) : (
                            <NormalStateMessage />
                          ),
                          secondary: (
                            <Translate id="Common@temperature">
                              Temperatura
                            </Translate>
                          ),
                        })}
                      </Tooltip>
                    )}
                    {!isEnterprise &&
                      hardware.power_supply &&
                      this.renderListItem({
                        divider: true,
                        icon: "bolt",
                        iconColor: hasEnergyAlert ? dangerColor : "inherit",
                        primary: hasCriticalEnergyAlert ? (
                          <CriticalStateMessage />
                        ) : hasEnergyAlert ? (
                          <IssueIdentifiedStateMessage />
                        ) : (
                          <NormalStateMessage />
                        ),
                        secondary: (
                          <Translate id="Device@powerSupplyInfo">
                            Energia
                          </Translate>
                        ),
                      })}
                    {!isEnterprise &&
                      this.renderListItem({
                        divider: true,
                        icon: "folder-open",
                        iconColor: hasDiskSpeedAlert ? dangerColor : "inherit",
                        primary: hasCriticalDiskSpeedAlert ? (
                          <CriticalStateMessage />
                        ) : hasDiskSpeedAlert ? (
                          <IssueIdentifiedStateMessage />
                        ) : (
                          <NormalStateMessage />
                        ),
                        secondary: (
                          <Translate id="Device@diskSpeedInfo">
                            Velocidade do disco
                          </Translate>
                        ),
                      })}
                    {this.renderListItem({
                      divider: resources.locationControl,
                      icon: "language",
                      primary: `${timezone} (${language})`,
                      secondary: (
                        <Translate id="Device@languageInfo">Idioma</Translate>
                      ),
                    })}
                    {resources.locationControl &&
                      this.renderListItem({
                        icon: "sitemap",
                        primary: group && group.name ? group.name : "-",
                        secondary: (
                          <Translate id="Device@locationInfo">
                            Localidade
                          </Translate>
                        ),
                      })}
                  </List>
                </DeviceInfoColumn>
                <DeviceInfoColumn>
                  <List>
                    {!isEnterprise &&
                      this.renderListItem({
                        divider: true,
                        icon: "video",
                        primary: (
                          <span>
                            {camera.hasExternalDevice ? (
                              <Translate id="Device@cameraExternalDeviceIsConnected">
                                Conectado
                              </Translate>
                            ) : (
                              <Translate id="Device@cameraExternalDeviceNotConnected">
                                Não encontrado
                              </Translate>
                            )}{" "}
                            <i
                              className={`fas fa-${
                                camera.hasExternalDevice ? "check" : "times"
                              }`}
                            />
                          </span>
                        ),
                        secondary: (
                          <Translate id="Device@cameraExternalDeviceInfo">
                            Módulo Câmera
                          </Translate>
                        ),
                      })}
                    {!isEnterprise &&
                      this.renderListItem({
                        divider: true,
                        icon: "map-pin",
                        primary: (
                          <span>
                            {gps.hasExternalDevice ? (
                              <Translate id="Device@gpsExternalDeviceIsConnected">
                                Conectado
                              </Translate>
                            ) : (
                              <Translate id="Device@gpsExternalDeviceNotConnected">
                                Não encontrado
                              </Translate>
                            )}{" "}
                            <i
                              className={`fas fa-${
                                gps.hasExternalDevice ? "check" : "times"
                              }`}
                            />
                          </span>
                        ),
                        secondary: (
                          <Translate id="Device@gpsExternalDeviceInfo">
                            Módulo GPS
                          </Translate>
                        ),
                      })}
                    {!isEnterprise &&
                      this.renderListItem({
                        divider: true,
                        icon: "broadcast-tower",
                        primary: (
                          <span>
                            {wifi.hasExternalAntenna ? (
                              <Translate id="Device@wifiExternalAntennaIsConnected">
                                Conectado
                              </Translate>
                            ) : (
                              <Translate id="Device@wifiExternalAntennaNotConnected">
                                Não encontrado
                              </Translate>
                            )}{" "}
                            <i
                              className={`fas fa-${
                                wifi.external_antenna ? "check" : "times"
                              }`}
                            />
                          </span>
                        ),
                        secondary: (
                          <Translate id="Device@wifiExternalAntennaInfo">
                            Módulo Wi-Fi
                          </Translate>
                        ),
                      })}
                    {monitorWifiIsActive &&
                      this.renderListItem({
                        divider: true,
                        icon: "wifi-2",
                        primary: (
                          <Translate id="Device@monitorWifiIsActiveCollecting">
                            Ativamente coletando
                          </Translate>
                        ),
                        secondary: (
                          <Translate id="Device@monitorWifiIsActive">
                            Análise de dispositivos no ambiente
                          </Translate>
                        ),
                      })}
                    {hardware.free_space &&
                      this.renderListItem({
                        divider: true,
                        icon: "folder-open",
                        iconColor:
                          parseInt(hardware.free_space, 10) < 500
                            ? dangerColor
                            : "inherit",
                        primary: (
                          <Translate
                            id="Device@diskFreeSpaceData"
                            variables={{ diskFreeSpace: hardware.free_space }}
                          >
                            $diskFreeSpace (livre)
                          </Translate>
                        ),
                        secondary: (
                          <Translate id="Device@diskFreeSpaceInfo">
                            Espaço em disco
                          </Translate>
                        ),
                      })}
                    {hardware.free_mem &&
                      this.renderListItem({
                        divider: true,
                        icon: "memory",
                        iconColor:
                          parseInt(hardware.free_mem, 10) < 25
                            ? dangerColor
                            : "inherit",
                        primary: (
                          <Translate
                            id="Device@diskFreeSpaceData"
                            variables={{ diskFreeSpace: hardware.free_mem }}
                          >
                            $diskFreeSpace (livre)
                          </Translate>
                        ),
                        secondary: (
                          <Translate id="Device@hardwareFreeMemorySpaceInfo">
                            Memória RAM
                          </Translate>
                        ),
                      })}
                    {isOnline && (
                      <Tooltip title={screen.name}>
                        {screen &&
                          screen.size &&
                          this.renderListItem({
                            divider: true,
                            icon: "tv",
                            iconColor:
                              screen.status === "on"
                                ? successColor
                                : screen.status === "off"
                                ? dangerColor
                                : "gray",
                            primary: screen.size,
                            secondary: (
                              <Translate id="Device@screenSizeInfo">
                                Formato da tela
                              </Translate>
                            ),
                          })}
                      </Tooltip>
                    )}
                    {hardware.model &&
                      this.renderListItem({
                        icon: isEnterprise ? "server" : "hdd",
                        primary: hardware.model,
                        secondary: (
                          <Translate id="Device@hardwareInfo">
                            Hardware
                          </Translate>
                        ),
                      })}
                  </List>
                </DeviceInfoColumn>
                <DeviceInfoColumn>
                  <List>
                    {network.internet_interface.includes("wlan") &&
                      wifi.ssid.length > 0 && (
                        <Tooltip
                          title={
                            <Translate
                              id="Device@wifiPasswordInfo"
                              variables={{ wifiPassword: wifi.password }}
                            >
                              Senha: $wifiPassword
                            </Translate>
                          }
                        >
                          {this.renderListItem({
                            divider: true,
                            icon: "wifi",
                            primary: wifi.ssid,
                            secondary: (
                              <Translate id="Device@wifiNameInfo">
                                Nome da rede Wi-fi
                              </Translate>
                            ),
                          })}
                        </Tooltip>
                      )}
                    {isOnline &&
                      network.internet_interface.includes("wlan") &&
                      wifi.signal.length > 0 &&
                      this.renderListItem({
                        divider: true,
                        icon: "signal",
                        primary: (
                          <Translate
                            id="Device@wifiQualityData"
                            variables={{
                              wifiSignal: wifi.signal,
                              wifiQuality: wifi.quality,
                            }}
                          >
                            Sinal: $wifiSignal, qualidade: $wifiQuality
                          </Translate>
                        ),
                        secondary: (
                          <Translate id="Device@wifiInfo">
                            Conexão Wi-Fi
                          </Translate>
                        ),
                      })}
                    {network.local_address && (
                      <Tooltip
                        title={`${network.internet_interface} > ${
                          network.internet_macaddress
                        } (${network.dhcp ? "DHCP" : "Manually"})`}
                      >
                        {this.renderListItem({
                          divider: true,
                          icon: "globe",
                          primary: network.local_address,
                          secondary: (
                            <Translate id="Device@localIpAddressInfo">
                              Endereço de rede
                            </Translate>
                          ),
                        })}
                      </Tooltip>
                    )}
                    {network.internet_address &&
                      this.renderListItem({
                        divider: network.download.length > 0,
                        icon: "globe",
                        primary: network.internet_address,
                        secondary: (
                          <Translate id="Device@internetIpAddressInfo">
                            Endereço de internet
                          </Translate>
                        ),
                      })}
                    {network.download.length > 0 &&
                      this.renderListItem({
                        icon: "cloud-download-alt",
                        primary: showInternetSpeedFromBytes(network.download),
                        secondary: (
                          <Translate id="Device@internetDownloadSpeedInfo">
                            Velocidade de internet
                          </Translate>
                        ),
                      })}
                  </List>
                </DeviceInfoColumn>
                {currentUserCanSupport && (
                  <DeviceAdvancedData
                    terminal={terminal}
                    renderListItem={this.renderListItem}
                  />
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Modal>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ModalTerminalDetails);
