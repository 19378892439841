// @ts-nocheck

import { inject, observer } from "mobx-react";

import { GridContainer, GridItem } from "../Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "../Badge/Badge";

// Translate
import Translate from "../I18n/Translate";

// Utils
import { stringDateFromTimestamp } from "../../utils/DateUtils";

// Style
import {
  primaryColor,
  successColor,
  warningColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    border: "1px solid #C6CACD",
    borderRadius: 6,
    margin: "8px 8px",
    marginTop: 0,
    padding: "0!important",
    width: "100%",
    background: "#fff",
    cursor: "pointer",
    "&:hover": {
      borderColor: primaryColor,
    },
  },
  innerContainer: {
    padding: 8,
  },
  subject: {
    color: primaryColor,
    margin: 0,
    fontWeight: "bold",
  },
  chatTimestamp: {
    margin: 0,
    fontSize: "0.8em",
    fontWeight: 300,
    fontStyle: "italic",
  },
  lastMessage: { margin: 0, marginTop: 4, textAlign: "justify" },
};

const ChatListItem = inject("i18nStore")(
  observer(({ classes, i18nStore, chat, didSelectChat, currentAuthor }) => {
    const unreadCount = chat[currentAuthor].unreadMessagesCount;
    const adminIsTheLastAuthor = chat.lastMessageAuthor === "admin";
    return (
      <GridItem
        onClick={() => didSelectChat(chat.id)}
        className={classes.container}
      >
        <GridContainer className={classes.innerContainer}>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={7}>
                <p className={classes.chatTimestamp}>
                  <Translate
                    id="Chat@lastUpdatedChatMessage"
                    variables={{
                      lastUpdated: stringDateFromTimestamp(
                        chat.lastMessageTimestamp,
                        `${i18nStore.dateFormatWithTwoDigitsYear} ${i18nStore.timeFormat}`
                      ),
                    }}
                  >
                    Atualizado em $lastUpdated h
                  </Translate>
                </p>
              </GridItem>
              <GridItem xs={5} style={{ textAlign: "right" }}>
                <Badge color={unreadCount > 0 ? "primary" : "gray"}>
                  {chat.messagesCount}
                </Badge>
              </GridItem>
            </GridContainer>
          </GridItem>
          {chat.subject && (
            <GridItem xs={12}>
              <p className={classes.subject}>{chat.subject}</p>
            </GridItem>
          )}
          <GridItem xs={12}>
            <p className={classes.lastMessage}>
              <i
                className={`fas fa-chevron-${
                  adminIsTheLastAuthor ? "left" : "right"
                }`}
                style={{
                  color: adminIsTheLastAuthor ? warningColor : successColor,
                }}
              />{" "}
              {chat.lastMessage.length > 200
                ? `${chat.lastMessage.substring(0, 200)}...`
                : chat.lastMessage}
            </p>
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  })
);

export default withStyles(styles)(ChatListItem);
