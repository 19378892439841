// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

import Modal from "../Modal/Modal";
import { Button } from "../Button";

import PeopleDataContainer from "../ModboxPeople/PeopleDataContainer";

// Translate
import Translate from "../I18n/Translate";


import ContractStore from "../../store/ContractStore/ContractStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";

enum VIEW_TYPE {
  menu,
  sendSMS,
  viewPeopleData,
}

interface IPeopleModalProps {}
interface IPeopleModalState {
  viewType: VIEW_TYPE;
}

interface InjectedProps extends IAlfredContainerProps {
  contractStore: ContractStore;
  routingStore: RoutingStore;
}

@inject("contractStore", "routingStore")
@observer
class PeopleModal extends Component<IPeopleModalProps, IPeopleModalState> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  state: IPeopleModalState = {
    viewType: VIEW_TYPE.menu,
  };

  setViewType = (viewType: VIEW_TYPE) =>
    this.setState({
      viewType,
    });

  didBackToMenu = () => this.setViewType(VIEW_TYPE.menu);
  setSendSMSView = () => this.setViewType(VIEW_TYPE.sendSMS);
  setPeopleView = () => this.setViewType(VIEW_TYPE.viewPeopleData);

  didCloseModal = () => {
    this.injected.routingStore.clearState();
    setTimeout(this.didBackToMenu, 500);
  };

  onChangeMessage = ({ target }) => {
    const { value: message } = target;
    this.setState({
      message,
    });
  };

  renderMenu = (peopleToken: string) => {
    return (
      <div>
        {peopleToken && (
          <Button
            color="primary"
            outline
            block
            large
            onClick={this.setPeopleView}
            style={{ marginBottom: 20 }}
          >
            <Translate id="Advertising@viewPeopleDataButtonLabel">
              Visualizar cadastro
            </Translate>{" "}
            <i className="fas fa-user-check" />
          </Button>
        )}
        <Button color="primary" block large onClick={this.setSendSMSView}>
          <Translate id="Advertising@directMessageToPhoneMenuButtonLabel">
            Escrever mensagem
          </Translate>{" "}
          <i className="fas fa-paper-plane" />
        </Button>
      </div>
    );
  };

  render() {
    const { contractStore, routingStore } = this.injected;
    const { resources, rules } = contractStore.selectedContract;
    if (
      !resources.connectivity ||
      !rules.connectivity ||
      !routingStore.location ||
      !routingStore.location.state
    ) {
      return null;
    }

    const {
      phoneNumber,
      phoneOperator,
      peopleToken,
    } = routingStore.location.state;

    return (
      <Modal
        isOpen={phoneNumber !== null && phoneNumber !== undefined}
        maxWidth="sm"
        didCloseModal={this.didCloseModal}
      >
        <PeopleDataContainer
          phoneNumber={phoneNumber}
          phoneOperator={phoneOperator}
          didCancel={this.didBackToMenu}
          didSend={this.didCloseModal}
          peopleToken={peopleToken}
          setSendSMSView={this.setSendSMSView}
        />
      </Modal>
    );
  }
}
export default PeopleModal;
