// @ts-nocheck

import { inject } from "mobx-react";
import PropTypes from "prop-types";
import classnames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { AppBar, Toolbar } from "@material-ui/core";

// core components
import { Button } from "../Button";

// Style
import headerStyle from "../../assets/jss/js-styles/components/headerStyle";

const AffiliateHeader = inject("affiliateAuthStore")(
  ({ affiliateAuthStore, classes }) => {
    return (
      <AppBar
        className={classnames({
          [classes.appBar]: true,
          [classes["primary"]]: true,
        })}
      >
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            <h1 className={classes.title}>
              <i className="fas fa-chart-line" style={{ marginRight: 16 }} />
              Painel do afiliado
            </h1>
          </div>

          <div>
            <Button color="transparent" onClick={affiliateAuthStore.logout}>
              <i className="fas fa-sign-out-alt" />
              Sair do sistema
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
);

AffiliateHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(headerStyle)(AffiliateHeader);
