// @ts-nocheck
import { Component } from "react";
import debounce from "lodash/debounce";
import update from "immutability-helper";
import { DragDropContext } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";

import ResourceRuleItem from "./ResourceRuleItem";

class ResourceRuleLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsList: props.resourceRuleList,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const left = JSON.stringify(prevProps.resourceRuleList);
    const right = JSON.stringify(this.props.resourceRuleList);

    if (left !== right) {
      this.setState({
        itemsList: this.props.resourceRuleList,
      });
    }
  }

  componentWillUnmount() {
    this.applyReorder.flush();
  }

  applyReorder = debounce((data) => {
    const { itemsList } = this.state;
    this.props.didReorderResourceRulesOfLocation(
      itemsList[0].group.token,
      itemsList
    );
  }, 4000);

  moveItem = ({ dragIndex, hoverIndex }) => {
    const { itemsList } = this.state;
    const dragItem = itemsList[dragIndex];

    this.setState(
      update(this.state, {
        itemsList: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        },
      }),
      () => {
        this.applyReorder();
      }
    );
  };

  render() {
    const {
      classes,
      resourceType,
      didSelectResourceRuleToEdit,
      didSelectResourceRuleToConfigureVideoWall,
    } = this.props;
    const { itemsList } = this.state;

    const dragNDropIsActive = itemsList.length > 1;
    const location = itemsList[0].group;

    return (
      <div>
        <p>{location.name}</p>
        {itemsList.map((resourceRule, index) => (
          <ResourceRuleItem
            key={resourceRule.token}
            index={index}
            classes={classes}
            resourceType={resourceType}
            resourceRule={resourceRule}
            canDrag={dragNDropIsActive}
            didSelectResourceRuleToEdit={didSelectResourceRuleToEdit}
            didSelectResourceRuleToConfigureVideoWall={
              didSelectResourceRuleToConfigureVideoWall
            }
            moveItem={this.moveItem}
          />
        ))}
      </div>
    );
  }
}

export default DragDropContext(MultiBackend(HTML5toTouch))(
  ResourceRuleLocation
);
