// @ts-nocheck
import { Fragment } from "react";
import withPageAllowedRules from "./withPageAllowedRules";
import PageTitle from "../components/PageTitle/PageTitle";

import DevicesListPageContainer from "../components/Devices/DevicesListPageContainer";

const DevicesListPage = ({ location }) => (
  <Fragment>
    <PageTitle id="Menu@devices">Dispositivos</PageTitle>
    <DevicesListPageContainer location={location} />
  </Fragment>
);

export default withPageAllowedRules({
  component: DevicesListPage,
  pageAllowedRule: "devices",
});
