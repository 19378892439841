// @ts-nocheck
import { observable, computed, action } from "mobx";

import ChartModel from "../../Model/ChartModel";

// Utils Stores
import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";

// Utils
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

import ModboxAPIService from "../../services/ModboxAPIService";

class ReportsStore {
  internalEventsStore: InternalEventsStore;
  modboxAPIService: ModboxAPIService;

  @observable isFetching = false;
  @observable dashboardChartData: ChartModel[] | null = null;
  @observable connectivityAuthenticationLogs = [];

  @observable mediaInsertionLogs = {
    logs: [],
    pages: 0,
    rows: 0,
  };

  @observable searchString = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    modboxAPIService: ModboxAPIService
  ) {
    this.internalEventsStore = internalEventsStore;
    this.modboxAPIService = modboxAPIService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didChangeSelectedContract,
      observer: this,
      callback: this.clearStore,
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.isFetching = false;
    this.dashboardChartData = null;
    this.connectivityAuthenticationLogs = [];
    this.searchString = "";
    this.setMediaInsertionLogs(null);
  };

  @action
  setIsFetching = (newValue) => {
    this.isFetching = newValue;
  };

  @action
  setSearchString = (newValue) => {
    this.searchString = newValue;
  };
  @action
  setDashboardChartData = (newValue: ChartModel[] | null) => {
    this.dashboardChartData = newValue;
  };
  @action
  setConnectivityAuthenticationLogs = (newValue) => {
    this.connectivityAuthenticationLogs = newValue;
  };
  @action
  setMediaInsertionLogs = (newValue) => {
    this.mediaInsertionLogs = newValue || {
      logs: [],
      pages: 0,
      rows: 0,
    };
  };

  getDashboardChartData = async () => {
    this.setIsFetching(true);

    try {
      const response: any = await this.modboxAPIService.requestDashboard();
      if (response.status === 200 && response.data) {
        this.setDashboardChartData(response.data);
      }
    } catch (error) {
      window.debugError("error in requestDashboard", error);
      if (error && error.statusCode === 429) {
        setTimeout(this.getDashboardChartData, 1000);
      }
    } finally {
      this.setIsFetching(false);
    }
  };

  @action
  getConnectivityAuthenticationsLogs = (fromDate, toDate) => {
    this.setIsFetching(true);

    this.modboxAPIService
      .requestDataWifi({
        fromDate,
        toDate,
      })
      .then((response) => {
        let newList = [];
        if (response.status === 200 && response.data && response.data.logs) {
          response.data.logs.forEach((log) => {
            const logExistsIndex = newList.findIndex(
              ($0) => $0.expiry_date === log.expiry_date
            );
            if (logExistsIndex >= 0) {
              const actualLog = newList[logExistsIndex];
              const logDuration =
                log.duration && log.duration.length > 0
                  ? parseInt(log.duration, 10)
                  : null;

              const duration =
                actualLog.duration && logDuration
                  ? actualLog.duration + logDuration
                  : null;

              newList[logExistsIndex] = {
                ...actualLog,
                duration,
                date: actualLog.date < log.date ? actualLog.date : log.date,
              };
            } else {
              const duration =
                log.duration && log.duration.length > 0
                  ? parseInt(log.duration, 10)
                  : null;
              newList.push({
                ...log,
                duration,
              });
            }
          });

          newList = newList.sort(dynamicSortByProperty("date", false));
        }
        this.setConnectivityAuthenticationLogs(newList);
        this.setIsFetching(false);
      })
      .catch((error) => {
        this.setIsFetching(false);
        window.debugError("error in requestData", error);
        if (error && error.statusCode === 429) {
          setTimeout(() => {
            this.getConnectivityAuthenticationsLogs(fromDate, toDate);
          }, 2000);
        }
      });
  };

  @computed
  get filteredConnectivityAuthenticationLogs() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.connectivityAuthenticationLogs,
        (log, textIncludesSearchString, searchStringItem) => {
          const matchName = textIncludesSearchString(log.name);
          const matchVendor = textIncludesSearchString(log.vendor);
          const matchMacAddress = textIncludesSearchString(log.mac_address);
          const matchPhone = textIncludesSearchString(log.phone);
          const matchEmail = textIncludesSearchString(log.email);
          const matchGroup = textIncludesSearchString(log.group);
          const matchWhatsApp =
            searchStringItem === "whatsapp" && log.whatsapp === "True";
          const matchHasPeopleToken =
            searchStringItem === "people" && log.people_token.length > 0;
          const matchIsOnline = searchStringItem === log.status;

          return (
            matchName ||
            matchVendor ||
            matchMacAddress ||
            matchPhone ||
            matchEmail ||
            matchGroup ||
            matchWhatsApp ||
            matchHasPeopleToken ||
            matchIsOnline
          );
        }
      );
    }
    return this.connectivityAuthenticationLogs;
  }

  getMediasFilesFromLog = () => {
    return new Promise(async (resolve, reject) => {
      this.modboxAPIService
        .requestMediasFromLog()
        .then((response) => {
          if (response.status === 200 && response.data) {
            const { groups, medias, terminals } = response.data;
            resolve({
              locationsList: groups.map(($0) => ({
                name: $0.group_name,
                token: $0.group_token,
              })),
              mediasList: medias.map(($0) => ({
                name: $0.file_name,
                md5: $0.file_md5,
              })),
              terminalsList: terminals.map(($0) => ({
                name: $0.terminal_name,
                token: $0.terminal_token,
              })),
            });
          } else {
            reject();
          }
        })
        .catch((error) => {
          window.debugError("error in getMediasFilesFromInsertionLog", error);
          reject(error);
        });
    });
  };

  requestMetricsTv = (data) => this.modboxAPIService.requestMetricsTv(data);

  mediaInsertionSyntheticPeriods = async ({
    media,
    fromDate,
    toDate,
    fromHour,
    toHour,
  }) => {
    this.setIsFetching(true);
    try {
      const response = await this.requestMetricsTv({
        mediaMd5: media,
        fromDate,
        toDate,
        fromHour,
        toHour,
        mediaInsertionSyntheticPeriods: true,
      });
      if (
        response.status === 200 &&
        response.data &&
        response.data.media_insertion_synthetic_periods
      ) {
        const {
          per_days,
          totals,
        } = response.data.media_insertion_synthetic_periods;

        this.setIsFetching(false);
        return Promise.resolve({
          totalInsertions: totals.insertions,
          totalLocations: totals.unique_locations,
          perDays: per_days,
        });
      } else {
        this.setIsFetching(false);
        return Promise.reject();
      }
    } catch (error) {
      this.setIsFetching(false);
      return Promise.reject(error);
    }
  };

  @action
  getMediaInsertionLogs = ({
    media,
    fromDate,
    toDate,
    fromHour,
    toHour,
    location,
    terminal,
    page,
    limit,
  }) => {
    this.setIsFetching(true);

    this.requestMetricsTv({
      mediaMd5: media,
      fromDate,
      toDate,
      fromHour,
      toHour,
      location,
      terminal,
      page: page,
      limit: limit,
      mediaInsertion: true,
    })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.media_insertion
        ) {
          const {
            data,
            total_pages,
            total_rows,
          } = response.data.media_insertion;

          this.setMediaInsertionLogs({
            logs: data,
            pages: total_pages,
            rows: total_rows,
          });
        } else {
          this.setMediaInsertionLogs(null);
        }
      })
      .catch((error) => {
        window.debugError("error in requestData", error);
        if (error && error.statusCode === 404) {
          this.setMediaInsertionLogs(null);
        }
      })
      .then(() => this.setIsFetching(false));
  };

  didSelectAction = (action: string) => {
    if (!action || action.length < 1) return;

    this.internalEventsStore.notify(EInternalEvents.triggeredAction, action);
  };
}

export default ReportsStore;
