//@ts-nocheck
import { FC } from "react";
import Table, { createIconColumn } from "../Table/Table";
import TerminalModel from "../../Model/TerminalModel";
import BooleanIcon from "../BooleanIcon/BooleanIcon";
import Tooltip from "../Tooltip/Tooltip";

// Translate
import {
  CriticalStateMessage,
  IssueIdentifiedStateMessage,
  NormalStateMessage,
} from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

import { successColor, grayColor } from "../../assets/jss/app-js-styles";
import { hexToRGBA } from "../../utils/Utils";

const CustomTooltipAlert = ({
  hasCriticalAlert,
  hasAlert,
}: {
  hasCriticalAlert: boolean;
  hasAlert: boolean;
}) => (
  <Tooltip
    // @ts-ignore
    title={
      hasCriticalAlert ? (
        <CriticalStateMessage />
      ) : hasAlert ? (
        <IssueIdentifiedStateMessage />
      ) : (
        <NormalStateMessage />
      )
    }
  >
    <span>
      <BooleanIcon isTrue={!hasAlert} falseIcon="exclamation-triangle" />
    </span>
  </Tooltip>
);

interface DevicesTableViewProps {
  showLocationColumn?: boolean;
  currentUserCanSupport: boolean;
  isAdminView?: boolean;
  isFetching: boolean;
  isFetchingPaginate: boolean;
  hideAlerts?: boolean;
  devicesPerPage: number;
  currentPage: number;
  totalDevices: number;
  onChangePaginate: (page: number, pageSize: number) => void;
  devicesList: TerminalModel[];
  ignoredTokens?: string[];
  selectedTokens?: string[];
  didSelect: (token: string) => () => void;
  sortedBy?: { column: string; asc: boolean };
  onSortedChange?: (column: string, asc: boolean) => void;
}

const DevicesTableView: FC<DevicesTableViewProps> = ({
  showLocationColumn = false,
  currentUserCanSupport = false,
  isAdminView = false,
  isFetching = false,
  isFetchingPaginate = false,
  hideAlerts = false,
  devicesPerPage,
  currentPage,
  totalDevices,
  onChangePaginate,
  devicesList,
  ignoredTokens,
  selectedTokens,
  didSelect,
  sortedBy,
  onSortedChange = null,
}) => {
  const canBeSorted = onSortedChange !== null;

  let columns: {
    Header: JSX.Element | string;
    accessor: string;
    sortable?: boolean;
    getHeaderProps?: any;
  }[] = [
    {
      Header: <Translate id="Common@name">Nome</Translate>,
      accessor: "name",
      sortable: canBeSorted,
    },
  ];

  if (showLocationColumn) {
    columns.push({
      Header: <Translate id="Device@locationInfo">Localidade</Translate>,
      accessor: "group_name",
      sortable: canBeSorted,
    });
  }
  if (isAdminView) {
    columns.push({
      Header: <Translate id="Menu@administrativeContract">Contrato</Translate>,
      accessor: "contract_name",
      sortable: canBeSorted,
    });
    columns.push({
      Header: <span>Company</span>,
      accessor: "company_name",
      sortable: canBeSorted,
    });
  }
  columns.push({
    Header: <Translate id="Device@lastStatusInfo">Último status</Translate>,
    accessor: "stats_last",
    sortable: canBeSorted,
    getHeaderProps: () => ({
      style: {
        textAlign: "left",
      },
    }),
  });
  columns.push({
    Header: <Translate id="Device@systemVersionInfo">Versão</Translate>,
    accessor: "version",
    sortable: canBeSorted,
  });

  if (!hideAlerts) {
    columns.push(
      createIconColumn({ icon: "thermometer-empty", accessor: "temperature" })
    );
    columns.push(createIconColumn({ icon: "bolt", accessor: "energy" }));
    columns.push(
      createIconColumn({ icon: "folder-open", accessor: "diskSpeed" })
    );
  }
  if (currentUserCanSupport) {
    columns.push(
      createIconColumn({
        icon: "hand-holding-box",
        accessor: "is_lending",
        sortable: canBeSorted,
      })
    );
    columns.push(
      createIconColumn({
        icon: "power-off",
        accessor: "is_active",
        sortable: canBeSorted,
      })
    );
  }

  return (
    <Table
      // @ts-ignore
      manual
      isLoading={isFetching || isFetchingPaginate}
      hidePagination={isFetching}
      page={currentPage}
      totalItems={totalDevices}
      pageSize={devicesPerPage}
      didChangePagination={({ page, pageSize }) =>
        onChangePaginate(page, pageSize)
      }
      data={devicesList.map(
        ({
          token,
          group,
          name,
          stats,
          version,
          category,
          hasTemperatureAlert,
          hasCriticalTemperatureAlert,
          hasEnergyAlert,
          hasCriticalEnergyAlert,
          hasCriticalDiskSpeedAlert,
          hasDiskSpeedAlert,
          isLending,
          isActive,
          contract,
          company,
        }) => ({
          token,
          name,
          version,
          group_name: group?.name || "-",
          contract_name: contract?.name || "-",
          company_name: company?.name || "-",
          stats_last: (
            <span>
              <i
                className={`fas fa-${
                  category === "standard" ? "hdd" : "server"
                }`}
                style={{ color: stats.color, marginRight: 8 }}
              />
              {stats.last}
            </span>
          ),
          temperature: (
            <CustomTooltipAlert
              hasCriticalAlert={hasCriticalTemperatureAlert}
              hasAlert={hasTemperatureAlert}
            />
          ),
          energy: (
            <CustomTooltipAlert
              hasCriticalAlert={hasCriticalEnergyAlert}
              hasAlert={hasEnergyAlert}
            />
          ),
          diskSpeed: (
            <CustomTooltipAlert
              hasCriticalAlert={hasCriticalDiskSpeedAlert}
              hasAlert={hasDiskSpeedAlert}
            />
          ),
          is_lending: (
            <Tooltip
              // @ts-ignore
              title={
                isLending ? (
                  <Translate id="Device@itIsALendingEquipment">
                    É um equipamento em comodato
                  </Translate>
                ) : (
                  <Translate id="Device@notALendingEquipment">
                    Não é comodato
                  </Translate>
                )
              }
            >
              <span>
                <BooleanIcon isTrue={isLending} falseIcon="minus" />
              </span>
            </Tooltip>
          ),
          is_active: (
            <Tooltip
              // @ts-ignore
              title={isActive ? "Enabled" : "Disabled"}
            >
              <span>
                <BooleanIcon isTrue={isActive} />
              </span>
            </Tooltip>
          ),
        })
      )}
      getTrProps={(state: any, rowInfo: any, column: any) => {
        const isSelected =
          rowInfo && rowInfo.original && selectedTokens
            ? selectedTokens.includes(rowInfo.original.token)
            : false;
        const isIgnored =
          ignoredTokens && rowInfo && rowInfo.original
            ? ignoredTokens.includes(rowInfo.original.token)
            : false;

        return {
          style: {
            border: "1px dashed transparent",
            cursor: isIgnored
              ? "not-allowed"
              : rowInfo && rowInfo.original
              ? "pointer"
              : "auto",
            borderColor: isSelected ? successColor : "transparent",
            backgroundColor: isSelected
              ? hexToRGBA(successColor, 0.3)
              : isIgnored
              ? hexToRGBA(grayColor, 0.3)
              : "inherit",
            color: isIgnored ? hexToRGBA(grayColor, 0.6) : "inherit",
          },
        };
      }}
      getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
        return {
          onClick: () => {
            const isIgnored =
              ignoredTokens && rowInfo && rowInfo.original
                ? ignoredTokens.includes(rowInfo.original.token)
                : false;

            if (!isIgnored && rowInfo && rowInfo.original) {
              didSelect(rowInfo.original.token)();
            }
          },
        };
      }}
      columns={columns}
      sortedBy={sortedBy ? { id: sortedBy.column, desc: !sortedBy.asc } : null}
      onSortedChange={(newSorted, column, shiftKey) => {
        if (onSortedChange && newSorted && newSorted.length > 0) {
          const { id, desc } = newSorted[0];
          onSortedChange(id, !desc);
        }
      }}
    />
  );
};

export default DevicesTableView;
