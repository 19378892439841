// @ts-nocheck

import moment from "moment";
import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { Divider } from "@material-ui/core";
import { GridItem } from "../Grid";
import Tooltip from "../Tooltip/Tooltip";

import Translate from "../I18n/Translate";

import DateRange, { labelForDataRange } from "./DateRange";

// Style
import chartsStyle from "../../assets/jss/js-styles/views/chartsStyle";
const styles = {
  ...chartsStyle,
  locationItem: {
    marginBottom: 0,
    textAlign: "left",
  },
  otherLocationsSpan: {
    position: "relative",
    marginRight: 6,
  },
};

const MetricChartSectionTitle = inject("i18nStore")(
  observer(
    ({
      classes,
      i18nStore,
      children,
      locationsList,
      datePeriod,
      showDivider,
    }) => {
      const { localizedString } = i18nStore;
      const isMultipleLocations = locationsList
        ? Array.isArray(locationsList)
        : false;

      const renderLocationsName = () => {
        if (!isMultipleLocations) return locationsList.label;

        const locations = locationsList.map(($0) => $0.label);

        switch (locations.length) {
          case 1:
          case 2:
          case 3:
            return locations.map((name, index) =>
              index === 0 ? name : `, ${name}`
            );

          default:
            return (
              <span>
                {`${locations[0]}, ${locations[1]}`}{" "}
                <Translate id="Common@andOthers">e outras</Translate>{" "}
                <Tooltip
                  title={
                    <div>
                      {locations.map((locationName) => (
                        <p className={classes.locationItem} key={locationName}>
                          <i className="fas fa-chevron-right" /> {locationName}
                        </p>
                      ))}
                    </div>
                  }
                >
                  <span className={classes.otherLocationsSpan}>
                    {locations.length - 2}{" "}
                    <Translate id="Metrics@chartSubtitleLocations">
                      localidades
                    </Translate>{" "}
                    <i
                      className="fas fa-info"
                      style={{
                        position: "absolute",
                        fontSize: 8,
                        right: -2,
                        top: -2,
                      }}
                    />
                  </span>
                </Tooltip>
              </span>
            );
        }
      };

      const renderDatePeroid = () => {
        const { dateRange, toDate, fromDate, toHour, fromHour } = datePeriod;

        const renderToAndFromHours = () => {
          if (fromHour && toHour) {
            return ` ${localizedString(
              {
                id: "Metrics@customHourChartSubtitle",
                defaultString: "das $fromHour às $toHour",
              },
              {
                fromHour,
                toHour,
              }
            )}`;
          }
          return "";
        };

        if (dateRange === DateRange.custom) {
          const { dateFormatWithTwoDigitsYear, timeFormat } = i18nStore;
          const displayDate = (date) =>
            moment(date).format(`${dateFormatWithTwoDigitsYear} ${timeFormat}`);
          return (
            <span>
              <Translate
                id="Common@fromToDate"
                variables={{
                  fromDate: displayDate(fromDate),
                  toDate: displayDate(toDate),
                }}
              >
                de $fromDate até $toDate
              </Translate>
              {renderToAndFromHours()}
            </span>
          );
        }
        return (
          <span style={{ textTransform: "lowercase" }}>
            {labelForDataRange(dateRange, localizedString)}
            {renderToAndFromHours()}
          </span>
        );
      };

      return (
        <GridItem xs={12}>
          {showDivider && <Divider style={{ marginTop: 30 }} />}
          <h4
            className={classes.cardIconTitle}
            style={{ marginBottom: 0, fontWeight: "bold" }}
          >
            {children}
          </h4>
          {locationsList && datePeriod && (
            <p className={classes.legendTitle}>
              {renderLocationsName()} {renderDatePeroid()}
            </p>
          )}
        </GridItem>
      );
    }
  )
);
export default withStyles(styles)(MetricChartSectionTitle);
