// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";

import Modal from "./Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../HOC";
import Badge from "../Badge/Badge";
import Tooltip from "../Tooltip/Tooltip";
import { Button, LinkButton } from "../Button";
import { GridContainer, GridItem } from "../Grid";
import LoadingView from "../LoadingView/LoadingView";
import PriceContainer from "../Price/PriceContainer";

// Model
import { ProductCategory } from "../../Model/ProductModel";

// Translate
import Translate from "../I18n/Translate";
import Plural from "../I18n/Plural";

//Utils
import predefinedMessage, {
  PREDEFINED_MESSAGES_KEYS,
} from "../../store/ChatStore/PredefinedMessages";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";
const styles = (theme) => ({
  categoryTitle: {
    fontSize: "1.3em",
    fontWeight: 500,
    textTransform: "uppercase",
    color: primaryColor,
    margin: 0,
  },
  productName: {
    fontWeight: 500,
    margin: 0,
  },
  order: {
    marginTop: 20,
    background: "#fff",
    position: "sticky",
    bottom: 0,
  },
  panel: {
    background: "#F4F6F8",
  },
  expandedPanel: {
    background: "#E3F2FD",
  },
  listItemPriceContainer: {
    minWidth: 220,
  },
  customPlanListItem: {
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
    },
  },
});

@withLocalized
@inject("chatStore", "contractStore", "productsStore", "ordersStore")
@observer
class ProductsListModal extends Component {
  getTitleForCategory = (category) => {
    const { localizedString } = this.props;
    switch (category) {
      case ProductCategory.SMS_CREDIT:
        return localizedString({
          id: "Product@smsCreditLabel",
          defaultString: "Créditos SMS",
        });

      case ProductCategory.STORAGE:
        return localizedString({
          id: "Product@storageLabel",
          defaultString: "Espaço em Disco",
        });

      case ProductCategory.WIFI_AUTH:
        return localizedString({
          id: "Product@wifiAuthenticationLabel",
          defaultString: "Autenticação Inteligente",
        });

      case ProductCategory.combo:
        return "Combo";

      default:
        return localizedString({
          id: "Product@othersCategoryLabel",
          defaultString: "Outras opções",
        });
    }
  };

  didSelectPanel = (panelName) => {
    this.props.productsStore.changeProductListModalPanel(panelName);
  };

  onClickProductButton = (product, isSelected) => {
    if (isSelected) {
      this.props.ordersStore.removeProductFromSelectedOrder(product);
    } else {
      this.props.ordersStore
        .addProductToSelectedOrder(product)
        .catch((error) => {
          window.debugError("error addingProduct", error);
        });
    }
  };

  onClickCustomPlanButton = async () => {
    const { productsStore, chatStore, localizedString } = this.props;
    productsStore.toggleProductsListModal();
    chatStore.didWantToStartNewChatWithSubjectAndMessage(
      localizedString({
        id: "UpgradeContract@requestCustomPlanSubject",
        defaultString: "Sobre o plano personalizado",
      }),
      predefinedMessage(
        PREDEFINED_MESSAGES_KEYS.requestCustomPlan,
        localizedString
      )
    );
  };

  renderProductTitle = (product) => {
    const { classes } = this.props;
    return (
      <p className={classes.productName}>
        {product.name}
        {product.afterPurchase.otherKey === "upgrade-to-advanced-contract" && (
          <Tooltip
            title={
              <Translate id="Product@readMoreButtonLabel">
                Clique e saiba mais
              </Translate>
            }
            placement="right"
          >
            <LinkButton
              color="transparent"
              small
              justIcon
              to="upgrade"
              style={{ margin: 0, marginTop: -10 }}
            >
              <i className="fas fa-question-circle" />
            </LinkButton>
          </Tooltip>
        )}
      </p>
    );
  };

  renderProducts = (productsList) => {
    const { classes } = this.props;
    return productsList.map((product, index) => {
      const isSelected = this.props.ordersStore.verifyIfSelectedOrderHasProduct(
        product.token
      );
      return (
        <ListItem
          key={product.token}
          divider={index < productsList.length - 1}
          button
          onClick={() => this.onClickProductButton(product, isSelected)}
        >
          <ListItemText
            primary={this.renderProductTitle(product)}
            secondary={
              <Fragment>
                {product.description}
                {product.daysToExpire > 0 && (
                  <Badge
                    color="info"
                    style={{
                      marginTop: 6,
                      display: "block",
                      width: "fit-content",
                    }}
                  >
                    <Translate
                      id="Product@daysToExpireMessage"
                      variables={{ daysToExpire: `${product.daysToExpire}` }}
                    >
                      válido por $daysToExpire dias
                    </Translate>
                  </Badge>
                )}
              </Fragment>
            }
          />
          <ListItemText
            classes={{
              root: classes.listItemPriceContainer,
            }}
            primary={
              <PriceContainer
                price={product.price}
                offerPrice={product.offerPrice}
                actualPriceColor="#333"
                containerStyle={{
                  fontSize: "1.1em",
                  textAlign: "right",
                  marginRight: 16,
                }}
              />
            }
          />
          <ListItemSecondaryAction>
            <Button
              justIcon
              color={isSelected ? "danger" : "primary"}
              onClick={() => this.onClickProductButton(product, isSelected)}
            >
              {isSelected ? (
                <i className="fas fa-minus" />
              ) : (
                <i className="fas fa-plus" />
              )}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  renderCategoryPanel = (category) => {
    const { classes, contractStore, productsStore } = this.props;
    const filteredProductsList = productsStore.filteredProductsList;

    const productsList = filteredProductsList.filter((product) => {
      const { selectedContract } = contractStore;
      const matchCategory = product.category === category;

      const matchProductMaxLimit =
        category === ProductCategory.STORAGE
          ? selectedContract.storage.limit + product.afterPurchase.storage <=
            10737418240
          : category === ProductCategory.SMS_CREDIT
          ? selectedContract.sms.limit + product.afterPurchase.smsCredit <=
            30000
          : category === ProductCategory.WIFI_AUTH
          ? selectedContract.wifiAuthentication.limit +
              product.afterPurchase.wifiAuthentication <=
            60000
          : true;

      return matchCategory && matchProductMaxLimit;
    });

    if (productsList.length < 1 && category !== "other") return null;

    return (
      <ExpansionPanel
        className={classes.panel}
        expanded={
          this.props.productsStore.productListModalSelectedPanel === category
        }
        onChange={() => this.didSelectPanel(category)}
      >
        <ExpansionPanelSummary
          expandIcon={
            <i className="fas fa-chevron-down" style={{ fontSize: 14 }} />
          }
        >
          <h3 className={classes.categoryTitle}>
            {this.getTitleForCategory(category)}
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GridContainer>
            <GridItem xs={12}>
              <List>
                {this.renderProducts(productsList)}
                {category === "other" && (
                  <ListItem button onClick={this.onClickCustomPlanButton}>
                    <ListItemText
                      primary={
                        <Translate id="UpgradeContract@customPlanTitle">
                          Contrato personalizado para sua empresa
                        </Translate>
                      }
                      secondary={
                        <Translate id="UpgradeContract@customPlanDescription">
                          Precisa ativar mais que 10 dispositivos ou não quer se
                          preocupar com limite de recursos? Este é o plano ideal
                          para você!
                        </Translate>
                      }
                      classes={{
                        root: classes.customPlanListItem,
                      }}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        color={"primary"}
                        onClick={this.onClickCustomPlanButton}
                      >
                        <Translate id="UpgradeContract@customPlanButtonLabel">
                          Solicitar contato
                        </Translate>
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            </GridItem>
          </GridContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { classes, productsStore, ordersStore, isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        didCloseModal={productsStore.toggleProductsListModal}
        modalTitle={"Adicionar recursos ao contrato"}
      >
        {productsStore.isFetching ? (
          <LoadingView />
        ) : (
          <GridContainer>
            <GridItem xs={12}>
              {this.renderCategoryPanel(ProductCategory.STORAGE)}
              {this.renderCategoryPanel(ProductCategory.SMS_CREDIT)}
              {this.renderCategoryPanel(ProductCategory.WIFI_AUTH)}
              {this.renderCategoryPanel(ProductCategory.OTHER)}
            </GridItem>
            <GridItem xs={12} className={classes.order}>
              {ordersStore.selectedOrderProductsCount > 0 && (
                <p>
                  <Plural
                    id="Products@selectedProductsCount"
                    value={ordersStore.selectedOrderProductsCount}
                    one={"Um produto selecionado."}
                    other={"# produtos selecionados."}
                  />
                </p>
              )}
              <LinkButton
                block
                color={
                  ordersStore.selectedOrderProductsCount < 1
                    ? "default"
                    : "success"
                }
                to="/checkout"
                disabled={ordersStore.selectedOrderProductsCount < 1}
                onClick={productsStore.toggleProductsListModal}
              >
                <Translate id="Common@continue">Continuar</Translate>{" "}
                {ordersStore.selectedOrderProductsCount > 0 && (
                  <i className="fas fa-chevron-right" />
                )}
              </LinkButton>
            </GridItem>
          </GridContainer>
        )}
      </Modal>
    );
  }
}

export default withStyles(styles)(ProductsListModal);
