import { observable, action } from "mobx";

import { IMAGE_PATH } from "../utils/EnvUtils";

import { ILocation } from "../types/Location";
import {
  ITerminalContract,
  ITerminalCompany,
  ITerminalStats,
  ITerminalHardware,
  ITerminalNetwork,
  ITerminalWifi,
  ITerminalScreen,
  ITerminalGPS,
  ITerminalCamera,
  ITerminalMediaPreferences,
  ITerminalAlerts,
  ITerminalServers,
  ITerminalOS,
  ETerminalAlertLevel,
  ETerminalWifiLevel,
} from "../types/Terminal";

export const TERMINAL_CATEGORY = Object.freeze({
  STANDARD: "standard",
  MODWIFI: "modwifi",
});

export const TERMINAL_DISABLED_OPTIONS = Object.freeze({
  VIDEO: "video",
  CLOCK: "clock",
  MESSAGES: "messages",
  LOGO: "logo",
  SOUND_NORMALIZER: "normalizer",
  WIFI: "wifi",
  SPEEDTEST: "speedtest",
  CAMERA: "camera",
  LOG: "log",
  MONITOR_WIFI: "monitorWifiIsActive",
});

class TerminalModel {
  name: string;
  comments: string;
  category: string;
  contract?: ITerminalContract | null;
  company?: ITerminalCompany | null;
  hostname?: string;
  token: string;
  tokenMd5: string;
  version: string;
  activationDate: string;
  timezone: string;
  language: string;
  stats: ITerminalStats;
  hardware: ITerminalHardware;
  network: ITerminalNetwork;
  wifi: ITerminalWifi;
  screen: ITerminalScreen;
  gps: ITerminalGPS;
  camera: ITerminalCamera;
  group: ILocation;
  disabledList: string[];
  mediaPreferences: ITerminalMediaPreferences;
  @observable screenshot: string | null = null;
  alerts: ITerminalAlerts;
  isEnterprise: boolean;
  isOnline: boolean;
  isActive: boolean;
  isLending: boolean;
  @observable isVerticalScreen: boolean = false;
  monitorWifiIsActive: boolean = false;
  servers?: ITerminalServers;
  operationalSystem?: ITerminalOS;

  constructor(terminal: any) {
    this.name = terminal.name;
    this.comments = terminal.comments;
    this.category = terminal.category;
    this.hostname = terminal.hostname;
    this.token = terminal.token;
    this.tokenMd5 = terminal.token_md5;
    this.contract = terminal.contract
      ? {
          apiKey: terminal.contract.api_key,
          hash: terminal.contract.hash,
          name: terminal.contract.name,
          path: terminal.contract.path,
          status: terminal.contract.status === "True",
          licence: terminal.contract.licence,
        }
      : null;
    this.company = terminal.company
      ? {
          name: terminal.company.name || "",
          status: terminal.company.status === "True",
        }
      : null;
    this.version = terminal.version;
    this.activationDate = terminal.activation_date;
    this.timezone = terminal.timezone;
    this.language = terminal.language;
    this.stats = terminal.stats;
    this.hardware = terminal.hardware;
    this.network = {
      internet_interface: terminal.network?.internet_interface || "",
      dhcp: terminal.network?.dhcp === "True" || false,
      internet_address: terminal.network?.internet_address || "",
      internet_macaddress: terminal.network?.internet_macaddress || "",
      download: terminal.network?.download || "",
      upload: terminal.network?.upload || "",
      local_address: terminal.network?.local_address || "",
    };
    this.wifi = {
      ssid: terminal.wifi?.ssid || "",
      password: terminal.wifi?.password || "",
      signal: terminal.wifi?.signal || "",
      quality: terminal.wifi?.quality || "",
      hasExternalAntenna: terminal.wifi?.external_antenna === "True",
    };
    this.screen = {
      size: terminal.screen?.size || "",
      rotate: terminal.screen?.rotate === "True",
      name: terminal.screen?.name,
      status: terminal.screen?.status,
    };
    this.setIsVerticalScreen(this.screen.rotate);
    this.monitorWifiIsActive = terminal.monitor?.is_active === "True";
    this.gps = {
      latitude: terminal.gps?.latitude || "",
      longitude: terminal.gps?.longitude || "",
      velocity: terminal.gps?.velocity || "",
      altitude: terminal.gps?.altitude || "",
      hasExternalDevice: terminal.gps?.external_device === "True",
    };
    this.camera = {
      hasExternalDevice: terminal.camera?.external_device === "True",
    };
    this.group = terminal.group;
    this.disabledList = terminal.disabled;
    this.mediaPreferences = terminal.media_preferences || {};
    this.isEnterprise = terminal.category !== "standard";
    this.isOnline = this.stats?.connection !== "offline" || false;
    this.isActive = terminal.active === "True";
    this.isLending = terminal.is_lending === "True";
    this.alerts = terminal.alerts
      ? {
          temperature: ETerminalAlertLevel[terminal.alerts.temperature],
          energy: ETerminalAlertLevel[terminal.alerts.energy],
          diskSpeed: ETerminalAlertLevel[terminal.alerts.disk_speed],
          wifi: ETerminalWifiLevel[terminal.alerts.wifi],
        }
      : {
          temperature: ETerminalAlertLevel.normal,
          energy: ETerminalAlertLevel.normal,
          diskSpeed: ETerminalAlertLevel.normal,
          wifi: ETerminalWifiLevel.strong,
        };
    this.servers = terminal.servers;
    this.operationalSystem = terminal.os;

    this.setScreenshot();
  }

  @action
  setScreenshot = () => {
    if (this.category === TERMINAL_CATEGORY.STANDARD) {
      if (
        this.disabledList.includes(TERMINAL_DISABLED_OPTIONS.VIDEO) &&
        this.disabledList.includes(TERMINAL_DISABLED_OPTIONS.CAMERA)
      ) {
        this.screenshot = `${IMAGE_PATH}/devices/raspberry-only-wifi.png`;
      } else if (this.isOnline) {
        this.screenshot = `https://simplyas.com/modbox/screen/${
          this.tokenMd5
        }.png?t=${new Date().getTime()}`;
      } else {
        this.screenshot = `${IMAGE_PATH}/devices/offline-screenshot.jpg`;
      }
    } else if (this.category === TERMINAL_CATEGORY.MODWIFI) {
      this.screenshot = `${IMAGE_PATH}/devices/enterprise-modwifi.png`;
    } else {
      this.screenshot = null;
    }
  };

  @action
  setIsVerticalScreen = (newValue: boolean) => {
    this.isVerticalScreen = newValue;
  };

  get hasTemperatureAlert(): boolean {
    return this.alerts.temperature !== ETerminalAlertLevel.normal;
  }
  get hasCriticalTemperatureAlert(): boolean {
    return this.alerts.temperature === ETerminalAlertLevel.critical;
  }
  get hasWifiAlert(): boolean {
    return this.alerts.wifi === ETerminalWifiLevel.weak;
  }
  get hasEnergyAlert(): boolean {
    return this.alerts.energy !== ETerminalAlertLevel.normal;
  }
  get hasCriticalEnergyAlert(): boolean {
    return this.alerts.energy === ETerminalAlertLevel.critical;
  }
  get hasDiskSpeedAlert(): boolean {
    return this.alerts.diskSpeed !== ETerminalAlertLevel.normal;
  }
  get hasCriticalDiskSpeedAlert(): boolean {
    return this.alerts.diskSpeed === ETerminalAlertLevel.critical;
  }
  get hasCriticalAlert(): boolean {
    return this.isEnterprise
      ? false
      : this.hasCriticalEnergyAlert ||
          this.hasCriticalTemperatureAlert ||
          this.hasCriticalDiskSpeedAlert;
  }

  get hasAnyAlert(): boolean {
    return (
      this.hasTemperatureAlert ||
      this.hasWifiAlert ||
      this.hasEnergyAlert ||
      this.hasCriticalAlert
    );
  }
}

export default TerminalModel;
