// @ts-nocheck


import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

import Translate from "../I18n/Translate";

type InputRepeaterProps = {
  items: any[];
  didAdd: any;
  didRemove: any;
  canRemoveLastItem: boolean;
  offset: number;
};

const InputRepeater = ({
  items,
  didAdd,
  didRemove,
  canRemoveLastItem,
  offset,
}: InputRepeaterProps) => {
  const buttonStyle = {
    margin: 0,
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        {items
          .filter((_, index) => index + 1 > offset)
          .map((item, index) => {
            return (
              <GridContainer key={index} alignItems="center">
                <GridItem xs>{item}</GridItem>
                <GridItem>
                  <Button
                    justIcon
                    small
                    outline
                    color="danger"
                    style={buttonStyle}
                    onClick={didRemove(index)}
                    disabled={items.length < 2 && !canRemoveLastItem}
                  >
                    <i className="fas fa-minus" />
                  </Button>
                </GridItem>
              </GridContainer>
            );
          })}
      </GridItem>
      <GridItem xs={12}>
        <Button color="primary" small outline block onClick={didAdd}>
          <Translate id="Common@add">Adicionar</Translate>{" "}
          <i className="fas fa-plus" />
        </Button>
      </GridItem>
    </GridContainer>
  );
};

InputRepeater.defaultProps = {
  canRemoveLastItem: true,
  offset: 0,
};

export default InputRepeater;
