// @ts-nocheck

import { inject, observer } from "mobx-react";

import Modal from "../Modal/Modal";
import ImageUpload from "../Form/ImageUpload";
import ChatInput from "./ChatInput";

const ModalMessageImageType = inject(
  "chatStore",
  "i18nStore"
)(
  observer(({ chatStore, i18nStore, didClose, onChangeMessage }) => {
    const { message } = chatStore;
    const selectedMessageForEditing = chatStore.isEditingMessageOfImageType
      ? chatStore.selectedMessageForEditing
      : null;

    return (
      <Modal
        isOpen={chatStore.isAttachmentModalOpen}
        didCloseModal={didClose}
        maxWidth="md"
        disableEscapeKeyDown
      >
        <ImageUpload
          src={
            selectedMessageForEditing
              ? selectedMessageForEditing.imageUrl
              : null
          }
          resizable
          didCancel={didClose}
          didUpdate={chatStore.didUpdateChatMessage}
          didCrop={chatStore.didWantToUploadImage}
          didFinish={chatStore.didUploadImage}
        >
          <ChatInput
            label={i18nStore.localizedString({
              id: "Chat@imageLabelPlaceholder",
              defaultString: "Escreva uma legenda...",
            })}
            message={message}
            onChangeMessage={onChangeMessage}
          />
        </ImageUpload>
      </Modal>
    );
  })
);

export default ModalMessageImageType;
