// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import InputText from "../Form/InputText";
import { Button } from "../Button";

// Translate
import { SaveString, BackString } from "../I18n/CommonStrings";
import Translate from "../I18n/Translate";

// Style
import { primaryColor } from "../../assets/jss/app-js-styles";

@inject("chatStore")
@observer
class ChatSubjectEditable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      newSubject: this.props.chatStore.selectedChatSubject,
    };
  }

  toggleIsEditing = () => {
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  onChangeSubject = ({ target }) => {
    this.setState({
      newSubject: target.value,
    });
  };

  saveNewSubject = () => {
    const { newSubject } = this.state;

    this.props.chatStore.onChangeSubject(newSubject);

    this.toggleIsEditing();
  };

  render() {
    const { isEditing, newSubject } = this.state;

    return (
      <div>
        {isEditing ? (
          <InputText
            name="subject"
            label={
              <Translate id="Chat@subjectLabel">Assunto da conversa</Translate>
            }
            onChange={this.onChangeSubject}
            value={newSubject}
            autoFocus
          />
        ) : (
          <Fragment>
            <p style={{ margin: 0 }}>
              <Translate id="Chat@subjectLabel">Assunto da conversa</Translate>
            </p>
            <h4 style={{ margin: 0, color: primaryColor }}>
              <strong onClick={this.toggleIsEditing}>
                {this.props.chatStore.selectedChatSubject}
              </strong>
              <Button
                justIcon
                small
                color={"warning"}
                onClick={this.toggleIsEditing}
                style={{ marginLeft: 8 }}
              >
                <i className="fas fa-pencil-alt" />
              </Button>
            </h4>
          </Fragment>
        )}
        {isEditing && (
          <Fragment>
            <Button small color={"default"} onClick={this.toggleIsEditing}>
              <i className="fas fa-chevron-left" /> <BackString />
            </Button>
            <Button small color={"success"} onClick={this.saveNewSubject}>
              <SaveString /> <i className="fas fa-check" />
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}

export default ChatSubjectEditable;
