// @ts-nocheck
import { Component, createRef } from "react";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";

import { GridContainer, GridItem } from "../../components/Grid";
import { Button } from "../../components/Button";
import LoadingView from "../../components/LoadingView/LoadingView";

import withStyles from "@material-ui/core/styles/withStyles";

import {
  KEYCODE,
  IMappedKeyFunctions,
} from "../../services/KeyboardEventsService";

// Translate
import Translate from "../../components/I18n/Translate";

// Styles
const styles = {
  inputContainer: {
    maxWidth: "100%",
    flexBasis: 0,
    margin: 4,
    textAlign: "center",
  },
  input: {
    width: 50,
    height: 50,
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
    border: "1px solid #e9e9e9",
    backgroundColor: "#e9e9e959",
    borderRadius: 8,
    margin: "0 auto",
  },
};

@inject("authStore", "keyboardEventsService")
@observer
class VerifyPinForm extends Component {
  state = {
    timeToRequestNewPin: 120,
    canRequestNewPin: false,
  };

  inputRefs = [createRef(), createRef(), createRef(), createRef()];

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        const newTime = prevState.timeToRequestNewPin - 1;
        if (newTime === 0) {
          clearInterval(this.timer);
        }
        return {
          timeToRequestNewPin: newTime,
          canRequestNewPin: newTime === 0,
        };
      });
    }, 1000);

    this.setInputFocus(0);

    this.props.keyboardEventsService.setListener(this, this.mapKeyFunctions);

    autorun(() => {
      this.setInputFocus(this.props.authStore.pin.length);
    });
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);

    this.props.keyboardEventsService.removeListener(this);
  }

  mapKeyFunctions = (): IMappedKeyFunctions => {
    return {
      [KEYCODE.DELETE]: () => {
        const { pin } = this.props.authStore;
        this.setPin(pin.substring(0, pin.length - 1));
      },
    };
  };

  setPin = this.props.authStore.setPin;

  onChangePin = ({ target }) => {
    const { pin } = this.props.authStore;
    if (pin.length === 4) return null;

    const newPin = `${pin}${target.value}`;
    this.setPin(newPin);
  };

  setInputFocus = (index) => {
    if (this.inputRefs[index] && this.inputRefs[index].current)
      this.inputRefs[index].current.focus();
  };

  onFocus = () => {
    const { pin } = this.props.authStore;
    if (pin.length < 4) {
      this.setInputFocus(pin.length);
    } else {
      this.setInputFocus(4);
    }
  };

  renderInput = (_, index) => {
    const { classes } = this.props;
    return (
      <div key={index} className={classes.inputContainer}>
        <input
          ref={this.inputRefs[index]}
          className={classes.input}
          name={`${index}`}
          value={this.props.authStore.pin[index] || ""}
          autoComplete="off"
          onChange={this.onChangePin}
          onFocus={this.onFocus}
        />
      </div>
    );
  };

  render() {
    const { canRequestNewPin } = this.state;
    const {
      pin,
      isRequestingNewPin,
      isWaitingForm,
      didSubmitForm,
      requestNewPin,
    } = this.props.authStore;

    return (
      <form onSubmit={didSubmitForm}>
        {this.props.body(
          <GridContainer justify="center">
            <GridItem xs={12}>
              <p style={{ textAlign: "center" }}>
                {isRequestingNewPin ? (
                  <Translate id="Login@isRequestingNewPinMessage">
                    Aguarde, solicitando novo PIN...
                  </Translate>
                ) : (
                  <Translate id="Login@requestPinMessage">
                    Informe o PIN enviado para o seu e-mail
                  </Translate>
                )}
              </p>
            </GridItem>
            {isRequestingNewPin ? (
              <GridItem
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <LoadingView />
              </GridItem>
            ) : (
              [0, 1, 2, 3].map(this.renderInput)
            )}
          </GridContainer>
        )}
        {this.props.actions(
          !isRequestingNewPin && (
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  block
                  disabled={pin.length < 4}
                  isLoading={isWaitingForm}
                >
                  <Translate id="Login@verifyPinButton">Verificar</Translate>{" "}
                  <i className="fas fa-check" />
                </Button>
              </GridItem>
              <GridItem xs={12}>
                <Button
                  color={canRequestNewPin ? "primary" : "transparent"}
                  outline={canRequestNewPin}
                  block
                  disabled={isWaitingForm || !canRequestNewPin}
                  onClick={requestNewPin}
                >
                  <span>
                    <Translate id="Login@requestNewPinButton">
                      Solicitar novo PIN
                    </Translate>
                    {"   "}
                    {canRequestNewPin ? "" : this.state.timeToRequestNewPin}
                  </span>
                </Button>
              </GridItem>
            </GridContainer>
          )
        )}
      </form>
    );
  }
}

export default withStyles(styles)(VerifyPinForm);
