// @ts-nocheck
import _forEach from "lodash/forEach";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Component } from "react";
import ReactGA from "react-ga";
import { Fade } from "react-reveal";
import { Redirect, Route, Switch } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "../components/Header/Header";
import LoadingView from "../components/LoadingView/LoadingView";
import PeopleModal from "../components/Modal/PeopleModal";
import ProductsListModal from "../components/Modal/ProductsListModal";
import NotificationsDrawer from "../components/NotificationsDrawer/NotificationsDrawer";
import Sidebar from "../components/Sidebar/Sidebar";

import AlfredContainer from "../components/Alfred/AlfredContainer";
import FloatingChatButton from "../components/Chat/FloatingChatButton";
import PlayerActivation from "../pages/PlayerActivation";
import ActivateDeviceLayout from "./ActivateDeviceLayout";
import InstallSystemLayout from "./InstallSystemLayout";
import WelcomeLayout from "./WelcomeLayout";

// Translate
import Translate from "../components/I18n/Translate";

import {
  filterAppRoutesByContractPlanAndResources,
  sidebarRoutesForCurrentUser,
  simplyAsMenuRoutes,
} from "../routes/AppRoutes";

import appStyle from "../assets/jss/js-styles/layouts/dashboardStyle";

import { isProductionServer } from "../utils/EnvUtils";

@inject(
  "authStore",
  "alfredStore",
  "contractStore",
  "chatStore",
  "notificationsStore",
  "productsStore",
  "settingsStore",
  "modboxAPIService"
)
@observer
class BaseLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileDrawerOpen: false,
    };
  }

  componentDidMount() {
    this.setSidebarBGImage(this.props.location.pathname);

    // url change listener
    this.props.history.listen((location) => {
      this.setSidebarBGImage(location.pathname);

      const currentUrl = window.location.pathname + window.location.search;

      if (isProductionServer) {
        ReactGA.pageview(currentUrl);
      }
      if (this.props.chatStore) {
        this.props.chatStore.didChangeUserCurrentUrl(currentUrl);
      }
    });

    const { alfredStore, authStore } = this.props;

    // Set routes actions for Alfred
    let options = [];
    this.sidebarRoutes().forEach((route) => {
      if (route.collapse) {
        route.views.forEach(($0) =>
          options.push(
            this.getAlfredRouteAction({
              ...$0,
              name: `${route.name} > ${$0.name}`,
            })
          )
        );
      } else if (!route.redirect) {
        options.push(this.getAlfredRouteAction(route));
      }
    });

    alfredStore.addActions({
      id: "routesActions",
      label: "Navegação",
      options,
    });

    let simplyAsMenuRoutesOptions = [];
    simplyAsMenuRoutes.forEach((route) => {
      if (route.collapse) {
        route.views.forEach(($0) =>
          simplyAsMenuRoutesOptions.push(
            this.getAlfredRouteAction({
              ...$0,
              name: `${route.name} > ${$0.name}`,
            })
          )
        );
      } else if (!route.redirect) {
        simplyAsMenuRoutesOptions.push(this.getAlfredRouteAction(route));
      }
    });

    if (authStore.currentUserIsAdmin) {
      alfredStore.addActions({
        id: "simplyAsMenuRoutesActions",
        label: "Simply As",
        options: simplyAsMenuRoutesOptions,
      });
    }
  }
  componentWillUnmount() {
    this.props.alfredStore.removeActions("routesActions");
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.state.isMobileDrawerOpen) {
        this.setState({ isMobileDrawerOpen: false });
      }
    }
  }

  handleNotificationsDrawerToggle = () => {
    this.props.notificationsStore.toggleIsDrawerOpen();
  };

  getAlfredRouteAction = (route) => {
    return {
      id: `${route.path}${route.icon}`,
      title: route.name,
      icon: route.icon,
      callback: () => this.props.history.push(route.path),
    };
  };

  handleMobileDrawerToggle = () => {
    this.setState({ isMobileDrawerOpen: !this.state.isMobileDrawerOpen });
  };

  setSidebarBGImage = (pathname) => {
    const { settingsStore } = this.props;
    if (pathname.includes("administrative/contract")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-contract");
    } else if (pathname.includes("administrative/users")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-contract-users");
    } else if (pathname.includes("connectivity")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-connectivity");
    } else if (pathname.includes("dashboard")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-dashboard");
    } else if (pathname.includes("developer")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-developer");
    } else if (pathname.includes("devices")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-devices");
    } else if (
      pathname.includes("informative") ||
      pathname.includes("set-playlist")
    ) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-informative");
    } else if (pathname.includes("locations")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-devices");
    } else if (pathname.includes("profile")) {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-profile");
    } else {
      settingsStore.actionChangeSidebarBgImage("bg-sidebar-default");
    }
  };

  renderRoute = ({ path, component: Component }) => (
    <Route
      key={path}
      path={path}
      render={(props) => (
        <Fade>
          <Component {...props} />
        </Fade>
      )}
    />
  );

  fromRedirect = () => {
    const urlSearch = this.props.location.search;

    if (urlSearch.includes("?from=")) {
      return <Redirect from="/" to={urlSearch.replace("?from=", "")} />;
    }
    return null;
  };

  getDataForActiveRoute = (routes) => {
    const { pathname } = this.props.location;
    let activeRoute = null;

    _forEach(routes, (route) => {
      if (route.collapse) {
        if (pathname.includes(route.path)) {
          activeRoute = route;
        }
        _forEach(route.views, (subRoute) => {
          if (subRoute.path === pathname) {
            activeRoute = subRoute;
            return false;
          } else if (pathname.includes(subRoute.path)) {
            activeRoute = subRoute;
          }
        });
        if (activeRoute) return false;
      }
    });

    return activeRoute
      ? {
          icon: activeRoute.icon,
          title: (
            <Translate id={activeRoute.nameId}>{activeRoute.name}</Translate>
          ),
          tutorialKey: activeRoute.tutorialKey,
        }
      : null;
  };

  appRoutes = () => {
    const {
      selectedContract: { plan, resources, rules },
    } = this.props.contractStore;
    return [
      ...simplyAsMenuRoutes,
      ...filterAppRoutesByContractPlanAndResources(plan, resources, rules),
    ];
  };

  sidebarRoutes = () => {
    const { authStore, contractStore } = this.props;
    return sidebarRoutesForCurrentUser(
      contractStore.selectedContract,
      authStore.currentUserCanSupport,
      authStore.currentUserIsAdmin
    );
  };

  render() {
    const {
      classes,
      authStore,
      contractStore,
      productsStore,
      ...rest
    } = this.props;
    const { currentUser } = authStore;
    const {
      selectedContract,
      didFinishedWelcomeStep,
      didFinishedInstallSystemStep,
      didFinishedActivateDeviceStep,
    } = contractStore;

    if (
      selectedContract.isAffiliateContract &&
      (didFinishedWelcomeStep === null || didFinishedInstallSystemStep === null)
    ) {
      return <LoadingView fullPageCenter />;
    }

    if (selectedContract.isAffiliateContract && !didFinishedWelcomeStep) {
      return <WelcomeLayout />;
    }

    if (selectedContract.isAffiliateContract && !didFinishedInstallSystemStep) {
      return <InstallSystemLayout />;
    }

    if (
      selectedContract.isAffiliateContract &&
      !didFinishedActivateDeviceStep
    ) {
      return <ActivateDeviceLayout />;
    }

    if (this.props.location.pathname === "/ativar") {
      return (
        <PlayerActivation
          modboxService={this.props.modboxAPIService}
          searchString={this.props.location.search}
        />
      );
    }

    const appRoutes = this.appRoutes();
    const activeRoute = this.getDataForActiveRoute(appRoutes);

    return (
      <AlfredContainer className={classes.wrapper}>
        <FloatingChatButton />
        <ProductsListModal isOpen={productsStore.isOpenProductsListModal} />
        <PeopleModal />
        <Sidebar
          activeRoute={activeRoute}
          routes={this.sidebarRoutes()}
          simplyAsMenuRoutes={simplyAsMenuRoutes}
          currentUser={currentUser}
          handleMobileDrawerToggle={this.handleMobileDrawerToggle}
          isMobileDrawerOpen={this.state.isMobileDrawerOpen}
          handleNotificationsDrawerToggle={this.handleNotificationsDrawerToggle}
          contractStore={contractStore}
          {...rest}
        />
        <NotificationsDrawer history={this.props.history} />
        <div className={classes.mainPanel}>
          <Header
            activeRoute={activeRoute}
            handleMobileDrawerToggle={this.handleMobileDrawerToggle}
            handleNotificationsDrawerToggle={
              this.handleNotificationsDrawerToggle
            }
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {this.fromRedirect()}
                {appRoutes.map((route) => {
                  if (route.redirect) {
                    return (
                      <Redirect
                        key={route.from}
                        from={route.from}
                        to={route.to}
                      />
                    );
                  }

                  if (route.collapse) {
                    return route.views.map((view) => this.renderRoute(view));
                  }
                  return this.renderRoute(route);
                })}
              </Switch>
            </div>
          </div>
        </div>
      </AlfredContainer>
    );
  }
}

BaseLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appStyle)(BaseLayout);
