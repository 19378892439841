// @ts-nocheck

import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { LinkButton } from "../Button";

import { EAppRoutes } from "../../routes/AppRoutes";

// Style
import { dangerColor } from "../../assets/jss/app-js-styles";
const styles = {
  container: {
    position: "fixed",
    bottom: 8,
    right: 8,
    zIndex: 1000,
  },
  badge: {
    zIndex: "4",
    position: "absolute",
    top: -2,
    border: "1px solid #FFF",
    right: -2,
    fontSize: 14,
    background: dangerColor,
    color: "#FFFFFF",
    minWidth: 22,
    height: 22,
    borderRadius: 11,
    textAlign: "center",
    lineHeight: "20px",
    verticalAlign: "middle",
    display: "block",
  },
};

const FloatingChatButton = inject("routingStore")(
  observer(({ classes, routingStore }) => {
    const { pathname } = routingStore;

    return (
      <div className={classes.container}>
        {pathname !== "/contact" ? (
          <LinkButton
            color={"primary"}
            round
            large
            to={EAppRoutes.CONTACT}
            justIcon
          >
            <i className="fas fa-comments" />
          </LinkButton>
        ) : (
          <span></span>
        )}
      </div>
    );
  })
);

export default withStyles(styles)(FloatingChatButton);
