// @ts-nocheck

import axios from "axios";

// Utils Stores
import {
  saveLog,
  saveSupportOperatorLog,
} from "../store/FirebaseStore/FirebaseStore";

import { isProductionServer } from "../utils/EnvUtils";
import { removeUndefinedFromObject } from "../utils/Utils";

import {
  ERequestTerminalMetadata,
  ETerminalConnectionStatus,
  ETerminalStatus,
  IRequestTerminalsParams,
} from "../types/Terminal";

import { QueueManagementControlType } from "../Model/QueueManagementControlModel";
import { USER_KEY } from "../store/AuthStore/AuthStore";

const BASE_URL = process.env.REACT_APP_URL;
const UPDATE_PASSWORD_URL = process.env.REACT_APP_PASSWORD_UPDATE_URL;

const API_METHOD = Object.freeze({
  submitLogin: "submitLogin",
  requestContracts: "requestContracts",
  requestContract: "requestContract",
  submitContract: "submitContract",
  updateContract: "updateContract",
  requestChannels: "requestChannels",
  recoverPassword: "recoverPassword",
  requestUser: "requestUser",
  submitUser: "submitUser",
  updateUser: "updateUser",
  submitRule: "submitRule",
  requestRules: "requestRules",
  updateRule: "updateRule",
  deleteRule: "deleteRule",
  submitTerminal: "submitTerminal",
  requestTerminals: "requestTerminals",
  requestListOfTerminals: "requestListOfTerminals",
  requestTerminalsNotConnectedToGroup: "requestTerminalsNotConnectedToGroup",
  requestTerminal: "requestTerminal",
  updateTerminal: "updateTerminal",
  sendCommand: "sendCommand",
  sendCommandToListOfTerminals: "sendCommandToListOfTerminals",
  requestGroups: "requestGroups",
  submitGroup: "submitGroup",
  updateGroup: "updateGroup",
  deleteGroup: "deleteGroup",
  requestFile: "requestFile",
  requestFiles: "requestFiles",
  submitFiles: "submitFiles",
  submitStreamings: "submitStreamings",
  requestStreamings: "requestStreamings",
  requestContents: "requestContents",
  submitContent: "submitContent",
  updateContent: "updateContent",
  updateMedia: "updateMedia",
  deleteMedia: "deleteMedia",
  requestPlaylists: "requestPlaylists",
  submitPlaylist: "submitPlaylist",
  clonePlaylist: "clonePlaylist",
  updatePlaylist: "updatePlaylist",
  deletePlaylist: "deletePlaylist",
  requestClocks: "requestClocks",
  submitClock: "submitClock",
  updateClock: "updateClock",
  deleteClock: "deleteClock",
  requestMessages: "requestMessages",
  submitMessage: "submitMessage",
  updateMessage: "updateMessage",
  deleteMessage: "deleteMessage",
  requestLogos: "requestLogos",
  submitLogo: "submitLogo",
  updateLogo: "updateLogo",
  deleteLogo: "deleteLogo",
  requestNetworks: "requestNetworks",
  submitNetwork: "submitNetwork",
  updateNetwork: "updateNetwork",
  submitNetworkEnterprise: "submitNetworkEnterprise",
  updateNetworkEnterprise: "updateNetworkEnterprise",
  deleteNetwork: "deleteNetwork",
  requestResources: "requestResources",
  submitResource: "submitResource",
  updateResource: "updateResource",
  deleteResource: "deleteResource",
  reorderResource: "reorderResource",
  requestDataWifi: "requestDataWifi",
  requestAdvertisingMessages: "requestAdvertisingMessages",
  submitAdvertisingMessages: "submitAdvertisingMessages",
  submitSMS: "submitSMS",
  submitPayment: "submitPayment",
  requestDashboard: "requestDashboard",
  requestMetricsWifi: "requestMetricsWifi",
  requestMetricsCamera: "requestMetricsCamera",
  requestMetricsTv: "requestMetricsTv",
  requestMetricsTerminal: "requestMetricsTerminal",
  requestMediasFromLog: "requestMediasFromLog",
  requestPeople: "requestPeople",
  requestBusiness: "requestBusiness",
  requestDevicesNetworks: "requestDevicesNetworks",
  submitDevicesNetwork: "submitDevicesNetwork",
  updateDevicesNetwork: "updateDevicesNetwork",
  deleteDevicesNetwork: "deleteDevicesNetwork",
  requestPreferences: "requestPreferences",
  updatePreference: "updatePreference",
  verifyPin: "verifyPin",
  submitQueueManagementControl: "submitQueueManagementControl",
  updateQueueManagementControl: "updateQueueManagementControl",
  deleteQueueManagementControl: "deleteQueueManagementControl",
  requestQueueManagementControls: "requestQueueManagementControls",
  setQueueManagementControlOpenToConnect:
    "setQueueManagementControlOpenToConnect",
  requestNotifications: "requestNotifications",
  updateNotification: "updateNotification",
  grantTemporaryAccessToContract: "grantTemporaryAccessToContract",
  submitActivationCode: "submitActivationCode",
  updatePassword: "", // TODO: revisar endpoint
});

class ModboxAPIService {
  postPublicMethod = (method: API_METHOD, data: any = {}) => {
    const publicApi = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const sanitizedData = removeUndefinedFromObject(data);

    return new Promise((resolve, reject) => {
      publicApi
        .post(method, sanitizedData)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(this.errorHandlingCenter(method, error));
        });
    });
  };

  postPrivateMethod = (method: API_METHOD, data: any = {}, url?: string) => {
    if (this.authStore.isAuthenticated) {
      const privateApi = axios.create({
        baseURL: url ?? BASE_URL,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${this.authStore.currentUser?.jwtToken ||
            this.requestUser().jwtToken}`,
          "Content-Type": "application/json",
        },
        maxRedirects: 0,
      });

      const sanitizedData = removeUndefinedFromObject(data);

      this.saveSupportOperatorLog(method, sanitizedData);

      return new Promise((resolve, reject) => {
        privateApi
          .post(method, sanitizedData)
          .then((response) => resolve(response))
          .catch((error) => {
            reject(this.errorHandlingCenter(method, error));
          });
      });
    }

    return Promise.reject(null);
  };

  saveSupportOperatorLog = (apiMethod: string, requestData: any) => {
    //* For now we ignore all "request" methods here
    if (apiMethod.includes("request")) {
      return;
    }
    //* Log only "support_operator" level
    if (
      !this.authStore ||
      !this.authStore.isAuthenticated ||
      (!this.authStore.currentUser.isSupportOperator &&
        apiMethod !== API_METHOD.grantTemporaryAccessToContract)
    ) {
      return;
    }

    const { contract_hash, user_token } = this.getDefaultParams();
    saveSupportOperatorLog({
      contractHash: contract_hash,
      userToken: user_token,
      apiMethod,
      requestData,
    });
  };

  saveErrorLog = (logData) => {
    saveLog("modbox API Error", logData);
  };

  errorHandlingCenter = (method, error) => {
    const logData = {
      method,
      originError: JSON.stringify(error),
    };

    if (
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.status
    ) {
      const errorStatusCode = error.response.status;

      const responseError = {
        statusCode: errorStatusCode,
        ...error.response.data,
        message: error.response.data.error,
      };

      if (
        [
          API_METHOD.recoverPassword,
          API_METHOD.requestMetricsWifi,
          API_METHOD.requestMetricsCamera,
          API_METHOD.requestMetricsTv,
          API_METHOD.requestMetricsTerminal,
          API_METHOD.requestMediasFromLog,
          API_METHOD.requestTerminal,
          API_METHOD.verifyPin,
        ].includes(method) &&
        errorStatusCode === 404
      ) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "404 error",
        });
        return responseError;
      }

      if (errorStatusCode === 429) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "429 error",
        });
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "warning",
        });
        // API is busy
        return { statusCode: 429 };
      }

      if (errorStatusCode === 502) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "502 error",
        });
        this.notificationsStore.addServerErrorNotification();
        return null;
      }

      if (errorStatusCode === 403 && method === API_METHOD.requestPeople) {
        return responseError;
      } else if (errorStatusCode === 403) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "403 error caused logout",
        });
        // FORBIDDEN / ACCESS DENIED
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "danger",
        });
        if (isProductionServer) this.authStore.logout();
        return null;
      } else if (errorStatusCode === 409) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "409 error",
        });
        // CONFLICT
        return responseError;
      } else if (errorStatusCode === 429) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "429 error",
        });
        // API is busy
        return responseError;
      } else {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "Generic error",
        });
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "danger",
        });
        return responseError;
      }
    } else {
      this.saveErrorLog({
        ...logData,
        comment: "Fatal error",
      });
      this.notificationsStore.addServerErrorNotification();
      return null;
    }
  };

  getDefaultParams = (customContractHash: string) => {
    if (!this.authStore || !this.authStore.isAuthenticated) {
      return {};
    }
    const contract_hash =
      customContractHash && customContractHash.length > 0
        ? customContractHash
        : this.contractStore.selectedContract?.hash ?? undefined;

    return {
      contract_hash,
      user_token: this.authStore.currentUser.token,
      language: this.authStore.currentUser.language,
    };
  };

  getExtraParams = () => {
    if (this.authStore.currentUser) {
      return {
        locale_timezone: this.authStore.currentUser.timezone,
        locale_metric: this.authStore.currentUser.metric,
        locale_temperature: this.authStore.currentUser.temperature,
      };
    }
    return {};
  };

  sanitizeColor = (color: string): string => color.replace("#", "");

  // Auth
  submitLogin = ({ email, password }) => {
    return this.postPublicMethod(API_METHOD.submitLogin, {
      email,
      password,
    });
  };

  recoverPassword = (data) => {
    return this.postPublicMethod(API_METHOD.recoverPassword, data);
  };

  // Player
  submitActivationCode = (pin: string) => {
    return this.postPrivateMethod(API_METHOD.submitActivationCode, {
      ...this.getDefaultParams(),
      pin,
    });
  };

  // Contracts
  requestContracts = () => {
    return this.postPrivateMethod(API_METHOD.requestContracts, {
      status: true,
    });
  };

  requestContract = () => {
    return this.postPrivateMethod(
      API_METHOD.requestContract,
      this.getDefaultParams()
    );
  };

  submitContract = ({ name, isActive, userToken, companyToken, language }) => {
    return this.postPublicMethod(API_METHOD.submitContract, {
      name,
      status: isActive,
      user_token: userToken,
      company_token: companyToken,
      language: language,
    });
  };

  updateContract = ({
    contractHash,
    name,
    isActive,
    resourceInformative,
    resourceConnectivity,
    resourceAdvertising,
    resourceCamera,
    licencesLimit,
    storageLimit,
    smsLimit,
    wifiAuthenticationLimit,
  }) => {
    return this.postPrivateMethod(API_METHOD.updateContract, {
      contract_hash: contractHash,
      user_token: this.authStore.currentUser.token,
      language: this.authStore.currentUser.language,
      name,
      status: isActive,
      resource_informative: resourceInformative,
      resource_connectivity: resourceConnectivity,
      resource_advertising: resourceAdvertising,
      resource_camera: resourceCamera,
      licences_limit: `${Math.round(licencesLimit)}`,
      storage_limit: `${Math.round(storageLimit)}`,
      sms_limit: `${Math.round(smsLimit)}`,
      auth_limit: `${Math.round(wifiAuthenticationLimit)}`,
    });
  };

  requestPreferences = (type) => {
    return this.postPrivateMethod(API_METHOD.requestPreferences, {
      ...this.getDefaultParams(),
      type,
    });
  };

  updatePreference = (type, preferences) => {
    return this.postPrivateMethod(API_METHOD.updatePreference, {
      ...this.getDefaultParams(),
      preferences: preferences,
      type,
    });
  };

  requestBusiness = () => {
    return this.postPrivateMethod(
      API_METHOD.requestBusiness,
      this.getDefaultParams()
    );
  };

  // User
  requestPeople = (token) => {
    return this.postPrivateMethod(API_METHOD.requestPeople, {
      ...this.getDefaultParams(),
      people_token: token,
    });
  };

  requestUser = () => {
    return JSON.parse(localStorage.getItem(USER_KEY));
  };

  sanitizeUserData = ({
    name,
    email,
    password,
    newsletter,
    temperature,
    metric,
    language,
    timezone,
    webPreferences,
  }) => ({
    name,
    email,
    password,
    newsletter,
    locale_temperature: temperature,
    locale_metric: metric,
    locale_language: language,
    locale_timezone: timezone,
    web_preferences: webPreferences,
  });

  submitUser = (data) => {
    return this.postPublicMethod(
      API_METHOD.submitUser,
      this.sanitizeUserData(data)
    );
  };

  updatePassword = ({ password, newPassword, confirmPassword }) => {
    return this.postPrivateMethod(
      API_METHOD.updatePassword,
      {
        password: password,
        new_password: newPassword,
        confirm_password: confirmPassword,
      },
      UPDATE_PASSWORD_URL
    );
  };

  updateUser = (data) => {
    return this.postPrivateMethod(API_METHOD.updateUser, {
      ...this.sanitizeUserData(data),
      user_token: data.userToken,
    });
  };

  // Rules
  sanitizeRulesData = ({ name, email, rules }) => {
    return {
      ...this.getDefaultParams(),
      name: name.trim(),
      email: email.trim(),
      access_devices: rules.devices,
      access_locations: rules.locations,
      access_informative: rules.informative,
      access_connectivity: rules.connectivity,
      access_administrative: rules.administrative,
      access_camera: rules.camera,
      access_advertising: rules.advertising,
      access_api: rules.api,
      access_support: rules.support,
    };
  };

  submitRule = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitRule,
      this.sanitizeRulesData(data)
    );
  };

  submitRuleForNewAffiliateContract = (data) => {
    return this.postPublicMethod(API_METHOD.submitRule, data);
  };

  requestRules = () => {
    return this.postPrivateMethod(
      API_METHOD.requestRules,
      this.getDefaultParams()
    );
  };

  updateRule = (data) => {
    return this.postPrivateMethod(API_METHOD.updateRule, {
      ...this.sanitizeRulesData(data),
      rules_token: data.token,
    });
  };

  deleteRule = ({ token }) => {
    return this.postPrivateMethod(API_METHOD.deleteRule, {
      ...this.getDefaultParams(),
      rules_token: token,
    });
  };

  // Devices
  submitTerminal = (data) => {
    return this.postPrivateMethod(API_METHOD.submitTerminal, data);
  };

  requestTerminals = ({
    page,
    limit,
    contractHash,
    connection,
    status,
    search,
    alerts,
    orderBy,
  }: IRequestTerminalsParams) => {
    return this.postPrivateMethod(API_METHOD.requestTerminals, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      contract_hash: contractHash,
      page: page || 1,
      limit: limit || 20,
      connection_status:
        connection !== ETerminalConnectionStatus.all ? connection : undefined,
      status:
        this.authStore.currentUserCanSupport && status !== ETerminalStatus.all
          ? status
          : undefined,
      search_string: search || undefined,
      order_by: orderBy,
      alerts,
    });
  };

  requestListOfTerminals = ({
    tokensList,
    metadata,
  }: {
    tokensList: string[];
    metadata: ERequestTerminalMetadata | ERequestTerminalMetadata[];
  }) => {
    return this.postPrivateMethod(API_METHOD.requestListOfTerminals, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      tokens_list: tokensList,
      metadata,
    });
  };
  requestTerminalsNotConnectedToGroup = () => {
    return this.postPrivateMethod(
      API_METHOD.requestTerminalsNotConnectedToGroup,
      this.getDefaultParams()
    );
  };

  requestTerminal = ({
    token,
    metadata,
  }: {
    token: string;
    metadata: ERequestTerminalMetadata | ERequestTerminalMetadata[];
  }) => {
    return this.postPrivateMethod(API_METHOD.requestTerminal, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      terminal_token: token,
      metadata,
    });
  };

  updateTerminal = ({
    token,
    name,
    comments,
    disabledList,
    networkPreferences,
    mediaPreferences,
    timezone,
    language,
    isLending,
    locationToken,
    monitorWifiIsActive,
  }) => {
    return this.postPrivateMethod(API_METHOD.updateTerminal, {
      ...this.getDefaultParams(),
      terminal_token: token,
      name,
      comments,
      disabled: disabledList,
      network_preferences: networkPreferences,
      media_preferences: mediaPreferences,
      locale_timezone: timezone,
      locale_language: language,
      is_lending: isLending,
      group_token: locationToken,
      monitor_is_active: monitorWifiIsActive,
    });
  };

  updateTerminalScreenRotate = ({ token, screenRotate }) => {
    return this.postPrivateMethod(API_METHOD.updateTerminal, {
      ...this.getDefaultParams(),
      terminal_token: token,
      screen_rotate: screenRotate,
    });
  };

  sendCommandToTerminal = ({
    token,
    command,
    contractHash,
  }: {
    token: string;
    command: string;
    contractHash: string;
  }) => {
    return this.postPrivateMethod(API_METHOD.sendCommand, {
      ...this.getDefaultParams(contractHash),
      token,
      command,
    });
  };
  sendCommandToListOfTerminals = (tokensList: string[], command: string) => {
    return this.postPrivateMethod(API_METHOD.sendCommandToListOfTerminals, {
      ...this.getDefaultParams(),
      tokens_list: tokensList,
      command,
    });
  };

  // Groups | Locations
  requestGroups = () => {
    return this.postPrivateMethod(API_METHOD.requestGroups, {
      ...this.getDefaultParams(),
      include_disabled_terminals: this.authStore.currentUserCanSupport,
    });
  };
  requestGroup = (token: string) => {
    return this.postPrivateMethod(API_METHOD.requestGroups, {
      ...this.getDefaultParams(),
      group_token: token,
    });
  };

  sanitizeGroupData = ({
    terminalsList,
    name,
    latitude,
    longitude,
    macAddressListToIgnoreOnMonitor,
  }) => {
    return {
      ...this.getDefaultParams(),
      terminals:
        terminalsList &&
        terminalsList.map(($0: any) =>
          typeof $0 === "string" ? $0 : $0.token
        ),
      name,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      monitor_macaddress: macAddressListToIgnoreOnMonitor,
    };
  };

  submitGroup = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitGroup,
      this.sanitizeGroupData(data)
    );
  };

  updateGroup = (data) => {
    return this.postPrivateMethod(API_METHOD.updateGroup, {
      ...this.sanitizeGroupData(data),
      group_token: data.token,
    });
  };

  deleteGroup = ({ token }) => {
    return this.postPrivateMethod(API_METHOD.deleteGroup, {
      ...this.getDefaultParams(),
      group_token: token,
    });
  };

  // Mod TV
  requestChannels = () => {
    return this.postPrivateMethod(
      API_METHOD.requestChannels,
      this.getDefaultParams()
    );
  };

  requestFile = (token: string) => {
    return this.postPrivateMethod(API_METHOD.requestFile, {
      ...this.getDefaultParams(),
      token,
    });
  };
  requestFiles = () => {
    return this.postPrivateMethod(
      API_METHOD.requestFiles,
      this.getDefaultParams()
    );
  };

  submitFiles = ({ files }) => {
    return this.postPrivateMethod(API_METHOD.submitFiles, {
      ...this.getDefaultParams(),
      files,
    });
  };

  requestStreamings = () => {
    return this.postPrivateMethod(
      API_METHOD.requestStreamings,
      this.getDefaultParams()
    );
  };

  submitStreamings = ({ url, name, description, isLive, isOffline }) => {
    return this.postPrivateMethod(API_METHOD.submitStreamings, {
      ...this.getDefaultParams(),
      url,
      name,
      description,
      live: isLive,
      offline: isOffline,
    });
  };

  // Contents
  requestContents = () => {
    return this.postPrivateMethod(
      API_METHOD.requestContents,
      this.getDefaultParams()
    );
  };

  sanitizeContentData = ({ name, description, url, updateTime, duration }) => {
    return {
      ...this.getDefaultParams(),
      name,
      description,
      url,
      update_time: updateTime,
      duration,
    };
  };

  submitContent = (data) => {
    return this.postPrivateMethod(API_METHOD.submitContent, {
      ...this.sanitizeContentData(data),
      content_type: data.contentType,
    });
  };

  updateContent = (data) => {
    return this.postPrivateMethod(API_METHOD.updateContent, {
      ...this.sanitizeContentData(data),
      token: data.token,
    });
  };

  // Media
  updateMedia = ({ mediaList }) => {
    return this.postPrivateMethod(API_METHOD.updateMedia, {
      ...this.getDefaultParams(),
      media_list: mediaList,
    });
  };

  deleteMedia = ({ token, mediaType }) => {
    return this.postPrivateMethod(API_METHOD.deleteMedia, {
      ...this.getDefaultParams(),
      media_type: mediaType,
      token,
    });
  };

  // Playlists
  // Doc: https://gist.github.com/mmprestes/e339a0cc7de14e6032fc4d0d454f5a08
  requestPlaylists = () => {
    return this.postPrivateMethod(
      API_METHOD.requestPlaylists,
      this.getDefaultParams()
    );
  };

  requestPlaylist = (token: string) => {
    if (!token) {
      return Promise.resolve(null);
    }
    return this.postPrivateMethod(API_METHOD.requestPlaylists, {
      ...this.getDefaultParams(),
      playlist_token: token,
    });
  };

  sanitizePlaylistData = (
    {
      label,
      isRandom,
      isMuted,
      isVertical,
      ledArea,
      videoWallScreensCount,
      durationToLiveContent,
      screenBrightness,
      files,
    },
    customContractHash
  ) => {
    return {
      ...this.getDefaultParams(customContractHash),
      label: label.trim(),
      random: isRandom,
      looping: true,
      mute: isMuted,
      led_area: ledArea || "",
      orientation: isVertical ? "90" : "0",
      videowall: videoWallScreensCount,
      duration_live: `${durationToLiveContent}`,
      alpha: screenBrightness,
      files,
    };
  };

  submitPlaylist = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitPlaylist,
      this.sanitizePlaylistData(data)
    );
  };

  clonePlaylist = (data, customContractHash) => {
    return this.postPrivateMethod(
      API_METHOD.clonePlaylist,
      this.sanitizePlaylistData(data, customContractHash)
    );
  };

  updatePlaylist = (data) => {
    return this.postPrivateMethod(API_METHOD.updatePlaylist, {
      ...this.sanitizePlaylistData(data),
      playlist_token: data.token,
    });
  };

  deletePlaylist = ({ token }) => {
    return this.postPrivateMethod(API_METHOD.deletePlaylist, {
      ...this.getDefaultParams(),
      playlist_token: token,
    });
  };

  // Clocks
  // Doc: https://github.com/simplyas/API/blob/master/Clocks
  requestClocks = () => {
    return this.postPrivateMethod(
      API_METHOD.requestClocks,
      this.getDefaultParams()
    );
  };

  sanitizeClockData = ({
    label,
    backgroundColor,
    fontColor,
    isTransparent,
    isVertical,
    ledArea,
  }) => {
    return {
      ...this.getDefaultParams(),
      label: label.trim(),
      background_color: this.sanitizeColor(backgroundColor),
      font_color: this.sanitizeColor(fontColor),
      transparent: isTransparent,
      orientation: isVertical ? "90" : "0",
      led_area: ledArea,
    };
  };

  submitClock = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitClock,
      this.sanitizeClockData(data)
    );
  };

  updateClock = (data) => {
    return this.postPrivateMethod(API_METHOD.updateClock, {
      ...this.sanitizeClockData(data),
      clock_token: data.clockToken,
    });
  };

  deleteClock = ({ clockToken }) => {
    return this.postPrivateMethod(API_METHOD.deleteClock, {
      ...this.getDefaultParams(),
      clock_token: clockToken,
    });
  };

  // Messages
  // Doc:https://github.com/simplyas/API/blob/master/Messages
  requestMessages = () => {
    return this.postPrivateMethod(
      API_METHOD.requestMessages,
      this.getDefaultParams()
    );
  };

  sanitizeMessageData = ({
    label,
    backgroundColor,
    fontColor,
    isTransparent,
    isVertical,
    isRandom,
    isExtended,
    informationsList,
    ledArea,
  }) => {
    return {
      ...this.getDefaultParams(),
      label: label.trim(),
      background_color: this.sanitizeColor(backgroundColor),
      font_color: this.sanitizeColor(fontColor),
      transparent: isTransparent,
      orientation: isVertical ? "90" : "0",
      random: isRandom,
      extended: isExtended,
      informations: informationsList,
      led_area: ledArea,
    };
  };

  submitMessage = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitMessage,
      this.sanitizeMessageData(data)
    );
  };

  updateMessage = (data) => {
    return this.postPrivateMethod(API_METHOD.updateMessage, {
      ...this.sanitizeMessageData(data),
      message_token: data.messageToken,
    });
  };

  deleteMessage = ({ messageToken }) => {
    return this.postPrivateMethod(API_METHOD.deleteMessage, {
      ...this.getDefaultParams(),
      message_token: messageToken,
    });
  };

  // Informative Logotype
  // Doc:https://github.com/simplyas/API/blob/master/Logotypes
  requestLogotypes = () => {
    return this.postPrivateMethod(
      API_METHOD.requestLogos,
      this.getDefaultParams()
    );
  };

  sanitizeLogotypeData = ({
    label,
    backgroundColor,
    isTransparent,
    isVertical,
    imageHash,
    ledArea,
  }) => {
    return {
      ...this.getDefaultParams(),
      label: label.trim(),
      background_color: this.sanitizeColor(backgroundColor),
      transparent: isTransparent,
      orientation: isVertical ? "90" : "0",
      image: imageHash,
      led_area: ledArea,
    };
  };

  submitLogotype = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitLogo,
      this.sanitizeLogotypeData(data)
    );
  };

  updateLogotype = (data) => {
    return this.postPrivateMethod(API_METHOD.updateLogo, {
      ...this.sanitizeLogotypeData(data),
      logo_token: data.logotypeToken,
    });
  };

  deleteLogotype = ({ logotypeToken }) => {
    return this.postPrivateMethod(API_METHOD.deleteLogo, {
      ...this.getDefaultParams(),
      logo_token: logotypeToken,
    });
  };

  // Mod Wifi
  // Doc: https://gist.github.com/mmprestes/1ed6ac7767022c99f47a37fca34e4966
  requestNetworks = () => {
    return this.postPrivateMethod(
      API_METHOD.requestNetworks,
      this.getDefaultParams()
    );
  };

  sanitizeNetworkData = ({
    label,
    wifiName,
    wifiPassword,
    smartAuth,
    maxTime,
    authRedirect,
    bandwidth,
    proxyFilter,
    denyReauth,
    wifiBanners,
    smsCustomMessage,
  }) => {
    return {
      ...this.getDefaultParams(),
      label: label.trim(),
      name: wifiName.trim(),
      password: wifiPassword.trim(),
      auth: smartAuth,
      max_time: maxTime,
      redirect: authRedirect,
      bandwidth,
      filter: proxyFilter,
      deny_reauth: denyReauth,
      wifi_banners: wifiBanners,
      sms_custom: smsCustomMessage,
    };
  };

  submitNetwork = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitNetwork,
      this.sanitizeNetworkData(data)
    );
  };

  updateNetwork = (data) => {
    return this.postPrivateMethod(API_METHOD.updateNetwork, {
      ...this.sanitizeNetworkData(data),
      network_token: data.networkToken,
    });
  };

  sanitizeNetworkEnterpriseData = ({
    label,
    authUrl,
    maxTime,
    authRedirect,
    bandwidth,
    proxyFilter,
    denyNetworks,
    denyReauth,
    denyCaptive,
    wifiBanners,
    smsCustomMessage,
    onlyAllowed,
  }) => {
    return {
      ...this.getDefaultParams(),
      label: label.trim(),
      auth_url: authUrl,
      max_time: maxTime,
      redirect: authRedirect,
      bandwidth,
      filter: proxyFilter,
      deny_networks: denyNetworks,
      deny_reauth: denyReauth,
      deny_captive: denyCaptive,
      wifi_banners: wifiBanners,
      sms_custom: smsCustomMessage,
      only_guest: onlyAllowed,
    };
  };

  submitNetworkEnterprise = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitNetworkEnterprise,
      this.sanitizeNetworkEnterpriseData(data)
    );
  };

  updateNetworkEnterprise = (data) => {
    return this.postPrivateMethod(API_METHOD.updateNetworkEnterprise, {
      ...this.sanitizeNetworkEnterpriseData(data),
      network_token: data.networkToken,
    });
  };

  deleteNetwork = ({ networkToken }) => {
    return this.postPrivateMethod(API_METHOD.deleteNetwork, {
      ...this.getDefaultParams(),
      network_token: networkToken,
    });
  };

  // ConnectivityDevice
  requestDevicesNetworks = () => {
    return this.postPrivateMethod(
      API_METHOD.requestDevicesNetworks,
      this.getDefaultParams()
    );
  };

  sanitizeConnectivityDeviceData = ({
    label,
    locations,
    expires,
    validityAfterActivation,
  }) => {
    return {
      ...this.getDefaultParams(),
      label,
      locations,
      expires: validityAfterActivation ? 0 : expires,
      validity_after_activation: validityAfterActivation,
    };
  };

  submitDevicesNetwork = (data) => {
    return this.postPrivateMethod(API_METHOD.submitDevicesNetwork, {
      ...this.sanitizeConnectivityDeviceData(data),
      phone_number: data.phoneNumber,
      mac_address: data.macAddress,
      is_banned: data.isBanned,
    });
  };

  updateDevicesNetwork = (data) => {
    return this.postPrivateMethod(API_METHOD.updateDevicesNetwork, {
      ...this.sanitizeConnectivityDeviceData(data),
      devices_network_token: data.token,
    });
  };
  deleteDevicesNetwork = (token) => {
    return this.postPrivateMethod(API_METHOD.deleteDevicesNetwork, {
      ...this.getDefaultParams(),
      devices_network_token: token,
    });
  };

  // Resources
  // Doc: https://gist.github.com/mmprestes/0fdef5542d39bfab9568a524b5e1c961
  requestResources = ({ resourceType }) => {
    return this.postPrivateMethod(API_METHOD.requestResources, {
      ...this.getDefaultParams(),
      resource: resourceType,
    });
  };

  sanitizeResourceData = ({
    resourceType,
    groupToken,
    resourceToken,
    secondResourceToken,
    schedule,
    isActive,
    order,
    terminalsList,
  }) => {
    return {
      ...this.getDefaultParams(),
      resource: resourceType,
      group_token: groupToken,
      resource_token: resourceToken,
      second_resource_token: secondResourceToken,
      schedule,
      is_active: isActive,
      order,
      terminals: terminalsList,
    };
  };

  submitResource = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitResource,
      this.sanitizeResourceData(data)
    );
  };

  updateResource = (data) => {
    return this.postPrivateMethod(API_METHOD.updateResource, {
      ...this.sanitizeResourceData(data),
      token: data.token,
    });
  };

  deleteResource = ({ token, resourceType }) => {
    return this.postPrivateMethod(API_METHOD.deleteResource, {
      ...this.getDefaultParams(),
      resource: resourceType,
      token,
    });
  };

  reorderResource = ({ resourceType, resourcesList }) => {
    return this.postPrivateMethod(API_METHOD.reorderResource, {
      ...this.getDefaultParams(),
      resource: resourceType,
      ordered_list: resourcesList,
    });
  };

  // ADVERTISING
  sanitizeAdvertisingData = ({
    fromDate,
    toDate,
    occurrences,
    occurrencesUntil,
    phoneNumber,
    connectedInTheLastValue,
    notConnectedInTheLastValue,
    isOnlineNow,
    gender,
    ageGroup,
    terminalToken,
    groupToken,
  }) => ({
    ...this.getDefaultParams(),
    from_date: fromDate,
    to_date: toDate,
    occurrences,
    until: occurrencesUntil,
    phone_number: phoneNumber,
    online: connectedInTheLastValue,
    not_online: notConnectedInTheLastValue,
    only_online: isOnlineNow,
    gender,
    age: ageGroup,
    terminal_token: terminalToken,
    group_token: groupToken,
  });

  requestAdvertisingMessages = (data) => {
    return this.postPrivateMethod(
      API_METHOD.requestAdvertisingMessages,
      this.sanitizeAdvertisingData(data)
    );
  };

  submitAdvertisingMessages = (data) => {
    return this.postPrivateMethod(API_METHOD.submitAdvertisingMessages, {
      ...this.sanitizeAdvertisingData(data),
      message: data.message,
      limit_people: data.limitPeople,
    });
  };

  submitSMS = ({ phonesList, message }) => {
    return this.postPrivateMethod(API_METHOD.submitSMS, {
      ...this.getDefaultParams(),
      phones: phonesList,
      message,
    });
  };

  // Payment / Store
  submitPayment = ({ gateway, parameters }) => {
    return this.postPrivateMethod(API_METHOD.submitPayment, {
      ...this.getDefaultParams(),
      sandbox: isProductionServer ? undefined : true,
      gateway,
      parameters,
      account: "modbox",
    });
  };

  // Reports | Metrics
  requestDataWifi = ({ fromDate, toDate }) => {
    return this.postPrivateMethod(API_METHOD.requestDataWifi, {
      ...this.getDefaultParams(),
      date_ini: fromDate,
      date_end: toDate,
    });
  };

  requestDashboard = () => {
    return this.postPrivateMethod(API_METHOD.requestDashboard, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
    });
  };

  requestMetricsWifi = ({
    groupsList,
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    accessByLocation,
    connectionsPerType,
    hourlyTraffic,
    peopleStatistics,
    accessByDeviceVendor,
    peopleByLocation,
    targetAudience,
    macaddressRule,
    connectionTimeLimit,
    connectionType,
  }) => {
    let dataTypeList = [];
    if (accessByLocation) dataTypeList.push("access_by_location");
    if (connectionsPerType) dataTypeList.push("connections_by_type");
    if (hourlyTraffic) dataTypeList.push("hourly_traffic");
    if (peopleStatistics) dataTypeList.push("users_statistics");
    if (accessByDeviceVendor) dataTypeList.push("access_by_device_vendor");
    if (peopleByLocation) dataTypeList.push("people_by_location");
    return this.postPrivateMethod(API_METHOD.requestMetricsWifi, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      groups: groupsList,
      date_ini: fromDate,
      date_end: toDate,
      hour_ini: fromHour,
      hour_end: toHour,
      week_days: weekDays,
      list_data_type: dataTypeList,
      target_audience: targetAudience === "all" ? null : targetAudience,
      macaddress_rule: macaddressRule === "all" ? null : macaddressRule,
      connection_time_limit: connectionTimeLimit,
      connection_type: connectionType,
    });
  };

  requestMetricsCamera = ({
    groupsList,
    fromDate,
    toDate,
    fromHour,
    toHour,
    weekDays,
    accessByLocation,
    hourlyTraffic,
    facesByLocation,
  }) => {
    let dataTypeList = [];
    if (accessByLocation) dataTypeList.push("access_by_location");
    if (hourlyTraffic) dataTypeList.push("hourly_traffic");
    if (facesByLocation) dataTypeList.push("faces_by_location");
    return this.postPrivateMethod(API_METHOD.requestMetricsCamera, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      groups: groupsList,
      date_ini: fromDate,
      date_end: toDate,
      hour_ini: fromHour,
      hour_end: toHour,
      week_days: weekDays,
      list_data_type: dataTypeList,
    });
  };

  requestMetricsTv = ({
    mediaMd5,
    fromDate,
    toDate,
    fromHour,
    toHour,
    location,
    terminal,
    page,
    limit,
    mediaInsertion,
    mediaInsertionSyntheticHourly,
    mediaInsertionSyntheticDaily,
    mediaInsertionSyntheticPeriods,
  }) => {
    let dataTypeList = [];
    if (mediaInsertion) dataTypeList.push("media_insertion");
    if (mediaInsertionSyntheticHourly)
      dataTypeList.push("media_insertion_synthetic_hourly");
    if (mediaInsertionSyntheticDaily)
      dataTypeList.push("media_insertion_synthetic_daily");
    if (mediaInsertionSyntheticPeriods)
      dataTypeList.push("media_insertion_synthetic_periods");
    return this.postPrivateMethod(API_METHOD.requestMetricsTv, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      file_md5: mediaMd5,
      date_ini: fromDate,
      date_end: toDate,
      hour_ini: fromHour,
      hour_end: toHour,
      groups: location ? [location] : undefined,
      terminals: terminal ? [terminal] : undefined,
      page,
      limit,
      list_data_type: dataTypeList,
    });
  };

  requestMediasFromLog = () => {
    return this.postPrivateMethod(
      API_METHOD.requestMediasFromLog,
      this.getDefaultParams()
    );
  };

  requestMetricsTerminal = (token) => {
    return this.postPrivateMethod(API_METHOD.requestMetricsTerminal, {
      ...this.getDefaultParams(),
      ...this.getExtraParams(),
      terminal_token: token,
    });
  };

  verifyPin = ({ email, pin, isRecovery }) => {
    return this.postPublicMethod(API_METHOD.verifyPin, {
      email,
      pin: isRecovery ? undefined : pin,
      recovery: isRecovery ? true : undefined,
    });
  };

  // Queue Management
  sanitizeQueueManagementData = ({
    name,
    value,
    terminalToken,
    layout,
    useVoice,
    alpha,
    displayTime,
    backgroundColor,
    fontColor,
    isVertical,
    useInputStream,
    inputStreamPosition,
    type,
  }) => ({
    ...this.getDefaultParams(),
    name,
    value,
    layout,
    use_voice: useVoice,
    alpha,
    display_time: displayTime,
    background_color: this.sanitizeColor(backgroundColor),
    font_color: this.sanitizeColor(fontColor),
    orientation: isVertical ? "90" : "0",
    terminal_token: terminalToken,
    use_input_stream: useInputStream,
    use_input_stream_position:
      useInputStream && type === QueueManagementControlType.constant
        ? inputStreamPosition
        : undefined,
  });
  submitQueueManagementControl = (control) => {
    return this.postPrivateMethod(API_METHOD.submitQueueManagementControl, {
      ...this.sanitizeQueueManagementData(control),
      type: control.type,
    });
  };
  updateQueueManagementControl = ({ token, ...rest }) => {
    return this.postPrivateMethod(API_METHOD.updateQueueManagementControl, {
      ...this.sanitizeQueueManagementData(rest),
      token,
    });
  };
  deleteQueueManagementControl = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deleteQueueManagementControl, {
      ...this.getDefaultParams(),
      token,
    });
  };
  requestQueueManagementControls = () => {
    return this.postPrivateMethod(
      API_METHOD.requestQueueManagementControls,
      this.getDefaultParams()
    );
  };
  setQueueManagementControlOpenToConnect = (token: string) => {
    return this.postPrivateMethod(
      API_METHOD.setQueueManagementControlOpenToConnect,
      {
        ...this.getDefaultParams(),
        token,
      }
    );
  };

  requestNotifications = () => {
    return this.postPrivateMethod(
      API_METHOD.requestNotifications,
      this.getDefaultParams()
    );
  };

  didReadNotification = (token: string) => {
    return this.postPrivateMethod(API_METHOD.updateNotification, {
      ...this.getDefaultParams(),
      notification_token: token,
      is_read: true,
    });
  };
  didShelveNotification = (token: string) => {
    return this.postPrivateMethod(API_METHOD.updateNotification, {
      ...this.getDefaultParams(),
      notification_token: token,
      is_shelved: true,
    });
  };

  grantTemporaryAccessToContract = (supportOperatorToken: string) => {
    return this.postPrivateMethod(API_METHOD.grantTemporaryAccessToContract, {
      ...this.getDefaultParams(),
      support_operator_user_token: supportOperatorToken,
    });
  };
}

export default ModboxAPIService;
