// @ts-nocheck
import { Component } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { inject, observer } from "mobx-react";

import { InputText } from "../../components/Form";
import InputEmail from "./InputEmail";
import { GridContainer, GridItem } from "../../components/Grid";
import { Button } from "../../components/Button";

// Translate
import Translate from "../../components/I18n/Translate";

// Utils Stores
import { TUTORIALS_KEYS } from "../../store/TutorialsStore/TutorialsStore";

const { REACT_APP_PASSWORD_RECOVERY_URL } = process.env

@inject("authStore", "tutorialsStore")
@observer
class LoginForm extends Component {
  onChangeEmail = ({ target }) => this.props.authStore.setEmail(target.value);

  onChangePassword = ({ target }) =>
    this.props.authStore.setPassword(target.value);

  openTutorial = () => {
    this.props.tutorialsStore.didSelectTutorialKey(TUTORIALS_KEYS.LOGIN_PAGE);
  };

  didSubmitForm = (event) => {
    if (event) event.preventDefault();
    this.props.authStore.didSubmitForm(this.state);
  };

  render() {
    const {
      email,
      password,
      isValid,
      formWasSubmit,
      isWaitingForm,
    } = this.props.authStore;

    return (
      <form onSubmit={this.didSubmitForm}>
        {this.props.body(
          <GridContainer>
            <GridItem xs={12}>
              <InputEmail
                value={email}
                formWasSubmit={formWasSubmit}
                isValid={isValid.email}
                onChange={this.onChangeEmail}
              />
            </GridItem>
            <GridItem xs={12}>
              <InputText
                label={<Translate id="Login@password">Senha</Translate>}
                name="password"
                value={password}
                inputProps={{
                  type: "password",
                  autoComplete: "off",
                }}
                rightIcon={
                  <InputAdornment position="end">
                    <i className="fas fa-key" style={{ color: "#555" }} />
                  </InputAdornment>
                }
                hasError={formWasSubmit && !isValid.password}
                hasSuccess={formWasSubmit && isValid.password}
                onChange={this.onChangePassword}
              />
            </GridItem>
          </GridContainer>
        )}
        {this.props.actions(
          <GridContainer>
            <GridItem xs={12}>
              <Button
                type="submit"
                color="primary"
                block
                disabled={!isValid.email || !isValid.password}
                isLoading={isWaitingForm}
              >
                <Translate id="Login@signInButton">Entrar</Translate>{" "}
                <i className="fas fa-sign-in-alt" />
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Button
                component="a"
                color="primary"
                outline
                block
                disabled={isWaitingForm}
                href={REACT_APP_PASSWORD_RECOVERY_URL}
              >
                <Translate id="Login@forgotPassword">
                  Esqueceu sua Senha?
                </Translate>
              </Button>
            </GridItem>
            {this.props.tutorialsStore.isAvailable && (
              <GridItem xs={12}>
                <Button
                  color="warning"
                  outline
                  block
                  disabled={isWaitingForm}
                  onClick={this.openTutorial}
                >
                  <Translate id="Login@openLoginTutorialButtonLabel">
                    Como acessar a plataforma
                  </Translate>
                </Button>
              </GridItem>
            )}
          </GridContainer>
        )}
      </form>
    );
  }
}

export default LoginForm;
