// @ts-nocheck
import * as Sentry from "@sentry/browser";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import ReactGA from "react-ga";
import { Router, Switch } from "react-router-dom";
import packageJson from "../../package.json";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import RandomAnimation from "../components/Animations/RandomAnimation";
import { Button } from "../components/Button";
import ChatDrawer from "../components/Chat/ChatDrawer";
import TutorialModal from "../components/Modal/TutorialModal";
import AffiliateLayout from "../layouts/AffiliateLayout";
import BaseLayout from "../layouts/BaseLayout";
import LoginLayout from "../layouts/LoginLayout";
import SelectContractLayout from "../layouts/SelectContractLayout";

import { GridFullScreen } from "../components/Grid";
import LoadingView from "../components/LoadingView/LoadingView";

// Translate
import Translate from "../components/I18n/Translate";

// Utils Store
import {
  settingsRef,
  versionControlRef,
} from "../store/FirebaseStore/FirebaseStore";

// Service
import SentryIOService from "../services/SentryIOService";

// CONSTANTS
const APP_VERSION = packageJson.version;

// Initialize Google Analytics
ReactGA.initialize("UA-32633733-2");

// Initialize Sentry IO
Sentry.init({
  dsn: SentryIOService.dsn,
  release: `${SentryIOService.projectName}@${APP_VERSION}`,
});

const extractVersion = (versionString) => {
  const splitList = versionString.split(".");
  return {
    major: parseInt(splitList[0], 10),
    minor: parseInt(splitList[1], 10),
    build: parseInt(splitList[2], 10) || 0,
  };
};

const checkIfVersionIsUpToDate = (left, right) => {
  if (left.major > right.major) {
    return true;
  } else if (left.major === right.major) {
    if (left.minor > right.minor) {
      return true;
    } else if (left.minor === right.minor) {
      return left.build >= right.build;
    }
    return false;
  }
  return false;
};

@inject("authStore")
class Logout extends Component {
  componentDidMount() {
    const { force = false } = this.props;
    this.props.authStore.logout(force);
  }
  render() {
    return <LoadingView fullPageCenter />;
  }
}

@inject("authStore", "chatStore", "contractStore", "i18nStore", "history")
@observer
class AppRouter extends Component {
  state = {
    isMaintenanceMode: false,
    versionIsUpToDate: true,
    isUpdatingVersion: false,
  };

  componentDidMount() {
    this.connectToFirebase();
  }

  connectToFirebase = () => {
    // MaintenanceMode
    settingsRef.child("isMaintenanceMode").on("value", (snapshot) => {
      const isMaintenanceMode = snapshot.exists() ? snapshot.val() : false;
      this.setState((prevState) => {
        if (isMaintenanceMode !== prevState.isMaintenanceMode) {
          return {
            isMaintenanceMode,
          };
        }
        return null;
      });
    });

    // Version Control
    versionControlRef.child("webApp/current").on("value", (snapshot) => {
      const allowedVersion = extractVersion(snapshot.val());
      const webAppVersion = extractVersion(APP_VERSION);
      const versionIsUpToDate = checkIfVersionIsUpToDate(
        webAppVersion,
        allowedVersion
      );

      this.setState((prevState) => {
        if (versionIsUpToDate !== prevState.versionIsUpToDate) {
          return {
            versionIsUpToDate,
          };
        }
        return null;
      });
    });
  };

  didWantToUpdateReactVersion = () => {
    this.setState(
      {
        isUpdatingVersion: true,
      },
      () => {
        setTimeout(() => {
          window.location.reload(true);
        }, 200);
      }
    );
  };

  render() {
    // Logout route
    if (window.location.href.includes("/logout-force")) {
      return <Logout force />;
    }

    const { authStore, chatStore, contractStore, i18nStore } = this.props;

    if (this.state.isMaintenanceMode) {
      return (
        <GridFullScreen>
          <p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
            <i className="fas fa-exclamation-triangle" />{" "}
            <Translate id="System@isMaintenanceMode">
              Sistema em manutenção no momento!
            </Translate>
          </p>
          <RandomAnimation height={140} />
        </GridFullScreen>
      );
    } else if (!this.state.versionIsUpToDate) {
      return (
        <GridFullScreen>
          <p style={{ fontWeight: "bold" }}>
            <Translate id="VersionControl@updateTitle">
              Existe uma atualização disponível!
            </Translate>
          </p>
          <Button
            color="primary"
            isLoading={this.state.isUpdatingVersion}
            onClick={this.didWantToUpdateReactVersion}
          >
            <Translate id="VersionControl@updateButtonLabel">
              Clique para atualizar
            </Translate>{" "}
            <i className="fas fa-sync-alt" />
          </Button>
        </GridFullScreen>
      );
    }
    if (
      i18nStore.isFetching ||
      authStore.isFetching ||
      contractStore.isFetching
    ) {
      return (
        <GridFullScreen>
          <LoadingView />
        </GridFullScreen>
      );
    }

    const { isAuthenticated } = authStore;
    const { selectedContract } = contractStore;

    return (
      <>
        <TutorialModal />
        <Router history={this.props.history}>
          <ChatDrawer isOpen={chatStore.shouldOpenChatDrawer} />
          <Switch>
            <PrivateRoute
              exact
              isAuthenticated={isAuthenticated}
              path="/logout"
              component={Logout}
            />
            <PublicRoute
              exact
              path="/facil"
              isAuthenticated={isAuthenticated}
              component={AffiliateLayout}
            />
            <PublicRoute
              exact
              path="/promo"
              isAuthenticated={isAuthenticated}
              component={AffiliateLayout}
            />
            <PublicRoute
              exact
              path="/afiliado"
              isAuthenticated={isAuthenticated}
              component={AffiliateLayout}
            />
            <PublicRoute
              exact
              path="/affiliate"
              isAuthenticated={isAuthenticated}
              component={AffiliateLayout}
            />
            <PublicRoute
              exact
              path="/recoverPassword"
              isAuthenticated={isAuthenticated}
              component={LoginLayout}
            />
            <PublicRoute
              exact
              path="/login"
              isAuthenticated={isAuthenticated}
              component={LoginLayout}
            />
            <PrivateRoute
              path="/"
              isAuthenticated={isAuthenticated}
              selectedContract={selectedContract}
              component={selectedContract ? BaseLayout : SelectContractLayout}
            />
          </Switch>
        </Router>
      </>
    );
  }
}

export default AppRouter;
