// @ts-nocheck

import { inject } from "mobx-react";

import { Card, CardBody, CardHeader, CardIcon } from "../../components/Card";
import { GridItem } from "../../components/Grid";
import IfNotFetching from "../../components/IfNotFetching/IfNotFetching";
import Table from "../../components/Table/Table";

import { formatPriceToText } from "../../components/Price/Price";

import { withLocalized } from "../../components/HOC";

// Translate
import { NothingFoundString } from "../../components/I18n/CommonStrings";
import Translate from "../../components/I18n/Translate";

// Utils
import { stringDateFromTimestamp } from "../../utils/DateUtils";

// style
import { successColor } from "../../assets/jss/app-js-styles";

const FinancialHistoryTable = inject("i18nStore")(
  ({
    classes,
    i18nStore,
    localizedString,
    isFetching,
    financialHistoryList,
  }) => {
    const getFinancialHistoryStatus = (financialHistory) => {
      const date = stringDateFromTimestamp(
        financialHistory.timestamp,
        i18nStore.dateFormatWithFourDigitsYear
      );
      if (financialHistory.status === "billingClosed") {
        return localizedString(
          {
            id: "Affiliate@financialHistoryBillingClosedStatus",
            defaultString: "Fechada no dia $date, aguardando pagamento.",
          },
          {
            date,
          }
        );
      } else if (financialHistory.status === "paid") {
        return localizedString(
          {
            id: "Affiliate@financialHistoryPaidStatus",
            defaultString: "Pago no dia $date",
          },
          {
            date,
          }
        );
      }

      return "-";
    };

    return (
      <GridItem xs={12} sm={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <i className={`fas fa-receipt ${classes.cardIcon}`} />
            </CardIcon>
            <h2 className={classes.cardIconTitle}>
              <Translate id="Affiliate@dashboardFinancialHistorySectionTitle">
                Histórico financeiro
              </Translate>
            </h2>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <IfNotFetching isFetching={isFetching} loadingHeight={50}>
              {financialHistoryList.length > 0 ? (
                <Table
                  data={financialHistoryList.map((item) => {
                    return {
                      date: stringDateFromTimestamp(
                        item.timestamp,
                        i18nStore.dateFormatWithFourDigitsYear
                      ),
                      commissionValue: (
                        <p
                          style={{
                            fontWeight: "bold",
                            color: successColor,
                            margin: 0,
                          }}
                        >
                          + {formatPriceToText(item.commissionValue, true)}
                        </p>
                      ),
                      status: getFinancialHistoryStatus(item),
                    };
                  })}
                  columns={[
                    {
                      Header: localizedString({
                        id: "Common@date",
                        defaultString: "Data",
                      }),
                      sortable: false,
                      accessor: "date",
                      width: 100,
                    },
                    {
                      Header: localizedString({
                        id: "Affiliate@dashboardFinancialPaidPricelabel",
                        defaultString: "Valor comissão",
                      }),
                      sortable: false,
                      accessor: "commissionValue",
                      width: 140,
                      getHeaderProps: () => ({
                        style: {
                          textAlign: "right",
                          marginRight: 16,
                        },
                      }),
                      getProps: () => ({
                        style: {
                          textAlign: "right",
                          marginRight: 16,
                        },
                      }),
                    },
                    {
                      Header: "Status",
                      sortable: false,
                      accessor: "status",
                      getHeaderProps: () => {
                        return {
                          style: {
                            textAlign: "left",
                          },
                        };
                      },
                    },
                  ]}
                />
              ) : (
                <p style={{ textAlign: "center" }}>
                  <NothingFoundString />
                </p>
              )}
            </IfNotFetching>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
);

export default withLocalized(FinancialHistoryTable);
