// @ts-nocheck

import { observer } from "mobx-react";
import BarChart from "../../components/Charts/BarChart";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";

import { GridContainer, GridItem } from "../../components/Grid";

// Translate
import Translate from "../../components/I18n/Translate";

// Style
import { primaryColor, grayColor } from "../../assets/jss/app-js-styles";
const styles = {
  statsTitle: {
    color: grayColor,
    textAlign: "center",
  },
  statsLabel: {
    color: primaryColor,
    textAlign: "center",
    fontSize: "1.4em",
    fontWeight: "bold",
  },
};

const CameraAccessByLocationTotalChartWidget = observer(
  ({ classes, chartData }) => {
    if (!chartData) return null;
    const accessByLocation = chartData.accessByLocation;
    const locationsCount = Object.keys(accessByLocation).length;
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <BarChart
              horizontal
              showDataLabels
              height={locationsCount * 46 + 60}
              xAxisCategories={Object.keys(accessByLocation)}
              customTooltip={{
                enabled: false,
              }}
              data={[
                {
                  data: Object.values(accessByLocation).map(
                    (data) => data.total
                  ),
                },
              ]}
            />{" "}
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: 30 }}>
          <GridItem xs={6} md={4}>
            <p className={classes.statsTitle}>
              <Translate id="Metrics@wifiBusiestLocationLabel">
                Mais acessos
              </Translate>
            </p>
            <p className={classes.statsLabel}>
              {chartData.wifiBusiestLocation}
            </p>
          </GridItem>
          <GridItem xs={6} md={4}>
            <p className={classes.statsTitle}>
              <Translate id="Metrics@wifiLessBusyLocationLabel">
                Menos acessos
              </Translate>
            </p>
            <p className={classes.statsLabel} style={{ color: grayColor }}>
              {chartData.wifiLessBusyLocation}
            </p>
          </GridItem>
        </GridContainer>
      </>
    );
  }
);

export default withLocalized(
  withStyles(styles)(CameraAccessByLocationTotalChartWidget)
);
