// @ts-nocheck

import Select from "react-select";

import { withLocalized } from "../HOC";

const SelectLocationFilter = ({
  localizedString,
  locationsList,
  isFetchingLocations,
  getSelectedLocations,
  onChange,
  allowMultipleLocations,
}) => (
  <Select
    value={getSelectedLocations}
    isLoading={isFetchingLocations}
    placeholder={localizedString({
      id: "Metrics@selectLocation",
      defaultString: "Selecione localidade(s)...",
    })}
    options={locationsList.map(($0) => ({
      label: $0.name,
      value: $0.token,
    }))}
    autoFocus={true}
    noOptionsMessage={() =>
      localizedString({
        id: "Metrics@noLocationFound",
        defaultString: "Nenhuma localidade encontrada",
      })
    }
    onChange={onChange}
    isMulti={allowMultipleLocations}
  />
);

export default withLocalized(SelectLocationFilter);
