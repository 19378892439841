// @ts-nocheck
import copy from "copy-to-clipboard";
import { action, computed, observable, reaction } from "mobx";

import AutonomediaService from "../../services/AutonomediaService";
import ModboxAPIService from "../../services/ModboxAPIService";
import TelegramAPIService from "../../services/TelegramAPIService";
import AlfredStore from "../AlfredStore/AlfredStore";
import { contractsRef } from "../FirebaseStore/FirebaseStore";
import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";

// Model
import ContractBusinessModel from "../../Model/ContractBusinessModel";
import ContractModel from "../../Model/ContractModel";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

export const ContractAvailableResources = [
  "informative",
  "connectivity",
  "camera",
  "advertising",
  "camera",
];

class ContractStore {
  @observable contractsList = [];
  @observable isFetching = false;
  @observable selectedContract?: ContractModel = null;
  @observable usersForSelectedContract = [];
  @observable usersSearchString = "";

  @observable selectedContractBusinessData = null;
  @observable isFetchingContractBusinessData = false;

  @observable didFinishedWelcomeStep = null;
  @observable didFinishedInstallSystemStep = null;
  @observable didFinishedActivateDeviceStep = null;

  intervalToUpdateSelectedContract = null;

  selectedAffiliateContractRef = null;

  constructor(
    authStore: AuthStore,
    alfredStore: AlfredStore,
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    modboxAPIService: ModboxAPIService,
    autonomediaService: AutonomediaService,
    telegramAPIService: TelegramAPIService
  ) {
    this.authStore = authStore;
    this.internalEventsStore = internalEventsStore;
    this.notificationsStore = notificationsStore;
    this.modboxAPIService = modboxAPIService;
    this.autonomediaService = autonomediaService;
    this.telegramAPIService = telegramAPIService;
    this.checkAuthStoreIsReady();

    this.internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.triggerActionOnContract,
      observer: this,
      callback: ({ action, contractHash }) => {
        if (!this.selectedContract) {
          return;
        }
        const triggerAction = () =>
          this.internalEventsStore.notify(
            EInternalEvents.triggeredAction,
            action
          );
        if (this.selectedContract.hash !== contractHash) {
          this.setIsFetching(true);
          this.didSelectContractWithHash(contractHash);
          setTimeout(() => {
            this.setIsFetching(false);
            triggerAction();
          }, 1000);
        } else {
          triggerAction();
        }
      },
    });

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.triggeredAction,
      observer: this,
      callback: async (params: any): Promise<any> => {
        if (!params) return;

        if (params?.includes("api_")) {
          const apiAction = params.replace("api_", "");
          if (apiAction.includes("grantTemporaryAccessToContract_")) {
            const operatorSupportToken = apiAction.replace(
              "grantTemporaryAccessToContract_",
              ""
            );
            return this.grantTemporaryAccessToContract(operatorSupportToken);
          }
        }
      },
    });

    reaction(
      () => this.selectedContract,
      (selectedContract) => {
        if (selectedContract) {
          this.autonomediaService.setFileServer(selectedContract.fileServer);

          alfredStore.addActions({
            id: "contractActions",
            label: "Contrato",
            options: [
              {
                id: "changeSelectedContract",
                title: "Alterar contrato",
                subtitle: "Gerenciar outro contrato",
                icon: "exchange",
                callback: this.didWantToRemoveSelectedContract,
              },
              {
                id: "copyContractHash",
                title: `Hash: ${selectedContract.hash}`,
                subtitle: "Copiar o hash do contrato",
                icon: "copy",
                callback: () => copy(selectedContract.hash),
              },
              {
                id: "copylicenseNumber",
                title: `Licença: ${selectedContract.licenseNumber}`,
                subtitle: "Copiar a licença do contrato",
                icon: "key",
                callback: () => copy(selectedContract.licenseNumber),
              },
            ],
          });
        } else {
          alfredStore.removeActions("contractActions");

          this.clearBusinessData();
        }
      }
    );
  }

  checkAuthStoreIsReady = () => {
    reaction(
      () => this.authStore.isAuthenticated,
      (isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.getContractsList();
        } else {
          this.clearStore();
        }
      },
      { delay: 100, name: "checkAuthStoreIsReady" }
    );
  };

  @action
  setUsersSearchString = (searchString: string) => {
    this.usersSearchString = searchString;
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setIsFetchingContractBusinessData = (newValue) => {
    this.isFetchingContractBusinessData = newValue;
  };

  @action
  setContractsList = (newValue) => {
    this.contractsList = newValue;
  };
  @action
  setUsersForSelectedContract = (newValue) => {
    this.usersForSelectedContract = newValue;
  };

  @action
  setSelectedContractBusinessData = (newValue) => {
    this.selectedContractBusinessData = newValue;
  };

  @action
  setFirebaseListenerIsAffiliate = (data) => {
    this.didFinishedWelcomeStep = data.didFinishedWelcomeStep;
    this.didFinishedInstallSystemStep = data.didFinishedInstallSystemStep;
    this.didFinishedActivateDeviceStep = data.didFinishedActivateDeviceStep;
  };

  @action
  setFirebaseListenerNotAffiliate = () => {
    this.didFinishedWelcomeStep = false;
    this.didFinishedInstallSystemStep = true;
    this.didFinishedActivateDeviceStep = true;
  };

  getContractsList = () => {
    this.setIsFetching(true);

    const selectedContractHashFromSession = window.sessionStorage.getItem(
      "selectedContract"
    );

    this.modboxAPIService
      .requestContracts()
      .then((response) => {
        const { status, data } = response;
        if (status >= 200 && status <= 299) {
          const readableContracts = data.map(
            (contract) =>
              new ContractModel({
                isSimplyAsEmployee: this.authStore.currentUser
                  .isSimplyAsEmployee,
                ...contract,
              })
          );
          this.setContractsList(readableContracts);

          // Set selected if only one contract
          if (this.contractsList.length === 1) {
            this.didSelectContractWithHash(this.contractsList[0].hash);
          }
          // Try to select from sessionStorage
          else if (selectedContractHashFromSession) {
            this.didSelectContractWithHash(selectedContractHashFromSession);
          }

          this.setIsFetching(false);
        } else {
          this.authStore.logout();
        }
      })
      .catch((error) => {
        window.debugError("Error in getContractsList", error);
        this.authStore.logout();
      });
  };

  setStatusForDidFinishedWelcomeStep = (status) => {
    contractsRef
      .child(`${this.selectedContract.hash}/didFinishedWelcomeStep`)
      .set(status);
  };

  setStatusForDidFinishedInstallSystemStep = (status) => {
    contractsRef
      .child(`${this.selectedContract.hash}/didFinishedInstallSystemStep`)
      .set(status);
  };
  setStatusForDidFinishedActivateDeviceStep = (status) => {
    contractsRef
      .child(`${this.selectedContract.hash}/didFinishedActivateDeviceStep`)
      .set(status);
  };

  setFirebaseListenerIfAffiliate = () => {
    if (this.selectedContract.isAffiliateContract) {
      this.selectedAffiliateContractRef = contractsRef.child(
        this.selectedContract.hash
      );
      this.selectedAffiliateContractRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          this.setFirebaseListenerIsAffiliate(data);
        } else {
          this.setFirebaseListenerNotAffiliate();
        }
      });
    } else {
      this.setFirebaseListenerNotAffiliate();
    }
  };

  @computed
  get affiliateContractIsReady() {
    return (
      this.didFinishedWelcomeStep &&
      this.didFinishedInstallSystemStep &&
      this.didFinishedActivateDeviceStep
    );
  }

  updateSelectedContract = () => {
    this.modboxAPIService
      .requestContract()
      .then((response) => {
        if (response.status === 200 && this.selectedContract) {
          const contract = response.data;
          if (contract.status) {
            if (this.selectedContractBusinessData) {
              this.selectedContractBusinessData.setStatusWithIsChecked(
                isChecked
              );
            }

            this.selectedContract.setIsCheckedAndPlan({
              plan: contract.plan,
              isChecked: contract.is_checked,
            });
            this.selectedContract.updateUsageData(contract);
          } else {
            this.didWantToRemoveSelectedContract();
          }
        }
      })
      .catch((error) => {
        window.debugError("Error in updateSelectedContract", error);
      });
  };

  setSelectedContractUpdate = () => {
    this.updateSelectedContract();
    this.cancelSelectedContractUpdateIfRunning();
    this.intervalToUpdateSelectedContract = setInterval(
      this.updateSelectedContract,
      600000
    );
  };

  cancelSelectedContractUpdateIfRunning = () => {
    if (this.intervalToUpdateSelectedContract !== null) {
      clearInterval(this.intervalToUpdateSelectedContract);
      this.intervalToUpdateSelectedContract = null;
    }
  };

  @action("set selectedContract & notify InternalEvents")
  didSelectContractWithHash = (contractHash) => {
    const contract = this.contractsList.find(
      ({ hash }) => hash === contractHash
    );
    if (contract) {
      window.sessionStorage.setItem("selectedContract", contract.hash);
      this.selectedContract = contract;
      this.internalEventsStore.notify(
        EInternalEvents.didChangeSelectedContract,
        true
      );
      this.setSelectedContractUpdate();
      this.setFirebaseListenerIfAffiliate();
    }
  };

  @action("clear selectedContract")
  didWantToRemoveSelectedContract = () => {
    if (this.contractsList.length > 1 && this.selectedContract) {
      this.cancelSelectedContractUpdateIfRunning();

      this.selectedContract = null;
      window.sessionStorage.removeItem("selectedContract");
      this.internalEventsStore.notify(
        EInternalEvents.didChangeSelectedContract,
        false
      );

      if (this.selectedAffiliateContractRef) {
        this.selectedAffiliateContractRef.off();
        this.selectedAffiliateContractRef = null;
      }
    }
  };

  getUsersRulesForSelectedContract = () => {
    let newUsersList = [];

    this.modboxAPIService
      .requestRules()
      .then((response) => {
        if (response.status === 200) {
          response.data.users.forEach((user) => {
            newUsersList.push({
              name: user.name,
              rulesToken: user.rules_token,
              email: user.email,
              userToken: user.user_token || null,
              rules: {
                devices: user.access_devices === "True",
                locations: user.access_locations === "True",
                informative: user.access_informative === "True",
                connectivity: user.access_connectivity === "True",
                administrative: user.access_administrative === "True",
                camera: user.access_camera === "True",
                advertising: user.access_advertising === "True",
                api: user.access_api === "True",
                support: user.access_support === "True",
              },
            });
          });

          this.setUsersForSelectedContract(
            newUsersList.sort(dynamicSortByProperty("name"))
          );
        }
      })
      .catch((error) => {
        if (error && error.statusCode === 429) {
          setTimeout(this.getUsersRulesForSelectedContract, 1000);
        }
      });
  };

  @computed
  get filteredUsersForSelectedContract() {
    if (this.usersSearchString.length > 0) {
      return didSearchList(
        this.usersSearchString,
        this.usersForSelectedContract,
        (user, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(user.name);
          const matchEmail = textIncludesSearchString(user.email);

          return matchName || matchEmail;
        }
      );
    }

    return this.usersForSelectedContract;
  }

  getContractBusinessData = () => {
    this.setIsFetchingContractBusinessData(true);
    this.modboxAPIService
      .requestBusiness()
      .then((result) => {
        if (result.status === 200 && result.data) {
          const contractBusiness = new ContractBusinessModel(result.data);

          this.setSelectedContractBusinessData(contractBusiness);
        }
        this.setIsFetchingContractBusinessData(false);
        this.selectedContract.setIsCheckedAndPlan({
          plan: this.selectedContract.plan,
          isChecked: this.selectedContractBusinessData.isChecked,
        });
      })
      .catch((error) => {
        window.debugError("error in requestBusiness", error);
        this.setIsFetchingContractBusinessData(false);
      });
  };

  clearBusinessData = () => this.setSelectedContractBusinessData(null);

  grantTemporaryAccessToContract = async (
    supportOperatorToken: string
  ): Promise<boolean> => {
    try {
      const response: any = await this.modboxAPIService.grantTemporaryAccessToContract(
        supportOperatorToken
      );
      return response?.status === 200 ?? false;
    } catch (error) {
      window.debugError("error in grantTemporaryAccessToContract", error);
      return false;
    }
  };

  @action("clearStore")
  clearStore = () => {
    this.didWantToRemoveSelectedContract();
    this.isFetching = false;
    this.contractsList = [];
    this.usersForSelectedContract = [];
    this.usersSearchString = "";

    this.selectedContractBusinessData = null;
    this.isFetchingContractBusinessData = false;
  };
}

export default ContractStore;
