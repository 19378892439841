// @ts-nocheck
import { Component, Fragment } from "react";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";
import withStyles from "@material-ui/core/styles/withStyles";

import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import { Button } from "../components/Button";
import FloatingChatButton from "../components/Chat/FloatingChatButton";
import YouTubeVideo from "../components/Video/YouTubeVideo";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { isSimplyasServer } from "../utils/EnvUtils";
import { settingsRef } from "../store/FirebaseStore/FirebaseStore";

// Style
const styles = {
  waitingActiveDeviceContainer: {
    display: "flex",
    alignItems: "center",
  },
  waitingActiveDeviceLabel: {
    fontWeight: 500,
    fontSize: "1.1em",
  },
  licenceContainer: {
    textAlign: "center",
    fontSize: "1.4em",
    marginTop: 30,
  },
  licenseNumber: {
    fontSize: "2em",
    fontWeight: "bold",
    background: "#FFD000",
    color: "#333",
    padding: "20px 8px",
    borderRadius: 8,
    margin: "0 auto",
    marginTop: 10,
    width: "fit-content",
  },
};

@inject(
  "authStore",
  "contractStore",
  "i18nStore",
  "terminalsStore",
  "telegramAPIService"
)
@observer
class ActivateDeviceLayout extends Component {
  state = {
    hasActiveDevice: false,
    showWaitingActiveDevice: false,
    pageVideo: null,
  };

  componentDidMount() {
    if (isSimplyasServer) {
      settingsRef
        .child(
          `affiliateVideos/activateDevicePage/${this.props.i18nStore.currentLanguage}`
        )
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            this.setState({ pageVideo: snapshot.val() });
          }
        });
    }

    this.props.terminalsStore.getTerminalsListWithoutAnimation();

    this.showWaitingActiveDeviceTimeout = setTimeout(() => {
      this.setState({
        showWaitingActiveDevice: true,
      });
    }, 10000);

    let counter = 0;

    this.intervalTimer = setInterval(() => {
      if (this.state.hasActiveDevice) {
        clearInterval(this.intervalTimer);
      } else {
        if (counter === 50) {
          const { name, email } = this.props.authStore.currentUser;
          const content = `Cliente esta a mais de 25 minutos visualizando a etapa de ativação do primeiro Dispositivo: \n**${name} - (${email})**`;
          this.props.telegramAPIService.postButterflyAnalyticsToThread(content);
        }
        counter++;
        this.props.terminalsStore.getTerminalsListWithoutAnimation();
      }
    }, 30000);

    this.disposerAutorun = autorun(
      () => {
        this.setState({
          hasActiveDevice: this.props.terminalsStore.terminalsList.length > 0,
        });
      },
      { name: "hasActiveDevice" }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.showWaitingActiveDeviceTimeout);
    clearInterval(this.intervalTimer);
    this.disposerAutorun();
  }

  backToWelcomeStep = () => {
    const { contractStore } = this.props;
    contractStore.setStatusForDidFinishedWelcomeStep(false);
    contractStore.setStatusForDidFinishedInstallSystemStep(false);
  };
  finishAndGoToDashboard = () => {
    this.props.contractStore.setStatusForDidFinishedActivateDeviceStep(true);
  };
  render() {
    const { classes, contractStore } = this.props;

    const pageVideo = this.state.pageVideo;

    return (
      <Fragment>
        <PageTitle id="ActivateDevice@activateDeviceStepTitle">
          Configuração do dispositivo
        </PageTitle>
        <GridContainer
          justify="center"
          direction="row"
          style={{ height: "100vh" }}
        >
          <GridItem xs={11}>
            <Fade>
              <SimpleCard>
                <GridContainer justify="center">
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    <h1>
                      <Translate id="ActivateDevice@activateDeviceStepTitle">
                        Configuração do dispositivo
                      </Translate>
                    </h1>
                  </GridItem>
                  <GridItem xs={12}>
                    {pageVideo && (
                      <YouTubeVideo
                        videoId={pageVideo}
                        autoplay
                        disableKeyboard
                        width={"100%"}
                        height={500}
                        showControls
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12} className={classes.licenceContainer}>
                    <p>
                      <Translate id="InstallSystem@takeNotelicenseNumber">
                        Anote a licença do seu contrato:
                      </Translate>
                    </p>
                    <p className={classes.licenseNumber}>
                      {contractStore.selectedContract.licenseNumber}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: 40 }}>
                  {!this.state.hasActiveDevice && (
                    <GridItem xs={12} md={4}>
                      <Button block large onClick={this.backToWelcomeStep}>
                        <i className="fas fa-chevron-left" />{" "}
                        <Translate id="InstallSystem@backButtonLabel">
                          Voltar
                        </Translate>
                      </Button>
                    </GridItem>
                  )}
                  <GridItem
                    xs={12}
                    md={8}
                    className={classes.waitingActiveDeviceContainer}
                  >
                    {this.state.hasActiveDevice ? (
                      <Button
                        color="primary"
                        block
                        large
                        onClick={this.finishAndGoToDashboard}
                      >
                        <Translate id="InstallSystem@continueButtonLabel">
                          Continuar
                        </Translate>{" "}
                        <i className="fas fa-chevron-right" />
                      </Button>
                    ) : (
                      this.state.showWaitingActiveDevice && (
                        <Button
                          color="primary"
                          block
                          disabled
                          isLoading
                          loadingMessage={
                            <Translate id="InstallSystem@waitingActiveDeviceLabel">
                              Aguardando a ativação do dispositivo...
                            </Translate>
                          }
                        />
                      )
                    )}
                  </GridItem>
                </GridContainer>
              </SimpleCard>
            </Fade>
          </GridItem>
        </GridContainer>
        <FloatingChatButton />
      </Fragment>
    );
  }
}

ActivateDeviceLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivateDeviceLayout);
