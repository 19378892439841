// @ts-nocheck

import withStyles from "@material-ui/core/styles/withStyles";
import { inject, observer } from "mobx-react";
import classnames from "classnames";

import { GridContainer, GridItem } from "../../components/Grid";
import { SimpleCard } from "../../components/Card";
import Tooltip from "../../components/Tooltip/Tooltip";

import { CONNECTIVITY_DEVICE_STATUS } from "../../store/ConnectivityDevicesStore/ConnectivityDevicesStore";

// Utils
import { stringDateFromUnixDate } from "../../utils/DateUtils";

// Translate
import Translate from "../../components/I18n/Translate";

// Styles
import {
  successColor,
  dangerColor,
  infoColor,
  grayColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  card: {
    margin: "6px 0",
    position: "relative",
    cursor: "pointer",
  },
  statusIcon: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  success: {
    color: successColor,
  },
  danger: {
    color: dangerColor,
  },
  info: {
    color: infoColor,
  },
  gray: {
    color: grayColor,
  },
  title: {
    margin: 0,
    fontSize: "1.4em",
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
  },
  identifier: {
    margin: 0,
    marginTop: 6,
    fontSize: "1.0em",
  },
  anonymousCode: {
    background: "#FFD000",
    color: "#333",
    padding: "4px 8px",
    borderRadius: 8,
    width: "fit-content",
  },
  locations: {
    margin: 0,
    fontSize: "0.9em",
  },
  expires: {
    fontSize: "0.9em",
    fontStyle: "italic",
    margin: 0,
    textAlign: "right",
  },
  locationItem: {
    marginBottom: 0,
    textAlign: "left",
  },
  otherLocationsSpan: {
    position: "relative",
    marginRight: 6,
  },
  expired: {
    color: grayColor,
    fontSize: "1.0",
    textDecoration: "line-through",
  },
};

const ConnectivityDevicesListContainer = ({
  classes,
  i18nStore,
  devicesList,
  didWantToEdit,
  didCopyCode,
}) => {
  const {
    localizedString,
    dateFormatWithFourDigitsYear,
    timeFormat,
  } = i18nStore;
  const formatDate = (date) =>
    stringDateFromUnixDate(
      date,
      `${dateFormatWithFourDigitsYear} ${timeFormat}`
    );

  const renderStatusIcon = (status, isExpired) => {
    if (
      isExpired ||
      status === CONNECTIVITY_DEVICE_STATUS.ANONYMOUS ||
      status === CONNECTIVITY_DEVICE_STATUS.DEFINED ||
      status === CONNECTIVITY_DEVICE_STATUS.UNDEFINED ||
      status === CONNECTIVITY_DEVICE_STATUS.BANNED
    )
      return null;
    return (
      <span
        className={classnames({
          [classes.statusIcon]: true,
          [classes.success]: true,
        })}
      >
        <i className="fa fa-fw fa-check" />
      </span>
    );
  };

  const renderExpires = (expires, validityAfterActivation) => {
    return (
      <p className={classes.expires}>
        {validityAfterActivation ? (
          <Translate
            id="ConnectivityDevice@validityAfterActivationLabel"
            variables={{ minutes: validityAfterActivation }}
          >
            $minutes minutos de uso
          </Translate>
        ) : expires > 0 ? (
          `${localizedString({
            id: "ConnectivityDevice@expiresColumnTitle",
            defaultString: "Validade",
          })}: ${formatDate(expires)}`
        ) : (
          localizedString({
            id: "ConnectivityDevice@expireUnlimitedDateLabel",
            defaultString: "Ilimitado",
          })
        )}
      </p>
    );
  };

  const renderLocationsName = (locations) => {
    if(!locations?.length){
      return <span/>
    }
    switch (locations.length) {
      case 1:
      case 2:
      case 3:
        return locations.map(({ label }, index) =>
          index === 0 ? label : `, ${label}`
        );

      default:
        return (
          <span>
            {`${locations[0].label}, ${locations[1].label}`}{" "}
            <Translate id="Common@andOthers">e outras</Translate>{" "}
            <Tooltip
              title={
                <div>
                  {locations.map(({ token, label }) => (
                    <p className={classes.locationItem} key={token}>
                      <i className="fas fa-chevron-right" /> {label}
                    </p>
                  ))}
                </div>
              }
            >
              <span className={classes.otherLocationsSpan}>
                {locations.length - 2}{" "}
                <Translate id="Metrics@chartSubtitleLocations">
                  localidades
                </Translate>
              </span>
            </Tooltip>
          </span>
        );
    }
  };

  const renderIdentifier = (code, macAddress, phoneNumber, isExpired) => {
    return (
      <p
        className={classnames({
          [classes.identifier]: true,
          [classes.expired]: isExpired,
        })}
      >
        {code && (
          <span className={classnames({ [classes.anonymousCode]: !isExpired })}>
            <i className="fas fa-fw fa-qrcode" /> {code}
          </span>
        )}
        {macAddress && (
          <span>
            <i className="fas fa-fw fa-desktop" /> {macAddress}
          </span>
        )}
        {phoneNumber && (
          <span>
            <i className="fas fa-fw fa-mobile-alt" /> {phoneNumber}
          </span>
        )}
      </p>
    );
  };

  return (
    <GridContainer>
      {devicesList.map(
        ({
          token,
          label,
          status,
          locations,
          code,
          macAddress,
          phoneNumber,
          expires,
          validityAfterActivation,
          isExpired,
        }) => {
          return (
            <GridItem key={token} xs={12}>
              <SimpleCard
                classNames={{
                  card: classes.card,
                }}
                onClick={didWantToEdit(token)}
              >
                {renderStatusIcon(status, isExpired)}
                <GridContainer>
                  <GridItem xs={12}>
                    <p
                      className={classnames({
                        [classes.title]: true,
                        [classes.expired]: isExpired,
                      })}
                    >
                      {label}
                    </p>
                  </GridItem>
                  <GridItem xs={12}>
                    <p
                      className={classnames({
                        [classes.locations]: true,
                        [classes.expired]: isExpired,
                      })}
                    >
                      {renderLocationsName(locations)}
                    </p>
                  </GridItem>
                </GridContainer>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} sm={6}>
                    {renderIdentifier(code, macAddress, phoneNumber, isExpired)}
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    {isExpired ? (
                      <p className={classes.expires}>
                        <Translate id="ConnectivityDevice@isExpiredLabel">
                          Expirou
                        </Translate>
                      </p>
                    ) : (
                      renderExpires(expires, validityAfterActivation)
                    )}
                  </GridItem>
                </GridContainer>
              </SimpleCard>
            </GridItem>
          );
        }
      )}
    </GridContainer>
  );
};

export default inject("i18nStore")(
  observer(withStyles(styles)(ConnectivityDevicesListContainer))
);
