// @ts-nocheck

import { Switch, Route } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

import LoginPage from "../pages/LoginPage";
import CreateNewPasswordPage from "../pages/CreateNewPasswordPage";

// Style
import pagesStyle from "../assets/jss/js-styles/layouts/pagesStyle";

// Utils
import { isSimplyasServer, LOGIN_PAGE_BG_IMAGE } from "../utils/EnvUtils";

const LoginLayout = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.fullPage}>
        <Switch>
          <Route path={"/login"} component={LoginPage} />
          <Route path={"/recoverPassword"} component={CreateNewPasswordPage} />
        </Switch>
      </div>
      {isSimplyasServer ? (
        <div
          className={classes.fullPageBackground}
          style={{ background: "#000" }}
        >
        </div>
      ) : (
        <div
          className={classes.fullPageBackground}
          style={{
            backgroundImage: `url("${LOGIN_PAGE_BG_IMAGE}")`,
          }}
        />
      )}
    </div>
  );
};

export default withStyles(pagesStyle)(LoginLayout);
