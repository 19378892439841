// @ts-nocheck


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Drawer } from "@material-ui/core";

import ChatsListContainer from "../Chat/ChatsListContainer";

// Style
const styles = {
  drawerPaper: {
    maxWidth: 500,
    backgroundColor: "transparent",
    marginLeft: "auto",
  },
};

const ChatDrawer = ({ classes, isOpen }) => (
  <Drawer
    variant={"temporary"}
    anchor={"bottom"}
    open={isOpen}
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <ChatsListContainer />
  </Drawer>
);

export default withStyles(styles)(ChatDrawer);
