// @ts-nocheck

import YouTube from "@u-wave/react-youtube";

const YouTubeVideo = ({
  videoId,
  width,
  height,
  autoplay,
  showControls,
  allowFullscreen,
  disableKeyboard,
}) => {
  return (
    <YouTube
      id={`youtubeVideo${videoId}`}
      video={videoId}
      autoplay={autoplay}
      controls={showControls}
      disableKeyboard={disableKeyboard}
      allowFullscreen={allowFullscreen}
      width={width}
      height={height}
      modestBranding
    />
  );
};

YouTubeVideo.defaultProps = {
  width: "560",
  height: "315",
};

export default YouTubeVideo;
