// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withPageAllowedRules from "./withPageAllowedRules";
import { withLocalized } from "../components/HOC";
import withStyles from "@material-ui/core/styles/withStyles";

import { GridContainer, GridItem } from "../components/Grid";
import { Button } from "../components/Button";
import { InputText } from "../components/Form";
import Modal from "../components/Modal/Modal";

import InfoLabel from "../components/Label/InfoLabel";
import LoadingView from "../components/LoadingView/LoadingView";
import { SimpleCard } from "../components/Card";
import UsageGridWidget from "../components/Widgets/UsageGridWidget";

import ChartWizard from "../components/Charts/ChartWizard";

import PageTitle from "../components/PageTitle/PageTitle";
import CameraAccessByLocationChartContainer from "./cameraMetricsReportPage/CameraAccessByLocationChartContainer";
import CameraHourlyTrafficChartContainer from "./cameraMetricsReportPage/CameraHourlyTrafficChartContainer";
import CameraVisitorsChartContainer from "./cameraMetricsReportPage/CameraVisitorsChartContainer";
import CameraSavedChartView from "./cameraMetricsReportPage/CameraSavedChartView";

import Translate from "../components/I18n/Translate";

import {
  CameraChartType,
  ModalType,
} from "../store/ReportsStore/MetricsReportStore";

// Style
const styles = {
  button: {
    padding: "6px 12px",
    marginRight: 4,
    marginLeft: 4,
  },
  buttonLabelBase: {
    display: "block",
    padding: "10px 8px",
    width: 120,
  },
};

@withLocalized
@inject("cameraMetricsReportStore", "groupsStore")
@observer
class CameraMetricsReportPage extends Component {
  componentDidMount() {
    this.props.cameraMetricsReportStore.updateContractLocationsList();
  }

  componentWillUnmount() {
    this.props.cameraMetricsReportStore.clearStore();
  }

  onChangeChartTitle = ({ target }) => {
    this.props.cameraMetricsReportStore.onChangeSelectedChartName(target.value);
  };

  renderReportForType = (type, props, viewId = null) => {
    switch (type) {
      case CameraChartType.accessByLocation:
        return <CameraAccessByLocationChartContainer {...props} />;
      case CameraChartType.visitors:
        return <CameraVisitorsChartContainer {...props} />;
      case CameraChartType.hourlyTraffic:
      default:
        return (
          <CameraHourlyTrafficChartContainer
            didSelectChartItem={this.didSelectHourlyTrafficChartItem(
              viewId,
              props.chartData
            )}
            {...props}
          />
        );
    }
  };

  renderSavedChartView = (option) => {
    return (
      <CameraSavedChartView
        {...option}
        titleForChartType={this.props.cameraMetricsReportStore.titleForChartType(
          option.type
        )}
      />
    );
  };

  didSelectHourlyTrafficChartItem = (viewId, chartData) => ({ x, y }) => {
    const daysOfWeekKeys = [
      "sun",
      "mon",
      "tue",
      "wed",
      "thu",
      "fri",
      "sat",
    ].reverse();
    const dayOfTheWeek = daysOfWeekKeys[y];
    const valueForSelectedPeriod = chartData.hourly_traffic[dayOfTheWeek][x];

    this.props.cameraMetricsReportStore.requestAdditionalChartDataForCameraHourlyTraffic(
      viewId,
      dayOfTheWeek,
      x,
      valueForSelectedPeriod
    );
  };

  renderChart = (viewId) => {
    const { cameraMetricsReportStore } = this.props;
    const chartView = cameraMetricsReportStore[viewId] || null;

    if (!chartView) return null;
    const locationsList = cameraMetricsReportStore.filteredLocationsList;

    const {
      type: chartType,
      isFetching,
      dateRange,
      fromDate,
      toDate,
      wizardCurrentStep,
      selectedLocationsList,
      chartData,
      error,
    } = chartView;
    const isSingleLocation = cameraMetricsReportStore.isSingleLocationForChartType(
      chartType
    );

    return (
      <ChartWizard
        isCameraType
        color={viewId === "mainChartView" ? "primary" : "success"}
        allowToContinue={cameraMetricsReportStore.allowToContinue(viewId)}
        allowToExportData={cameraMetricsReportStore.allowToExportData(viewId)}
        savedCharts={cameraMetricsReportStore.userSavedCharts}
        isCompareChartActive={
          cameraMetricsReportStore.compareChartView !== null
        }
        didWantToManageSavedCharts={
          cameraMetricsReportStore.didWantToManageSavedCharts
        }
        didSelectSavedChart={cameraMetricsReportStore.didSelectSavedChart(
          viewId
        )}
        currentStep={wizardCurrentStep}
        disabledSteps={cameraMetricsReportStore.disabledStepsForView(viewId)}
        onChangeCurrentStep={cameraMetricsReportStore.didChangeChartWizardCurrentStep(
          viewId
        )}
        reportTypes={[
          {
            id: CameraChartType.accessByLocation,
            title: cameraMetricsReportStore.titleForChartType(
              CameraChartType.accessByLocation
            ),
          },
          {
            id: CameraChartType.hourlyTraffic,
            title: cameraMetricsReportStore.titleForChartType(
              CameraChartType.hourlyTraffic
            ),
          },

          {
            id: CameraChartType.visitors,
            title: cameraMetricsReportStore.titleForChartType(
              CameraChartType.visitors
            ),
          },
        ]}
        chartType={chartType}
        locationsList={locationsList}
        dateRange={dateRange}
        fromDate={fromDate}
        toDate={toDate}
        singleLocation={isSingleLocation}
        onChangeChartType={cameraMetricsReportStore.onChangeChartTypeForView(
          viewId
        )}
        onChangeLocationsListForView={cameraMetricsReportStore.onChangeLocationsListForView(
          viewId
        )}
        onChangeDateRange={cameraMetricsReportStore.onChangeDateRangeForView(
          viewId
        )}
        onChangeFromDate={cameraMetricsReportStore.onChangeFromDateForView(
          viewId
        )}
        onChangeToDate={cameraMetricsReportStore.onChangeToDateForView(viewId)}
        selectedLocationsList={selectedLocationsList}
        isFetchingLocations={this.props.groupsStore.isFetching}
        renderReport={this.renderReportForType(
          chartType,
          {
            isFetching,
            error,
            chartData,
            locationsList: selectedLocationsList,
            datePeriod: { dateRange, fromDate, toDate },
          },
          viewId
        )}
        renderSavedChartView={this.renderSavedChartView}
        didWantToSaveChart={() =>
          cameraMetricsReportStore.didWantToSaveNewChart(viewId)
        }
        didWantToResetChart={() =>
          cameraMetricsReportStore.resetChartView(viewId)
        }
        didWantToStartNewChart={cameraMetricsReportStore.didWantToStartNewChart}
        didSelectAllLocations={() => {
          if (!isSingleLocation) {
            cameraMetricsReportStore.onChangeLocationsListForView(viewId)(
              locationsList.map(($0) => ({
                label: $0.name,
                value: $0.token,
              }))
            );
          }
        }}
        didWantToExportCsv={() =>
          cameraMetricsReportStore.exportChartViewToCsv(viewId)
        }
      />
    );
  };

  render() {
    const { cameraMetricsReportStore } = this.props;
    const {
      modal,
      didCloseModal,
      saveChartView,
      userSavedCharts,
      selectedSavedChartToEdit,
      filteredLocationsList,
      additionalChart,
    } = cameraMetricsReportStore;

    return (
      <Fragment>
        <PageTitle id="Menu@cameraMetricsReports">
          Relatórios Avançados
        </PageTitle>
        {modal.type !== null && (
          <Modal
            isOpen={modal.isOpen}
            modalTitle={modal.title}
            didCloseModal={didCloseModal}
            maxWidth={
              modal.type === ModalType.showAdditionalChartData
                ? "lg"
                : modal.type === ModalType.manageSavedCharts
                ? "md"
                : "sm"
            }
          >
            <GridContainer>
              {modal.type === ModalType.manageSavedCharts &&
                userSavedCharts.map((savedChart) => (
                  <GridItem key={savedChart.id} xs={12} sm={6}>
                    <SimpleCard cardStyle={{ marginTop: 0 }}>
                      <CameraSavedChartView
                        {...savedChart}
                        titleForChartType={cameraMetricsReportStore.titleForChartType(
                          savedChart.type
                        )}
                        isEditing
                        didWantToEdit={() =>
                          cameraMetricsReportStore.didWantToEditSavedChart(
                            savedChart
                          )
                        }
                        didWantToDelete={() =>
                          cameraMetricsReportStore.didWantToDeleteSavedChart(
                            savedChart.id
                          )
                        }
                      />
                    </SimpleCard>
                  </GridItem>
                ))}
              {modal.type === ModalType.chooseNewChartOption && (
                <GridItem xs={12}>
                  <Button
                    color="primary"
                    block
                    onClick={() => {
                      cameraMetricsReportStore.addCompareChart();
                      cameraMetricsReportStore.didCloseModal();
                    }}
                    style={{ marginBottom: 20 }}
                  >
                    <Translate id="Metrics@addCompareChartViewButtonLabel">
                      Manter relatório atual para comparação
                    </Translate>{" "}
                    <i className="fal fa-clone" />
                  </Button>
                  <Button
                    color="warning"
                    block
                    onClick={() => {
                      cameraMetricsReportStore.resetChartView("mainChartView");
                      cameraMetricsReportStore.didCloseModal();
                    }}
                  >
                    <Translate id="Metrics@resetChartViewButtonLabel">
                      Resetar relatório atual
                    </Translate>{" "}
                    <i className="fas fa-undo-alt" />
                  </Button>
                </GridItem>
              )}
              {(modal.type === ModalType.addSavedChart ||
                modal.type === ModalType.editSavedChart) && (
                <GridItem xs={12}>
                  {selectedSavedChartToEdit && (
                    <InputText
                      label={
                        <Translate id="Metrics@setSavedChartTitleLabel">
                          Defina um nome
                        </Translate>
                      }
                      autoFocus
                      name="chartTitle"
                      value={selectedSavedChartToEdit.label}
                      inputProps={{ autoComplete: "off" }}
                      onChange={this.onChangeChartTitle}
                    />
                  )}
                  <Button
                    color="primary"
                    block
                    disabled={!selectedSavedChartToEdit}
                    onClick={saveChartView}
                  >
                    {ModalType.editSavedChart ? (
                      <Translate id="Common@update">Atualizar</Translate>
                    ) : (
                      <Translate id="Common@save">Salvar</Translate>
                    )}{" "}
                    <i className="fas fa-save" />
                  </Button>
                </GridItem>
              )}
              {modal.type === ModalType.showAdditionalChartData && (
                <GridItem xs={12}>
                  {this.renderReportForType(additionalChart.type, {
                    ...additionalChart,
                    locationsList: additionalChart.selectedLocationsList,
                    datePeriod: {
                      dateRange: additionalChart.dateRange,
                      fromDate: additionalChart.fromDate,
                      toDate: additionalChart.toDate,
                      fromHour: additionalChart.fromHour,
                      toHour: additionalChart.toHour,
                    },
                  })}
                </GridItem>
              )}
            </GridContainer>
          </Modal>
        )}
        <SimpleCard
          cardStyle={{ marginTop: 0 }}
          cardBodyStyle={{ padding: "0 16px" }}
        >
          <GridContainer>
            <UsageGridWidget type="cameraFaces" />
          </GridContainer>
        </SimpleCard>

        {this.props.groupsStore.isFetching ? (
          <LoadingView />
        ) : filteredLocationsList.length < 1 ? (
          <InfoLabel large>
            <Translate id="Connectivity@noAvailableLocation">
              Você não possui nenhuma localidade com o recurso Wi-Fi ativo no
              momento.
            </Translate>
          </InfoLabel>
        ) : (
          <GridContainer justify="center">
            <GridItem xs={12}>{this.renderChart("compareChartView")}</GridItem>
            <GridItem xs={12}>{this.renderChart("mainChartView")}</GridItem>
          </GridContainer>
        )}
      </Fragment>
    );
  }
}

export default withPageAllowedRules({
  component: withStyles(styles)(CameraMetricsReportPage),
  pageAllowedRule: "camera",
});
