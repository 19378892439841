// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";
import Wizard from "../../components/Wizard/Wizard";
import { GridContainer, GridItem } from "../../components/Grid";
import { ButtonGroup } from "../../components/Form";
import RadioGroup from "../../components/Form/RadioGroup";
import DatePicker from "../../components/Form/DatePicker";
import MaterialSelectInput from "../../components/Form/MaterialSelectInput";
import LoadingView from "../../components/LoadingView/LoadingView";
import Slider from "../../components/Slider/Slider";

import AdvertisingMessageTextarea from "../../components/Advertising/AdvertisingMessageTextarea";

// Translate
import Translate from "../../components/I18n/Translate";

// Styles
import { successColor } from "../../assets/jss/app-js-styles";
const styles = {
  sessionTitle: {
    fontWeight: "bold",
    fontSize: "1.2em",
  },
};

@withLocalized
@inject("advertisingStore", "authStore", "groupsStore")
@observer
class AdvancedContractSendMessageWizard extends Component {
  createMinutesString = (startString) => {
    return `${startString} ${this.props.localizedString({
      id: "Common@minutesString",
      defaultString: "minutos",
    })}`;
  };

  createDaysString = (startString) => {
    return `${startString} ${this.props.localizedString({
      id: "Common@daysString",
      defaultString: "dias",
    })}`;
  };

  render() {
    const {
      classes,
      advertisingStore,
      authStore,
      groupsStore,
      localizedString,
      title,
      subtitle,
      customersCount,
      availableCredits,
      whereFilterGroup,
      fromDate,
      toDate,
      connectedInTheLastValue,
      notConnectedInTheLastValue,
      occurrencesOptions,
      selectedGroup,
      gender,
      ageGroup,
      limitPeople,
      canSubmit,
      onChangeRadioGroup,
      onChangeDatePicker,
      onChangeSelectInput,
      onChangeSelectedGroup,
      onChangeOccurrencesOptions,
      onChangeGenderOption,
      onChangeLimitPeopleSlider,
      onSubmit,
    } = this.props;
    const allowToContinue = advertisingStore.message.length > 0;

    const ageRanges = authStore.ageRanges;

    return (
      <Wizard
        title={title}
        subtitle={subtitle}
        showFinishButton
        allowToContinue={allowToContinue}
        allowToFinish={canSubmit}
        disabledSteps={allowToContinue ? [] : [1, 2, 3, 4, 5]}
        resetOnFinish
        removeNavMargin
        previousButtonLabel={
          <span>
            <i className="fas fa-chevron-left" />{" "}
            <Translate id="Common@back">Voltar</Translate>
          </span>
        }
        nextButtonLabel={
          <span>
            <Translate id="Common@goForward">Avançar</Translate>{" "}
            {allowToContinue ? (
              <i className="fas fa-chevron-right" />
            ) : (
              <i className="fas fa-ban" />
            )}
          </span>
        }
        finishButtonLabel={
          <span>
            <Translate id="Advertising@sendMessageButtonLabel">
              Enviar agora
            </Translate>{" "}
            {canSubmit ? (
              <i className="fas fa-paper-plane" />
            ) : (
              <i className="fas fa-ban" />
            )}
          </span>
        }
        finishButtonClick={onSubmit}
        color="primary"
        steps={[
          {
            stepName: localizedString({
              id: "Advertising@messageStepTitle",
              defaultString: "Mensagem",
            }),
            stepComponent: (
              <GridContainer>
                <GridItem xs={12}>
                  <AdvertisingMessageTextarea />
                </GridItem>
              </GridContainer>
            ),
          },
          {
            stepName: localizedString({
              id: "Advertising@dateFilterStepTitle",
              defaultString: "Período",
            }),
            stepComponent: (
              <GridContainer>
                <GridItem xs={12}>
                  <p className={classes.sessionTitle}>
                    <Translate id="Advertising@dateFilterTitle">
                      Gostaria de filtrar pessoas por períodos específicos?
                    </Translate>
                  </p>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <RadioGroup
                    name="whereFilterGroup"
                    value={whereFilterGroup}
                    onChange={onChangeRadioGroup}
                    options={[
                      {
                        label: localizedString({
                          id: "Advertising@periodFilterOptionAny",
                          defaultString: "Qualquer data",
                        }),
                        value: "any",
                      },
                      {
                        label: localizedString({
                          id: "Advertising@periodFilterOptionSpecificDate",
                          defaultString: "Uma data específica",
                        }),
                        value: "aSpecificDate",
                      },
                      {
                        label: localizedString({
                          id: "Advertising@periodFilterOptionIsOnlineNow",
                          defaultString: "Conectado neste momento",
                        }),
                        value: "isOnlineNow",
                      },
                      {
                        label: localizedString({
                          id:
                            "Advertising@periodFilterOptionConnectedInTheLast",
                          defaultString: "Conectado nos últimos",
                        }),
                        value: "connectedInTheLast",
                      },
                      {
                        label: localizedString({
                          id:
                            "Advertising@periodFilterOptionNotConnectedInTheLast",
                          defaultString: "Não conectaram nos últimos",
                        }),
                        value: "notConnectedInTheLast",
                      },
                    ]}
                  />
                </GridItem>
                {whereFilterGroup === "aSpecificDate" && (
                  <GridItem xs={12} sm={6}>
                    <DatePicker
                      label={
                        <Translate id="Advertising@startDateFilter">
                          Data início
                        </Translate>
                      }
                      name="fromDate"
                      value={fromDate}
                      onChange={onChangeDatePicker}
                      fullWidth
                    />
                    <DatePicker
                      label={
                        <Translate id="Advertising@endDateFilter">
                          Data fim
                        </Translate>
                      }
                      name="toDate"
                      value={toDate}
                      onChange={onChangeDatePicker}
                      fullWidth
                    />
                  </GridItem>
                )}
                {whereFilterGroup === "connectedInTheLast" && (
                  <GridItem xs={12} sm={6}>
                    <MaterialSelectInput
                      label={
                        <Translate id="Advertising@connectedInTheLastValueSelectLabel">
                          Selecione
                        </Translate>
                      }
                      name="connectedInTheLastValue"
                      value={connectedInTheLastValue}
                      options={[
                        {
                          label: this.createMinutesString("15"),
                          value: "15",
                        },
                        {
                          label: this.createMinutesString("30"),
                          value: "30",
                        },
                        {
                          label: localizedString({
                            id:
                              "Advertising@connectedInTheLastOneHourOrLessFilter",
                            defaultString: "60 minutos ou menos",
                          }),
                          value: "60",
                        },
                      ]}
                      onChange={onChangeSelectInput}
                    />
                  </GridItem>
                )}
                {whereFilterGroup === "notConnectedInTheLast" && (
                  <GridItem xs={12} sm={6}>
                    <MaterialSelectInput
                      label="Selecione"
                      name="notConnectedInTheLastValue"
                      value={notConnectedInTheLastValue}
                      options={[
                        { label: this.createDaysString("7"), value: "7" },
                        {
                          label: this.createDaysString("14"),
                          value: "14",
                        },
                        {
                          label: localizedString({
                            id:
                              "Advertising@notConnectedInTheLastOneMonthOrMore",
                            defaultString: "31 dias ou mais",
                          }),
                          value: "31",
                        },
                      ]}
                      onChange={onChangeSelectInput}
                    />
                  </GridItem>
                )}
              </GridContainer>
            ),
          },
          {
            stepName: localizedString({
              id: "Advertising@whereStepTitle",
              defaultString: "Localidade",
            }),
            stepComponent: (
              <GridContainer>
                <GridItem xs={12}>
                  <p className={classes.sessionTitle}>
                    <Translate id="Advertising@whereSectionTitle">
                      Gostaria de filtrar os resultados pela localidade?
                    </Translate>
                  </p>
                </GridItem>
                <GridItem xs={12}>
                  {groupsStore.isFetching ? (
                    <LoadingView height={40} />
                  ) : (
                    <MaterialSelectInput
                      label="Localidade"
                      name="selectedGroup"
                      value={selectedGroup}
                      options={[
                        {
                          label: localizedString({
                            id: "Advertising@anyGroupSelectFilter",
                            defaultString: "Qualquer localidade",
                          }),
                          value: "any",
                        },
                        ...groupsStore.groupsList.map((group) => ({
                          label: group.name,
                          value: group.token,
                        })),
                      ]}
                      onChange={onChangeSelectedGroup}
                    />
                  )}
                </GridItem>
              </GridContainer>
            ),
          },
          {
            stepName: localizedString({
              id: "Advertising@occurrencesStepTitle",
              defaultString: "Frequência",
            }),
            stepComponent: (
              <GridContainer>
                <GridItem xs={12}>
                  <p className={classes.sessionTitle}>
                    <Translate id="Advertising@occurrencesSectionTitle">
                      Gostaria de filtrar por frequência de conexões?
                    </Translate>
                  </p>
                </GridItem>
                <GridItem xs={12}>
                  <ButtonGroup
                    name="occurrencesOptions"
                    value={occurrencesOptions}
                    small
                    onChange={onChangeOccurrencesOptions}
                    options={[
                      {
                        value: "lessThan5",
                        label: localizedString({
                          id: "Advertising@occurrencesOptionLessThanFive",
                          defaultString: "Conectou menos que 5 vezes",
                        }),
                      },
                      {
                        value: "any",
                        label: localizedString({
                          id: "Advertising@occurrencesOptionAny",
                          defaultString: "Indifererente",
                        }),
                      },
                      {
                        value: "moreThan5",
                        label: localizedString({
                          id: "Advertising@occurrencesOptionMoreThanFive",
                          defaultString: "Conectou mais que 5 vezes",
                        }),
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            ),
          },
          {
            stepName: localizedString({
              id: "Advertising@profileStepTitle",
              defaultString: "Perfil",
            }),
            stepComponent: (
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <p className={classes.sessionTitle}>
                    <Translate id="Advertising@genderSectionTitle">
                      Gostaria de filtrar por sexo?
                    </Translate>
                  </p>
                  <ButtonGroup
                    name="gender"
                    value={gender}
                    small
                    onChange={onChangeGenderOption}
                    options={[
                      {
                        value: "male",
                        label: localizedString({
                          id: "Common@male",
                          defaultString: "Masculino",
                        }),
                      },
                      {
                        value: "female",
                        label: localizedString({
                          id: "Common@female",
                          defaultString: "Feminino",
                        }),
                      },
                      {
                        value: "all",
                        label: localizedString({
                          id: "Advertising@allFilter",
                          defaultString: "Todos",
                        }),
                      },
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <p className={classes.sessionTitle}>
                    <Translate id="Advertising@ageGroupSectionTitle">
                      Gostaria de filtrar por faixa etária?
                    </Translate>
                  </p>
                  <RadioGroup
                    name="ageGroup"
                    value={ageGroup}
                    onChange={onChangeRadioGroup}
                    options={[
                      {
                        value: "all",
                        label: localizedString({
                          id: "Advertising@allFilter",
                          defaultString: "Todos",
                        }),
                      },
                      ...ageRanges.map(($0, index) => ({
                        label: $0.name,
                        value: `${index}`,
                      })),
                    ].reverse()}
                  />
                </GridItem>
              </GridContainer>
            ),
          },
          {
            stepName: localizedString({
              id: "Advertising@revisionStepTitle",
              defaultString: "Revisão",
            }),
            stepComponent: (
              <GridContainer>
                {customersCount > 1 && (
                  <Fragment>
                    <GridItem xs={12}>
                      <p className={classes.sessionTitle}>
                        <Translate id="Advertising@revisionSectionTitle">
                          Gostaria de ajustar a quantidade de mensagens que
                          serão enviadas?
                        </Translate>
                      </p>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: 30 }}>
                      <Slider
                        values={[limitPeople]}
                        min={1}
                        max={customersCount}
                        step={1}
                        onSlide={onChangeLimitPeopleSlider}
                      />
                      {limitPeople <= availableCredits && (
                        <p
                          style={{
                            marginTop: 20,
                            textAlign: "center",
                            color: successColor,
                          }}
                        >
                          <Translate id="Advertising@submitButtonHintMessage">
                            Use o botão abaixo para enviar sua mensagem!
                          </Translate>
                        </p>
                      )}
                    </GridItem>
                  </Fragment>
                )}
                {customersCount < 1 && (
                  <GridItem xs={12}>
                    <p
                      style={{
                        marginTop: 20,
                        textAlign: "center",
                      }}
                    >
                      <Translate id="Advertising@insufficientCustomersMessage">
                        Não encontrei pessoas suficientes para enviar sua
                        mensagem. Tente alterar os filtros ou aguarde novas
                        conexões na sua Wi-Fi.
                      </Translate>
                    </p>
                  </GridItem>
                )}
                {customersCount === 1 && (
                  <GridItem xs={12}>
                    <p
                      style={{
                        marginTop: 20,
                        textAlign: "center",
                        color: successColor,
                      }}
                    >
                      <Translate id="Advertising@submitButtonHintMessage">
                        Use o botão abaixo para enviar sua mensagem!
                      </Translate>
                    </p>
                  </GridItem>
                )}
              </GridContainer>
            ),
          },
        ]}
      />
    );
  }
}

export default withStyles(styles)(AdvancedContractSendMessageWizard);
