// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import { withLocalized } from "../../components/HOC";
import { GridContainer, GridItem } from "../../components/Grid";
import { Card, CardBody, CardHeader, CardIcon } from "../../components/Card";
import BigCounter from "../../components/BigCounter/BigCounter";
import { ButtonGroup } from "../../components/Form";
import IfNotFetching from "../../components/IfNotFetching/IfNotFetching";

import FinancialHistoryTable from "./FinancialHistoryTable";
import CommissionHistoricTable from "./CommissionHistoricTable";

import { formatPriceToText } from "../../components/Price/Price";

// Translate
import Translate from "../../components/I18n/Translate";

// Utils
import {
  getNextMonthDateForDay,
  startOfTheDayMoment,
  endOfTheDayMoment,
} from "../../utils/DateUtils";

// style
import {
  cardTitle,
  successColor,
  warningColor,
} from "../../assets/jss/app-js-styles";
const styles = {
  mainContainer: {
    marginTop: 120,
    padding: "0 16px",
  },
  cardIconTitle: {
    ...cardTitle,
    fontSize: "1.6em",
    marginTop: 16,
    marginLeft: 16,
    marginBottom: 0,
  },
  cardIcon: {
    fontSize: 22,
  },
  cardBody: {},
  nextPaymentInfoLabel: {
    fontSize: "1.4em",
    marginBottom: 16,
    textAlign: "center",
    border: `1px dashed ${successColor}`,
    color: successColor,
    padding: 16,
    borderRadius: 8,
    lineHeight: "1.2em",
  },
  nextBillPeriodInfoLabel: {
    fontSize: "1.4em",
    marginBottom: 16,
    color: warningColor,
    textAlign: "center",
    border: `1px dashed ${warningColor}`,
    padding: 16,
    borderRadius: 8,
    lineHeight: "1.2em",
  },
};

const today = startOfTheDayMoment();
const billClosingDay = startOfTheDayMoment({ day: 10 });
const invoicePaymentDay = endOfTheDayMoment({ day: 20 });

@withLocalized
@inject("affiliateStore", "i18nStore")
@observer
class AffiliateDashboardContainer extends Component {
  state = {
    selectedDateRange: "week",
  };

  onChangeDateRange = (name, selectedValue) => {
    this.setState({
      [name]: selectedValue,
    });
  };

  getChildContractsCountWithSelectedDateRange = () => {
    const { selectedDateRange } = this.state;
    const startDay = moment({}).startOf(selectedDateRange);

    let count = 0;

    this.props.affiliateStore.childContracts.forEach((contract) => {
      if (startDay.isSameOrBefore(moment(contract.registrationTimestamp))) {
        count++;
      }
    });

    return count;
  };

  getSalesCountWithSelectedDateRange = () => {
    const { selectedDateRange } = this.state;
    const startDay = moment({}).startOf(selectedDateRange);

    let count = 0;

    this.props.affiliateStore.ordersList.forEach((item) => {
      if (startDay.isSameOrBefore(moment(item.timestamp))) {
        count++;
      }
    });

    return count;
  };

  renderBigConter = (value, label) => {
    const { classes } = this.props;
    return (
      <IfNotFetching
        isFetching={this.props.affiliateStore.isFetching}
        loadingHeight={50}
      >
        <BigCounter classes={classes} value={value} label={label} />
      </IfNotFetching>
    );
  };

  renderNextPaymentInfo = () => {
    const { affiliateStore, i18nStore } = this.props;
    const formatedPrice = formatPriceToText(
      affiliateStore.getNextPaymentInfo,
      true
    );

    return (
      <p className={this.props.classes.nextPaymentInfoLabel}>
        {today.isSameOrAfter(billClosingDay) &&
        today.isBefore(invoicePaymentDay) ? (
          <Fragment>
            <i className="fas fa-check" />{" "}
            <Translate
              id="Affiliate@nextPaymentInfo"
              variables={{
                price: formatedPrice,
                date: invoicePaymentDay.format(
                  i18nStore.dateFormatWithFourDigitsYear
                ),
              }}
            >
              Você receberá um repasse de <strong>$price</strong> em $date.
            </Translate>
          </Fragment>
        ) : today.isSame(invoicePaymentDay) ? (
          <Fragment>
            <i className="fas fa-check" />{" "}
            <Translate
              id="Affiliate@nextPaymentIsTodayInfo"
              variables={{ price: formatedPrice }}
            >
              Você receberá hoje um repasse de <strong>$price</strong>.
            </Translate>
          </Fragment>
        ) : (
          <Fragment>
            <i className="fas fa-long-arrow-alt-right" />{" "}
            <Translate
              id="Affiliate@nextBillClosingInfo"
              variables={{
                date: getNextMonthDateForDay("10").format(
                  i18nStore.dateFormatWithFourDigitsYear
                ),
              }}
            >
              A próxima fatura será fechada no dia $date.
            </Translate>
          </Fragment>
        )}
      </p>
    );
  };

  render() {
    const { classes, affiliateStore, localizedString } = this.props;
    const { selectedDateRange } = this.state;
    const { financialHistoryList } = affiliateStore;

    return (
      <GridContainer className={classes.mainContainer}>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <GridContainer>
                <GridItem xs={12} sm={7}>
                  <CardIcon color="primary">
                    <i className={`fas fa-chart-area ${classes.cardIcon}`} />
                  </CardIcon>
                  <h2 className={classes.cardIconTitle}>
                    <Translate id="Affiliate@dashboardPerformanceSectionTitle">
                      Desempenho
                    </Translate>
                  </h2>
                </GridItem>
                <GridItem xs={12} sm style={{ textAlign: "right" }}>
                  <ButtonGroup
                    name="selectedDateRange"
                    value={selectedDateRange}
                    small
                    onChange={this.onChangeDateRange}
                    options={[
                      {
                        value: "week",
                        label: localizedString({
                          id: "Affiliate@selectedDateRangeWeek",
                          defaultString: "Semana",
                        }),
                      },
                      {
                        value: "month",
                        label: localizedString({
                          id: "Affiliate@selectedDateRangeMonth",
                          defaultString: "Mês",
                        }),
                      },
                      {
                        value: "year",
                        label: localizedString({
                          id: "Affiliate@selectedDateRangeYear",
                          defaultString: "Ano",
                        }),
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <GridContainer>
                <GridItem xs={6}>
                  {this.renderBigConter(
                    this.getChildContractsCountWithSelectedDateRange(),
                    localizedString({
                      id:
                        "Affiliate@dashboardPerformanceDevicesActivationsStatsLabel",
                      defaultString: "ativações",
                    })
                  )}
                </GridItem>
                <GridItem xs={6}>
                  {this.renderBigConter(
                    this.getSalesCountWithSelectedDateRange(),
                    localizedString({
                      id: "Affiliate@dashboardPerformanceSalesStatsLabel",
                      defaultString: "vendas comissionadas",
                    })
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <i className={`fas fa-dollar-sign ${classes.cardIcon}`} />
              </CardIcon>
              <h2 className={classes.cardIconTitle}>
                <Translate id="Affiliate@dashboardRevenuesSectionTitle">
                  Faturamento
                </Translate>
              </h2>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <GridContainer alignItems="center" justify="center">
                <GridItem xs={12} sm={6}>
                  <IfNotFetching
                    isFetching={affiliateStore.isFetching}
                    loadingHeight={50}
                  >
                    {this.renderNextPaymentInfo()}
                  </IfNotFetching>
                </GridItem>
                {today.isSame(invoicePaymentDay) && (
                  <IfNotFetching
                    isFetching={affiliateStore.isFetching}
                    loadingHeight={50}
                  >
                    <GridItem xs={12} sm={6}>
                      <p className={classes.nextBillPeriodInfoLabel}>
                        <i className="fas fa-info-circle" />{" "}
                        <Translate
                          id="Affiliate@nextBillPeriodInfoLabel"
                          variables={{
                            price: formatPriceToText(
                              affiliateStore.getNextBillPeriodValue,
                              true
                            ),
                          }}
                        >
                          Total acumulado para o próximo período de faturamento:{" "}
                          <strong>$price</strong>.
                        </Translate>
                      </p>
                    </GridItem>
                  </IfNotFetching>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <FinancialHistoryTable
          classes={classes}
          isFetching={affiliateStore.isFetching}
          financialHistoryList={financialHistoryList}
        />
        <CommissionHistoricTable classes={classes} />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(AffiliateDashboardContainer);
