// @ts-nocheck

import { Switch, Redirect, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";

import AffiliateLoginPage from "../pages/AffiliateLoginPage";
import AffiliateDashboardPage from "../pages/AffiliateDashboardPage";
import AffiliatePromoRegisterNewContract from "../pages/AffiliatePromoRegisterNewContract";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Style
import pagesStyle from "../assets/jss/js-styles/layouts/pagesStyle";

// Utils
import { isSimplyasServer } from "../utils/EnvUtils";

const AffiliateLayout = inject("affiliateAuthStore")(
  observer(({ classes, affiliateAuthStore }) => {
    return (
      <div className={classes.wrapper}>
        <div>
          <Switch>
            {!isSimplyasServer && <Redirect to="/login" />}

            <Route
              path={["/afiliado", "/affiliate"]}
              component={
                affiliateAuthStore.isAuthenticated
                  ? AffiliateDashboardPage
                  : AffiliateLoginPage
              }
            />
            <Route
              exact
              path={["/facil", "/promo"]}
              component={AffiliatePromoRegisterNewContract}
            />
          </Switch>
        </div>
        <div
          className={classes.fullPageBackground}
          style={{ background: "#E2E2E2", zIndex: -1, height: "100vh" }}
        />
      </div>
    );
  })
);

export default withStyles(pagesStyle)(AffiliateLayout);
