// @ts-nocheck
import { Component } from "react";
import { when } from "mobx";
import { inject, observer } from "mobx-react";
import axios from "axios";
import PropTypes from "prop-types";

import PageTitle from "../components/PageTitle/PageTitle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import YouTubeVideo from "../components/Video/YouTubeVideo";

import Success from "../components/Animations/Success/Success";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import { Button } from "../components/Button";
import { InputText } from "../components/Form";
import Validate, { ValidationType } from "../components/Form/Validate";
import { Card, CardBody, CardHeader, CardFooter } from "../components/Card";
import Modal from "../components/Modal/Modal";
import LoadingView from "../components/LoadingView/LoadingView";
import FloatingChatButton from "../components/Chat/FloatingChatButton";

// Model
import UserModel from "../Model/UserModel";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { isSimplyasServer } from "../utils/EnvUtils";
import {
  analyticsRef,
  serverTimestamp,
  settingsRef,
} from "../store/FirebaseStore/FirebaseStore";

// Style
import {
  primaryColor,
  successColor,
  container,
  cardTitle,
} from "../assets/jss/app-js-styles";
const styles = {
  container: {
    ...container,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF",
  },
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  listItemText: {},
  completedListItemText: {
    fontSize: "0.9em",
    color: successColor,
    textDecoration: "line-through",
  },
  listItemIcon: {
    color: primaryColor,
    width: 8,
  },
  completedListItemIcon: {
    width: 8,
    color: successColor,
  },
};

export const ViewState = Object.freeze({
  CONFIRM_CODE: "confirmCode",
  REGISTER_USER: "registerUser",
  LOGIN_USER: "loginUser",
  REGISTER_CONTRACT: "registerContract",
  WAITING_CONTRACT_REGISTRATION: "waitingContractRegistration",
  SUCCESS: "success",
});

@inject(
  "authStore",
  "affiliateStore",
  "i18nStore",
  "firebaseAuthStore",
  "notificationsStore",
  "modboxAPIService",
  "telegramAPIService"
)
@observer
class AffiliatePromoRegisterNewContract extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewState: ViewState.CONFIRM_CODE,
      affiliateCode: "",
      connectedUser: null,
      invalidCode: false,
      affiliateToken: "",
      masterUserToken: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      contractName: "",
      isWaitingForm: false,
      formWasSubmit: false,
      isValid: {
        affiliateCode: false,
        name: false,
        email: false,
        password: false,
        confirmPassword: false,
        contractName: false,
      },
      terms: null,
      openTermsModal: false,
      pageVideo: null,
    };
  }

  componentDidMount() {
    if (isSimplyasServer) {
      settingsRef
        .child(
          `affiliateVideos/promoPage/${this.props.i18nStore.currentLanguage}`
        )
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            this.setState({ pageVideo: snapshot.val() });
          }
        });
    }

    const urlSearch = this.props.location.search;
    if (urlSearch.includes("?code=")) {
      const code = urlSearch.replace("?code=", "");
      const isValid = code.length === 4;
      this.setState(
        {
          affiliateCode: isValid ? code.toUpperCase() : "",
          isWaitingForm: isValid,
          isValid: {
            ...this.state.isValid,
            affiliateCode: isValid,
          },
        },
        () => {
          this.listenFirebase();
          if (isValid) {
            this.didSubmitForm();
          }
        }
      );
    } else {
      this.listenFirebase();
    }
  }

  listenFirebase = () => {
    when(
      () => this.props.firebaseAuthStore.isAuthenticated,
      () => {
        this.registerAnonymousLog({
          startedAt: serverTimestamp,
        });
      }
    );
  };

  registerAnonymousLog = (data) => {
    if (this.props.firebaseAuthStore.uid) {
      analyticsRef
        .child(`promo/${this.props.firebaseAuthStore.uid}`)
        .update(data);
    }
  };

  postButterflyAnalyticsToThread = (content) => {
    this.props.telegramAPIService.postButterflyAnalyticsToThread(content);
  };

  toggleTermsModal = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState(
      (state) => ({
        openTermsModal: !state.openTermsModal,
      }),
      () => {
        if (!this.state.terms) {
          axios
            .get(`/files/modbox/terms_${this.props.i18nStore.currentLanguage}.txt`)
            .then((response) => {
              this.setState({
                terms: response.data,
              });
            });
        }
      }
    );
  };

  onChangeAffiliateCode = ({ target }) => {
    const affiliateCode = target.value;
    this.setState({
      affiliateCode: affiliateCode.toUpperCase(),
      isValid: {
        ...this.state.isValid,
        affiliateCode: affiliateCode.length === 4,
      },
    });
  };

  onChangeName = ({ target }) => {
    const name = target.value;
    this.setState({
      name,
      isValid: {
        ...this.state.isValid,
        name: Validate(name, ValidationType.REQUIRED),
      },
    });
  };

  onChangeEmail = ({ target }) => {
    const email = target.value;
    this.setState({
      email,
      isValid: {
        ...this.state.isValid,
        email: Validate(email, ValidationType.EMAIL),
      },
    });
  };

  onChangeContractName = ({ target }) => {
    const contractName = target.value;
    this.setState((state) => ({
      contractName,
      isValid: {
        ...state.isValid,
        contractName: contractName.length > 3,
      },
    }));
  };

  onChangePassword = ({ target }) => {
    const password = target.value;
    this.setState((state) => ({
      password,
      isValid: {
        ...state.isValid,
        password: Validate(password, ValidationType.REQUIRED),
        confirmPassword: password === state.confirmPassword,
      },
    }));
  };

  onChangeConfirmPassword = ({ target }) => {
    const confirmPassword = target.value;
    this.setState((state) => ({
      confirmPassword,
      isValid: {
        ...state.isValid,
        confirmPassword: state.password === confirmPassword,
      },
    }));
  };

  canSubmitForm = () => {
    const { viewState, isValid } = this.state;

    switch (viewState) {
      case ViewState.CONFIRM_CODE:
        return isValid.affiliateCode;

      case ViewState.REGISTER_USER:
        return isValid.name && isValid.email && isValid.confirmPassword;

      case ViewState.LOGIN_USER:
        return isValid.email && isValid.password;

      case ViewState.REGISTER_CONTRACT:
      default:
        return isValid.contractName;
    }
  };

  verifyCodeToGetAffiliateData = () => {
    const { affiliateCode } = this.state;
    this.props.affiliateStore
      .verifyCodeToGetAffiliateData(affiliateCode)
      .then(({ affiliateToken, masterUserToken }) => {
        this.setState(
          {
            affiliateToken,
            masterUserToken,
            viewState: ViewState.REGISTER_USER,
            isWaitingForm: false,
            formWasSubmit: false,
            invalidCode: false,
          },
          () => {
            this.registerAnonymousLog({
              code: affiliateCode,
            });
            this.postButterflyAnalyticsToThread(
              `entrou com o Código: ${affiliateCode}`
            );
          }
        );
      })
      .catch(() => {
        this.setState(
          {
            isWaitingForm: false,
            invalidCode: true,
          },
          () => {
            this.props.notificationsStore.addSnackbarNotification({
              message: (
                <Translate id="Affiliate@invalidCodeMessage">
                  O cupom informado é inválido.
                </Translate>
              ),
              timeout: 10000,
              color: "danger",
            });
          }
        );
      });
  };

  requestUser = (userToken) => {
    this.props.modboxAPIService
      .requestUser({
        userToken,
      })
      .then((userResponse) => {
        const userData = userResponse.data;
        if (userData.status === "True") {
          const connectedUser = new UserModel({
            ...userData,
            token: userToken,
          });

          this.setState({
            viewState: ViewState.REGISTER_CONTRACT,
            isWaitingForm: false,
            formWasSubmit: false,
            connectedUser,
          });
        } else {
          this.setState(
            { isWaitingForm: false },
            this.props.notificationsStore.addSnackbarNotification({
              message: (
                <Translate id="Login@userIsInactiveMessage">
                  Seu cadastro está inativo! Entre em contato com o suporte.
                </Translate>
              ),
              timeout: 10000,
              color: "warning",
            })
          );
        }
      });
  };

  loginUser = () => {
    const { email, password } = this.state;

    this.props.modboxAPIService
      .submitLogin({
        email,
        password,
      })
      .then((loginResponse) => {
        return loginResponse.data.token;
      })
      .then((userToken) => {
        this.registerAnonymousLog({
          didLogin: true,
          email,
        });
        this.postButterflyAnalyticsToThread(
          `submitLogin em /promo\nE-mail: ${email}`
        );
        this.requestUser(userToken);
      })
      .catch((error) => {
        this.setState({ isWaitingForm: false });
      });
  };

  submitUser = () => {
    const { name, email, password } = this.state;

    this.props.modboxAPIService
      .submitUser({
        name,
        email,
        password,
      })
      .then((userResponse) => {
        return userResponse.data.token;
      })
      .then((userToken) => {
        this.registerAnonymousLog({
          didCreateNewAccount: true,
          email,
        });
        this.requestUser(userToken);

        this.postButterflyAnalyticsToThread(
          `submitUser em /promo\nE-mail: ${email}`
        );
      })
      .catch(() => {
        this.setState({
          isWaitingForm: false,
        });
      });
  };

  submitContract = () => {
    const { modboxAPIService } = this.props;
    const {
      connectedUser,
      affiliateToken,
      masterUserToken,
      contractName,
    } = this.state;

    this.setState(
      {
        viewState: ViewState.WAITING_CONTRACT_REGISTRATION,
      },
      () => {
        modboxAPIService
          .submitContract(
            {
              userToken: masterUserToken,
              companyToken: affiliateToken,
              name: contractName,
              language: connectedUser.language,
              isActive: true,
            },
            true
          )
          .then((response) => response.data.contract_hash)
          .then((contractHash) => {
            modboxAPIService
              .submitRuleForNewAffiliateContract({
                user_token: masterUserToken,
                contract_hash: contractHash,
                language: connectedUser.language,
                name: connectedUser.name,
                email: connectedUser.email,
                access_devices: true,
                access_locations: true,
                access_informative: true,
                access_connectivity: true,
                access_administrative: true,
                access_advertising: true,
                access_api: false,
                access_support: true,
              })
              .then(() => {
                // Register child contract
                this.props.affiliateStore.didRegisterNewChildConctract({
                  affiliateToken,
                  masterUserToken,
                  contractHash,
                });

                this.setState(
                  {
                    viewState: ViewState.SUCCESS,
                  },
                  () => {
                    this.registerAnonymousLog({
                      didCreateNewContract: true,
                      contractName,
                      contractHash,
                    });

                    this.postButterflyAnalyticsToThread(
                      `**Novo contrato butterfly**\n\n**Nome contrato:** ${contractName}\n**Hash:** ${contractHash}\n**Nome usuário:** ${connectedUser.name}\n**E-mail:** ${connectedUser.email}`
                    );
                    setTimeout(() => {
                      // Login the user
                      this.props.authStore.didLogin(connectedUser);
                    }, 2000);
                  }
                );
              });
          })
          .catch((error) => {
            this.setState(
              {
                isWaitingForm: false,
                viewState: ViewState.REGISTER_CONTRACT,
              },
              () => {
                if (error) {
                  this.props.notificationsStore.addSnackbarNotification({
                    message: error.message,
                    timeout: 10000,
                    color: "danger",
                  });
                }
              }
            );
          });
      }
    );
  };

  didSubmitForm = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!this.canSubmitForm()) {
      return false;
    }

    const { viewState } = this.state;

    this.setState(
      {
        formWasSubmit: true,
        isWaitingForm: true,
      },
      () => {
        if (viewState === ViewState.CONFIRM_CODE) {
          this.verifyCodeToGetAffiliateData();
        } else if (viewState === ViewState.LOGIN_USER) {
          this.loginUser();
        } else if (viewState === ViewState.REGISTER_USER) {
          this.submitUser();
        } else if (viewState === ViewState.REGISTER_CONTRACT) {
          this.submitContract();
        }
      }
    );
  };

  renderCompletedListItem = (label) => {
    const { classes } = this.props;
    return (
      <ListItem divider disabled>
        <ListItemIcon classes={{ root: classes.completedListItemIcon }}>
          <i className="fas fa-check" />
        </ListItemIcon>
        <ListItemText
          primary={label}
          classes={{ primary: classes.completedListItemText }}
        />
      </ListItem>
    );
  };

  renderCurrentListItem = (label) => {
    const { classes } = this.props;
    return (
      <ListItem>
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <i className="fas fa-hand-point-right" />
        </ListItemIcon>
        <ListItemText
          primary={label}
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
    );
  };

  renderPageTitle = () => {
    let id = "";
    let title = "";

    switch (this.state.viewState) {
      case ViewState.CONFIRM_CODE:
        id = "Affiliate@getCodePageTitle";
        title = "Informe o cupom";
        break;

      case ViewState.REGISTER_USER:
        id = "Affiliate@registerUserPageTitle";
        title = "Faça seu cadastro";
        break;

      case ViewState.LOGIN_USER:
        id = "Affiliate@loginrUserPageTitle";
        title = "Login";
        break;

      case ViewState.REGISTER_CONTRACT:
      default:
        id = "Affiliate@registerContractPageTitle";
        title = "Escolha nome do contrato";
        break;
    }

    return <PageTitle id={id}>{title}</PageTitle>;
  };

  renderTermsOfUse = () => (
    <GridItem xs={12} style={{ marginTop: 30 }}>
      <p style={{ textAlign: "center", fontSize: "0.9em" }}>
        <Translate id="Register@termsOfUse">
          Ao cadastrar você declara que leu e aceita os{" "}
          <a href="#terms" onClick={this.toggleTermsModal}>
            Termos de uso
          </a>
          .
        </Translate>
      </p>
    </GridItem>
  );

  render() {
    if (!this.props.firebaseAuthStore.isAuthenticated) {
      return <LoadingView />;
    }

    const { classes } = this.props;
    const {
      viewState,
      affiliateCode,
      name,
      email,
      password,
      confirmPassword,
      contractName,
      isValid,
      isWaitingForm,
      formWasSubmit,
    } = this.state;

    const showVideo =
      this.state.pageVideo &&
      viewState !== ViewState.WAITING_CONTRACT_REGISTRATION &&
      viewState !== ViewState.SUCCESS;

    return (
      <div className={classes.content}>
        {this.renderPageTitle()}
        <Modal
          isOpen={this.state.openTermsModal}
          didCloseModal={this.toggleTermsModal}
          modalTitle={<Translate id="termsOfUse">Termos de uso</Translate>}
          maxWidth="md"
        >
          {this.state.terms ? <p>{this.state.terms}</p> : <LoadingView />}
        </Modal>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={8}>
              <form onSubmit={this.didSubmitForm}>
                <Card login>
                  {showVideo && (
                    <CardHeader style={{ paddingTop: 20, paddingBottom: 20 }}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <YouTubeVideo
                            videoId={this.state.pageVideo}
                            autoplay
                            disableKeyboard
                            width={"100%"}
                            height={380}
                            showControls
                          />
                        </GridItem>
                      </GridContainer>
                    </CardHeader>
                  )}
                  <CardBody>
                    {viewState === ViewState.CONFIRM_CODE && (
                      <GridContainer justify="center">
                        <GridItem xs={12}>
                          <List>
                            {this.renderCurrentListItem(
                              isWaitingForm ? (
                                <Translate id="Affiliate@checkingCodeLabel">
                                  Aguarde, validando cupom...
                                </Translate>
                              ) : (
                                <Translate id="Affiliate@insertCodeStepLabel">
                                  Informe o cupom
                                </Translate>
                              )
                            )}
                          </List>
                        </GridItem>
                        <GridItem xs={10}>
                          <InputText
                            label={
                              <Translate id="Affiliate@codeLabel">
                                Cupom
                              </Translate>
                            }
                            name="affiliateCode"
                            value={affiliateCode}
                            disabled={isWaitingForm}
                            maxLength={4}
                            autoFocus
                            rightIcon={
                              <InputAdornment position="end">
                                {isValid.affiliateCode ? (
                                  <i className="fas fa-check" />
                                ) : (
                                  <i className="fas fa-hand-point-left" />
                                )}
                              </InputAdornment>
                            }
                            hasError={this.state.invalidCode}
                            hasSuccess={isValid.affiliateCode}
                            onChange={this.onChangeAffiliateCode}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    {[ViewState.REGISTER_USER, ViewState.LOGIN_USER].includes(
                      viewState
                    ) && (
                      <GridContainer justify="center">
                        <GridItem xs={12}>
                          <List>
                            {this.renderCompletedListItem(
                              <Translate id="Affiliate@insertCodeStepLabel">
                                Informe o cupom
                              </Translate>
                            )}
                            {this.renderCurrentListItem(
                              <Translate id="Affiliate@registerUserStepLabel">
                                Faça seu cadastro / login
                              </Translate>
                            )}
                          </List>
                        </GridItem>
                        <GridItem xs={10}>
                          {viewState === ViewState.REGISTER_USER && (
                            <InputText
                              label={
                                <Translate id="Common@name">Nome</Translate>
                              }
                              name="name"
                              value={name}
                              autoFocus
                              hasError={formWasSubmit && !isValid.name}
                              hasSuccess={formWasSubmit && isValid.name}
                              onChange={this.onChangeName}
                              rightIcon={
                                <InputAdornment position="end">
                                  <i
                                    className={`fas fa-user ${classes.inputAdornmentIcon}`}
                                  />
                                </InputAdornment>
                              }
                            />
                          )}
                          <InputText
                            label={
                              <Translate id="Login@email">E-mail</Translate>
                            }
                            name="email"
                            value={email}
                            inputProps={{
                              type: "email",
                            }}
                            rightIcon={
                              <InputAdornment position="end">
                                <i
                                  className={`fas fa-envelope ${classes.inputAdornmentIcon}`}
                                />
                              </InputAdornment>
                            }
                            autoFocus={viewState !== ViewState.REGISTER_USER}
                            hasError={formWasSubmit && !isValid.email}
                            hasSuccess={formWasSubmit && isValid.email}
                            onChange={this.onChangeEmail}
                          />
                          <InputText
                            label={
                              <Translate id="Login@password">Senha</Translate>
                            }
                            name="password"
                            value={password}
                            inputProps={{
                              type: "password",
                              autoComplete: "off",
                            }}
                            rightIcon={
                              <InputAdornment position="end">
                                <i
                                  className={`fas fa-key ${classes.inputAdornmentIcon}`}
                                />
                              </InputAdornment>
                            }
                            hasError={formWasSubmit && !isValid.password}
                            hasSuccess={formWasSubmit && isValid.password}
                            onChange={this.onChangePassword}
                          />
                          {viewState === ViewState.REGISTER_USER && (
                            <InputText
                              label={
                                <Translate id="Common@confirmPassword">
                                  Confirme a senha
                                </Translate>
                              }
                              name="confirmPassword"
                              value={confirmPassword}
                              inputProps={{
                                type: "password",
                                autoComplete: "off",
                              }}
                              rightIcon={
                                <InputAdornment position="end">
                                  <i
                                    className={`fas fa-key ${classes.inputAdornmentIcon}`}
                                  />
                                </InputAdornment>
                              }
                              hasError={
                                formWasSubmit && !isValid.confirmPassword
                              }
                              hasSuccess={
                                formWasSubmit && isValid.confirmPassword
                              }
                              onChange={this.onChangeConfirmPassword}
                            />
                          )}
                        </GridItem>
                      </GridContainer>
                    )}
                    {viewState === ViewState.REGISTER_CONTRACT && (
                      <GridContainer justify="center">
                        <GridItem xs={12}>
                          <List>
                            {this.renderCompletedListItem(
                              <Translate id="Affiliate@insertCodeStepLabel">
                                Informe o cupom
                              </Translate>
                            )}
                            {this.renderCompletedListItem(
                              <Translate id="Affiliate@registerUserStepLabel">
                                Faça seu cadastro
                              </Translate>
                            )}
                            {this.renderCurrentListItem(
                              <Translate id="Affiliate@registerContractPageTitle">
                                Escolha um nome para o seu contrato
                              </Translate>
                            )}
                          </List>
                        </GridItem>
                        <GridItem xs={10}>
                          <InputText
                            label={
                              <Translate id="Affiliate@registerContractTitleLabel">
                                Nome do contrato
                              </Translate>
                            }
                            name="contractName"
                            value={contractName}
                            inputProps={{
                              autoComplete: "off",
                            }}
                            autoFocus
                            hasError={formWasSubmit && !isValid.contractName}
                            hasSuccess={formWasSubmit && isValid.contractName}
                            minLength={4}
                            onChange={this.onChangeContractName}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    {viewState === ViewState.WAITING_CONTRACT_REGISTRATION && (
                      <CardFooter>
                        <GridContainer justify="center">
                          <GridItem xs={12}>
                            <p
                              style={{
                                color: successColor,
                                fontSize: "1.2em",
                                textAlign: "center",
                              }}
                            >
                              <Translate id="Affiliate@registerContractInProgressLabel">
                                Aguarde, estamos configurando seu contrato...
                              </Translate>
                            </p>
                          </GridItem>
                          <GridItem xs={10}>
                            <LoadingView />
                          </GridItem>
                        </GridContainer>
                      </CardFooter>
                    )}
                    {viewState === ViewState.SUCCESS && (
                      <CardFooter>
                        <GridContainer justify="center">
                          <GridItem xs={12}>
                            <Success loop={false} height={120} />
                          </GridItem>
                        </GridContainer>
                      </CardFooter>
                    )}
                  </CardBody>
                  {viewState === ViewState.CONFIRM_CODE && (
                    <CardFooter>
                      <GridContainer justify="center">
                        <GridItem xs={10}>
                          <Button
                            type="submit"
                            color="primary"
                            block
                            disabled={!isValid.affiliateCode}
                            isLoading={isWaitingForm}
                          >
                            <Translate id="Affiliate@confirmCodeButton">
                              Continuar
                            </Translate>{" "}
                            <i className="fas fa-check-circle" />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  )}
                  {viewState === ViewState.REGISTER_USER && (
                    <CardFooter>
                      <GridContainer justify="center">
                        <GridItem xs={10}>
                          <Button
                            type="submit"
                            color="primary"
                            block
                            disabled={
                              !isValid.name ||
                              !isValid.email ||
                              !isValid.confirmPassword
                            }
                            isLoading={isWaitingForm}
                          >
                            <Translate id="Register@registerUserButton">
                              Cadastrar
                            </Translate>{" "}
                            <i className="fas fa-user-plus" />
                          </Button>
                          {!isWaitingForm && (
                            <Button
                              color="primary"
                              outline
                              block
                              onClick={() =>
                                this.setState({
                                  viewState: ViewState.LOGIN_USER,
                                })
                              }
                            >
                              <Translate id="Register@backToLoginButton">
                                Já possui uma conta? Faça login
                              </Translate>
                            </Button>
                          )}
                        </GridItem>
                        {this.renderTermsOfUse()}
                      </GridContainer>
                    </CardFooter>
                  )}
                  {viewState === ViewState.LOGIN_USER && (
                    <CardFooter>
                      <GridContainer justify="center">
                        <GridItem xs={10}>
                          <Button
                            type="submit"
                            color="primary"
                            block
                            disabled={!isValid.email || !isValid.password}
                            isLoading={isWaitingForm}
                          >
                            <Translate id="Login@signInButton">
                              Entrar
                            </Translate>{" "}
                            <i className="fas fa-sign-in-alt" />
                          </Button>
                          {!isWaitingForm && (
                            <Button
                              color="primary"
                              outline
                              block
                              onClick={() =>
                                this.setState({
                                  viewState: ViewState.REGISTER_USER,
                                })
                              }
                            >
                              <Translate id="Register@backToRegisterUserButton">
                                Cadastrar novo acesso
                              </Translate>
                            </Button>
                          )}
                        </GridItem>
                        {this.renderTermsOfUse()}
                      </GridContainer>
                    </CardFooter>
                  )}
                  {viewState === ViewState.REGISTER_CONTRACT && (
                    <CardFooter>
                      <GridContainer justify="center">
                        <GridItem xs={10}>
                          <Button
                            type="submit"
                            color="primary"
                            block
                            disabled={!isValid.contractName}
                            isLoading={isWaitingForm}
                          >
                            <Translate id="Affiliate@registerContractButton">
                              Criar contrato
                            </Translate>{" "}
                            <i className="fas fa-check-circle" />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardFooter>
                  )}
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
        <FloatingChatButton />
      </div>
    );
  }
}

AffiliatePromoRegisterNewContract.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AffiliatePromoRegisterNewContract);
