// @ts-nocheck
import { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Fade } from "react-reveal";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import { Button } from "../components/Button";
import FloatingChatButton from "../components/Chat/FloatingChatButton";

import YouTubeVideo from "../components/Video/YouTubeVideo";

// Translate
import Translate from "../components/I18n/Translate";

// Utils
import { isSimplyasServer } from "../utils/EnvUtils";
import { settingsRef } from "../store/FirebaseStore/FirebaseStore";

// Style
const styles = {
  welcomeMessage: {
    fontSize: "1.2em",
    marginBottom: 20,
  },
};

@inject("i18nStore", "affiliateStore", "contractStore")
@observer
class WelcomeLayout extends Component {
  state = { pageVideo: null };

  componentDidMount() {
    if (isSimplyasServer) {
      settingsRef
        .child(
          `affiliateVideos/welcomePage/${this.props.i18nStore.currentLanguage}`
        )
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            this.setState({ pageVideo: snapshot.val() });
          }
        });
    }
  }

  goToActivateDeviceStep = () => {
    const { affiliateStore, contractStore } = this.props;
    contractStore.setStatusForDidFinishedWelcomeStep(true);
    contractStore.setStatusForDidFinishedInstallSystemStep(true);

    affiliateStore.didSetChildContractCommissionFeePercentage(
      contractStore.selectedContract.hash,
      0.2
    );
  };

  goToInstallSystemStep = () => {
    const { affiliateStore, contractStore } = this.props;

    contractStore.setStatusForDidFinishedWelcomeStep(true);
    affiliateStore.didSetChildContractCommissionFeePercentage(
      contractStore.selectedContract.hash,
      0.1
    );
  };

  render() {
    const { classes } = this.props;
    const pageVideo = this.state.pageVideo;

    return (
      <Fragment>
        <PageTitle id="PageTitle@welcome">Bem vindo!</PageTitle>
        <GridContainer
          justify="center"
          direction="row"
          style={{ height: "100vh" }}
        >
          <GridItem xs={11}>
            <Fade>
              <SimpleCard>
                <GridContainer justify="center">
                  <GridItem xs={12} style={{ textAlign: "center" }}>
                    <h1>
                      <Translate id="Welcome@pageTitle">
                        Seja bem vindo!
                      </Translate>
                    </h1>
                    <p className={classes.welcomeMessage}>
                      <Translate id="Welcome@pageSubtitle">
                        Vamos ativar seu primeiro dispositivo? Escolha uma opção
                        abaixo para continuar:
                      </Translate>
                    </p>
                  </GridItem>

                  {pageVideo && (
                    <GridItem xs={12}>
                      <YouTubeVideo
                        videoId={pageVideo}
                        autoplay
                        disableKeyboard
                        width={"100%"}
                        height={500}
                      />
                    </GridItem>
                  )}

                  <GridItem xs={12} style={{ marginBottom: 30 }}>
                    <Button
                      color="primary"
                      block
                      large
                      onClick={this.goToInstallSystemStep}
                    >
                      <Translate id="Welcome@goToInstallSystemStepButtonLabel">
                        Preciso de ajuda para instalar o sistema no meu cartão
                        cartão de memória
                      </Translate>{" "}
                      <i className="fas fa-info-circle" />
                    </Button>
                  </GridItem>

                  <GridItem xs={12} style={{ marginBottom: 30 }}>
                    <Button block large onClick={this.goToActivateDeviceStep}>
                      <Translate id="Welcome@goToActivateDeviceStepButtonLabel">
                        Pular para a etapa de configuração do sistema
                      </Translate>{" "}
                      <i className="fas fa-long-arrow-alt-right" />
                    </Button>
                  </GridItem>
                </GridContainer>
              </SimpleCard>
            </Fade>
          </GridItem>
        </GridContainer>
        <FloatingChatButton />
      </Fragment>
    );
  }
}

WelcomeLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WelcomeLayout);
