// @ts-nocheck
import { Component } from "react";
import { inject, observer } from "mobx-react";

import { withLocalized } from "../../components/HOC";

import { Card, CardBody, CardHeader, CardIcon } from "../../components/Card";
import { GridItem } from "../../components/Grid";
import IfNotFetching from "../../components/IfNotFetching/IfNotFetching";
import Table from "../../components/Table/Table";

import { formatPriceToText } from "../../components/Price/Price";

// Translate
import { NothingFoundString } from "../../components/I18n/CommonStrings";

// Utils
import { stringDateFromTimestamp } from "../../utils/DateUtils";

// style
import { successColor } from "../../assets/jss/app-js-styles";

@withLocalized
@inject("affiliateStore", "i18nStore")
@observer
class CommissionHistoricTable extends Component {
  render() {
    const { classes, affiliateStore, i18nStore, localizedString } = this.props;
    const { isFetching, ordersList } = affiliateStore;

    return (
      <GridItem xs={12} sm={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <i className={`fas fa-money-bill-alt ${classes.cardIcon}`} />
            </CardIcon>
            <h2 className={classes.cardIconTitle}>
              Histórico das vendas comissionadas
            </h2>
          </CardHeader>
          <CardBody className={classes.cardBody}>
            <IfNotFetching isFetching={isFetching} loadingHeight={50}>
              {ordersList.length > 0 ? (
                <Table
                  data={ordersList.map((item) => {
                    return {
                      date: stringDateFromTimestamp(
                        item.timestamp,
                        `${i18nStore.dateFormatWithTwoDigitsYear} ${i18nStore.timeFormat}`
                      ),
                      price: (
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {formatPriceToText(item.price, true)}
                        </p>
                      ),
                      commissionFeePercentage: (
                        <span
                          style={{ fontWeight: 300, fontSize: "0.9em" }}
                        >{`x ${item.commissionFeePercentage * 100}%`}</span>
                      ),
                      commission: (
                        <p
                          style={{
                            fontWeight: "bold",
                            color: successColor,
                            margin: 0,
                          }}
                        >
                          +{" "}
                          {formatPriceToText(
                            item.price * item.commissionFeePercentage,
                            true
                          )}
                        </p>
                      ),
                    };
                  })}
                  columns={[
                    {
                      Header: localizedString({
                        id: "Common@date",
                        defaultString: "Data",
                      }),
                      sortable: false,
                      accessor: "date",
                    },
                    {
                      Header: localizedString({
                        id: "Affiliate@dashboardCommissionTablePriceLabel",
                        defaultString: "Valor venda",
                      }),
                      sortable: false,
                      accessor: "price",
                      width: 160,
                      getHeaderProps: () => ({
                        style: {
                          textAlign: "right",
                          marginRight: 16,
                        },
                      }),
                      getProps: () => ({
                        style: {
                          textAlign: "right",
                          marginRight: 16,
                        },
                      }),
                    },
                    {
                      Header: "%",
                      sortable: false,
                      accessor: "commissionFeePercentage",
                      width: 60,
                      getHeaderProps: () => ({
                        style: {
                          textAlign: "right",
                          marginRight: 16,
                        },
                      }),
                      getProps: () => ({
                        style: {
                          textAlign: "right",
                          marginRight: 16,
                        },
                      }),
                    },
                    {
                      Header: localizedString({
                        id: "Affiliate@dashboardCommissionLabel",
                        defaultString: "Valor comissão",
                      }),
                      sortable: false,
                      accessor: "commission",
                      width: 140,
                      getHeaderProps: () => ({
                        style: {
                          textAlign: "right",
                        },
                      }),
                      getProps: () => ({
                        style: {
                          textAlign: "right",
                        },
                      }),
                    },
                  ]}
                />
              ) : (
                <p style={{ textAlign: "center" }}>
                  <NothingFoundString />
                </p>
              )}
            </IfNotFetching>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default CommissionHistoricTable;
